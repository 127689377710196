import React from 'react';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemAvatar, ListItemText, Checkbox, ListItemSecondaryAction } from '@material-ui/core';
const DocsFileList = ({linkFilesInfo, selectLinkFileFn, linkFileToSectionFn, toggleModalFn}) => {
  return(
    <Dialog
      open={linkFilesInfo.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        Contact
      </DialogTitle>
      <DialogContent dividers={true}>
      <List>
        { linkFilesInfo.fileInfos.list && linkFilesInfo.fileInfos.list.map((item, index) =>
          <ListItem key={index} role={undefined} dense button>
            <ListItemAvatar>
              <Avatar
                alt=''
                src={null}
              />
            </ListItemAvatar>
            <ListItemText id={item.id} primary={item.name} secondary={item.location} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={() => selectLinkFileFn(item.id)}
                checked={linkFilesInfo.selected.includes(item.id)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
        <Button onClick={linkFileToSectionFn} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}
export default DocsFileList;
