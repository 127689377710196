import React from 'react';
import { regexConstants, errorMsg1 } from '../constants/regEx';
// Error Code Object
export const findingErrorCode = {
  finding_type: {
    0: '',
    1:'Please select Damage Type'
  },
  finding_typeObj: {
    required: true
  },
  damage_dimension: {
    0:'',
    4: errorMsg1
  },
  damage_dimensionObj:{
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  damage_limit: {
    0:'',
    4: errorMsg1
  },
  damage_limitObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  remarks: {
    0: '',
    1: 'Please enter Description',
    4: errorMsg1
  },
  remarksObj: {
    required: true,
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  dd_wo_reference: {
    0:'',
    4: errorMsg1
  },
  dd_wo_referenceObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  arm_srm_reference: {
    0:'',
    4: errorMsg1
  },
  arm_srm_referenceObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  corrective_actions: {
    0:'',
    4: errorMsg1
  },
  corrective_actionsObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  intervals: {
    0:'',
    4: errorMsg1
  },
  intervalsObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  }
}
export const contactObj = {
  full_name: '',
  email: '',
  phone_number: '',
  fax: '',
  title: ''
}
export const contactFilterOps = {
  name: {
    inputType: 'text',
    placeholder: 'Search by Name',
    title:'Name'
  },
  email: {
    inputType: 'text',
    placeholder: 'Search by Email',
    title:'Email'
  }
}
export const robberyObj = {
  part_number:'',
  removal_date:null,
  part_description:'',
  part_location:'',
  reason_for_removal:'',
  date_verified_removed_from_aircraft:null,
  date_verified_installed_on_aircraft:null,
  date_verified_in_store_house:null,
  price:'',
  work_card_no:'',
  comments:''
}
export const robberyFilterOps = {
  part_number: {
    inputType: 'text',
    placeholder: 'Search by Part Number',
    title:'Part Number'
  },
  part_description: {
    inputType: 'text',
    placeholder: 'Search by Part Description',
    title:'Part Description'
  },
  part_location: {
    inputType: 'text',
    placeholder: 'Search by Part Location',
    title:'Part Location'
  }
}

export const t011GapReportObj = {
  description: '',
  started_date: '',
  estimated_completion_date: '',
  requirement_remarks: '',
  background_remarks: '',
  image_url: null,
  priority:null,
  assignee:{name:'None', id:0},
  gap_report_section_id:''
}
export const prsRptHd1 = [
  { label: 'Item', sortOption: false },
  { label: 'Action', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Image', sortOption: false },
  { label: 'Priority', sortOption: false },
  { label: 'Disposition', sortOption: false },
  { label: 'Started Date', sortOption: false },
  { label: 'Completed Date', sortOption: false },
  { label: <table style={{width: '100%'}}><thead><tr><th colSpan="2" align="center">Discrepancy</th></tr><tr><th align="center">Requirement</th><th align="center">Background</th></tr></thead></table>, sortOption: false },
  { label: <table style={{width: '100%'}}><thead><tr><th colSpan="3" align="center">Comments</th></tr><tr><th align="center">Project Lead/Engineer</th><th align="center">Current Lessee</th><th align="center">Next Lessee</th></tr></thead></table>, sortOption: false },
  { label: 'Assignee', sortOption: false },
]
export const prsRptHd2 = [
  { label: 'Item', sortOption: false },
  { label: 'Action', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Image', sortOption: false },
  { label: 'Priority', sortOption: false },
  { label: 'P/N', sortOption: false },
  { label: 'S/N', sortOption: false },
  { label: 'Disposition', sortOption: false },
  { label: 'Started Date', sortOption: false },
  { label: 'Completed Date', sortOption: false },
  { label: <table style={{width: '100%'}}><thead><tr><th colSpan="2" align="center">Discrepancy</th></tr><tr><th align="center">Requirement</th><th align="center">Background</th></tr></thead></table>, sortOption: false },
  { label: <table style={{width: '100%'}}><thead><tr><th colSpan="3" align="center">Comments</th></tr><tr><th align="center">Project Lead/Engineer</th><th align="center">Current Lessee</th><th align="center">Next Lessee</th></tr></thead></table>, sortOption: false },
  { label: 'Install Date', sortOption: false },
  { label: 'Position', sortOption: false },
  { label: 'Assignee', sortOption: false },

]
export const prsRptFilterOps = {
  disposition:{
    inputType: 'dropdown',
    placeholder: 'Select Disposition',
    options: [{label:'Open',value:0},{label:'Close',value:1}],
    labelKey: 'label',
    valueKey: 'value',
    title:'Disposition',
    multiple: false
  },
  priority: {
    inputType: 'dropdown',
    placeholder: 'Select Priority',
    options: [{label:'P1',value:1},{label:'P2',value:2}],
    labelKey: 'label',
    valueKey: 'value',
    title:'Priority',
    multiple: false
  }
}
export const accessoriesList = {
  ata: null,
  description: null,
  delivery_part_number: null,
  delivery_serial_number: null,
  installed_part_number: null,
  installed_serial_number: null,
  tsn: null,
  csn: null,
  remarks: null
}
export const additionalData = {
  description: null,
  item: null,
  part_number: null,
  remarks: null,
  serial_number: null
}
export const missingHardware = {
  description: null,
  ata: null,
  part_number: null,
  remarks: null,
  serial_number: null
}
export const recordDetail = {
  description: null,
  acumen_remarks: null,
  status: 1,
  comments:null,
  ref_document: null,
  operator_comments: null,
  engine_gap_report_section: null,
  engine_work_order: null
}
export const llpDetail = {
  description: null,
  part_number: null,
  serial_number: null,
  cycles_remaining: null,
  acumen_remarks: null,
  status: 1,
  comments:null,
  ref_document: null,
  operator_comments: null,
  engine_gap_report_section: null,
  engine_work_order: null
}
export const llpDetail005 = {
  delAttachmentModal: false,
  deleteId: '',
  deleteIndex: '',
  preReqModal: false,
  deleteType: '',
  description : '',
  part_number: '',
  serial_number: '',
  csn: '',
  cycles_remaining: '',
  status: '',
  remarks:'',
  engine_work_order: ''
}
export const mrSection = {
  content: '',
  title: ''
}

export const createProject = {
  name: '',
  start_date: null,
  expected_end_date: null,
  leads:[],
  engineers:[],
  operator_id:'',
  lessee_id:'',
  assets: [],
  leads: [],
  location:'',
  third_party: '',
  cor_required: true,
  project_form: 0,
  contacts:[{name: '', type:null, email:'',designation:'', phone_number: ''}],
  client_contacts:[
    {name: '', email:'',designation:'', phone_number: ''}
  ],
  operator_contacts:[
    {name: '', email:'',designation:'', phone_number: ''}
  ],
  third_party_contacts: [
    {name: '', email:'',designation:'', phone_number: ''}
  ],
  instructions: '',
  miscellaneous_comments:'',
  equipment_requirements: ''
}
export const aircraftWorkOrder = {
  aircraft_id:'',
  start_date: null,
  expected_end_date: null,
  aircraft_type_id:null,
  blueprint_id: null,
  lease_start_date:null,
  date_of_manufacture:null,
  tsn:'',
  csn:'',
  location:'',
  registration:'',
  line_number:'',
  status_as_of:null,
  operator_storage_facility:'',
  aoc_regulation:'',
  no_of_gears:3,
  forms:[],
  inspection_types:null,
  no_of_galleys: {fwd:1 ,mid:0, aft:1},
  no_of_lavatories: {fwd:1,mid:0, aft:1},
  records_inspection_on_start_date:null,
  records_inspection_on_end_date:null,
  physical_inspection_on_start_date:null,
  physical_inspection_on_end_date:null,
  create_msn:false
}
export const alpWorkOrder = {
  create_msn:false,
  start_date: null,
  expected_end_date: null,
  lease_start_date:null,
  date_of_manufacture:null,
  tsn:'',
  csn:'',
  location:'',
  status_as_of:null,
  forms:[],
  inspection_types:null,
  records_inspection_on_start_date:null,
  records_inspection_on_end_date:null,
  physical_inspection_on_start_date:null,
  physical_inspection_on_end_date:null,
}
export const engineWorkOrder = {
  create_esn:false,
  ad_status: null,
  aircraft_msn: null,
  aircraft_registration: null,
  aircraft_type_id: null,
  as_on_date: null,
  attachments: [],
  build_standard: null,
  country:null,
  constructed_model: null,
  cshi: null,
  cslv: null,
  csn: null,
  cso: null,
  csr: null,
  date_of_inspection: null,
  date_of_installation: null,
  ectm_detail: null,
  end_of_lease: null,
  engine_id: null,
  engine_manufacturing_date:null,
  engine_status: 1,
  engine_type_id: null,
  esn: null,
  forms: [],
  inspection_cshi: null,
  inspection_cslv: null,
  inspection_csn: null,
  inspection_cso: null,
  inspection_csr: null,
  inspection_details: null,
  inspection_performed_by: null,
  inspection_tshi: null,
  inspection_tslv: null,
  inspection_tsn: null,
  inspection_tso: null,
  inspection_tsr: null,
  install_position: 1,
  lang: null,
  lat: null,
  lease_contract: null,
  lease_return_standard: null,
  llp_status_sheet: null,
  location: null,
  mpa_test_margin: null,
  mr: null,
  mr_available_for_erp: null,
  mr_available_for_llp: null,
  operation: null,
  owned_by: null,
  owned_by_type: 1,
  progress: 0,
  project: null,
  qme: null,
  ratio: null,
  reference_links: [],
  remarks: null,
  sb_status: null,
  shop_visits: [],
  status: 1,
  tshi: null,
  tslv: null,
  tsn: null,
  tso: null,
  tsr: null,
}
export const engineShopVist = {
  added_from: 0,
  arc_date: null,
  attachments: [],
  build_target: "",
  cshi: null,
  cslsv: null,
  csn: null,
  cso: null,
  csr: null,
  date_of_reinstallation: null,
  date_of_removal: null,
  engine_work_order: 0,
  final_workscope: "",
  induction_date: null,
  llp_status: "",
  reason_for_removal: "",
  shop_facility: "",
  shop_visit_number: "",
  status: 0,
  test_run_egt_margin: "",
  total_air_temperature: "",
  tshi: null,
  tslsv: null,
  tsn: null,
  tso: null,
  tsr: null,
}
export const projectContactHd = [
  { label: 'Type', sortOption: false },
  { label: 'Name', sortOption: false },
  { label: 'Email', sortOption: false },
  { label: 'Designation', sortOption: false },
  { label: 'Phone', sortOption: false },
  { label: '', sortOption: false },
]
export const te001ComponenttHd = [
  { label: 'SI No.', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Findings', sortOption: false },
  { label: 'Status', sortOption: false }
];
export const te002AccessoriesListHd = [
  { label: 'ATA', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: <table style={{width:'100%'}}><thead><tr><th colSpan="2" align="center">Delivery</th></tr><tr><th align="center">PN</th><th align="center">SN</th></tr></thead></table>, sortOption: false },
  { label: <table style={{width:'100%'}}><thead><tr><th colSpan="2" align="center">Installed</th></tr><tr><th align="center">PN</th><th align="center">SN</th></tr></thead></table>, sortOption: false },
  { label: 'TSN', sortOption: false },
  { label: 'CSN', sortOption: false },
  { label: 'Remarks', sortOption: false }
];
export const te002AdditionalDataHd = [
  { label: 'Item No.', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'P/N', sortOption: false },
  { label: 'S/N', sortOption: false },
  { label: 'Remarks', sortOption: false }
];
export const te002MissingHardwareHd = [
  { label: 'ATA', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'P/N', sortOption: false },
  { label: 'S/N', sortOption: false },
  { label: 'Remarks', sortOption: false },
];
export const te003RecordDetailHd = [
  { label: 'Item No', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Remarks	', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'Comments/Notes	', sortOption: false },
  { label: 'Ref Documents	', sortOption: false },
  { label: 'Operator Comments	', sortOption: false },
];
export const te003LLPDetailHd = [
  { label: 'Item No', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'P/N', sortOption: false },
  { label: 'S/N', sortOption: false },
  { label: 'Cycle Remaining', sortOption: false },
  { label: 'Remarks', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'Comments/Notes', sortOption: false },
  { label: 'Ref Documents', sortOption: false },
  { label: 'Operator Comments', sortOption: false },
];
export const te005LLPDetailHd = [
  { label: 'SI No.', sortOption: false },
  { label: 'LLP Description', sortOption: false },
  { label: 'P/N', sortOption: false },
  { label: 'S/N', sortOption: false },
  { label: 'LLP CSN', sortOption: false },
  { label: 'Cycles Remaining', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'Comments', sortOption: false },
];
