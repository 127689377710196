import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { Drawer, Paper, TextField, Grid, Button, Avatar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import ProgressBar from './ProgressBar';
import FormsDropdown from './FormsDropdown';
import { ExportManu } from '../../shared_elements';
import { displayDateFormatShort } from '../../constants';
import { dateTransformAllSet, localTimeFn } from '../../utils';
const InspectionFormWrapper = ({inspectionHdInfo, skeletonLoader, saveButton, reviewerForm, onSaveChanges, changeFormStatusFn, files=[], exportReportFn, importReportCta=null, match, history}) => {
  const [open, setOpen] = useState(false);
  const [downloadLogs, drawerOpen] = useState(false);
  const [value, setValue] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setValue('');
  };
  const onChange = (value) => {
    setValue(value);
  };
  return(
    <Fragment>
      <div className="inspection-form-wrapper">
        { skeletonLoader ? <Skeleton variant="rect" height={46} />: (
          <Paper className="inspection-form-card">
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} md={6}>
                <ul classN className="list-inline flex-not-centered">
                  <li className="list-inline-item inspection-asset-info">
                    { inspectionHdInfo.asset ?
                      <h4 className="flex-centered"><Link onClick={() => history.goBack()}><ArrowBackIcon /></Link>
                        {(() => {
                          if(inspectionHdInfo.asset.asset_type === 1){
                            return(
                              <Fragment>MSN {inspectionHdInfo.asset.msn} <span>{inspectionHdInfo.asset.aircraft_type}</span></Fragment>
                            )
                          }else if (inspectionHdInfo.asset.asset_type === 2) {
                            return(
                              <Fragment>ESN {inspectionHdInfo.asset.esn} <span>{inspectionHdInfo.asset.engine_type}</span></Fragment>
                            )
                          }else if (inspectionHdInfo.asset.asset_type === 3) {
                            return(
                              <Fragment>APU {inspectionHdInfo.asset.serial_number}</Fragment>
                            )
                          }else if (inspectionHdInfo.asset.asset_type === 4) {
                            return(
                              <Fragment>LG {inspectionHdInfo.asset.serial_number}</Fragment>
                            )
                          }else if (inspectionHdInfo.asset.asset_type === 5) {
                            return(
                              <Fragment>Propeller {inspectionHdInfo.asset.serial_number}</Fragment>
                            )
                          }
                        })()}
                      </h4>:'--'
                    }

                  </li>
                  <li className="list-inline-item inspection-project-info">
                    <h4><span>Technical</span> <Link className="project-name" to={`/project/view/${inspectionHdInfo.project ? inspectionHdInfo.project.slug:''}`}>{inspectionHdInfo.project ? inspectionHdInfo.project.name:'--' }</Link></h4>
                    <Link style={{color:'#3f51b5'}} className="flex-centered" to={`/technical-specification/${match.params.assetType}/${match.params.assetSlug}`}>
                      <LocalAirportIcon fontSize="small" color="primary" />  Technical Specification
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid className="inspection-header-left" item xs={12} md={6}>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <p>Project Lead</p>
                    { inspectionHdInfo.project ?
                      inspectionHdInfo.project.leads.length > 1 ?
                      <AvatarGroup max={3}>
                        { inspectionHdInfo.project.leads.map((lead) =>
                          <Avatar style={{width:'24px', height:'24px'}} alt={lead.name} src={lead.profile_pic} />
                        )}
                      </AvatarGroup>

                      :
                      inspectionHdInfo.project.leads.length === 1 ?
                      <h6 className="flex-centered"><Avatar style={{width:'24px', height:'24px', float:'left', marginRight:'4px'}} src={inspectionHdInfo.project.leads[0].profile_pic} /> {inspectionHdInfo.project.leads[0].name}</h6>:''
                      :''
                    }
                  </li>
                  <li className="list-inline-item">
                    <p>Lessee</p>
                    <h6>{inspectionHdInfo.project && inspectionHdInfo.project.lessee && inspectionHdInfo.project.lessee.name ? inspectionHdInfo.project.lessee.name:'--' }</h6>
                  </li>
                  <li className="list-inline-item">
                    <p>Project Start Date</p>
                    <h6>{inspectionHdInfo.project && inspectionHdInfo.project.start_date ? moment(inspectionHdInfo.project.start_date).format(displayDateFormatShort) :'--' }</h6>
                  </li>
                  <li className="list-inline-item">
                    <p>Project End Date</p>
                    <h6>{inspectionHdInfo.project && inspectionHdInfo.project.expected_end_date ? moment(inspectionHdInfo.project.expected_end_date).format(displayDateFormatShort):'--' }</h6>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Paper>
        )}
        { skeletonLoader ? <Skeleton variant="react" height={57} />:
          <Paper className="inspection-form-card">
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} md={7}>
                <ul classN className="list-inline flex-centered">
                  <li className="list-inline-item inspection-form-info">
                    <FormsDropdown reviewerForm={reviewerForm} inspectionHdInfo={inspectionHdInfo} forms={inspectionHdInfo.list ?inspectionHdInfo.list.filter(form => form.name !== inspectionHdInfo.current_form.name):[]} />
                  </li>
                  { !reviewerForm ?
                    <Fragment>
                      <li className="list-inline-item inspection-form-cta">
                        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.status && inspectionHdInfo.current_form.status.id !== 1 && inspectionHdInfo.current_form.permissions.can_save && saveButton ?
                          <Button onClick={onSaveChanges} size="small" color="primary" variant="contained">Save Changes</Button>:null
                        }
                        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_submit ?
                          <Button onClick={() => changeFormStatusFn({status: 5})} size="small" color="primary" variant="contained">Submit</Button>:null
                        }
                        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_accept ?
                          <Fragment>
                            <Button onClick={() => changeFormStatusFn({status: 6})} size="small" color="primary" variant="contained">Accept</Button>
                            <Button onClick={() => {handleClickOpen()}} size="small" color="secondary" variant="contained">Reject</Button>
                          </Fragment>
                          :null
                        }
                        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_publish ?
                          <Button onClick={() => changeFormStatusFn({status: 7})} size="small" color="primary" variant="contained">Publish</Button>:null
                        }
                      </li>
                      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_export ?
                        <li className="list-inline-item">
                          <ExportManu title="Export Report" files={files} exportReportFn={(file)=>exportReportFn(file)} />
                          { inspectionHdInfo.current_form && inspectionHdInfo.current_form.download_report.length ?
                            <Fragment><br/><span style={{fontSize:'12px', position:'relative', top:'-4px', cursor: 'pointer'}} onClick={() => drawerOpen(true)}>{`Last Downloaded By ${inspectionHdInfo.current_form.download_report[0].user}`}</span></Fragment>:null
                          }
                        </li>:null
                      }
                      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_export && importReportCta ?
                        <li className="list-inline-item" style={{marginLeft:'4px'}}>
                          {importReportCta}
                        </li>:null
                      }
                    </Fragment>:null
                  }
                </ul>
              </Grid>
              <Grid className="inspection-header-left" item xs={12} md={5}>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <p>Project Engineer</p>
                    <h6>{inspectionHdInfo.current_form && inspectionHdInfo.current_form.engineer && inspectionHdInfo.current_form.engineer.name ? inspectionHdInfo.current_form.engineer.name:'--' }</h6>
                  </li>
                  <li className="list-inline-item">
                    <p>Last Activity</p>
                    <h6>{inspectionHdInfo.current_form && inspectionHdInfo.current_form.updated_at ? dateTransformAllSet(inspectionHdInfo.current_form.updated_at):'--' }</h6>
                  </li>
                  <li className="list-inline-item">
                    <p>Form Status</p>
                    <h6>{inspectionHdInfo.current_form && inspectionHdInfo.current_form.status && inspectionHdInfo.current_form.status.label ? inspectionHdInfo.current_form.status.label:'--' }</h6>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Paper>
        }
      </div>
      { isMobile ? null:<div style={{height:'82px'}}></div>}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div style={{width: isMobile ? 'auto':'380px'}}>
            <TextField
              autoFocus
              margin="dense"
              id="remarks"
              fullWidth
              multiline
              label='Reason of Rejection'
              rows="4"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={value && value.trim().length ? false:true} variant="contained" color="secondary" onClick={() => {handleClose(); changeFormStatusFn({status: 3, remarks:value})}}>
            Reject
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer anchor='right' open={downloadLogs}>
        <div className="forms-download-logs" style={{width:'380px'}}>
          { downloadLogs ?
            <Fragment>
              <h1>Downloaded Logs <span className="close-drawer" onClick={() => drawerOpen(false)}><CloseIcon /></span></h1>
              <div className="logs-list">
                {inspectionHdInfo.current_form.download_report.map((item, index) =>
                  <p>{`${item.user} ${localTimeFn(item.downloaded_at)} UTC`}</p>
                )}
              </div>
            </Fragment>:null
          }
        </div>
      </Drawer>
    </Fragment>
  )
}
export default withRouter(InspectionFormWrapper);
