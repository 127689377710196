import moment from 'moment';
import { globalGetService, globalExportService, globalPutService, globalPostService, globalDeleteService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
import { errorCode} from './index';
export function getGenReportDetailApi(props={}, query={}){
  globalGetService(`technical/engine-workorder/${props.match.params.workOrderSlug}/gap-report-sections/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        generalReportInfo: response.data.data,
        skeletonLoader:false
      })
    }
  })
}
export function exportGenReportDetailApi(props={}, file={}){
  this.setState({pageLoader: true});
  globalExportService(`technical/engine-workorder/${props.match.params.workOrderSlug}/gap-report-sections/`, {download:file.extension})
  .then(response => {
    downloadFileType(response.data, (`TE003_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.setState({pageLoader: false});
    this.props.enqueueSnackbar('Gap Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function editGenReportDetailApi(props={}, data={}){
  debugger
  let llpFlag = true;
  let llpError = [];
  let validationInputs = {
    report_date:errorCode['report_date'][fieldValidation({...errorCode['report_dateObj'], fieldval: data.general_details.report_date})],
    report_issued_place:errorCode['report_issued_place'][fieldValidation({...errorCode['report_issued_placeObj'], fieldval: data.general_details.report_issued_place})],
  }

  llpError = data.list.find(listItem => listItem.name === 'LLP Details').gap_reports.map(item => {
    return {
      part_number: item.part_number && item.part_number.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.part_number) ? '':'Alphanumeric with space and hypen only':'',
      serial_number: item.serial_number && item.serial_number.trim() != '' ? regexConstants.alphanumericWithHyphenSpace.test(item.serial_number) ? '':'Alphanumeric with space and hypen only':'',
      cycles_remaining:item.cycles_remaining && item.cycles_remaining.toString().trim().length ? '':'Please enter Cycle Remaining'
    }
  })
  llpError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      llpFlag = false
    }
  });
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) && llpFlag){
    this.setState({pageLoader:true});
    globalPostService(`technical/engine-workorder/${props.match.params.workOrderSlug}/gap-report/`, {data:data})
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        general_details: validationInputs,
        llpDetails: llpError,
      }
    }));
  }
}
export function deleteGenReportDetailApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/engine-workorder/${props.match.params.workOrderSlug}/gap-report/${data.id}/delete/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        generalReportInfo: {
          ...prevState.generalReportInfo,
          list: [...prevState.generalReportInfo.list].map(item => item.name === data.type ? {
            ...item, gap_reports: [...item.gap_reports].filter((rpt, index) => index !== data.deleteIndex)
          } :item)
        },
        deleteIds:{},
        deleteModal:false
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
