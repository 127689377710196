import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const errorCode = {
  report_date:{
    0:'',
    1: 'Please enter Report Date',
  },
  report_dateObj:{
    required: true,
  },
  report_issued_place:{
    0:'',
    1: 'Please enter Report Issued Place'
  },
  report_issued_placeObj:{
    required: true
  },
}
