import React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Grid } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ProjectCRU from './ProjectCRU';
import {ProgressBar} from '../../Elements';
import { CircularProgress } from '../../../shared_elements';
import { LabelValueCard } from '../../../shared_elements';
const ProjectViewHd = ({project}) => {
  return(
    <Paper className="project-info-hd">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <table>
            <tbody>
              <tr className="flex-centered">
                <td>
                  <Link to="/projects">
                    <KeyboardBackspaceIcon color="primary" />
                  </Link>
                </td>
                <td className="flex-centered" style={{minWidth:'300px'}}>
                  <CircularProgress value={project.progress ? project.progress:0} />
                  <h2 style={{marginLeft:'6px'}}>{project.name}</h2>
                </td>
                <td className="flex-centered">

                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={4}>
          <ul className="list-inline flex-centered">
            <li className="list-inline-item">
              <h6>Lessee</h6>
              <p>{project.lessee && project.lessee.name ? project.lessee.name:'--'}</p>
            </li>
            <li className="list-inline-item">
              <h6>Status</h6>
              <p>{project.status && project.status.label ? project.status.label:'--'}</p>
            </li>
            <li className="list-inline-item">
              <ProjectCRU id={project.id} />
            </li>
          </ul>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default ProjectViewHd;
