import React, { Fragment } from 'react';
import Drawer from '@material-ui/core/Drawer';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
export default function MobileSecMenu({extraProps}) {
  const [state, setState] = React.useState({left: false});
  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };
  if(['ContractInner', 'ContractOuter','Maintenance'].includes(extraProps.apps)){
    return(
      <Fragment>

      </Fragment>
    )
  }else{
    return null
  }

}
