import React, { Component, Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tabs, Tab } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DropzoneArea} from 'material-ui-dropzone';
import MomentUtils from '@date-io/moment';
import { isMobile } from 'react-device-detect';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from '../../../../constants';
import { t011GapReportObj } from '../../../';
class AddReport extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentItem:null,
      tabIndex:0,
      modal:false,
      reportCrud:{},
      error:{},
      sectionId:'',
      ids:[5,6,7,8,9,10,14,15,16,17,21,22,23,24,25]
    }
  }
  componentDidMount(){
    this.setState({currentItem: this.props.selectedListItem});
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      reportCrud: {
        ...prevState.reportCrud,
        [keyParam]:data
      }
    }));
  }
  handleChange = (event, newValue) => {
    this.setState({tabIndex: newValue});
  }
  addIssueReportFn = () => {}
  render(){
    const { tabIndex, currentItem, reportCrud, modal, sectionId, ids, error } = this.state;
    const { gapReport, selectedListItem, projectUsers, type } = this.props;
    return(
      <Fragment>
        { type === 'add' ?
          <Button
            onClick={() => this.setState({currentItem:selectedListItem,reportCrud:t011GapReportObj, error:{}, modal:true})}
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<ControlPointIcon color="primary" fontSize="small" />}
          >
              Add Item
          </Button>:null
        }
        { type === 'import' ?
          <Button
            onClick={() => this.setState({currentItem:selectedListItem,reportCrud:t011GapReportObj, error:{}, modal:true, tabIndex:1})}
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<ImportExportIcon color="primary" fontSize="small" />}
          >
              Import Report
          </Button>:null
        }

        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              Create
            </DialogTitle>
            <DialogContent dividers={true}>
              <div className="t011-add-modal">
                <Tabs
                  value={tabIndex}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons={isMobile ? 'on':'auto'}
                >
                  <Tab label="Single Entry" />
                  <Tab label="Import Via Excel" />
                </Tabs>
                { tabIndex === 0 ?
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Autocomplete
                        options = {gapReport.list}
                        getOptionLabel={option => option.name}
                        id="section"
                        value={currentItem.id === 0 ? null:currentItem}
                        onChange={(e, value) => this.setState({currentItem:value})}
                        renderInput={params => <TextField required {...params} label="Add to Section" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        options = {[{label:'P1',value:1},{label:'P2',value:2}]}
                        getOptionLabel={option => option.label}
                        id="priority"
                        value={reportCrud.priority ? [{label:'P1',value:1},{label:'P2',value:2}].find(item => item.value === reportCrud.priority):null}
                        onChange={(e, value) => { this.onFieldChange(e, 'priority', value ? value.value : ''); }}
                        renderInput={params => <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        options = {[{name:'None', id:0}, ...projectUsers]}
                        getOptionLabel={option => option.name}
                        id="assignee"
                        value={reportCrud.assignee}
                        onChange={(e, value) => { this.onFieldChange(e, 'assignee', value ? value.value : ''); }}
                        renderInput={params => <TextField {...params} label="Assignee" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="description"
                        label="Description"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        value={reportCrud.description ? reportCrud.description:''}
                        onChange={(e, value) => this.onFieldChange(e, 'description', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    { ids.includes(currentItem.id) &&
                      <Grid item xs={6}>
                        <TextField
                          id="ata"
                          label="ATA"
                          fullWidth
                          margin="normal"
                          value={reportCrud.ata ? reportCrud.ata:''}
                          onChange={(e, value) => this.onFieldChange(e, 'ata', e.target.value)}
                          InputLabelProps={{shrink: true}}
                        />
                      </Grid>
                    }
                    { ids.includes(currentItem.id) &&
                      <Grid item xs={6}>
                        <TextField
                          id="part_number"
                          label="Part Number"
                          fullWidth
                          margin="normal"
                          value={reportCrud.part_number ? reportCrud.part_number:''}
                          onChange={(e, value) => this.onFieldChange(e, 'part_number', e.target.value)}
                          InputLabelProps={{shrink: true}}
                        />
                      </Grid>
                    }

                    { ids.includes(currentItem.id) &&
                      <Grid item xs={6}>
                        <TextField
                          id="serial_number"
                          label="Serial Number"
                          fullWidth
                          margin="normal"
                          value={reportCrud.serial_number ? reportCrud.serial_number:''}
                          onChange={(e, value) => this.onFieldChange(e, 'serial_number', e.target.value)}
                          InputLabelProps={{shrink: true}}
                        />
                      </Grid>
                    }
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="started_date"
                           label="Start Date"
                           format={fieldDateFormat}
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           value={reportCrud.started_date ? reportCrud.started_date:null}
                           onChange={(data, value) => this.onFieldChange(value, 'started_date', moment(data).format(backendDateFormat))}
                         />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="estimated_completion_date"
                           label="Completed Date"
                           format={fieldDateFormat}
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           value={reportCrud.estimated_completion_date ? reportCrud.estimated_completion_date:null}
                           onChange={(data, value) => this.onFieldChange(value, 'estimated_completion_date', moment(data).format(backendDateFormat))}
                         />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="requirement_remarks"
                        label="Requirement Discrepancy"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        value={reportCrud.requirement_remarks ? reportCrud.requirement_remarks:''}
                        onChange={(e, value) => this.onFieldChange(e, 'requirement_remarks', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="background_remarks"
                        label="Background Discrepancy"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        value={reportCrud.background_remarks ? reportCrud.background_remarks:''}
                        onChange={(e, value) => this.onFieldChange(e, 'background_remarks', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    { ids.includes(currentItem.id) &&
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                             margin="normal"
                             id="install_date"
                             label="Install Date"
                             format={fieldDateFormat}
                             fullWidth
                             InputLabelProps={{shrink: true}}
                             value={reportCrud.install_date ? reportCrud.install_date:null}
                             onChange={(data, value) => this.onFieldChange(value, 'install_date', moment(data).format(backendDateFormat))}
                           />
                          </MuiPickersUtilsProvider>
                      </Grid>
                    }
                    { ids.includes(currentItem.id) &&
                      <Grid item xs={6}>
                        <TextField
                          id="position"
                          label="Position"
                          fullWidth
                          margin="normal"
                          value={reportCrud.position ? reportCrud.position:''}
                          onChange={(e, value) => this.onFieldChange(e, 'position', e.target.value)}
                          InputLabelProps={{shrink: true}}
                        />
                      </Grid>
                    }
                    <Grid item xs={12}>
                      <TextField
                        id="operator_comments"
                        label="Operator Comments"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        value={reportCrud.operator_comments ? reportCrud.operator_comments:''}
                        onChange={(e, value) => this.onFieldChange(e, 'operator_comments', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{width:'250px'}}>
                        <DropzoneArea
                          filesLimit={20}
                          maxFileSize={10000000}
                          showPreviewsInDropzone={false}
                          acceptedFiles={['image/*']}
                          dropzoneClass="drag-drop-cnt"
                          showAlerts={['error', 'info']}
                          alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                        />
                      </div>
                    </Grid>
                  </Grid>:
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{width:'100%'}}>
                        <DropzoneArea
                          filesLimit={20}
                          maxFileSize={10000000}
                          showPreviewsInDropzone={false}
                          acceptedFiles={['image/*']}
                          dropzoneClass="drag-drop-cnt"
                          showAlerts={['error', 'info']}
                          alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                        />
                      </div>
                      <div className="t011-report-import-notes">
                        <div>
                          <h4>Please follow the below instruction to use this function:</h4>
                          <ul>
                            <li>Export the MS EXCEL file using “Export Report” button in T011.</li>
                            <li>Without editing the fields or the COLUMN HEADERS, start updating the PRS.</li>
                            <li>Once updated with the information, click “IMPORT FILE” and select the same file from the local drive to upload.</li>
                            <li>Maintain the same file on the local drive and update through the project.</li>
                            <li>During every IMPORT, the additional information on the Excel will get updated in T011 report on SPARTA.</li>
                          </ul>
                        </div>
                        <div>
                          <h4>Notes:</h4>
                          <ul>
                            <li>DO use the exported PRS template ONLY. Any modification to the fields and / or the column header will cause a conflict with the system and the PRS will not get imported successfully.</li>
                            <li>DO specify the finding under the “Description” field. This field is mandatory in each row item.</li>
                            <li>DO use "YYYY-MM-DD" as the date format.</li>
                            <li>DO use only option “1” or “2” to update the PRIORITY.</li>
                          </ul>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                }
              </div>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false})} color="primary">Cancel</Button>
              <Button onClick={this.addIssueReportFn} variant="contained" color="primary">Save</Button>
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default AddReport;
