import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {ProgressBar} from '../../Elements';
import AircraftCRU from './AircraftCRU';
import ALPCRU from './ALPCRU';
import { CircularProgress } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
const assetTypes = {
  3: 'APU',
  4: 'Landing Gear',
  5: 'Propeller'
}
const WorkOrderAsset = ({icon, serialNo, type, progress, asset, workOrder, inspectionTypes, match, history}) => {
  return(
    <div className="work-order-asset">
      <img src={icon} alt="Asset" />
      <div className="flex-centered">
        <CircularProgress value={progress} />
        <div style={{marginLeft:'6px'}}>
          { type ? <span>{type}</span>:''}
          <h4>{serialNo}
              {(() => {
                if(asset.type === 1){
                  return(<AircraftCRU inspectionTypes={inspectionTypes} asset={asset} workOrder={workOrder}/>)
                }else if (asset.type === 2) {
                  return(<span onClick={(e) => {e.preventDefault(); e.stopPropagation();}}><Link style={{color:'#2f8cff'}} to={`/project/${match.params.projectSlug}/engine/edit/${workOrder.slug}`}><EditIcon fontSize="small" /> Add Forms</Link></span>)
                }else{
                  return(<ALPCRU inspectionTypes={inspectionTypes} asset={asset} workOrder={workOrder} />)
                }
              })()}
          </h4>
        </div>
      </div>
    </div>
  )
}
export default withRouter(WorkOrderAsset);
