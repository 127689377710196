import React, { Fragment } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const TableRowActions = ({actions, previewDetail}) => {
  return(
    <Fragment>
      { actions.includes('V') ?
        <Tooltip title="View" arrow>
          <IconButton size="small" color="primary" onClick={() => previewDetail('V')}>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>:null
      }
      { actions.includes('E') ?
        <Tooltip title="Edit" arrow>
          <IconButton size="small" color="primary" onClick={() => previewDetail('E')}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>:null
      }
      { actions.includes('D') ?
        <Tooltip title="Delete" arrow>
          <IconButton size="small" color="primary" onClick={() => previewDetail('D')}>
            <DeleteOutlineIcon fontSize="small" color="secondary" />
          </IconButton>
        </Tooltip>:null
      }
    </Fragment>
  )
}
export default TableRowActions;
