import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
const PrerequisiteContent = ({}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  return(
    <Fragment>
      <ol>
        <li>To check for the documents received against the <span style={{textDecoration:'underline', cursor:'pointer', color:'#368ffa'}} onClick={handleClickOpen}>list of engine documents</span></li>
        <li>Sort out the engine documents received / not received and update the client accordingly</li>
        <li>Review each document throughly for the technical details available and/or missing, incorrect data</li>
        <li>Review each document to trace the operation of the engine from new and the supporting documents from the operator / owner / shop</li>
        <li>Check for all supplied documents provided for the correctness of data</li>
        <li>Cross check the LLP BTB provided is in line with the latest available LLP sheet</li>
        <li>Review each installed LLP BTB documents for the trace the operation from new and the supporting documents from the operator / owner / shop</li>
        <li>Consolidate all the missing data and prepare the gap report accordingly</li>
        <li>Finalize the gap report with all the essential data filled</li>
        <li>If any supplement document supplied for closing the gaps, repeat the process check list (wherever applicable) from point number 1</li>
      </ol>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          Documents required for engine assessment - Check List
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="read-prerequisite-modal">
            <ol>
              <li>Engine log books</li>
              <li>Engine manufacturer delivery documents</li>
              <li>Engine shop visit documents</li>
              <li>Engine BSI report (Latest, including video recording if available)</li>
              <li>Engine ECTM report (Last 6 months)</li>
              <li>Latest LLP status and back to birth history</li>
              <li>Engine Power Assurance check report</li>
              <li>Engine maintenance program (hard time / on condition) - For Turboprop engines</li>
              <li>Gaspath wash interval</li>
              <li>On-wing maintenance history for last 6 months</li>
              <li>Engine preservation details (if engine is not operating / unserviceable)</li>
              <li>AD, SB status</li>
              <li>Engine LRU list</li>
              <li>Engine statements (NIA, No PMA/DER part used statement, Engine oil and fuel used statement, Thrust statement) Propeller Documents (if applicable) - For Turboprop engines</li>
              <li>Propeller log books</li>
              <li>Propeller shop visit reports</li>
            </ol>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Okay</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default PrerequisiteContent;
