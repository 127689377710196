import React from 'react';
import { Tooltip } from '@material-ui/core';
export default function AircraftBluePrints({bluePrintId, index, sectionDetail, auditSectionFn}){
  let status = 'blue';
  switch(sectionDetail['status']){
    case 1 :
      status = 'poor'
    break;
    case 2 :
     status = 'average'
      break;
    case 3:
     status = 'good'
      break;
    default:
      break;
  }
  if(sectionDetail.finding_count && sectionDetail.finding_count > 0 ){
    status = 'poor'
  }
  return(
    <div
      onClick={auditSectionFn}
      className="indicater-image position"
      id={bluePrintId==3?'flight-indicater'+sectionDetail['id']:([8, 9].includes(bluePrintId) && [16, 8, 32].includes(sectionDetail['id'])) ? 'indicater_' + sectionDetail['id'] :'indicater'+sectionDetail['id']}
      onClick={auditSectionFn}
    >
      <div className="indicater" style={{opacity:'1'}}>
        <Tooltip title={sectionDetail.name} arrow>
          <i className={"fa fa-circle "+ status}>
            <span className="indicater-number" >
            { sectionDetail['finding_count'] == 0  ? '' :  sectionDetail['finding_count'] > 0 ?
              sectionDetail['finding_count'] : index >=0? index+1 : sectionDetail['id']
            }
            </span>
          </i>
        </Tooltip>
      </div>
    </div>
  )
}
