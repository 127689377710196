import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { CkEditorText } from '../../../../shared_elements';
export default function AuditIntroduction({modal, question, toggleModalFn, onFieldChange, saveQuestionAnsFn}){
  return(
    <Dialog
      open={modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        <TextField
          id="name"
          margin="normal"
          fullWidth
          value={question.title ? question.title:'Introduction'}
          InputLabelProps={{shrink: true}}
          onChange={(e) => onFieldChange(e, 'title', e.target.value)}
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        <CkEditorText
          htmlData={Object.keys(question.answer).length ? question.answer.value : question.prefill}
          onChangeData={(data) => onFieldChange('', 'value', data)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
        <Button onClick={saveQuestionAnsFn} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}
