import React from 'react';
import { Grid } from '@material-ui/core';
import { CkEditorText } from '../../../shared_elements';
const EctmDetails = ({engine, onFieldChange}) => {
  return(
    <div className="">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CkEditorText
            htmlData={engine.ectm_detail ? engine.ectm_detail:''}
            onChangeData={(data) => onFieldChange('', 'ectm_detail', data)}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default EctmDetails;
