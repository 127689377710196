import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel,  FormControlLabel, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AssetInspectionDate from './AssetInspectionDate';
import EditIcon from '@material-ui/icons/Edit';
import { PageLoader } from '../../../shared_elements';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { getProjectFormsApi, getAssetWorkOrderLinkApi, getWorkOrderAssetApi, addEditWorkOrderApi, getWorkOrderDetail } from '../apiServices';
import { capitalizeFirstLetter } from '../../../utils';
import { alpWorkOrder } from '../../';
const assetTypeInfo = {
  3: {
    label:'APU',
    key:'apu',
    params:'apu_id'
  },
  4: {
    label: 'Landing Gear',
    key:'lg',
    params:'lg_id'
  },
  5: {
    label: 'Propeller',
    key: 'propeller',
    params:'propeller_id'
  }
}
class ALPCRU extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      workOrderInfo:{data:{},error:{}, modal:false, mode:'',type:''},
      forms:[]
    }
    this.getProjectFormsApi = getProjectFormsApi.bind(this);
    this.addEditWorkOrderApi = addEditWorkOrderApi.bind(this);
    this.getAssetWorkOrderLinkApi = getAssetWorkOrderLinkApi.bind(this);
    this.getWorkOrderAssetApi = getWorkOrderAssetApi.bind(this);
    this.getWorkOrderDetail = getWorkOrderDetail.bind(this);
  }
  componentDidMount(){
    const { workOrder } = this.props;
    if(!(workOrder && workOrder.slug)){
      this.updateApiInfo();
      this.setState(prevState => ({
        ...prevState,
        workOrderInfo: {
          data:alpWorkOrder,
          error:{},
          modal:true,
          mode:'add',
          type:this.props.workOrderPop.assetType
        }
      }))
    }
  }
  updateApiInfo = () => {
    this.getProjectFormsApi(this.props, {apu_type:'True',lg_type:'True'});
  }
  toggleModalFn = (data) => {
    this.updateApiInfo();
    this.setState({
      workOrderInfo: data
    });
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      workOrderInfo: {
        ...prevState.workOrderInfo,
        data: {
          ...prevState.workOrderInfo.data,
          [keyParam]:data
        }
      }
    }));
  }
  render(){
    const { workOrderInfo, forms, pageLoader } = this.state;
    const { assetList, asset, workOrder, workOrderPop, inspectionTypes } = this.props;
    return(
      <Fragment>
        { workOrder && workOrder.slug  ?
          <span onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.updateApiInfo(); this.getWorkOrderDetail(this.props, workOrder.slug)}}>
            <EditIcon fontSize="small" /> Add Forms
          </span>:
          <span onClick={() => this.toggleModalFn({data:{}, error:{}, modal:true,mode:'add', type:assetTypeInfo[asset.type].label})}>{assetTypeInfo[asset.type].label}</span>
        }
        {(workOrderInfo.modal || (workOrderPop && workOrderPop.modal)) ?
          <Dialog
            fullScreen
            open={(workOrderInfo.modal || (workOrderPop && workOrderPop.modal))}
            onClose={(e) => {this.toggleModalFn({data:{}, error:{}, modal:false, mode:''}); (workOrder && workOrder.slug) ? e.preventDefault():this.props.toggleWorkOrderFn()}}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {capitalizeFirstLetter(workOrderInfo.mode)} {assetTypeInfo[asset.type].label}
            </DialogTitle>
            <DialogContent dividers={true}>
              <form>
                <Grid container spacing={3}>
                  {
                    // <Grid item xs={12}>
                    //   <FormControl component="fieldset">
                    //     <RadioGroup row aria-label="position" name="ownership_type" defaultValue="top">
                    //       <FormControlLabel value="is_titled" control={<Radio color="primary" />} label="Link Existing MSN" />
                    //       <FormControlLabel value="is_fitted" control={<Radio color="primary" />} label="Create New MSN" />
                    //     </RadioGroup>
                    //   </FormControl>
                    // </Grid>
                  }
                  { !(workOrder && workOrder.slug) ?
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        options = { assetList ? assetList.filter(ast => ast.asset_type === asset.type):[]}
                        getOptionLabel={option => option.serial_number}
                        id={assetTypeInfo[asset.type].params}
                        value={assetList.filter(asset => asset.asset_type === asset.type).find(assetInfo => assetInfo.slug === workOrderInfo.data[assetTypeInfo[asset.type].params])}
                        onChange={(e, value) => {this.onFieldChange(e, assetTypeInfo[asset.type].params, value ? value.slug :value); value ? this.getWorkOrderAssetApi(this.props, workOrderInfo.data, assetTypeInfo[asset.type].key, value.slug): this.toggleModalFn({data:{}, error:{}, modal:true, mode:'add'}) }}
                        renderInput={params => <TextField required {...params} error={workOrderInfo.error[assetTypeInfo[asset.type].params] ? true:false} helperText={workOrderInfo.error[assetTypeInfo[asset.type].params] ? workOrderInfo.error[assetTypeInfo[asset.type].params]:''} label={assetTypeInfo[asset.type].label} margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>:null
                  }
                  <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         required
                         id="start_date"
                         label="Start Date"
                         format={fieldDateFormat}
                         fullWidth
                         disableFuture={true}
                         clearable={true}
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         error={workOrderInfo.error.start_date ? true:false}
                         helperText={workOrderInfo.error.start_date ? workOrderInfo.error.start_date:''}
                         value={workOrderInfo.data.start_date ? workOrderInfo.data.start_date:null}
                         onChange={(data, value) => {this.onFieldChange(value, 'start_date', data ? moment(data).format(backendDateFormat):data);}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         required
                         id="expected_end_date"
                         label="Expected End Date"
                         format={fieldDateFormat}
                         fullWidth
                         clearable={true}
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         minDate={workOrderInfo.data.start_date ? moment(workOrderInfo.data.start_date).add(30,'days') : moment().add(30, 'days')}
                         error={workOrderInfo.error.expected_end_date ? true:false}
                         helperText={workOrderInfo.error.expected_end_date ? workOrderInfo.error.expected_end_date:''}
                         value={workOrderInfo.data.expected_end_date ? workOrderInfo.data.expected_end_date:null}
                         onChange={(data, value) => {this.onFieldChange(value, 'expected_end_date', data ? moment(data).format(backendDateFormat):data);}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         id="lease_start_date"
                         label="Lease Start Date"
                         format={fieldDateFormat}
                         fullWidth
                         disableFuture={true}
                         clearable={true}
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         value={workOrderInfo.data.lease_start_date ? workOrderInfo.data.lease_start_date:null}
                         onChange={(data, value) => {this.onFieldChange(value, 'lease_start_date', data ? moment(data).format(backendDateFormat):data);}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="tsn"
                      label="TSN"
                      fullWidth
                      margin="normal"
                      value={workOrderInfo.data.tsn ? workOrderInfo.data.tsn:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'tsn', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="csn"
                      label="CSN"
                      fullWidth
                      margin="normal"
                      value={workOrderInfo.data.csn ? workOrderInfo.data.csn:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'csn', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="location"
                      label="Location"
                      fullWidth
                      margin="normal"
                      value={workOrderInfo.data.location ? workOrderInfo.data.location:''}
                      InputLabelProps={{shrink: true}}
                      error={workOrderInfo.error.location ? true:false}
                      helperText={workOrderInfo.error.location ? workOrderInfo.error.location:''}
                      onChange={(e) => this.onFieldChange(e, 'location', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         required
                         id="date_of_manufacture"
                         label="Date of Manufacture"
                         format={fieldDateFormat}
                         fullWidth
                         disableFuture={true}
                         clearable={true}
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         error={workOrderInfo.error.date_of_manufacture ? true:false}
                         helperText={workOrderInfo.error.date_of_manufacture ? workOrderInfo.error.date_of_manufacture:''}
                         value={workOrderInfo.data.date_of_manufacture ? workOrderInfo.data.date_of_manufacture:null}
                         onChange={(data, value) => {this.onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat):data);}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         id="status_as_of"
                         label="Status As Of"
                         format={fieldDateFormat}
                         fullWidth
                         disableFuture={true}
                         clearable={true}
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         value={workOrderInfo.data.status_as_of ? workOrderInfo.data.status_as_of:null}
                         onChange={(data, value) => {this.onFieldChange(value, 'status_as_of', data ? moment(data).format(backendDateFormat):data);}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      options = {inspectionTypes}
                      getOptionLabel={option => option.name}
                      id="inspection_types"
                      value={workOrderInfo.data.inspection_types ? inspectionTypes.find(insp => insp.id === workOrderInfo.data.inspection_types):null}
                      onChange={(e, value) => this.onFieldChange(e, 'inspection_types', value ?value.id :value)}
                      renderInput={params => <TextField required {...params} error={workOrderInfo.error.inspection_types ? true:false} helperText={workOrderInfo.error.inspection_types ? workOrderInfo.error.inspection_types:''} label="Inspection Types" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      options = {forms}
                      getOptionLabel={option => option.name}
                      id="forms"
                      multiple
                      value={workOrderInfo.data.forms ? forms.filter(form => workOrderInfo.data.forms.includes(form.id)):[]}
                      onChange={(e, value) => this.onFieldChange(e, 'forms', value ? value.map(form => form.id) :value)}
                      renderInput={params => <TextField required {...params} error={workOrderInfo.error.forms ? true:false} helperText={workOrderInfo.error.forms ? workOrderInfo.error.forms:''} label="Forms" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  <AssetInspectionDate
                    workOrderInfo={workOrderInfo}
                    onFieldChange={this.onFieldChange}
                  />
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={(e) => {this.toggleModalFn({data:{}, error:{}, modal:false, mode:''}); (workOrder && workOrder.slug) ? e.preventDefault() :this.props.toggleWorkOrderFn()}}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={() => this.addEditWorkOrderApi(this.props, workOrderInfo, assetTypeInfo[asset.type].params)}>Save</Button>
            </DialogActions>
          </Dialog>:null
        }
        { pageLoader ? <PageLoader />:null}
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(ALPCRU));
