import moment from 'moment';
import { globalGetService, globalExportService, globalPutService, globalDeleteService, globalPostService, globalFileUploadService } from '../../../utils/globalApiServices';
import { fieldValidation, dateRangeValidation } from '../../../utils/formValidation';
import { errorCode } from './index';
import { checkApiStatus, downloadFileType, dataURItoBlob, getLocalStorageInfo } from '../../../utils';
export function getBoroscopeDetailsApi(props={}, query={}){
  globalGetService(`technical/engine-workorder/${props.match.params.workOrderSlug}/borescope-details/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        borescopeInspectionDetail: response.data.data,
        skeletonLoader:false
      })
    }
  })
}
export function exportBoroscopeDetailsApi(props={}, file={}){
  this.setState({pageLoader: true});
  globalExportService(`technical/engine-workorder/${props.match.params.workOrderSlug}/borescope-details/`, {download:file.extension})
  .then(response => {
    downloadFileType(response.data, (`TE001_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.setState({pageLoader: false});
    this.props.enqueueSnackbar('Borescope Inspection Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function editBoroscopeDetailsApi(props={}, data={}){
  let validationInputs = {
    type_of_report:errorCode['type_of_report'][fieldValidation({...errorCode['type_of_reportObj'], fieldval: data.borescope_details.type_of_report})],
    place_of_inspection:errorCode['place_of_inspection'][fieldValidation({...errorCode['place_of_inspectionObj'], fieldval: data.borescope_details.place_of_inspection})],
    amm_revision_number:errorCode['amm_revision_number'][fieldValidation({...errorCode['amm_revision_numberObj'], fieldval: data.borescope_details.amm_revision_number})],
    amm_revision_date:errorCode['amm_revision_date'][fieldValidation({...errorCode['amm_revision_dateObj'], fieldval: data.borescope_details.amm_revision_date})],
    date_of_inspection_from:errorCode['date_of_inspection_from'][fieldValidation({...errorCode['date_of_inspection_fromObj'], fieldval: data.borescope_details.date_of_inspection_from})],
    report_date:errorCode['report_date'][fieldValidation({...errorCode['report_dateObj'], fieldval: data.borescope_details.report_date})],
    report_issued_place:errorCode['report_issued_place'][fieldValidation({...errorCode['report_issued_placeObj'], fieldval: data.borescope_details.report_issued_place})],
  }
  if(data.borescope_details.date_of_inspection_from && data.borescope_details.date_of_inspection_to){
    let msg = dateRangeValidation(data.borescope_details.date_of_inspection_from, data.borescope_details.date_of_inspection_to, 30);
    validationInputs = {
      ...validationInputs,
      date_of_inspection_from: msg ? 'Minimun Differnce b/w Inspection dates should be 30 days':'',
      date_of_inspection_to: msg ? 'Minimun Differnce b/w Inspection dates should be 30 days':''
    }
  }
  if(data.borescope_details.date_of_inspection_from && data.borescope_details.report_date){
    validationInputs = {
      ...validationInputs,
      report_date: dateRangeValidation(data.borescope_details.date_of_inspection_from, data.borescope_details.report_date, 0) ? 'Report Date should be greater then Inspection From':''
    }
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    this.setState({pageLoader: true});
    globalPostService(`technical/engine-workorder/${props.match.params.workOrderSlug}/borescope-details/`, data)
    .then(response => {
      this.setState({pageLoader: false});
      if(checkApiStatus(response)){
        this.setState({
          borescopeInspectionDetail: response.data.data,
        })
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        this.getInspectionHeaderApi(this.props);
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState({error: validationInputs});
  }
}
export function deleteBoroscopeCompApi(props={}, data){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/engine-workorder/${props.match.params.workOrderSlug}/delete-borescope-component/${data.id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({pageLoader:false});
      this.setState(prevState => ({
        ...prevState,
        borescopeInspectionDetail: {
          ...prevState.borescopeInspectionDetail,
          components_inspected: [...prevState.borescopeInspectionDetail.components_inspected].filter((item, index) => index !== data.deleteIndex)
        },
        deleteIds:{},
        deleteModal:false
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function addBoroscopePlateApi(props={}, files){
  this.setState({pageLoader:true});
  let formData = new FormData();
  formData.append('borescope', true);
  formData.append('file', files[0]);
  globalFileUploadService(`technical/engine-workorder/${props.match.params.workOrderSlug}/file-upload/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState(prevState => ({
        ...prevState,
        borescopeInspectionDetail:{
          ...prevState.borescopeInspectionDetail,
          inspection_pictures:[...prevState.borescopeInspectionDetail.inspection_pictures, response.data.data]
        }
      }));
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function editBoroscopePlateApi(params={}, query={}, data){
  globalGetService(`technical/engine-workorder/${params.workOrderSlug}/form/${params.formSlug}/get-pictures-url/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function deleteBoroscopePlateApi(props={}, deleteIds){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/engine-workorder/${props.match.params.workOrderSlug}/delete-borescope-image/${deleteIds.id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        deleteModal:false,
        deleteIds:{}
      });
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState(prevState => ({
        ...prevState,
        borescopeInspectionDetail: {
          ...prevState.borescopeInspectionDetail,
          inspection_pictures: prevState.borescopeInspectionDetail.inspection_pictures.filter(item => item.id !== deleteIds.id)
        }
      }))
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function annotateBoroscopePlateApi(props={}, file=null, data=null){
  this.setState({pageLoader:true});
  dataURItoBlob(file).then(editedImage => {
    let formData = new FormData();
    formData.append('file', editedImage);
    globalPostService(`technical/engine-workorder/${props.match.params.workOrderSlug}/borescope-inspection/${data.id}/`, formData)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          borescopeInspectionDetail:{
            ...prevState.borescopeInspectionDetail,
            inspection_pictures: prevState.borescopeInspectionDetail.inspection_pictures.map(item => item.id === data.id ? response.data.data:item)
          },
          imgAnnotation: { modal:false, data:{}}
        }))
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  })
}
