import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Button, TextField, CircularProgress, InputAdornment, IconButton, Tooltip} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import config from '../../../config';
export default function LoginForm({error, authInfo, handleChange, onLogin, formSubmitLoader}){
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const longText = `${
  <ul className="list-unstyled password-tooltip">
  <li>Password must contain minimum of 8 characters</li>
  <li>Password must contain at least 1 digit</li>
  <li>Password must contain at least 1 uppercase character</li>
  <li>Password must contain at least 1 lowercase character</li>
  <li>Password must contain at least 1 special character</li>
  </ul>
  }`;
  return(
    <form onSubmit={(e) => {e.preventDefault();onLogin()}}>
      <TextField
        id="email"
        label="Registered Email Address"
        fullWidth
        margin="normal"
        value={authInfo.email}
        error={error.email ? true:false }
        helperText={error.email}
        InputLabelProps={{shrink: true}}
        onChange={(e) => handleChange(e.target.value, 'email')}
      />
      <TextField
        id="password"
        type={values.showPassword ? 'text' : 'password'}
        label={
          <p>Password</p>
          }
        fullWidth
        margin="normal"
        value={authInfo.password}
        error={error.password ? true:false }
        helperText={error.password}
        InputLabelProps={{shrink: true}}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
          }}
        onChange={(e) => handleChange(e.target.value, 'password')}
      />
      {/* { config.api.networkInterface.includes('dev.beta') ? null:
        <Fragment>
          <Link className="forgot-pwd-link" to="/forgot-password">Forgot Password?</Link>
        </Fragment>
      } */}
      <div className="text-center" style={{marginTop:'20px'}}>
        <p className="terms-and-condition">
          By clicking Sign In, you agree to our&nbsp;
          <a className="url" href="/termscondition" > Terms, </a>
          <a className="url" href="/data-policy" >&nbsp;Data Policy </a> and <br/>
          <a className="url" href="https://acumenaviation.ie/privacy-policy/" target="_blank"> Privacy Policy</a>.
          </p>
      </div>
      <div className="text-center" style={{marginTop:'10px'}}>
        <Button style={{width:'120px'}} disabled={authInfo.email.trim() === '' || authInfo.password.trim() === '' || formSubmitLoader} variant="contained" color="primary" type="submit">{ formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Sign In' }</Button>
      </div>
    </form>
  )
}
