import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
export default function ProgressBar({progress}){
  return(
    <Tooltip title={`${progress}%`}>
      <p style={{width: '100%', height:'4px', background: '#e8e8e8', position: 'relative'}}>
        <span style={{position: 'absolute', top: '0', left: '0', height:'100%', background:'#3ce966', width: progress +'%'}}></span>
      </p>
    </Tooltip>
  )
}
