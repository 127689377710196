import React from 'react';
const PrerequisiteContent = ({}) => {
  return(
    <ol>
      <li>To avail the engine accessories delivery part number and serial number details from the operator / lessor</li>
      <li>To check for the latest available engine accessories part number and serial number details from the operator / lessor</li>
      <li>Physically check for the accessories part number and serial number installed on the engine</li>
      <li>Prepare the accessory list with the delivery part number, serial number and installed part number, serial number</li>
      <li>Make sure to note the TSN and CSN of the installed accessories and provide remarks (if any) for the missing accessories</li>
      <li>Make sure to update the additional data and missing hardware details</li>
      <li>Ensure the information provided is accurate</li>
    </ol>
  )
}
export default PrerequisiteContent;
