import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const errorCode = {
  part_number:{
    0:'',
    1: 'Please enter part number',
    4: errorMsg1
  },
  part_numberObj:{
    required: true,
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  part_description: {
    0: '',
    4: errorMsg1
  },
  part_descriptionObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  part_location: {
    0: '',
    4: errorMsg1
  },
  part_locationObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  reason_for_removal: {
    0: '',
    4: errorMsg1
  },
  reason_for_removalObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  price:{
    0:'',
    4: 'Please enter number value only'
  },
  priceObj:{
    regexPattern: regexConstants.numberWithDot
  },
  wcard_number:{
    0:'',
    4: 'Please enter numeric value only'
  },
  wcard_numberObj:{
    regexPattern: regexConstants.onlyNumeric
  },
}
