import React, { Fragment } from 'react';
import { TableRow, TableCell, Popover, Grid, Avatar, List, ListItem, ListItemText, ListItemAvatar, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import InfoIcon from '@material-ui/icons/Info';
import { TableRowActions, LabelValueCard } from '../../../shared_elements';
import { getTrackerRangeValueFn } from '../apiServices';
const ManPowerList = ({user}) => {
  function popOverCard(projects){
    return(
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <Fragment>
            <InfoIcon {...bindTrigger(popupState)} />
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{maxHeight:'300px', overflow:'auto'}}>
                {projects.map((project, index) =>
                  <div style={{padding:'15px 15px 0 15px', borderBottom:'1px solid #d7d7d7', maxWidth:'300px'}}>
                    <Grid container spacing={1}>
                      <List>
                      <Fragment>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar alt={project.name} src={project.profile_pic} />
                          </ListItemAvatar>
                          <ListItemText primary={project.name}></ListItemText>
                        </ListItem>
                        <Divider />
                      </Fragment>
                      </List>
                    </Grid>

                    <Grid container spacing={1}>
                      <LabelValueCard md={6} label='Start Date' value={project.start_date} />
                      <LabelValueCard md={6} label='End Date' value={project.end_date} />
                    </Grid>

                    {user.projects.map((asset, index) =>
                    <Grid container spacing={1}>
                      <LabelValueCard md={6} label='Department' value={ asset.department && asset.department.label ? asset.department.label : '--'} />
                      <LabelValueCard md={6} label='Location' value={asset.location ? asset.location : '--'} />
                    </Grid>
                  )}
                  </div>
                )}
              </div>
            </Popover>
          </Fragment>
        )}
      </PopupState>
    )
  }
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <div className="user-profile-info flex-centered">
          <span className="profile-pic">
            <Avatar alt={user.name} src={user.profile_pic} />
          </span>
          <div className="">
            <h4>{user.name} {user.rating ?<span>{parseFloat(user.rating.toFixed(2))} <i class="fa fa-star" style={{ fontSize: '12px', color: '#cccccc', position: 'relative', bottom: '1px' }}></i> </span> : ''}</h4>
            { user.designation ?
              <p className="designation">{user.designation}</p>:null
            }
            { user.department && user.department.label ?
              <p className="department">{user.department.label}</p>:null
            }
            { user.base_location ?
              <p className="location">{user.base_location}</p>:null
            }
          </div>
        </div>
      </TableCell>
      <TableCell>{`${user.projects.length} Project(s)`}
      { user.projects.length && user.projects.length != '' ?
        popOverCard(user.projects):null}
      </TableCell>
      <TableCell>{user.status}</TableCell>
      <TableCell>test</TableCell>
    </TableRow>
  )
}
export default ManPowerList;
