import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { Button, Grid } from '@material-ui/core';
import { AircraftBluePrints, Findings, InspectionFormWrapper } from '../../../Elements';
import { FabrikAudit, CustomFinding, EditAuditReport, FindingPoints, AuditSideBar, Observations, AircraftCoverImg, AuditIntroduction, AircraftDocsAndRecord } from '../components';
import { PageLoader, DeletePopUp } from '../../../../shared_elements';
import { checkApiStatus } from '../../../../utils';
import { getAuditsSectionsApi, getAuditSectionApi, getInspectionQuestionAnsApi, editAuditSectionApi, deleteCustomComponentApi, editInspectionQuestionAnsApi, exportAuditsSectionsApi, uploadInspectionCoverPicApi, getInspectionCoverPicApi, deleteFindingApi, uploadLOPAImgApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
import { addEditFindingApi } from '../../t007/apiServices';
import { returnBluePrintClass } from '../../';
const fabrikUrl = 'https://fabrik.sparta.aero/viewer/cpd/A320';
// const fabrikUrl = 'https://staging.vark.fbrk.in/viewer/cpd/A320';
const findingObj = {
  finding_type: null,
  damage_dimension: '',
  damage_limit:'',
  remarks:'',
  classifications:null,
  category:null,
  repair_type: null,
  arm_srm_reference:'',
  dd_wo_reference:'',
  corrective_actions:'',
  intervals:''
};
class InspectionFormT004 extends Component {
  constructor(props){
    super(props);
    this.state = {
      imgSrc:'',
      pageLoader:false,
      skeletonLoader:false,
      sectionId:'',
      inspectionHdInfo:{},
      upGalleys: [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169],
      auditsSections: {},
      auditSectionCrud: {
        modal:false,
        data:[]
      },
      inspectionQA: [],
      question: {},
      auditIntroModal: false,
      aircraftDocsRecordModal: false,
      coverImgModal: false,
      lopa_image_url:null,
      deleteIds:{},
      deleteModal:false,

      // Custom Findings
      hotSpotsList:[],
      stepList:[],
      customHotspots:[],
      findingCrud:{
        modal:false,
        data:findingObj,
        error:{},
        mode:''
      },
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getAuditsSectionsApi = getAuditsSectionsApi.bind(this);
    this.getAuditSectionApi = getAuditSectionApi.bind(this);
    this.editAuditSectionApi = editAuditSectionApi.bind(this);
    this.deleteCustomComponentApi = deleteCustomComponentApi.bind(this);
    this.getInspectionQuestionAnsApi = getInspectionQuestionAnsApi.bind(this);
    this.editInspectionQuestionAnsApi = editInspectionQuestionAnsApi.bind(this);
    this.exportAuditsSectionsApi = exportAuditsSectionsApi.bind(this);
    this.uploadInspectionCoverPicApi = uploadInspectionCoverPicApi.bind(this);
    this.getInspectionCoverPicApi = getInspectionCoverPicApi.bind(this);
    this.deleteFindingApi = deleteFindingApi.bind(this);
    this.uploadLOPAImgApi = uploadLOPAImgApi.bind(this);
    this.addEditFindingApi = addEditFindingApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getInspectionQuestionAnsApi(this.props);
    window.addEventListener("message", ({ data }) => {
      console.log(data, 'data 1')
      switch (data?.type) {
        // Event to Trigger the 3D initialize with real HotSpots
        case 'initialize':
            this.setState({stepList:data.stepsList});
            this.setupFabrikHotspots();
            if (!data?.setup_fabrik_success) {
              return;
            }
            break;
        // Event to Trigger the selected HotSpots
        case 'selectedHotspot':
          if(data.hotspot.title && data.hotspot.title !== 'New Point of Interest' && !data.hotspot.title.includes('Custom')){
            if(this.state.hotSpotsList.length){
              let sectionDetail = this.state.hotSpotsList.find(item => item.title.trim() == data.hotspot.title.trim());
              if(sectionDetail){
                this.setState({goToStep:data.hotspot.goToStep, sectionId:sectionDetail.id});
                this.getAuditSectionApi(this.props, {id:sectionDetail.id, name:sectionDetail.title, status:sectionDetail.status})
              }
            }
          }
          if(data.hotspot.title === 'New Point of Interest'){
            this.setState({findingCrud:{modal:true, data:{...findingObj, x_coordinate:data.hotspot.xPos, y_coordinate:data.hotspot.yPos, z_coordinate:data.hotspot.zPos}, error:{}, mode:''}})
          }
          break;
        // Event for Checking the Custom HotSpots Movement on 3D
        case 'hotspotMovement':
          const { auditSectionCrud } = this.state;
          debugger
          if(data.hotspotPositions.title && data.hotspotPositions.title.includes('Custom')){
            let payload = auditSectionCrud.data[0].inspection_findings.find(item => item.id == data.hotspotPositions.title.split('_')[1])
            this.addEditFindingApi(this.props, this.state.sectionId, {...payload, x_coordinate:data.hotspotPositions.xPos, y_coordinate:data.hotspotPositions.yPos, z_coordinate:data.hotspotPositions.zPos}, 'contentOnly')
          }else{
            this.setState({findingCrud:{modal:true, data:{...findingObj, x_coordinate:data.hotspotPositions.xPos, y_coordinate:data.hotspotPositions.yPos, z_coordinate:data.hotspotPositions.zPos}, error:{}, mode:''}})
          }
          break;
        // Event to Capture the screenshot of custom finding for Reports
        case 'screenshotData':
          this.setState({imgSrc:URL.createObjectURL(data.file)})
          break;
      }
    });
  }
  plotCustomPlots = (customHotSpots) => {
    const messages = [
        {
            type: 'updateHotspots',
            customHots:customHotSpots.length ?customHotSpots.map(item => {return{title:'Custom_'+item.id, step:this.state.goToStep, xPos:item.x_coordinate, yPos:item.y_coordinate, zPos:item.z_coordinate, bgColor:'#FFFF00'}}):[],
        },
    ];
    console.log('updateHotspots', messages);
    document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
  }
  // Custom Findings Code
  uploadFindingImgFn = (files) => {
    const { findingCrud } = this.state;
    this.setState({pageLoader:true});
    this.addEditFindingApi(this.props, this.props.sectionId, {files:files, id: findingCrud.data.id}, 'fileOnly')
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            data:response.data.data
          }
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }

  changeFindingImageFn = (id, file) => {
    this.setState({pageLoader:true});
    this.changeFindingImageApi(this.props, id, file)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            data: {
              ...prevState.findingCrud.data,
              images: prevState.findingCrud.data.images.map(item => item.id === id ? response.data.data :item)
            }
          }
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }

  updateSlider = (pictures) => {
    this.setState(prevState => ({
      ...prevState,
      sliderInfo: {
        ...prevState.sliderInfo,
        modal:true,
        images: pictures.map(item => {return {title:'', original:item.image, thumbnail:item.image}}),
        startIndex:0
      }
    }))
  }

  onCustomFieldChange = (event, keyParam, data) => {
    if(keyParam === 'reject_file'){
      this.props.enqueueSnackbar(`${data.name} File format is not supported`, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.setState(prevState => ({
        ...prevState,
        findingCrud: {
          ...prevState.findingCrud,
          data:{
            ...prevState.findingCrud.data,
            [keyParam]:data
          }
        }
      }));
    }
  }

  // .End of Custom Findings Code
  addFindingFn = () => {
    const  { findingCrud, auditSectionCrud, customHotspots, goToStep, sectionId } = this.state;
    // debugger
    this.setState({pageLoader:true});
    let customPoints =[...customHotspots, findingCrud.data]
    this.addEditFindingApi(this.props, sectionId, findingCrud.data, 'contentOnly')
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            data:{},
            error:{},
            modal:false,
            mode:''
          },
          auditSectionCrud: {
            ...prevState.auditSectionCrud,
            data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
              ...section, inspection_findings: [...section.inspection_findings, findingCrud.data]
            } : section)
          }
        }));
        this.getAuditSectionApi(this.props, {id:sectionId, name:auditSectionCrud.title, status:''})
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else if (response.data.statusCode === 100) {
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            error: response.data.error
          }
        }))
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  getScreenShot = () => {
    // Trigger the screenshot function when needed
    const messages = [{type: 'getScreenshot'}];
    document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
  }
  previewNextPrev = (type) => {
    // Trigger Next/Prev Section of the 3D Model
    const messages = [{type: type === 'Next' ? 'nextstep':'prevstep'}];
    document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
  }
  resetFabrikToInitial = () => {
    // Trigger the 3D model to Initial Stage
    const messages = [{type: 'reset'}];
    document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
    this.setupFabrikHotspots();
  }
  triggerNewPoint = () => {
    // Trigger the New or Custom HotSpot on 3D Model
    const messages = [{type: 'addNewHotspot'}];
    document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
  }
  setupFabrikHotspots = () => {
    // Trigger Initial HotSpot on 3D modal
    this.getAuditsSectionsApi(this.props)
    .then(response => {
      let hotspots = response.data.data.list.map(item => {return{id:item.id, title:item.name, status:item.status, hotspot_flickr: item.status === 1 ? true:false ,bgColor: item.status === 1 ? '#fb5353':item.status === 2 ? '#ffae00': item.status === 3 ?'#44bc29':'#468cc8'}})
      this.setState({hotSpotsList:hotspots, auditsSections: response.data.data});
      const messages = [
          {
              type: 'addHotspots',
              hotspots:hotspots,
          },
      ];
      document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
    });
  }
  onChangeSection = (e, section) => {
    const { stepList } = this.state;
    if(section && stepList.find(item => item.name == section.title)){
      this.setState({goToStep:stepList.find(item => item.name == section.title).id, sectionId:section.id});
      const messages = [
				{
					type: 'goToStep',
					step_id : stepList.find(item => item.name == section.title).id
				}
			];

			// sends the message to Fabrik
			document.getElementById('fabrikIframe').contentWindow.postMessage(messages, fabrikUrl);
      this.getAuditSectionApi(this.props, {id:section.id, name:section.title, status:section.status})
    }

  }

  getFindingResponse = (response, sectionId, id) => {
    if(id){
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, inspection_findings: section.inspection_findings.map(finding => finding.id === id ? response.data.data : finding)
          } :section)
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, inspection_findings: [...section.inspection_findings, response.data.data]
          } : section)
        }
      }))
    }
  }
  updateAnswer = (otherInfo, answers, key, value) => {
    let newAnswers = answers;
    if(answers.filter(answer => answer.label == key).length){
      newAnswers = newAnswers.map(newAnswer => newAnswer.label == key ? {...newAnswer, value: value} : newAnswer)
    }else{
      if(otherInfo.component_configuration_id){
        newAnswers = [...newAnswers, {label: key, value: value, component_configuration_id: otherInfo.component_configuration_id}]
      }else if (otherInfo.sub_component_configuration_id) {
        newAnswers = [...newAnswers, {label: key, value: value, sub_component_configuration_id: otherInfo.sub_component_configuration_id}]
      }else{
        newAnswers = [...newAnswers, {label: key, value: value }]
      }
    }
    return newAnswers;
  }
  toggleModal = (item, modalType) => {
    if(modalType === 'coverImgModal' && this.state.coverImgModal === false){
      this.getInspectionCoverPicApi(this.props);
    }
    this.setState(prevState => ({
      ...prevState,
      question: item,
      [modalType]: !prevState[modalType],
    }));
  }
  toggleModalFn = (data) => {
    this.setState({auditSectionCrud: data})
  }
  addDynamicComponent = (componentType, data, sectionId, componentId) => {
    let specs = Object.assign({}, data.specs);
    Object.keys(specs).map(key => {
      if(specs[key].is_fitted === 'true'){
        delete specs[key]
      }
    });
    delete specs.is_fitted;
    if(componentType === 'section'){
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, component_questions: section.component_questions ? [
              ...section.component_questions,
              {
                specs: specs,
                section_id: sectionId,
                answers: [],
                is_custom_component: true
              }
            ]:[
              {
                specs: specs,
                answers: []
              }
            ]
          } : section)
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, component_questions: [
              ...section.component_questions,
              {
                specs: specs,
                section_id: sectionId,
                component_id:componentId,
                answers: [],
                is_custom_component: true
              }
            ]
          } : section)
        }
      }))
    }
  }
  removeDynamicComponent = (sectionId, componentIndex, data) => {
    if(data && data.id){
      this.deleteCustomComponentApi(this.props, sectionId, componentIndex, data.id)
    }else{
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, component_questions: section.component_questions.filter((component, innerIndex) => innerIndex !== componentIndex)
          } : section)
        }
      }))
    }
  }
  addNewAnswer = () => {
    this.setState(prevState => ({
      ...prevState,
      question: {
        ...prevState.question,
        answer: {
          ...prevState.question.answer,
          value: [...prevState.question.answer.value, {title: '', content: '', flag: 0}]
        }
      }
    }))
  }
  onLOPARemarksChange = (keyParam, value, sectionId) => {
    this.setState(prevState => ({
      ...prevState,
      auditSectionCrud: {
        ...prevState.auditSectionCrud,
        data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
          ...section, lopa:
          {
            ...section.lopa,
            remarks:value
          }
        } :section)
      }
    }));
  }
  onObservationChange = (keyParam, value, sectionId, observationIndex) => {
    this.setState(prevState => ({
      ...prevState,
      auditSectionCrud: {
        ...prevState.auditSectionCrud,
        data: prevState.auditSectionCrud.data.map((section) => section.id === sectionId ? {
          ...section, physical_questions: section.physical_questions.map((observation, obIndex) => obIndex === observationIndex ? {
            ...observation, answer: {...observation.answer, [keyParam]: value}
          } :observation)
        }: section)
      }
    }))
  }
  onFieldChange = (questionObj, key, value, specsType, sectionId, componentIndex=null, subComponentIndex=null) => {
    if(componentIndex === null && subComponentIndex === null){
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, answers: this.updateAnswer(questionObj, section.answers, key, value)
          } :section)
        }
      }))
    }else if (subComponentIndex === null) {
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, component_questions: section.component_questions.map((component, innerIndex) => innerIndex === componentIndex ? {
              ...component, answers: this.updateAnswer(questionObj, component.answers, key, value)
            } : component)
          } : section)
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, component_questions: section.component_questions.map((component, innerIndex) => innerIndex === componentIndex ? {
              ...component, sub_components: component.sub_components.map((subComponent, subIndex) => subIndex === subComponentIndex ? {
                ...subComponent, answer: this.updateAnswer(questionObj, subComponent.answers, key, value)
              } : subComponent)
            } : component)
          } : section)
        }
      }))
    }
  }
  changeIntroFn = (e, keyParam, value) => {
    if(keyParam === 'title'){
      this.setState(prevState => ({
        ...prevState,
        question:{
          ...prevState.question,
          title:value
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        question:{
          ...prevState.question,
          answer: {
            ...prevState.question.answer,
            value: value
          }
        }
      }))
    }
  }
  changeSectionFn = (e, keyParam, value, index) => {
    if(keyParam === 'title' && index === undefined){
      this.setState(prevState => ({
        ...prevState,
        question: {
          ...prevState.question,
          title:value
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        question: {
          ...prevState.question,
          answer: {
            ...prevState.question.answer,
            value: prevState.question.answer.value.map((ans, ansIndex) => ansIndex === index ? {
              ...ans, [keyParam]: value
            } : ans)
          }
        }
      }))
    }
  }
  removeSectionFn = (index) => {
    this.setState(prevState => ({
      ...prevState,
      question:{
        ...prevState.question,
        answer: {
          ...prevState.question.answer,
          value: prevState.question.answer.value.filter((ans, ansIndex) => ansIndex !== index)
        }
      }
    }))
  }
  handleTabChange = (event, newValue, tabType) => {
    this.setState(prevState => ({
      ...prevState,
      auditSectionCrud: {
        ...prevState.auditSectionCrud,
        [tabType]:newValue
      }
    }))
  }
  render(){
    const { hotSpotsList, lopa_image_url, findingCrud, deleteIds, deleteModal, inspectionHdInfo, upGalleys, auditsSections, auditSectionCrud, inspectionQA, question, coverImgModal, auditIntroModal, aircraftDocsRecordModal, skeletonLoader, pageLoader, sectionId } = this.state;
    return(
      <div>
        <InspectionFormWrapper
          saveButton={false}
          inspectionHdInfo={inspectionHdInfo}
          skeletonLoader={skeletonLoader}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''},
            {title:'Consolidate Report', extension: 'pdf', key:''}
          ]}
          exportReportFn={(file) => this.exportAuditsSectionsApi(this.props, file)}
        />
        <div>
          <img src={this.state.imgSrc} />
          <Grid container spacing={3}>
            <Grid item md={auditSectionCrud.modal ? 6:12} xs={12}>
              <iframe
                id="fabrikIframe"
                style={{height:window.innerHeight-244, width:'100%'}}
                src={fabrikUrl}
              >
                    Sorry your browser does not support inline frames.
              </iframe>
            </Grid>
            { auditSectionCrud.modal ?
              <Grid item md={6} xs={12}>
                <FabrikAudit
                  inspectionHdInfo={inspectionHdInfo}
                  auditSectionCrud={auditSectionCrud}
                  handleTabChange={this.handleTabChange}
                  toggleModalFn={() => {this.toggleModalFn({modal:false, data:[]}); this.resetFabrikToInitial()}}
                  onObservationChange={this.onObservationChange}
                  onFieldChange={this.onFieldChange}
                  editAuditSectionFn={() => {this.editAuditSectionApi(this.props, auditSectionCrud.data);}}
                  addDynamicComponent={this.addDynamicComponent}
                  removeDynamicComponent={this.removeDynamicComponent}
                  getFindingResponse={this.getFindingResponse}
                  deleteFindingFn={(id, sectionId) => this.setState({deleteModal:true, deleteIds:{id: id, sectionId: sectionId}})}
                  uploadLOPAImgFn={(sectionId, file) => this.uploadLOPAImgApi(this.props, sectionId, file)}
                  onLOPARemarksChange={this.onLOPARemarksChange}
                  triggerNewPoint={this.triggerNewPoint}
                  previewNextPrev={() => this.previewNextPrev()}
                  hotSpotsList={hotSpotsList}
                  onChangeSection={this.onChangeSection}
                />
              </Grid>:null
            }
          </Grid>
        </div>
        <div className="form-t004-cn" style={{display:'none'}}>
          <div>
            <FindingPoints />
            { inspectionQA.length ?
              <AuditSideBar inspectionQA={inspectionQA} toggleModalFn={(item, modalType) => this.toggleModal(item, modalType)} />:null
            }
          </div>
          {  Object.keys(auditsSections).length && auditsSections.list.length ?
            <div className={returnBluePrintClass(auditsSections)}>
              <div className="model-image">
                <img src={'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/'+auditsSections.aircraft_model.blueprint.image}  alt="aircraft-model" />
                { auditsSections.list.map((item, index) =>
                  <AircraftBluePrints
                    bluePrintId={auditsSections.aircraft_model.blueprint.id}
                    index={index}
                    key={index}
                    auditSectionFn={() => this.getAuditSectionApi(this.props, item)}
                    sectionDetail={item}
                  />
                )}
              </div>
            </div>:null
          }
        </div>

        { coverImgModal ?
          <AircraftCoverImg
            modal={coverImgModal}
            lopa_image_url={lopa_image_url}
            uploadCoverPicFn={(file) => this.uploadInspectionCoverPicApi(this.props, file)}
            toggleModalFn={() => this.toggleModal(null, 'coverImgModal')}
          />:null
        }

        { aircraftDocsRecordModal && question && question.id === 1 ?
          <AuditIntroduction
            modal={aircraftDocsRecordModal}
            question={question}
            toggleModalFn={() => this.toggleModal(null,'auditIntroModal')}
            onFieldChange={this.changeIntroFn}
            saveQuestionAnsFn = {() => this.editInspectionQuestionAnsApi(this.props, question)}
          />:null
        }
        { aircraftDocsRecordModal && question &&  question.id !== 1 ?
          <AircraftDocsAndRecord
            modal={aircraftDocsRecordModal}
            question={question}
            addNewAnswer={this.addNewAnswer}
            toggleModalFn={() => this.toggleModal(null, 'aircraftDocsRecordModal')}
            onFieldChange={this.changeSectionFn}
            saveQuestionAnsFn = {() => this.editInspectionQuestionAnsApi(this.props, question)}
            removeSectionFn={this.removeSectionFn}
          />:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => this.setState({deleteModal:false, deleteIds:{}}) }
          title="Delete Finding"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.deleteFindingApi(this.props, deleteIds)}
        />
        <CustomFinding
          findingCrud={findingCrud}
          toggleModalFn={() => {this.setState({findingCrud:{modal:false, data:{}, error:{}, mode:''}}); this.getAuditSectionApi(this.props, {id:sectionId, name:auditSectionCrud.title, status:''})}}
          uploadFindingImgFn={this.uploadFindingImgFn}
          updateSlider={() => console.log('hji')}
          changeFindingImageFn={this.changeFindingImageFn}
          onFieldChange={this.onCustomFieldChange}
          updateErrorField={() => console.log('hji')}
          addFindingFn={this.addFindingFn}
        />
        { pageLoader ? <PageLoader />:null}
      </div>
    )
  }
}
export default withSnackbar(InspectionFormT004);
