import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, Container, Tabs, Tab, Paper, TextField, Link, Tooltip, IconButton, Avatar } from '@material-ui/core';
import { PageLoader } from '../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddCommentIcon from '@material-ui/icons/AddComment';
import CloseIcon from '@material-ui/icons/Close';
import { addEditReviewCommentApi, getFormIssueListApi, getFormIssueApi, toggleFormIssueStatusApi, deleteFormIssueApi, addCommentToIssueApi, resolveCommentToIssueApi, resolveAllCommentsApi } from '../reviewer/apiServices';
import { localTimeFn } from '../../utils';
class ReviewIssuesComp extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      tabIndex:2,
      issueCrud:{
        modal:false,
        data:{}
      },
      issueDetail: {
        modal:false,
        data:{}
      },
      issueListDrawer:false,
      description:'',
      formIssueList:[],
      message:''
    }
    this.addEditReviewCommentApi = addEditReviewCommentApi.bind(this);
    this.deleteFormIssueApi = deleteFormIssueApi.bind(this);
    this.getFormIssueListApi = getFormIssueListApi.bind(this);
    this.getFormIssueApi = getFormIssueApi.bind(this);
    this.toggleFormIssueStatusApi = toggleFormIssueStatusApi.bind(this);
    this.resolveAllCommentsApi = resolveAllCommentsApi.bind(this);
    this.addCommentToIssueApi = addCommentToIssueApi.bind(this);
    this.resolveCommentToIssueApi = resolveCommentToIssueApi.bind(this);
  }
  toggleModalFn = (data) => {
    this.setState(prevState => ({
      ...prevState,
      issueCrud: data
    }))
  }
  updateDesc = (value) => {
    this.setState(prevState => ({
      ...prevState,
      issueCrud: {
        ...prevState.issueCrud,
        data: {
          ...prevState.issueCrud.data,
          description: value
        }
      }
    }))
  }
  addEditReviewCommentFn = () => {
    const  { issueCrud } = this.state;
    if(issueCrud.data.description.trim() !== ''){
      this.setState({pageLoader:true});
      this.addEditReviewCommentApi(this.props, issueCrud.data)
      .then(response => {
        this.setState({pageLoader:false});
        if(response.data.statusCode === 200){
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          if(issueCrud.data.location_slug){
            this.getFormIssueListApi(this.props);
          }else{
            this.props.loadSectionFn();
          }
          this.setState({issueCrud:{modal:false, data:{}}})
        }
      })
    }else{
      this.props.enqueueSnackbar('Please enter Description', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  }
  deleteFormIssueFn = (slug) => {
    this.setState({pageLoader:true});
    this.deleteFormIssueApi(this.props, slug)
    .then(response => {
      this.setState({pageLoader:false});
      if(response.data.statusCode === 200){
        this.getFormIssueListApi(this.props);
        this.props.loadSectionFn();
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  render(){
    const { type, payload, issue } = this.props
    const { pageLoader, tabIndex, message, issueCrud, issueDetail, issueListDrawer, description, formIssueList } = this.state;
    let filteredIssues = tabIndex === 2 ? formIssueList : formIssueList.filter(item => item.status === tabIndex);
    return(
      <Fragment>
        { type === 'add' ? <AddCommentIcon fontSize="large" onClick={() => this.toggleModalFn({modal:true, data:{description:'', ...payload} })} color="primary" />:null }
        { type === 'view' ? <Avatar onClick={() => this.getFormIssueListApi(this.props)} color="primary">{`#${issue.order_number}`}</Avatar>:null }
        <Drawer anchor='right' open={issueListDrawer}>
          <div className="forms-review-issues-section" style={{width: '420px'}}>
            <Fragment>
              <h1>
                Issues List
                <span className="close-drawer" onClick={()=>this.setState({issueListDrawer:false, formIssueList:[], tabIndex:2})}><CloseIcon /></span>
              </h1>
              <div className="form-issues-list" style={{height: window.innerHeight-48, overflow:'auto'}}>
                <ul className="list-inline issues-tabs">
                  <li onClick={() => this.setState({tabIndex:2})} className={`list-inline ${tabIndex === 2 ? 'active':''}`}>All</li>
                  <li onClick={() => this.setState({tabIndex:0})} className={`list-inline ${tabIndex === 0 ? 'active':''}`}>Unresolved</li>
                  <li onClick={() => this.setState({tabIndex:1})} className={`list-inline ${tabIndex === 1 ? 'active':''}`}>Resolved</li>
                </ul>
                {filteredIssues.map(item =>
                  <div className="form-issues-card">
                    { item.status === 1 ?
                      <p className="resolved-info">
                        <span className="issued-date" style={{color: '#7ed321'}}>
                          Resolved on {item.action_date ? `${localTimeFn(item.action_date)} UTC `:''}
                          { item.action_performed_by && item.action_performed_by.name ?
                            `By ${item.action_performed_by.name}`:''
                          }
                        </span>
                      </p>:null
                    }
                    <div>
                      <Avatar className={`issue-id ${ item.status === 1 ? 'resolved-id':''}`} sizes="20" >{`#${item.order_number}`}</Avatar>
                      <div className="form-issue-info">
                        <h4 onClick={() => this.getFormIssueApi(this.props, item.location_slug)}>{item.user.name}</h4>
                        <span className="date">Post on {item.created_at ? `${localTimeFn(item.created_at)} UTC`:''}</span>
                        <p>{item.description}</p>
                        { item.status === 0 ?
                          <ul className="list-inline flex-centered cta-list">
                            <li className="list-inline-item">
                              <Button variant="outlined" size="small" color="primary" onClick={() => this.toggleFormIssueStatusApi(this.props, item.location_slug , {status:1})}>Resolve</Button>
                            </li>
                            <li className="list-inline-item">
                              <EditIcon onClick={() => this.toggleModalFn({modal:true, data:item})} />
                            </li>
                            <li className="list-inline-item">
                              <DeleteOutlineIcon onClick={() => this.deleteFormIssueFn(item.location_slug)} />
                            </li>
                          </ul>:null
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          </div>
        </Drawer>
        <Drawer anchor='right' open={issueDetail.modal}>
          <div className="forms-review-issues-section" style={{width: '400px'}}>
            { issueDetail.modal ?
              <Fragment>
                <h1>
                  Issue Discussion
                  <span className="close-drawer" onClick={()=>this.setState({issueDetail:{modal:false, data:{}}})}><CloseIcon /></span>
                </h1>
                <div className="form-issues-list" style={{height:window.innerHeight- (issueDetail.data.status === 0 ? 177 : 48), overflow:'auto'}}>
                  <div className="form-issues-card">
                    { issueDetail.data.status === 1 ?
                      <p className="resolved-info">
                        <span className="issued-date">
                          Resolved on
                          { issueDetail.data.action_date ? ` ${localTimeFn(issueDetail.data.action_date)} UTC `:'' }
                          { issueDetail.data.action_performed_by && issueDetail.data.action_performed_by.name ? `By ${issueDetail.data.action_performed_by.name}`:''}
                        </span>
                      </p>:null
                    }

                    <div>
                      <Avatar sizes="20" className="issue-id">{`#${issueDetail.data.order_number}`}</Avatar>
                      <div className="form-issue-info">
                        <h4>{ issueDetail.data.user ? issueDetail.data.user.name:'' }</h4>
                        { issueDetail.data.action_date ? <span className="date">Post on {localTimeFn(issueDetail.data.action_date)} UTC</span>:null}

                        <p>{issueDetail.data.description}</p>
                        <ul className="list-inline flex-centered cta-list">
                          {issueDetail.data.status === 0 ?
                            <li className="list-inline-item">
                              <Button variant="outlined" size="small" color="primary" onClick={() => this.resolveAllCommentsApi(this.props, issueDetail.data.location_slug)}>Resolve All</Button>
                            </li>:
                            <li className="list-inline-item">
                              <Button variant="outlined" size="small" color="primary" onClick={() => this.toggleFormIssueStatusApi(this.props, issueDetail.data.location_slug, { status:0 })}>Reopen</Button>
                            </li>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="issue-discussion-list">
                    {issueDetail.data.issue_comments.map(item =>
                      <div className="issue-discuss">
                        { item.status === 1 ?
                          <p className="resolved-info">
                            <span className="issued-date">
                              Resolved on
                              { item.action_date ? ` ${localTimeFn(item.action_date)} UTC `:'' }
                              { item.action_performed_by && item.action_performed_by.name ? `By ${item.action_performed_by.name}`:''}
                            </span>
                          </p>:null
                        }
                        <h5>{item.user && item.user.name ? item.user.name:''}</h5>
                        { item.created_at ?
                          <span className="date">Post on {localTimeFn(item.created_at)} UTC</span>:null
                        }
                        <p>{item.message}</p>
                        { item.status === 0 ?
                          <ul className="list-inline flex-centered cta-list">
                            <li className="list-inline-item">
                              <Button variant="outlined" size="small" color="primary" onClick={() => this.resolveCommentToIssueApi(this.props, issueDetail.data.location_slug, item.id, {status:1})}>Resolve</Button>
                            </li>
                          </ul>:null
                        }
                      </div>
                    )}
                  </div>
                </div>
                { issueDetail.data.status === 0 ?
                  <div style={{position:'fixed', bottom:0, width:'380px', padding:'10px', background:'#f9f5f5'}}>
                    <TextField
                      id="message"
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      margin="normal"
                      multiline
                      rows="2"
                      value={message ? message:''}
                      onChange={(e) => this.setState({message:e.target.value})}
                      InputLabelProps={{shrink: true}}
                    />
                    <Button variant="contained" color="primary" onClick={() => this.addCommentToIssueApi(this.props, issueDetail.data.location_slug, {message:message})}>Post</Button>
                  </div>:null
                }
              </Fragment>:null
            }
          </div>
        </Drawer>

        <Dialog
          open={issueCrud.modal}
          onClose={() => this.toggleModalFn({modal:false, data:{} })}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
             Post Issue
          </DialogTitle>
          <DialogContent dividers={true}>
            <form>
              <TextField
                id="item_description"
                label="Description"
                fullWidth
                inputProps={{ maxLength: 255 }}
                margin="normal"
                multiline
                rows="4"
                value={issueCrud.data.description ? issueCrud.data.description:''}
                onChange={(e) => this.updateDesc(e.target.value)}
                InputLabelProps={{shrink: true}}
                style={{width: isMobile ? 'auto':'440px'}}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => this.addEditReviewCommentFn()}>Save</Button>
            <Button variant="outlined" color="primary" onClick={() => this.toggleModalFn({modal:false, data:{} })}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {pageLoader ? <PageLoader /> :null }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(ReviewIssuesComp));
