import { globalGetService, globalPostService } from '../utils/globalApiServices';
import { checkApiStatus } from '../utils';
export function getWhatsNewFeaturesListApi(props={}){
  this.setState({pageLoader:true});
  globalGetService('api/features/')
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        whtsNewModal:true,
        whtsNewList: response.data.data
      })
    }
  })
}
export function getFaqsListApi(props={}){
  this.setState({pageLoader:true});
  globalGetService('console/faq/')
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        faqsModal:true,
        faqsList: response.data.data
      })
    }
  })
}
export function getReportAnIssueConstantsApi(props={}, data){
  globalPostService('console/get-constants/', data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        pltConstants:response.data.data
      })
    }
  })
}
export function submitAnIssueApi(props, data){
  let formData = new FormData();
  formData.append('title', data.title);
  formData.append('description', data.description);
  formData.append('severity', data.severity);
  formData.append('module', data.module);
  formData.append('classification', data.classification);
  formData.append('current_page_url',window.location.href);
  data.attachments.map((file, index) => {
    formData.append('attachments', file);
  });

  globalPostService('console/report-bug/', formData)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({modal:false, error:{}, issueInfo:{}});
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function getRevisionHistoryApi(url, queryParams){
  this.setState({pageLoader:true});
  globalGetService(url, queryParams)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({revisionHistories:response.data.data, open:true})
    }
  })
}
export function getHeaderCartCountApi(){
  globalGetService(`console/cart/count/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        headerCount:response.data.data
      })
    }
  })
}
export function lesseeListApi(){
  this.setState({ skeletonLoader : true})
  globalGetService(`console/lessees/`, {})
  .then(response => {
    this.setState({ skeletonLoader:false})
    if(checkApiStatus(response)){
      this.setState({
        lesseeList:response.data.data.lessees
      })
    }
  })
}
