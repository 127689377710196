import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
const ProjectTabs = ({tabIndex, count, handleTabChange}) => {
  return(
    <Tabs
      value={tabIndex}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons={isMobile ? 'on':'auto'}
    >
      <Tab label={`All (${count.all})`} value="all" />
      <Tab label={`Up Coming (${count.upcoming})`} value="upcoming" />
      <Tab label={`On Going (${count.ongoing})`} value="ongoing" />
      <Tab label={`Submitted (${count.submitted})`} value="submitted" />
      <Tab label={`Overdue (${count.overdue})`} value="overdue" />
      <Tab label={`Completed (${count.completed})`} value="completed" />
      <Tab label={`Archived (${count.archived})`} value="archived" />
    </Tabs>
  )
}
export default ProjectTabs;
