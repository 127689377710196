import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Grid, Tooltip, IconButton, TextField, Badge } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import UserDropDownMenu from './UserDropDownMenu';
import WhatsNewCompleteList from './WhatsNewCompleteList';
import FAQsList from './FAQsList';
import ReportAnIssue from './ReportAnIssue';
import PageLoader from './PageLoader';
import { getLocalStorageInfo } from '../../utils';
import { getWhatsNewFeaturesListApi, getFaqsListApi, getHeaderCartCountApi } from '../apiServices';
class DesktopHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      formSubmitLoader:false,
      whtsNewModal:false,
      whtsNewList:[],
      faqsModal:false,
      faqsList:[],
      headerCount:{}
    }
    this.getWhatsNewFeaturesListApi = getWhatsNewFeaturesListApi.bind(this);
    this.getFaqsListApi = getFaqsListApi.bind(this);

    this.getHeaderCartCountApi = getHeaderCartCountApi.bind(this);
  }
  componentDidMount(){
    this.getHeaderCartCountApi();
  }
  render(){
    const { whtsNewModal, whtsNewList, faqsModal, faqsList, headerCount, pageLoader, formSubmitLoader } = this.state;
    return(
      <Fragment>
        <header className="desktop-header">
          <Container maxWidth={false}  style={{padding:'0px 5px'}}>
            <Grid container  spacing={0} alignItems="center" justify="center">
              <Grid item xs={5}>
                <ul className="list-inline app-logo-search flex-centered">
                  <li className="list-inline-item app-logo">
                    <Link to="/">
                      <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" />
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={7}>
                <ul className="list-inline header-right-nav flex-centered">
                  <li className="list-inline-item">
                    <Tooltip title="What's New" arrow>
                      <IconButton size="small" color="primary" onClick={() => this.getWhatsNewFeaturesListApi(this.props)}>
                        <RecordVoiceOverIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </li>
                  <li className="list-inline-item">
                    <ReportAnIssue type="head" />
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="FAQs" arrow>
                      <IconButton size="small" color="primary" onClick={() => this.getFaqsListApi(this.props)}>
                        <QuestionAnswerIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="Notifications" arrow>
                      <IconButton size="small" color="primary">
                        <Link to="/notifications">
                          <Badge max={99} badgeContent={headerCount.notification_count? headerCount.notification_count:0} color="secondary">
                            <NotificationsActiveIcon color="primary" fontSize="small" />
                          </Badge>
                        </Link>
                      </IconButton>
                    </Tooltip>
                  </li>
                  { getLocalStorageInfo().user.permission.console.cart ?
                    <li className="list-inline-item">
                      <IconButton size="small" color="primary">
                        <Link to="/license/cart">
                          <Badge max={99} badgeContent={headerCount.card_count? headerCount.card_count:0} color="secondary">
                            <ShoppingCartIcon />
                          </Badge>
                        </Link>
                      </IconButton>
                    </li>:null
                  }

                  <li className="list-inline-item" style={{width:'200px'}}>
                    <Autocomplete
                      options = {getLocalStorageInfo().lessor_list}
                      clearOnBlur={false}
                      getOptionLabel={option => option.name}
                      disableClearable={true}
                      filterSelectedOptions={true}
                      value={{id:getLocalStorageInfo().defaultLessor.id, name: getLocalStorageInfo().defaultLessor.name}}
                      renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </li>
                  <li className="list-inline-item">
                    <UserDropDownMenu
                      logout={() => {localStorage.clear(); this.props.history.push('/login')}}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
          { whtsNewModal ?
            <WhatsNewCompleteList toggleModalFn={() => this.setState({whtsNewModal:false})} modal={whtsNewModal} whtsNewList={whtsNewList} />:null
          }
          { faqsModal ?
            <FAQsList toggleModalFn={() => this.setState({faqsModal:false})} modal={faqsModal} faqsList={faqsList} />:null
          }
        </header>
        <ReportAnIssue type="foot" />
        { pageLoader || formSubmitLoader ? <PageLoader/>:null }
      </Fragment>
    )
  }
}
export default withRouter(DesktopHeader);
