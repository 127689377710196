import React, { Fragment, Component } from 'react';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemAvatar, ListItemText, Checkbox, ListItemSecondaryAction, CircularProgress } from '@material-ui/core';
const ProjectUserPopUp = ({userListPopup, selectProjectUserFn, toggleModalFn, addUserToProject, assignToAllFormFn}) => {
  return(
    <Dialog
      open={userListPopup.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {userListPopup.title}
      </DialogTitle>
      <DialogContent dividers={true}>
        <List>
          {userListPopup.userList.map((item, index) =>
            <ListItem key={index} role={undefined} dense button>
              <ListItemAvatar>
                <Avatar
                  alt=''
                  src={item.profile_pic}
                />
              </ListItemAvatar>
              <ListItemText id={item.id} primary={item.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={() => selectProjectUserFn(item.id)}
                  checked={userListPopup.selectedUser.indexOf(item.id) !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
        <div className="text-right">
          { userListPopup.type === '2' ?
            <p>
            <Checkbox
              onChange={(e) => assignToAllFormFn(e.target.checked)}
              checked={userListPopup.assign_to_all}
            /> Assign to all Form</p>:null
          }
          <Button variant="contained" onClick={addUserToProject} color="primary">Save</Button>

        </div>
      </DialogActions>
    </Dialog>
  )
}
export default ProjectUserPopUp;
