import React, { Component } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from "react-infinite-scroll-component";
import { TableListComp, FilterUIComp, EmptyCollection, ExportManu, PageLoader } from '../../../shared_elements';
import { removeEmptyKey, convertFilterObject } from '../../../utils';
import { STabsLoader, STableLoader } from '../../../shared_elements/loaders';
import { CorList } from '../components';
import { getCorTrackerApi, exportCorTrackerFormApi } from '../apiServices';
import { corFilterOptions } from '../';
export default class CorTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader:false,
      tabIndex:2,
      corTrackerInfo:{
        projects:[],
        pagination:{}
      },
      filter: {},
      applyingFilter:{},
      page:1
    }
    this.getCorTrackerApi = getCorTrackerApi.bind(this);
    this.exportCorTrackerFormApi = exportCorTrackerFormApi.bind(this);
  }
  componentDidMount(){
    this.getCorTrackerApi(this.props, {page:1}, 'skeletonLoader');
  }
  handleChange = (event, newValue) => {
    this.props.history.push(newValue === 0 ? '/projects/tracker' : newValue === 1 ? '/man-power/tracker':'/cor/tracker');
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getCorTrackerApi(this.props, {...query, page:1}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  moveNextPage = () => {
    const { filter } = this.state;
    this.getCorTrackerApi(this.props, {...filter, page:this.state.page+1}, 'loadmore');
    this.setState({
      page: this.state.page + 1
    });
  }
  reportDetailHd = () => {
    return(
      <table className="cor-hd" style={{width:'100%',}}>
        <thead>
          <tr>
            <th align="center" colSpan="8">REPORT DETAILS IN AVERAGE</th>
          </tr>
          <tr>
            <th><p style={{width:'100px'}}>General</p></th>
            <th><p style={{width:'100px'}}>Physical Inspection</p></th>
            <th><p style={{width:'100px'}}>Maintenance</p></th>
            <th><p style={{width:'100px'}}>Technical Records</p></th>
            <th><p style={{width:'100px'}}>Operation Of Aircraft</p></th>
            <th><p style={{width:'100px'}}>GeoPolitical</p></th>
            <th><p style={{width:'100px'}}>Overall Rating</p></th>
            <th><p style={{width:'100px'}}>Download Report</p></th>
          </tr>
        </thead>
      </table>
    )
  }

  render(){
    const { tabIndex, filter, applyingFilter, corTrackerInfo, skeletonLoader, pageLoader } = this.state;
    const filterOptions = corFilterOptions;
    return(
      <div className="project-tracker-section">
        <div className="tracker-title">
          <h1>Trackers</h1>
          <p>Offers a view of the Projects status and Manpower status as applicable</p>
        </div>
        { skeletonLoader ? <STabsLoader count={3} />:
          <Tabs
            value={tabIndex}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons={isMobile ? 'on':'auto'}
          >
            <Tab label="Project" />
            <Tab label="Man Power" />
            <Tab label="COR" />
          </Tabs>
        }

        { skeletonLoader ? <STableLoader count={5} /> :
          <div className="cor-table">
            <span style={{float: 'right', padding: '5px 0'}}>
              <ExportManu disabled={corTrackerInfo.projects && corTrackerInfo.projects.length ? false:true} title="Export" files={[{title:'PDF', extension: 'pdf', key:''}, {title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportCorTrackerFormApi(this.props, {...filter}, file)} />
            </span>
            <FilterUIComp
              filter={filter}
              applyingFilter={applyingFilter}
              removeFilter={this.removeFilter}
              removeAllFilter={() => this.getCorTrackerApi(this.props, {page: 1}, 'pageLoader')}
              submitFilter={() => this.getCorTrackerApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')}
              applyFilter={this.applyFilter}
              filterMenu={filterOptions}
            />
            <InfiniteScroll
              dataLength={corTrackerInfo.projects ? corTrackerInfo.projects.length : 0}
              next={() => this.moveNextPage()}
              hasMore={corTrackerInfo.projects && corTrackerInfo.pagination && corTrackerInfo.projects.length < corTrackerInfo.pagination.total}
              loader={<h4 style={{textAlign: 'center'}}>Loading...</h4>}
              endMessage={
                <p style={{textAlign: 'center'}}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <TableListComp
                heads={[
                  { label: 'Project Name', sortOption: false },
                  { label: 'Operator', sortOption: false },
                  { label: 'Project Engineer', sortOption: false },
                  { label: 'Status', sortOption: false },
                  { label: this.reportDetailHd(),sortOption: false}
                ]}
                data={corTrackerInfo.projects.map((item,index) =>
                  <CorList
                    item={item}
                  />
                )}
                noRecord={ corTrackerInfo.projects.length ? null:
                  <EmptyCollection title="No records found" />
                }
              />
            </InfiniteScroll>
          </div>
        }
        { pageLoader ? <PageLoader />:null }
      </div>
    )
  }
}
