import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {Paper} from '@material-ui/core';
import { PageLoader, TableListComp, FilterUIComp, ExportManu, EmptyCollection } from '../../../shared_elements';
import { STabsLoader, STableLoader } from '../../../shared_elements/loaders';
import { removeEmptyKey, convertFilterObject, getLocalStorageInfo } from '../../../utils';
import { ProjectAnalyticList, ProjectsChart } from '../components';
import { getAnalyticProjectsApi, getAnalyticProjectsGrpApi } from '../apiServices';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { projectsFilterOptions,  projectAnalyticHd } from '../'
import RefreshIcon from '@material-ui/icons/Refresh';
class ProjectsAnalyticTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:true,
      filter: {},
      country_code:'',
      applyingFilter:{},
      projectsDetail:{list:[], pagination:{}},
      projectsGrph:{plots:[], general_info:{}}
    }
    this.getAnalyticProjectsApi = getAnalyticProjectsApi.bind(this);
    this.getAnalyticProjectsGrpApi = getAnalyticProjectsGrpApi.bind(this);
  }
  componentDidMount(){
    this.getAnalyticProjectsApi(this.props, {list:true}, 'skeletonLoader');
    this.getAnalyticProjectsGrpApi(this.props, {list:false}, 'skeletonLoader');
    this.props.getPlatformConstants();
  }

  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getProjectTrackerApi(this.props, {...query, page:1}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  render(){
    const { country_code, filter, applyingFilter, projectsDetail, projectsGrph, skeletonLoader,  pageLoader } = this.state;
    const { pltConstants } = this.props;
    let filterOptions = projectsFilterOptions;
    filterOptions = {
      ...filterOptions,
      name: {
        ...filterOptions.name,
        options: pltConstants.filter(item => item.type === 'project_name')
      },
      project_departments: {
        ...filterOptions.project_departments,
        options: pltConstants.filter(item => item.type === 'project_departments')
      }
    }
    return(
      <section className="resources-analytic-tracker">
        <div className="analytic-head">
          <h2>Analytic</h2>
          <p>This analytic represents the overall technical manpower resource and projects globally for Acumen Aviation only.</p>
          <ul className="list-inline">
            <li className="list-inline-item" onClick={()=>this.props.history.push(`/tracker/analytic/resources`)}>Resources Tracker</li>
            <li className="list-inline-item active">Projects Tracker</li>
          </ul>
        </div>
        <div className="analytic-blk">
          <div className="flex-centered">
            <div>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Paper>
                    <h4 style={{color:'#5d3cdb'}}>199</h4>
                    <p style={{color:'#5d3cdb'}}>Completed</p>
                  </Paper>
                </li>
                <li className="list-inline-item">
                  <Paper>
                    <h4 style={{color:'#df8c0c'}}>71</h4>
                    <p style={{color:'#df8c0c'}}>Ongoing</p>
                  </Paper>
                </li>
                <li className="list-inline-item">
                  <Paper>
                    <h4 style={{color:'#2eaeb5'}}>71</h4>
                    <p style={{color:'#2eaeb5'}}>Upcoming</p>
                  </Paper>
                </li>
              </ul>
            </div>
            <div style={{marginLeft:'auto'}}>
              <ul className="list-inline flex-centered">
                <li className="list-inline-item">
                  <RefreshIcon style={{cursor:'pointer'}} onClick={() => { this.getAnalyticProjectsApi(this.props, {list:true}, 'pageLoader'); this.getAnalyticProjectsGrpApi(this.props, {list:false}, 'pageLoader');}} color="primary" />
                </li>
                <li className="list-inline-item">
                  <span style={{float: 'right', padding: '5px 0'}}>
                    <ExportManu title="Export" files={[{title:'Excel', extension: 'xls', key:''}]} />
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div>
            { skeletonLoader ? <STableLoader count={10} />:
              <Fragment>

                <FilterUIComp
                   filter={filter}
                   applyingFilter={applyingFilter}
                   removeFilter={this.removeFilter}
                   removeAllFilter={() => this.getAnalyticProjectsApi(this.props, {}, 'pageLoader')}
                   submitFilter={() => this.getAnalyticProjectsApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')}
                   applyFilter={this.applyFilter}
                   filterMenu={filterOptions}
                 />
                 { projectsGrph.plots.length ?
                   <ProjectsChart
                      plots={projectsGrph.plots.map(plotItem => {return{...plotItem, value:plotItem.count}})}
                      onClickCountry={(country_code) =>{this.getAnalyticProjectsGrpApi(this.props, {list:false, country_code:country_code}, 'pageLoader'); this.getAnalyticProjectsApi(this.props, {...filter, list:true, country_code:country_code}, 'pageLoader'); this.setState({country_code:country_code})}}
                   />
                   :null
                 }
                <TableListComp
                  heads={projectAnalyticHd}
                  data={ projectsDetail.list.map((item,index) =>
                    <ProjectAnalyticList
                      key={index}
                      index={index+1}
                      item={item}
                    />
                  )}
                  noRecord={ projectsDetail.list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                  pagination={projectsDetail.pagination}
                  onChangePage={(event, newPage) => this.getAnalyticProjectsApi(this.props, {...filter, country_code:country_code, list:true, page:newPage+1, per_page: projectsDetail.pagination.per_page}, 'pageLoader')}
                  onChangeRowsPerPage={(event) => this.getAnalyticProjectsApi(this.props, {...filter, country_code:country_code, list:true, page:1, per_page: event.target.value}, 'pageLoader')}
                />
              </Fragment>
            }
            { pageLoader ? <PageLoader />:null }
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['project_departments', 'project_name']}))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsAnalyticTracker)
