import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const CircularProgress = ({width=200, height=200, value}) => {
  return(
    <div style={{ width: 30, height: 30 }}>
      <CircularProgressbar
        text={`${(value/100).toFixed(2)*100}%`}
        value={value}
        styles={buildStyles({
          rotation: 1,
          strokeLinecap: 'butt',
          textSize: '30px',
          pathTransitionDuration: 0.5,
          textColor: '#f88',
          trailColor: '#d6d6d6',
          backgroundColor: '#3e98c7',
        })}
      />
    </div>
  )
}
export default CircularProgress;
