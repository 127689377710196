import React, { Component, Fragment } from 'react';
import { InspectionFormWrapper } from '../../Elements';
import { TableListComp,  EmptyCollection, PageLoader } from '../../../shared_elements';
import { RobberyList } from '../components';
import { getFormReviewListApi } from '../apiServices';
import { getInspectionHeaderApi } from '../../forms/t001/apiServices';
class InspectionReviewT009 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      inspectionHdInfo:{},
      t009ReviewList: {
        list:[],
        pagination:{}
      }
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.getFormReviewListApi = getFormReviewListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getFormReviewListApi(this.props, 't009ReviewList');
  }
  render(){
    const { t009ReviewList, inspectionHdInfo, skeletonLoader } = this.state;
    return(
      <Fragment>
        <InspectionFormWrapper reviewerForm={true} skeletonLoader={skeletonLoader} inspectionHdInfo={inspectionHdInfo} />
        <div className="technical-review-forms-cn">
          {t009ReviewList.list.map((item,index) =>
            <RobberyList
              key={index}
              index={index}
              item={item}
              loadSectionFn={() => this.getFormReviewListApi(this.props, 't009ReviewList')}
            />
          )}
        </div>
      </Fragment>
    )
  }
}
export default InspectionReviewT009;
