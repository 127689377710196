import React, {  Component } from 'react';
export default function userRoleBaseAccessHoc(HocComponent, extraProps=[]){
  return class extends Component{
    // constructor(props) {super(props)}
    render(){
      return (
        <HocComponent {...this.props} />
      )
      // return(
      //   <img style={{maxWidth: '100%'}} src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/permissions/mr_calculator_dashboard.jpg" alt="" />
      // )
    }
  }
}
