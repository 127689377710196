import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { Tabs, Tab, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { regexConstants } from '../../../../constants/regEx';
import { getTechSpecsConfigApi, saveTechSpecsConfigApi } from '../apiServices';
class TechSpecsConfig extends Component {
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader: false,
      techSpecs:[],
      tabIndex: 0,
      modal:false
    }
    this.getTechSpecsConfigApi = getTechSpecsConfigApi.bind(this);
    this.saveTechSpecsConfigApi = saveTechSpecsConfigApi.bind(this);
  }
  componentDidMount(){}
  changeTab = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue
    }))
  }
  toggleModalFn = () => {
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal
    }))
  }
  onFieldChange = (e, keyParams, value, index, specsIndex) => {
    this.setState(prevState => ({
			...prevState,
			techSpecs: prevState.techSpecs.map((data, dataIndex) => dataIndex == index ? {
				...data,
				seat_specs: data.seat_specs.map((specData, specDataIndex) =>  specsIndex == specDataIndex ?  {
					...specData,
					answers :  {
						...specData.answers,
						[keyParams] : value
					}
				} : specData)
			}	: data
			)
		}));
  }
  checkAPITest = () => {
    this.getTechSpecsConfigApi(this.props)
    .then(response => {
      
    })
  }
  render(){
    const { formSubmitLoader, techSpecs, modal, tabIndex } = this.state;
    const { manufacturers } = this.props;
    return(
      <Fragment>
        <span onClick={this.checkAPITest}>Technical Specification</span>
        <Dialog
          fullScreen
          open={modal}
          onClose={this.toggleModalFn}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            Technical Specification
          </DialogTitle>
          <DialogContent dividers={true}>
            { modal ?
              <form>
                <Tabs
                  value={tabIndex}
                  onChange={this.changeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons={isMobile ? 'on':'auto'}
                >
                  {techSpecs.map((item, index) =>
                    <Tab key={index} label={item.name}  />
                  )}
                </Tabs>
                { techSpecs.map((item, index) =>
                    index === tabIndex &&
                    <Fragment>
                      {item.seat_specs.map((specs, specsIndex) =>
                        <div className="t008-specs-modal">
                          <h4>{specs.name}</h4>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <TextField
                                id="part_number"
                                label="Part Number"
                                fullWidth
                                margin="normal"
                                value={specs.answers.part_number ? specs.answers.part_number:''}
                                onChange={(e) => this.onFieldChange(e, 'part_number', e.target.value, index, specsIndex)}
                                InputLabelProps={{shrink: true}}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                id="serial_number"
                                label="Serial Number"
                                fullWidth
                                margin="normal"
                                value={specs.answers.serial_number ? specs.answers.serial_number:''}
                                onChange={(e) => this.onFieldChange(e, 'serial_number', e.target.value, index, specsIndex)}
                                InputLabelProps={{shrink: true}}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Autocomplete
                                options = {manufacturers}
                                getOptionLabel={option => option.name}
                                id="manufacturer"
                                value={''}
                                onChange={(e, value) => this.onFieldChange(e, 'manufacturer', value)}
                                renderInput={params => <TextField {...params} label="Manufacturer" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Fragment>
                )}
              </form>:null
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleModalFn} color="primary">Cancel</Button>
            <Button style={{width:'120px'}} disabled={formSubmitLoader} variant="contained" onClick={() => this.saveTechSpecsConfigApi(this.props, techSpecs)} color="primary">
              { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save' }
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(TechSpecsConfig))
