import React, { Component, Fragment } from 'react';
import { InspectionFormWrapper, ReviewIssuesComp } from '../../Elements';
import { PageLoader } from '../../../shared_elements';
import { getFormReviewListApi } from '../apiServices';
import { getInspectionHeaderApi } from '../../forms/t001/apiServices';
class InspectionReviewT002 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      inspectionHdInfo:{},
      t002ReviewList: []
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.getFormReviewListApi = getFormReviewListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getFormReviewListApi(this.props, 't002ReviewList')
  }
  render(){
    const { t002ReviewList, inspectionHdInfo, skeletonLoader, pageLoader } = this.state;
    return(
      <Fragment>
        <InspectionFormWrapper reviewerForm={true} skeletonLoader={skeletonLoader} inspectionHdInfo={inspectionHdInfo} />
        <div className="technical-review-forms-cn">
          { t002ReviewList.map((section, sectionIndex) =>
            <div className="t001-images">
              <h3>{section.name}</h3>
              <ul className="list-unstyled">
                { section.pictures.map((pic, picIndex) =>
                  <li>
                    <span className="add-issue">
                      { pic.issue && pic.issue.id ?
                        <ReviewIssuesComp type="view" issue={pic.issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't002ReviewList')}  />
                        :
                        <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't002ReviewList')} type="add" payload={{aircraft_section:pic.aircraft_section, picture_id:pic.id, work_order_id:pic.work_order, form_id:2}} />
                      }
                    </span>
                    <img className="section-image" src={pic.image} />
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        { pageLoader ? <PageLoader />:null }
      </Fragment>
    )
  }
}
export default InspectionReviewT002;
