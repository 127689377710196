import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Tabs, Tab, Popover, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import TimelineIcon from '@material-ui/icons/Timeline';
import { PageLoader } from '../../../shared_elements';
import { fieldDateFormat } from '../../../constants';
import { utilizationManPowerTrackerApi } from '../apiServices';
require('highcharts/modules/exporting')(Highcharts);
class UtilizationGraph extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      pageLoader:false,
      utilizationInfo:{},
      utilization_year:null
    }
    this.utilizationManPowerTrackerApi = utilizationManPowerTrackerApi.bind(this);
  }
  toggleModalFn = () => {
    this.setState({utilizationModal:false})
  }
  render(){
    const { modal, utilization_year, utilizationInfo, pageLoader } = this.state;
    let data = [];
    let categories = [];
    let options = [];
    if(modal){
      let year=moment(utilizationInfo.start_date,"YYYY-MM-DD").year();
      for (let i = 1; i < 13; i++) {
         let d = [];
        if(utilizationInfo.utilizations[i]==null){
         categories.push(moment(new Date(year+"/"+i+"/01")).format('MMM YYYY'));
         data.push(0);
        }else{
          categories.push(moment(year+"/"+i+"/01").format('MMM YYYY'));
          data.push(parseFloat((utilizationInfo.utilizations[i].percentage).toFixed(2)));
        }
      }
      options = {
        chart: {
          type: 'area',
          zoomType: 'x',
        },
        title: {
          text: ''
        },
        xAxis: {
          title: {
            text: 'Duration (Months)'
          },
          categories: categories
        },
        yAxis:{
          title: {
            text: 'Manpower Usage (%)'
          },
          labels: {
            formatter: function () {
              return this.value+"%";
            }
          },
          gridLineDashStyle: 'longdash'
        },
        tooltip: {
          formatter: function () {
              return 'Month <b>' + this.x +
                  '<br/></b>Percentage(%) <b>' + this.y + '</b>';
          }
        },
        credits: {
          enabled: false
        },
        series:  [{
          showInLegend: false,
          name: 'Hours',
          data: data,
          color: '#FEF7D1',
          lineColor: '#F8D41C',
          marker: {
            fillColor: '#F8D41C'
          }
        }],
        exporting: {
          buttons: {
            contextButton: {
              text: 'Export',
              menuItems: [
                {
                  text: 'Export as PNG',
                  onclick: function () {
                    this.exportChart();
                  }
                },
                {
                  text: 'Export as SVG',
                  onclick: function () {
                    this.exportChart({ type: 'image/svg+xml' });
                  }
                },
                {
                  text: 'Export as PDF',
                  onclick: function () {
                    this.exportChart({ type: 'application/pdf' });
                  }
                }
              ]
            }
          }
        }
      }
    }

    return(
      <Fragment>
        <span className="utilization-graph flex-centered" onClick={() => this.utilizationManPowerTrackerApi(this.props)}><TimelineIcon color="primary" /> Show Utilization</span>
        { modal ?
          <Dialog
            open={modal}
            fullScreen
            onClose={this.toggleModalFn}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              Utilization
            </DialogTitle>
            <DialogContent dividers={true}>
              <div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     required
                     id="year"
                     label="Filter By Year"
                     disableFuture={true}
                     views={['year']}
                     clearable={true}
                     InputLabelProps={{shrink: true}}
                     inputProps={{readOnly: true}}
                     value={utilization_year ? `01-01-${utilization_year}`:null}
                     onChange={(data, value) => this.utilizationManPowerTrackerApi(this.props,  data ? {utilization_year: moment(data).year()}:{})}
                   />
                </MuiPickersUtilsProvider>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.toggleModalFn}>Cancel</Button>
            </DialogActions>
          </Dialog>:null
        }
        {pageLoader ? <PageLoader />:null }
      </Fragment>
    )
  }
}
export default withRouter(UtilizationGraph);
