import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, TextField, Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import { dropzonePreviewUI } from '../../../../constants';
import { getLopaInfoApi, updateLopaInfoApi } from '../apiServices';
class LopaConfig extends Component {
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader:false,
      modal:false,
      lopaConfig:{
        lopa_image_url:'',
        lopa_remarks:'',
        file:[]
      }
    }
    this.getLopaInfoApi = getLopaInfoApi.bind(this);
    this.updateLopaInfoApi = updateLopaInfoApi.bind(this);
  }
  componentDidMount(){

  }
  onFieldChange = (keyParam, value) => {
    if(keyParam === 'file'){
      let file = URL.createObjectURL(value[0]);
      this.setState(prevState => ({
        ...prevState,
        lopaConfig:{
          ...prevState.lopaConfig,
          [keyParam]:value,
          lopa_image_url:URL.createObjectURL(value[0])
        }
      }));
    }else{
      this.setState(prevState => ({
        ...prevState,
        lopaConfig:{
          ...prevState.lopaConfig,
          [keyParam]:value
        }
      }))
    }
  }
  render(){
    const { formSubmitLoader, modal, lopaConfig } = this.state;
    const { is_lopa_details } = this.props;
    return(
      <Fragment>
        <span onClick={() => this.getLopaInfoApi(this.props, is_lopa_details)}>View LOPA</span>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              View LOPA
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <div style={{width:'250px'}}>
                      { lopaConfig.lopa_image_url ?
                        <div>
                          <img style={{maxWidth:'200px'}} src={lopaConfig.lopa_image_url} alt="LOPA" />
                          <div style={{margin: '10px 0 10px 0'}}>
                            <div>
                              <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                style={{display:'none'}}
                                onChange={(e) => this.onFieldChange('file', e.target.files)}
                              />
                              <label htmlFor="contained-button-file">
                                <Button size="small" variant="outlined" color="primary" component="span">
                                  Change LOPA
                                </Button>
                              </label>
                            </div>
                          </div>
                        </div>:
                        <DropzoneArea
                          filesLimit={1}
                          maxFileSize={10000000}
                          showPreviews={false}
                          showPreviewsInDropzone={false}
                          acceptedFiles={['image/*']}
                          dropzoneClass="drag-drop-cnt"
                          showAlerts={['error', 'info']}
                          onDrop={(files) => this.onFieldChange('file', files)}
                          alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                        />
                      }
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="lopa_remarks"
                      label="Remarks"
                      fullWidth
                      margin="normal"
                      value={lopaConfig.lopa_remarks ? lopaConfig.lopa_remarks:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange('lopa_remarks', e.target.value)}
                    />
                  </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false})} color="primary">Cancel</Button>
              <Button style={{width:'120px'}} disabled={formSubmitLoader} variant="contained" onClick={() => this.updateLopaInfoApi(this.props, lopaConfig)} color="primary">
                { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save' }
              </Button>
            </DialogActions>
          </Dialog>:null
        }

      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(LopaConfig));
