import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Avatar } from '@material-ui/core';
const ProjectUserProfile = ({userProfile, toggleModalFn}) => {
  return(
    <Dialog
      open={userProfile.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogContent dividers={true}>
        <div className="project-engineer-profile-modal">
          <div className="text-center">
            <Avatar src={userProfile.data.profile_pic} />
            <h3>{userProfile.data.name}</h3>
          </div>
          <table>
            <tr>
              <td>Email</td>
              <td>{userProfile.data.email}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{userProfile.data.contact_no ? userProfile.data.contact_no:'--'}</td>
            </tr>
            <tr>
              <td>Department</td>
              <td>{userProfile.data.department && userProfile.data.department.label ? userProfile.data.department.label:-''}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>{userProfile.data.city ? userProfile.data.city:'--'}</td>
            </tr>
            <tr>
              <td>Country</td>
              <td>{userProfile.data.base_location ? userProfile.data.base_location:'--'}</td>
            </tr>
            <tr>
              <td>Category</td>
              <td>{userProfile.data.category && userProfile.data.category.label ? userProfile.data.category.label:'--'}</td>
            </tr>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default ProjectUserProfile;
