import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../constants';
export default function DateComp({label, value, keyParam, disabled, onFieldChange}){
  return(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
         margin="normal"
         id={keyParam}
         label={label}
         format={fieldDateFormat}
         fullWidth
         inputProps={{readOnly: true}}
         clearable={true}
         InputLabelProps={{shrink: true}}
         value={value ? value:null}
         disabled={disabled}
         onChange={(data, value) => onFieldChange(value, keyParam, data ? moment(data).format(backendDateFormat):null)}
       />
    </MuiPickersUtilsProvider>
  )
}
