import moment from 'moment';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, dataURItoBlob } from '../../../utils';
export function getDataPlateListApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/data-plate-pictures/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    this.setState({skeletonLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        dataPlates: response.data.data
      })
    }
  })
}
export function exportDataPlateListApi(props={}, file={}){
  this.setState({pageLoader:true});
  globalExportService(`technical/workorder/${props.match.params.workOrderSlug}/data-plate-pictures/`, {download:file.extension})
  .then(response => {
    this.setState({pageLoader:false});
    downloadFileType(response.data, (`TE002_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.setState({pageLoader: false});
    this.props.enqueueSnackbar('Data Plate Pictures Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function addDataPlatesApi(props={}, data={}){
  var formData = new FormData();
  formData.append('data_plate_id', data.plateId);
  formData.append('aircraft_section_id', data.sectionId);
  if(data.picture_id){
    formData.append('picture_id', data.picture_id);
  }
  formData.append('file', data.files[0], data.files[0].name);
  this.setState({pageLoader:true});
  globalFileUploadService(`technical/workorder/${props.match.params.workOrderSlug}/data-plate-pictures/upload/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        dataPlates: [...prevState.dataPlates].map(section => section.id === response.data.data.id ? {
          ...section, pictures: response.data.data.pictures
        } :section)
      }));
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function deleteDataPlatesApi(props={}, deleteIds={}){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/delete-pictures/`, deleteIds)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        dataPlates: [...prevState.dataPlates].map(section => section.id === deleteIds.id ? {
          ...section, pictures: []
        } :section),
        deleteModal:false,
        deleteIds: {}
      }))
    }
  })
}
export function annotateDataPlatesApi(props={}, file,  annotated={}){
  this.setState({pageLoader:true});
  dataURItoBlob(file).then(editedImage => {
    let formData = new FormData();
    formData.append('file', editedImage);
    globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/general-pictures/${annotated.data.id}/`, formData)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          imgAnnotation:{modal:false, sectionId:'', data:{}},
          dataPlates: prevState.dataPlates.map(section => section.id === annotated.sectionId ? {
            ...section, pictures:response.data.data.pictures
          }:section)
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  })
}
