import moment from 'moment';
import { globalGetService, globalPostService, globalPutService, globalDeleteService, globalExportService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
export function assigneeBasedPlotApi(props={}){
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/assignee-plot-data/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({userStats:response.data.data})
    }
  })
}
export function getIssueReportTableApi(props={}, query={}){
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/gap-report-sections/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        gapReport: response.data.data,
        skeletonLoader:false
      })
    }
  })
}
export function exportIssueReportTableApi(props={}, file={}){
  this.setState({pageLoader: true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/gap-report-sections/`, {download:file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE011_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.props.enqueueSnackbar('PRS Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function getIssueReportListApi(props={}, query={}){
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/gap-report/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        rptIssues: response.data.data
      })
    }
  })
}
export function getIssueReportApi(params={}, query={}){
  globalGetService(``, query)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
export function addIssueReportApi(props={}, data={}){
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/gap-report/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        reportCrud:{data:{}, error:{}, modal:false}
      }));
      this.getIssueReportListApi(props, {section: data.gap_report_section_id})
    }
  })
}
export function editIssueReportApi(props={}, data={}, key, value, sectionId, rowIndex){
  let description = key === 'description' ? value: data.description;
  if(description.trim() === ''){
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        rowIndex: rowIndex+1,
        description: 'Please enter Ddescription'
      }
    }))
  }else{
    let formData = new FormData();
    formData.append([key],value);
    formData.append('gap_report_section_id',sectionId);
    if(key !== 'description'){
      formData.append('description',data.description);
    }
    globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/gap-report/${data.id}/`, formData)
    .then(response => {
      if(checkApiStatus(response)){

      }
    })
  }
}
export function deleteIssueReportApi(props={}, data={}, sectionId){
  globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/gap-reports/delete/`,{gap_report_ids:[]})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({deleteModal:false, deleteIds:[]});
      this.getIssueReportListApi(props, {section: sectionId})
    }
  })
}
export function movePRSIssuesApi(props={}, data){
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/move-items/`, {item_ids:[],to_section_id:''})
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
export function assignPRSIssuesApi(props={}, data){
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/multiple-assignee/`, {item_ids:[],assignee_id:''})
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
export function editGeneralReportApi(props={}, data={}){
  globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/gap-report/`, data)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
export function deletedPRSReportApi(props={}){
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/deleted-gap-reports/`, {per_page:200})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({deletedLogs:response.data.data, logsStatus:true});
    }
  })
}
export function projectUsersApi(props={}){
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/project-users/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({projectUsers:response.data.data});
    }
  })
}
