import React, { Component } from 'react';
import { Tabs, Tab, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { TableListComp, EmptyCollection, DeletePopUp, PageLoader } from '../../../../shared_elements';
import { MissingHardware, AdditinalData, AccessoriesList, GeneralDetail, PrerequisiteContent } from '../components';
import { InspectionFormWrapper, ReadPrerequisite } from '../../../Elements';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { getPowerPlantDetailApi, editPowerPlantDetailApi, deletePowerPlantDetailApi, exportPowerPlantDetailApi } from '../apiServices';
import { accessoriesList, additionalData, missingHardware, te002AccessoriesListHd, te002AdditionalDataHd, te002MissingHardwareHd } from '../../../';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
import { fieldValidation } from '../../../../utils/formValidation';
import { errorCode } from '../';
class InspectionFormTE002 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      tabIndex: 0,
      inspectionHdInfo:{},
      powerPlantInfo: {
        general_details: {},
        accessories_list:[],
        additional_data: [],
        missing_hardware: []
      },
      error: {
        general_details:{},
        accessories_list:[],
        additional_data: [],
        missing_hardware: []
      },
      deleteModal: false,
      deleteIds:{},
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getPowerPlantDetailApi = getPowerPlantDetailApi.bind(this);
    this.editPowerPlantDetailApi = editPowerPlantDetailApi.bind(this);
    this.deletePowerPlantDetailApi = deletePowerPlantDetailApi.bind(this);
    this.exportPowerPlantDetailApi = exportPowerPlantDetailApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getPowerPlantDetailApi(this.props);
  }
  handleChange = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      powerPlantInfo: {
        ...prevState.powerPlantInfo,
        general_details: {
          ...prevState.powerPlantInfo.general_details,
          [keyParam]: data
        }
      }
    }))
  }
  onComponentChange = (event, keyParam, data, index, tableType) => {
    this.setState(prevState => ({
      ...prevState,
      powerPlantInfo: {
        ...prevState.powerPlantInfo,
        [tableType]: [...prevState.powerPlantInfo[tableType]].map((component, componentIndex) =>  componentIndex == index ?  {
          ...component,
          [keyParam]: data
         }: component )
      }
    }));
  }
  addComponent = (tableType, data) => {
    this.setState(prevState => ({
      ...prevState,
      powerPlantInfo: {
        ...prevState.powerPlantInfo,
        [tableType]: [...prevState.powerPlantInfo[tableType], data]
      }
    }))
  }
  deleteTableComFn = () => {
    const { deleteIds } = this.state;
    if(deleteIds.id){
      this.deletePowerPlantDetailApi(this.props, deleteIds)
    }else{
      this.setState(prevState => ({
        ...prevState,
        powerPlantInfo: {
          ...prevState.powerPlantInfo,
          [deleteIds.type]: [...prevState.powerPlantInfo[deleteIds.type]].filter((item, index) => index !== deleteIds.deleteIndex)
        },
        deleteIds:{},
        deleteModal:false
      }))
    }
  }
  handleError = (value, key) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        general_details: {
          ...prevState.error.general_details,
          [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
        }
      }
    }));
  }
  resetErrorKey = (key) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        general_details: {
          ...prevState.error.general_details,
          [key]: ''
        }
      }
    }));
  }
  render(){
    const { inspectionHdInfo, tabIndex, powerPlantInfo, deleteModal, deleteIds, skeletonLoader, pageLoader, error } = this.state;
    return(
      <div className="technical-engine-forms">
        <InspectionFormWrapper
          saveButton={true}
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          onSaveChanges={() => this.editPowerPlantDetailApi(this.props, powerPlantInfo)}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[{title:'PDF', extension: 'pdf', key:''}]}
          exportReportFn={(file) => this.exportPowerPlantDetailApi(this.props, file)}
        />
        <h4 className="gen-detail-title">General Details <ReadPrerequisite content={<PrerequisiteContent />} /></h4>
        <GeneralDetail
          inspectionHdInfo={inspectionHdInfo}
          generalDetail={powerPlantInfo.general_details}
          onFieldChange={this.onFieldChange}
          error={error.general_details}
          handleError={this.handleError}
          resetErrorKey={(key) => this.resetErrorKey(key)}
        />
        <Tabs
          value={tabIndex}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={isMobile ? 'on':'auto'}
        >
          <Tab label="Accessories List" />
          <Tab label="Additional Data" />
          <Tab label="Missing Hardware" />
        </Tabs>
        { tabIndex === 0 &&
          <div className="accessories-list-table">
            { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
              <div className="text-right" style={{margin: '0 0 20px 0'}}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  disableElevation={true}
                  onClick={()=>this.addComponent('accessories_list', accessoriesList)}
                  startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                >
                    Add Item
                </Button>
              </div>:null
            }
            <TableListComp
              heads={ inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ? [...te002AccessoriesListHd, { label: 'Action', sortOption: false, actionCell: true }]:te002AccessoriesListHd}
              data={powerPlantInfo.accessories_list.map((item,index) =>
                <AccessoriesList
                  inspectionHdInfo={inspectionHdInfo}
                  key={index}
                  item={item}
                  error={error.accessories_list.length && error.accessories_list[index] ? error.accessories_list[index] :{}}
                  onFieldChange={(event, keyParam, data) => this.onComponentChange(event, keyParam, data, index, 'accessories_list')}
                  previewDetail={(mode) => { this.setState({deleteModal:true, deleteIds:{...item, deleteIndex: index, type: 'accessories_list'}}) }}
                />
              )}
              noRecord={ powerPlantInfo.accessories_list.length ? null:
                <EmptyCollection title="No records found" />
              }
            />
          </div>
        }
        { tabIndex === 1 &&
          <div className="">
            { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
              <div className="text-right" style={{margin: '0 0 20px 0'}}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  disableElevation={true}
                  onClick={()=>this.addComponent('additional_data', additionalData)}
                  startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                >
                  Add Item
                </Button>
              </div>:null
            }

            <TableListComp
              heads={ inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ? [...te002AdditionalDataHd, { label: 'Action', sortOption: false, actionCell: true }]: te002AdditionalDataHd}
              data={powerPlantInfo.additional_data.map((item,index) =>
                <AdditinalData
                  inspectionHdInfo={inspectionHdInfo}
                  key={index}
                  item={item}
                  error={error.additional_data.length && error.additional_data[index] ? error.additional_data[index]:{}}
                  onFieldChange={(event, keyParam, data) => this.onComponentChange(event, keyParam, data, index, 'additional_data')}
                  previewDetail={(mode) => { this.setState({deleteModal:true, deleteIds:{...item, deleteIndex: index, type:'additional_data'}}) }}
                />
              )}
              noRecord={ powerPlantInfo.additional_data.length ? null:
                <EmptyCollection title="No records found" />
              }
            />
          </div>
        }
        { tabIndex === 2 &&
          <div className="">
            { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
              <div className="text-right" style={{margin: '0 0 20px 0'}}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  disableElevation={true}
                  onClick={()=>this.addComponent('missing_hardware', missingHardware)}
                  startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                >
                  Add Item
                </Button>
              </div>:null
            }
            <TableListComp
              heads={ inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ? [...te002MissingHardwareHd, { label: 'Action', sortOption: false, actionCell: true }]:te002MissingHardwareHd}
              data={powerPlantInfo.missing_hardware.map((item,index) =>
                <MissingHardware
                  inspectionHdInfo={inspectionHdInfo}
                  key={index}
                  item={item}
                  error={error.missing_hardware.length && error.missing_hardware[index] ? error.missing_hardware[index]:{}}
                  onFieldChange={(event, keyParam, data) => this.onComponentChange(event, keyParam, data, index, 'missing_hardware')}
                  previewDetail={(mode) => { this.setState({deleteModal:true, deleteIds:{...item, deleteIndex: index, type: 'missing_hardware'}}) }}
                />
              )}
              noRecord={ powerPlantInfo.missing_hardware.length ? null:
                <EmptyCollection title="No records found" />
              }
            />
          </div>
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{}})}}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={this.deleteTableComFn}
        />
        { pageLoader ? <PageLoader />:null }
      </div>
    )
  }
}

export default withSnackbar(withRouter(InspectionFormTE002))
