import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip, TextField, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { CkEditorText } from '../../../../shared_elements';
export default function AircraftDocsAndRecord({modal, question, toggleModalFn, addNewAnswer, onFieldChange, saveQuestionAnsFn, removeSectionFn}){
  return(
    <Dialog
      open={modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
      fullScreen
    >
      <DialogTitle id="scroll-dialog-title">
        <TextField
          id="name"
          margin="normal"
          fullWidth
          value={question.title}
          InputLabelProps={{shrink: true}}
          onChange={(e) => onFieldChange(e,'title', e.target.value)}
        />
      </DialogTitle>
      { modal ?
        <DialogContent dividers={true}>
          { question.answer && question.answer.value && question.answer.value.map((item, index) =>
            <ExpansionPanel key={index} className="working-groups-section">
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id='12'
              >
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <TextField
                      id="name"
                      margin="normal"
                      placeholder="Order"
                      value={item.index}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => onFieldChange(e,'index', e.target.value, index)}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="name"
                      fullWidth
                      margin="normal"
                      placeholder="Section Title"
                      value={item.title}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => onFieldChange(e,'title', e.target.value, index)}
                    />
                  </Grid>
                </Grid>
                { question.answer.value.length > 1 ?
                  <span onClick={() => removeSectionFn(index)}>
                    <DeleteOutlineIcon color="secondary" />
                  </span>:null
                }
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="" style={{width:'100%', clear:'both'}}>
                  <CkEditorText
                    htmlData={item.content}
                    onChangeData={(data) => onFieldChange('', 'content', data, index)}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </DialogContent>:null
      }

      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
        <Button onClick={addNewAnswer} color="primary" variant="outlined">+ Add New Section</Button>
        <Button onClick={saveQuestionAnsFn} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}
