import axios from 'axios';
import config from '../config';
var axiosInstance = axios.create();
axiosInstance.defaults.baseURL = config.api.networkInterface;
axiosInstance.interceptors.request.use(function (config) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(userInfo){
      config['headers']['Authorization'] = userInfo.access;
    }
    return config;
},function (error) {
  return Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return error.response;
  }
);
export default axiosInstance;
