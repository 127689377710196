import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Popover } from '@material-ui/core';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from 'react-device-detect';
import InfoIcon from '@material-ui/icons/Info';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { PageLoader, TableListComp, FilterUIComp, ExportManu } from '../../../shared_elements';
import { STabsLoader, STableLoader } from '../../../shared_elements/loaders';
import { removeEmptyKey, convertFilterObject, getLocalStorageInfo } from '../../../utils';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { displayDateFormatShort } from '../../../constants';
import { ProjectTrackerList } from '../components';
import { getProjectTrackerApi, exportProjectTrackerApi } from '../apiServices';
import { projectFilterOptions } from '../'
class ProjectTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:true,
      tabIndex:0,
      projectTrackerDtls: {},
      filter: {},
      applyingFilter:{},
      page:1
    }
    this.getProjectTrackerApi = getProjectTrackerApi.bind(this);
    this.exportProjectTrackerApi = exportProjectTrackerApi.bind(this);
  }
  componentDidMount(){
    this.getProjectTrackerApi(this.props, {page:1}, 'skeletonLoader');
    this.props.getPlatformConstants();
  }
  handleChange = (event, newValue) => {
    this.props.history.push(newValue === 0 ? '/projects/tracker' : newValue === 1 ? '/man-power/tracker':'/cor/tracker');
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getProjectTrackerApi(this.props, {...query, page:1}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  moveNextPage = () => {
    const { filter } = this.state;
    this.getProjectTrackerApi(this.props, {...filter, page:this.state.page+1}, 'loadmore');
    this.setState({
      page: this.state.page + 1
    });
  }
  render(){
    const { tabIndex, filter, applyingFilter, projectTrackerDtls, skeletonLoader,  pageLoader } = this.state;
    const { pltConstants } = this.props;
    let filterOptions = projectFilterOptions;
    filterOptions = {
      ...filterOptions,
      department: {
        ...filterOptions.department,
        options: this.props.pltConstants.filter(item => item.type === 'project_departments')
      },
      project_type: {
        ...filterOptions.project_type,
        options: this.props.pltConstants.filter(item => item.type === 'project_name')
      }
    }
    if( getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR'))){
      filterOptions = {
        ...filterOptions,
        lessor_id:{
          inputType: 'dropdown',
          placeholder: 'Select Lessor',
          options: getLocalStorageInfo().lessor_list,
          labelKey: 'name',
          valueKey: 'id',
          title: 'Lessor',
          multiple: true
        }
      }
    }
    return(
      <Fragment>
        <div className="project-tracker-section">
          <div className="tracker-title">
            <h1>Trackers</h1>
            <p>Offers a view of the Projects status and Manpower status as applicable</p>
          </div>
          { skeletonLoader ? <STabsLoader count={3} />:
            <Tabs
              value={tabIndex}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={isMobile ? 'on':'auto'}
            >
              <Tab label="Project" />
              <Tab label="Man Power" />
              <Tab label="COR" />
            </Tabs>
          }
          { skeletonLoader ? <STableLoader count={6} />:
            <div className="">
              <ul className="list-inline flex-centered tracker-export">
                <li className="list-inline-item">
                    <ExportManu disabled={projectTrackerDtls.projects && projectTrackerDtls.projects.length ? false:true} title="Export" files={[{title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportProjectTrackerApi(this.props, {...filter}, file)} />
                </li>
                <li className="list-inline-item">
                  <PopupState variant="popover" popupId="project-tracker-info">
                    {(popupState) => (
                      <Fragment>
                        <InfoIcon {...bindTrigger(popupState)} />
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <div className="tracker-info-popover" style={{maxHeight:'300px', maxWidth:'280px', overflow:'auto'}}>
                            <h3>Project Status</h3>
                            <div className="list-card">
                              <ul className="list-unstyled">
                                <li>
                                  <span style={{background:'#2993ff'}}></span>Upcoming
                                  <ul className="list-unstyled">
                                    <li>
                                      <span style={{background:'#9ed5ff'}}></span> Initated - Project is setup in the system in advance, but the project is not yet started, applicable to all planned projects.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <span style={{background:'#2993ff'}}></span> Ongoing
                                  <ul className="list-unstyled">
                                    <li><span style={{background:'#9b9a9b'}}></span> Launched/In Progress: Project is setup in the system in advance, but the project is not yet started, applicable to all planned projects</li>
                                    <li><span style={{background:'#2c6fb2'}}></span> Draft - Project is completed by REP and with PM for final QC.</li>
                                    <li><span style={{background:'#ecd910'}}></span> Review - Completed report issued to client for review and feedback</li>
                                    <li><span style={{background:'#89d153'}}></span> Approved</li>
                                  </ul>
                                </li>
                                <li><span style={{background:'#ff0100'}}></span> Overdue - Project end date which has crossed the due date</li>
                              </ul>
                            </div>
                          </div>
                        </Popover>
                      </Fragment>
                    )}
                  </PopupState>
                </li>
              </ul>
              <FilterUIComp
                filter={filter}
                applyingFilter={applyingFilter}
                removeFilter={this.removeFilter}
                removeAllFilter={() => this.getProjectTrackerApi(this.props, {page: 1}, 'pageLoader')}
                submitFilter={() => this.getProjectTrackerApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')}
                applyFilter={this.applyFilter}
                filterMenu={filterOptions}
              />
              <InfiniteScroll
                dataLength={ projectTrackerDtls.projects ? projectTrackerDtls.projects.length:0}
                next={() => this.moveNextPage()}
                hasMore={projectTrackerDtls.projects && projectTrackerDtls.pagination && projectTrackerDtls.projects.length < projectTrackerDtls.pagination.total}
                loader={<h4 style={{textAlign: 'center'}}>Loading...</h4>}
                endMessage={
                  <p style={{textAlign: 'center'}}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <TableListComp
                  heads={[
                    {label:'Project Name', sortOption:false},
                    {label:'Assets', sortOption:false},
                    {label:'Status', sortOption:false},
                    {label:'Remarks', sortOption:false},
                    {label:<p><span>Project Period</span><br/>{`${moment(projectTrackerDtls.start_date).format(displayDateFormatShort)} - ${moment(projectTrackerDtls.end_date).format(displayDateFormatShort)}`}</p>, sortOption:false}
                  ]}
                  data={ projectTrackerDtls.projects && projectTrackerDtls.projects.map((project, index) =>
                    <ProjectTrackerList
                      project={project}
                      csd={projectTrackerDtls.start_date}
                      ced={projectTrackerDtls.end_date}
                    />
                  )}
                />
              </InfiniteScroll>
            </div>
          }
        </div>
        {pageLoader ? <PageLoader/>:null}
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['project_departments', 'project_name']}))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ProjectTracker))
