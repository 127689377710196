import React, { Component } from 'react';
import { PageLoader } from '../../../shared_elements';
import { getFormReviewListApi } from '../apiServices';

class InspectionReviewT004 extends Component {
  constructor(props){
    super(props);
    this.state = {
      t004ReviewList: []
    }
    this.getFormReviewListApi = getFormReviewListApi.bind(this);
  }
  componentDidMount(){
    this.getFormReviewListApi(this.props, 't004ReviewList');
  }
  render(){
    return(
      <h1>InspectionReviewT004</h1>
    )
  }
}
export default InspectionReviewT004;
