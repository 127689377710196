import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ProgressBar from './ProgressBar';
const FormsDropdown = ({toggleModalFn, forms, match, history, inspectionHdInfo, reviewerForm}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event, form) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    if(form){
      history.push(`/${match.params.assetType}/${match.params.assetSlug}/${match.params.workOrderSlug}/forms/${form.slug}/${form.name.toLowerCase()}`)
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  return(
    <Fragment>
      <div
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={(e) => {!reviewerForm ? handleToggle():e.preventDefault()}}
        style={{position: 'relative', cursor: !reviewerForm ? 'pointer':'default'}}
      >
        <h2>{inspectionHdInfo.current_form && inspectionHdInfo.current_form.name ? inspectionHdInfo.current_form.name:'' }</h2>
        <p className="form-name">{inspectionHdInfo.current_form && inspectionHdInfo.current_form.title ? inspectionHdInfo.current_form.title:'' }</p>
        <ProgressBar progress={inspectionHdInfo.current_form && inspectionHdInfo.current_form.progress ? inspectionHdInfo.current_form.progress:0 } />
        { !reviewerForm ?
          <ArrowDropDownIcon style={{position:'absolute', right:'0', top:'0'}} />:null
        }

      </div>
      <Popper placement="bottom-end" style={{zIndex: '9'}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <div className="forms-list-cn">
                    {forms.map((form, index) =>
                      <MenuItem onClick={(event) => handleClose(event,form)}>
                        <div className="forms-dropdown-list">
                          <h4>{form.name}</h4>
                          <p>{form.title}</p>
                        </div>
                      </MenuItem>
                    )}
                  </div>

                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

export default withRouter(FormsDropdown);
