import React from 'react';
import { Paper, TextField, Grid } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { LabelValueCard } from '../../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
export default function GeneralDetail({inspectionHdInfo, generalDetail, onFieldChange}){
  return(
    <Paper elevation={1} className="form-detail-card">
      <Grid container spacing={3}>
        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="inspection_performed_from"
                 label="Inspection Performed From"
                 format={fieldDateFormat}
                 fullWidth
                 disableFuture={true}
                 inputProps={{readOnly: true}}
                 clearable={true}
                 InputLabelProps={{shrink: true}}
                 value={generalDetail.inspection_performed_from ? generalDetail.inspection_performed_from:null}
                 // onChange={(data, value) => onFieldChange(value, 'inspection_performed_from', moment(data).format(backendDateFormat))}
                 onChange={(data, value) => {onFieldChange(value, 'inspection_performed_from', data ? moment(data).format(backendDateFormat):data);}}
               />
            </MuiPickersUtilsProvider>
          </Grid>:
          <LabelValueCard xs={12} md={4} label='Inspection Performed From' value={generalDetail.inspection_performed_from ? moment(generalDetail.inspection_performed_from).format(displayDateFormatShort):'--'} />
        }

        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="inspection_performed_till"
                 label="Inspection Performed Till"
                 format={fieldDateFormat}
                 fullWidth
                 disableFuture={true}
                 inputProps={{readOnly: true}}
                 clearable={true}
                 InputLabelProps={{shrink: true}}
                 disabled={ generalDetail.inspection_performed_from ? false:true}
                 value={generalDetail.inspection_performed_till ? generalDetail.inspection_performed_till:null}
                 // onChange={(data, value) => onFieldChange(value, 'inspection_performed_till', moment(data).format(backendDateFormat))}
                 onChange={(data, value) => {onFieldChange(value, 'inspection_performed_till', data ? moment(data).format(backendDateFormat):data);}}
               />
            </MuiPickersUtilsProvider>
          </Grid>:
          <LabelValueCard xs={12} md={4} label='Inspection Performed Till' value={generalDetail.inspection_performed_till ? moment(generalDetail.inspection_performed_till).format(displayDateFormatShort):'--'} />
        }

        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="report_date"
                 label="Report Date"
                 format={fieldDateFormat}
                 fullWidth
                 disableFuture={true}
                 inputProps={{readOnly: true}}
                 clearable={true}
                 InputLabelProps={{shrink: true}}
                 value={generalDetail.report_date ? generalDetail.report_date:null}
                 // onChange={(data, value) => onFieldChange(value, 'report_date', moment(data).format(backendDateFormat))}
                 onChange={(data, value) => {onFieldChange(value, 'report_date', data ? moment(data).format(backendDateFormat):data);}}
               />
            </MuiPickersUtilsProvider>
          </Grid>:
          <LabelValueCard xs={12} md={4} label='Report Date' value={generalDetail.report_date ? moment(generalDetail.report_date).format(displayDateFormatShort):'--'} />
        }

        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
          <Grid item xs={12} md={3}>
            <TextField
              id="report_issued_place"
              label="Report Issued Place"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 30}}
              value={generalDetail.report_issued_place}
              onChange={(e) => onFieldChange(e, 'report_issued_place', e.target.value)}
              error={false}
              helperText=''
              InputLabelProps={{shrink: true}}
            />
          </Grid>:
          <LabelValueCard xs={12} md={4} label='Report Issued Place' value={ generalDetail.report_issued_place ? generalDetail.report_issued_place:'--'} />
        }
      </Grid>
    </Paper>
  )
}
