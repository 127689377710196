import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { TableListComp, FilterUIComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { List, ContactCRU } from '../components';
import { InspectionFormWrapper } from '../../../Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { getContactListApi, getContactApi, addEditContactApi, deleteContactApi, exportContactListApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
import { contactObj, contactFilterOps } from '../../../';
import { fieldValidation } from '../../../../utils/formValidation';
import { errorCode } from '../';
class InspectionFormT006 extends Component {
  constructor(props){
    super(props);
    this.state = {
      inspectionHdInfo:{},
      skeletonLoader: true,
      pageLoader: false,
      formSubmitLoader: false,
      contacts: {
        list: [],
        pagination:{}
      },
      error:{
        list: [],
      },
      contact: {data:{}, error:{}, modal:false, mode:''},
      filter: {},
      applyingFilter:{},
      sort:'',
      sort_by:'',
      deleteModal: false,
      deleteIds:[],
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getContactListApi = getContactListApi.bind(this);
    this.getContactApi = getContactApi.bind(this);
    this.addEditContactApi = addEditContactApi.bind(this);
    this.deleteContactApi = deleteContactApi.bind(this);
    this.exportContactListApi = exportContactListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getContactListApi(this.props);
  }
  applyFilter = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      applyingFilter: {
        ...prevState.applyingFilter,
        [keyParam]: value
      }
    }))
  }
  removeFilter = (keyParam) => {
    const { filter, tabIndex } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getContactListApi(this.props, query);
  }
  toggleModalFn = (data) => {
    this.setState(prevState => ({
      ...prevState,
      contact: data
    }));
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      contact:{
        ...prevState.contact,
        data:{
          ...prevState.contact.data,
          [keyParam]: data
        }
      }
    }))
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort:sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    });
    if(sortField === sort){
      if(sort_by === 'asc'){
        this.getContactListApi(this.props, {...filter, sort: sortField, sort_by: 'desc'});
      }else{
        this.getContactListApi(this.props, {...filter, sort: sortField, sort_by: 'asc'});
      }
    }else{
      this.getContactListApi(this.props, {...filter, sort: sortField, sort_by: 'asc'});
    }
  }
  // handleError = (value, key) => {
  //   this.setState(prevState => ({
  //     ...prevState,
  //     error: {
  //       ...prevState.error,
  //       list: {
  //         ...prevState.error.list,
  //         [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
  //       }
  //     }
  //   }));
  // }
  handleError = (value, key) => {
    this.setState((prevState)=> ({
      ...prevState,
      contact: {
        ...prevState.contact,
      error: {
        ...prevState.contact.error,
        [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
      }
    }
    }))
  }
  render(){
    const { inspectionHdInfo, contacts, contact, filter, applyingFilter, sort, sort_by, deleteModal, deleteIds, skeletonLoader, pageLoader, formSubmitLoader, error } = this.state;
    return(
      <div>
        <InspectionFormWrapper
          saveButton={false}
          inspectionHdInfo={inspectionHdInfo}
          skeletonLoader={skeletonLoader}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''}
          ]}
          exportReportFn={(file) => this.exportContactListApi(this.props, file)}
        />
        <div style={{marginBottom:'20px', textAlign:'right'}}>
          { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
            <Button onClick={() => this.toggleModalFn({data:contactObj, error:{}, modal:true, mode:'add'})} color="primary" variant="contained">Add Contact</Button>:null
          }
        </div>
        { skeletonLoader ? (<STableLoader count={6} />) :
          (<Fragment>
              <FilterUIComp
                 filter={filter}
                 applyingFilter={applyingFilter}
                 removeAllFilter={() => this.getContactListApi(this.props)}
                 removeFilter={this.removeFilter}
                 submitFilter={() => this.getContactListApi(this.props, applyingFilter)}
                 applyFilter={this.applyFilter}
                 filterMenu={contactFilterOps}
               />
              <TableListComp
                heads={[
                  { id:'name', label: 'Name', sortOption: true },
                  { id:'email', label: 'Email', sortOption: true },
                  { label: 'Designation', sortOption: false },
                  { label: 'Phone Number', sortOption: false },
                  { label: 'Fax', sortOption: false },
                  { label: 'Action', sortOption: false, actionCell: true }
                ]}
                sort={sort}
                sort_by={sort_by}
                data={contacts.list.map((item,index) =>
                  <List
                    inspectionHdInfo={inspectionHdInfo}
                    key={index}
                    item={item}
                    error={error.list.length && error.list[index] ? error.list[index]:{}}
                    previewDetail={(mode) => {mode === 'D' ? this.setState({deleteModal:true, deleteIds:[item.id]}) : this.getContactApi(this.props, mode, item.id)} }
                  />
                )}
                onChangePage={(event, newPage) => {this.getContactListApi(this.props, { page:newPage+1, per_page: contacts.pagination.per_page, ...filter}, 'pageLoader')}}
                onChangeRowsPerPage={(event) => this.getContactListApi(this.props, { page:1, per_page: event.target.value, ...filter}, 'pageLoader')}
                noRecord={ contacts.list.length ? null:
                  <EmptyCollection title="No records found" />
                }
                createSortHandler={this.createSortHandler}
                pagination={contacts.pagination}
              />
            </Fragment>
          )
        }
        { contact.modal ?
          <ContactCRU
            contact={contact}
            toggleModalFn={() => this.toggleModalFn({data:{}, error:{}, modal:false, mode:''})}
            onFieldChange={this.onFieldChange}
            addEditContactFn={() => this.addEditContactApi(this.props, contact)}
            error={error}
            handleError={this.handleError}
            resetErrorKey={(key) => {
              this.setState(prevState => ({
                ...prevState,
                contact: {
                  ...prevState.contact,
                error: {
                  ...prevState.contact.error,
                  [key]: ''
                }
              }
              }))
            }}
          />:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:[]})}}
          title="Delete Contact"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.deleteContactApi(this.props, {ids: deleteIds})}
        />
        { pageLoader ? <PageLoader />:null}
      </div>
    )
  }
}
export default withSnackbar(withRouter(InspectionFormT006));
