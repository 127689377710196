import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
const AssetInspectionDate = ({workOrderInfo, onFieldChange}) => {
  return(
    <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div className="" style={{background: '#ecebeb', padding:'15px'}}>
            <h4>Record Inspection</h4>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     id="records_inspection_on_start_date"
                     label="Start Date"
                     format={fieldDateFormat}
                     fullWidth
                     disableFuture={true}
                     clearable={true}
                     InputLabelProps={{shrink: true}}
                     inputProps={{readOnly: true}}
                     value={workOrderInfo.data.records_inspection_on_start_date ? workOrderInfo.data.records_inspection_on_start_date:null}
                     onChange={(data, value) => {onFieldChange(value, 'records_inspection_on_start_date', data ? moment(data).format(backendDateFormat):data);}}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     id="records_inspection_on_end_date"
                     label="End Date"
                     format={fieldDateFormat}
                     fullWidth
                     clearable={true}
                     InputLabelProps={{shrink: true}}
                     inputProps={{readOnly: true}}
                     minDate={workOrderInfo.data.records_inspection_on_start_date ? moment(workOrderInfo.data.records_inspection_on_start_date).add(30, 'days'):moment().add(30, 'days')}
                     value={workOrderInfo.data.records_inspection_on_end_date ? workOrderInfo.data.records_inspection_on_end_date:null}
                     onChange={(data, value) => {onFieldChange(value, 'records_inspection_on_end_date', data ? moment(data).format(backendDateFormat):data);}}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="" style={{background: '#ecebeb', padding:'15px'}}>
            <h4>Physical Inspection</h4>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     id="physical_inspection_on_start_date"
                     label="Start Date"
                     format={fieldDateFormat}
                     fullWidth
                     disableFuture={true}
                     clearable={true}
                     InputLabelProps={{shrink: true}}
                     inputProps={{readOnly: true}}
                     value={workOrderInfo.data.physical_inspection_on_start_date ? workOrderInfo.data.physical_inspection_on_start_date:null}
                     onChange={(data, value) => {onFieldChange(value, 'physical_inspection_on_start_date', data ? moment(data).format(backendDateFormat):data);}}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     id="physical_inspection_on_end_date"
                     label="End Date"
                     format={fieldDateFormat}
                     fullWidth
                     clearable={true}
                     InputLabelProps={{shrink: true}}
                     inputProps={{readOnly: true}}
                     minDate={workOrderInfo.data.physical_inspection_on_start_date ? moment(workOrderInfo.data.physical_inspection_on_start_date).add(30, 'days'):moment().add(30, 'days')}
                     value={workOrderInfo.data.physical_inspection_on_end_date ? workOrderInfo.data.physical_inspection_on_end_date:null}
                     onChange={(data, value) => {onFieldChange(value, 'physical_inspection_on_end_date', data ? moment(data).format(backendDateFormat):data);}}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default AssetInspectionDate;
