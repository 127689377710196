import moment from 'moment';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, dataURItoBlob } from '../../../utils';
export function getInspectionHeaderApi(props={}){
  globalGetService(`technical/${props.match.params.assetType === 'engine' ? 'engine-workorder' :'workorder'}/${props.match.params.workOrderSlug}/forms/`,{form:props.match.params.formSlug})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        inspectionHdInfo:response.data.data
      })
    }
  })
}


export function changeFormStatusApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/form/${props.match.params.formSlug}/status-update/`,data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.getInspectionHeaderApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getGenPicListApi(props={}, query={}){
  this.setState({pageLoader: true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/general-pictures/`, query)
  .then(response => {
    this.setState({pageLoader: false});
    if(checkApiStatus(response)){
      this.setState({
        generalPictures: response.data.data,
        skeletonLoader: false
      })
    }
  })
}
export function exportGenPicListApi(props={}, file={}){
  this.setState({pageLoader: true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/general-pictures/`, {download:file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE001_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.setState({pageLoader: false});
    this.props.enqueueSnackbar('General Pictures Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function sortGenPicListApi(props={}, data={}){
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/reorder-pictures/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function importGenPicListApi(props={}, count, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/general-pictures/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        importedPictures: {
          modal:true,
          data: response.data.data,
          selectedImages:[],
          existingImgCount:count
        }
      }))
    }
  })
}
export function importGenPicReportApi(props={}, section={}){
  this.setState({pageLoader:true});
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/publish-pictures/`, {aircraft_section_id:section.data.id, pictures:section.selectedImages})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        importedPictures:{
          modal:false,
          data:{},
          selectedImages:[],
          existingImgCount:0
        }
      }));
      this.getGenPicListApi(props);
    }
  })
}
export function addGenPicApi(props={}, data={}){
  let formData = new FormData();
  formData.append('aircraft_section_id', data.sectionId);
  formData.append('status', data.status);
  for(let i = 0; i < data.files.length; i++) {
     let file = data.files[i];
     formData.append('files[]', file, file.name);
  }
  this.setState({pageLoader:true});
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/general-pictures/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      if(data.status === 1){
        this.setState(prevState => ({
          ...prevState,
          generalPictures: [...prevState.generalPictures].map(section => section.id === data.sectionId ? {
            ...section, pictures:[...section.pictures, ...response.data.data.pictures]
          } : section)
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          importedPictures: {
            ...prevState.importedPictures,
            data: {
              ...prevState.importedPictures.data,
              pictures: [...prevState.importedPictures.data.pictures, ...response.data.data.pictures]
            }
          }
        }))
      }
    }
  })
}
export function editGenPicApi(params={}, query={}){
  globalPutService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/get-pictures-url/`, query)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
export function removeGenPicApi(props={}, deleteIds){
  let formData = new FormData();
  formData.append('status', 0);
  this.setState({pageLoader:true});
  globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/general-pictures/${deleteIds.id}/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        generalPictures: [...prevState.generalPictures].map(section => section.id === deleteIds.sectionId ? {
          ...section, pictures: [...section.pictures].filter(item => item.id !== deleteIds.id)
        } :section),
        deleteModal:false,
        deleteIds:{}
      }))
    }
  })
}
export function deleteGenPicApi(props={}, deleteIds={}){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/delete-pictures/`, {aircraft_section_id:deleteIds.data.id, pictures: deleteIds.selectedImages})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        importedPictures: {
          modal:false,
          data:{},
          selectedImages:[]
        }
      }))
    }
  })
}
export function annotateGenPicApi(props={}, file, annotated={}){
  this.setState({pageLoader:true});
  dataURItoBlob(file).then(editedImage => {
    let formData = new FormData();
    formData.append('file', editedImage);
    globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/general-pictures/${annotated.data.id}/`, formData)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          imgAnnotation:{modal:false, sectionId:'', data:{}},
          generalPictures: prevState.generalPictures.map(section => section.id === annotated.sectionId ? {
            ...section, pictures:section.pictures.map(picture => picture.id === annotated.data.id ? response.data.data:picture)
          }:section)
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  })
}
