import React from 'react';
const PrerequisiteContent = ({}) => {
  return(
    <ol>
      <li>To ensure BSI report is legible and complete</li>
      <li>To check for the availability of Video / Image for all BSI inspected engine hardware</li>
      <li>To check for quality of Video / Image received is in good condition to interpret the findings (if any)</li>
      <li>To use latest revision AMM reference to review the BSI report</li>
      <li>To check if all findings reported in the BSI report have substantiating Video / Image (with measurement if any)</li>
      <li>To review any additional documents sent with the BSI report to substantiate serviceability of any findings beyond AMM limits</li>
      <li>Prepare the form with all essential engine data and borescope finding details</li>
      <li>Review the form for correctness of the engine data and borescope finding details</li>
      <li>Insert borescope pictures at its respective locations</li>
      <li>Update recommendation based on the BSI report and pictorial evidences</li>
    </ol>
  )
}
export default PrerequisiteContent;
