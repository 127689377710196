import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Checkbox, CircularProgress } from '@material-ui/core';
const SeatDetails = ({seatWindFlag, seatDetails, seatFindings, updateFinding, saveSeatFindingsAnsFn, changeSeatStatusFn, toggleModalFn}) => {
  return(
    <Dialog
      open={seatDetails.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        Seat Details
      </DialogTitle>
      <DialogContent dividers={true}>
        <List>
          {seatDetails.data.map((item, index) => {
            let selectedItem = seatFindings.findings.find(finding => finding.id === item.id);
            return(
              <ListItem key={index} role={undefined} dense button>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    checked={selectedItem ? true:false}
                    onChange={(e) => updateFinding(item, e.target.checked)}
                  />
                </ListItemIcon>
                <ListItemText id={item.id} primary={item.name} />
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        { seatWindFlag === 1 ?
          <Button onClick={() => changeSeatStatusFn( seatFindings.status === 1 ? 0:1)} size="small" variant="contained" color="secondary">
            { seatFindings && seatFindings.status && seatFindings.status === 1 ? 'Disable':'Enabled'}
          </Button>:null
        }

        { seatFindings.status === 1 ?
          <Button size="small" onClick={saveSeatFindingsAnsFn} variant="contained" color="primary">Save</Button>:null
        }
        <Button size="small" onClick={toggleModalFn} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
export default SeatDetails;
