import React from 'react';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
export default function AircraftCoverImg({modal, toggleModalFn, lopa_image_url, uploadCoverPicFn}){
  return(
    <Dialog
      open={modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Add/Update Cover Photo</DialogTitle>
      <DialogContent dividers={true}>
        { lopa_image_url ?
          <div className="dropped-pic-card" style={{backgroundImage: "url(" + lopa_image_url + ")"}}>
            <div className="overlay"></div>
            <div className="dropped-picture-cta">
              <span>
                <input
                  accept="image/*"
                  id={`contained-button-file`}
                  type="file"
                  style={{display:'none'}}
                  onChange={(e) => uploadCoverPicFn(e.target.files)}
                />
                <label htmlFor={`contained-button-file`}>Change</label>
              </span>
            </div>
          </div>
          :
          <div style={{width:'250px'}}>
            <DropzoneArea
              filesLimit={20}
              maxFileSize={10000000}
              showPreviewsInDropzone={false}
              acceptedFiles={['image/png', 'image/jpeg', 'image/jpg']}
              dropzoneClass="drag-drop-cnt"
              showAlerts={['error', 'info']}
              alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
              onDrop={(files) => uploadCoverPicFn(files)}
            />
          </div>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
