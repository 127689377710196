import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { TableListComp, FilterUIComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { List, RobberyCRU } from '../components';
import { InspectionFormWrapper } from '../../../Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { getRobberyListApi, addEditRobberyApi, getRobberyApi, deleteRobberyApi, exportRobberyListApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
import { robberyObj, robberyFilterOps } from '../../../';
import { fieldValidation } from '../../../../utils/formValidation';
import { errorCode } from '../';
class InspectionFormT009 extends Component {
  constructor(props){
    super(props);
    this.state = {
      inspectionHdInfo:{},
      skeletonLoader: false,
      pageLoader: false,
      formSubmitLoader: false,
      robberies : {
        list: [],
        pagination:{}
      },
      robbery: {data:{}, error:{}, modal:false, mode:''},
      filter: {},
      applyingFilter:{},
      sort:'',
      sort_by:'',
      deleteModal: false,
      deleteIds:[],
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getRobberyListApi = getRobberyListApi.bind(this);
    this.getRobberyApi = getRobberyApi.bind(this);
    this.addEditRobberyApi = addEditRobberyApi.bind(this);
    this.deleteRobberyApi = deleteRobberyApi.bind(this);
    this.exportRobberyListApi = exportRobberyListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getRobberyListApi(this.props, {}, 'skeletonLoader');
  }
  applyFilter = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      applyingFilter: {
        ...prevState.applyingFilter,
        [keyParam]: value
      }
    }))
  }
  removeFilter = (keyParam) => {
    const { filter, tabIndex } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getRobberyListApi(this.props, query);
  }
  toggleModalFn = (data) => {
    this.setState(prevState => ({
      ...prevState,
      robbery: data
    }));
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      robbery: {
        ...prevState.robbery,
        data: {
          ...prevState.robbery.data,
          [keyParam]: data
        }
      }
    }))
  }

  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort:sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    });
    if(sortField === sort){
      if(sort_by === 'asc'){
        this.getRobberyListApi(this.props, {...filter, sort: sortField, sort_by: 'desc'});
      }else{
        this.getRobberyListApi(this.props, {...filter, sort: sortField, sort_by: 'asc'});
      }
    }else{
      this.getRobberyListApi(this.props, {...filter, sort: sortField, sort_by: 'asc'});
    }
  }
  handleError = (value, key) => {
    this.setState((prevState)=> ({
      ...prevState,
      robbery: {
        ...prevState.robbery,
      error: {
        ...prevState.robbery.error,
        [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
      }
    }
    }))
  }

  render(){
    const { inspectionHdInfo, robberies, robbery, filter, applyingFilter, sort, sort_by, deleteModal, deleteIds, skeletonLoader, pageLoader, formSubmitLoader, error } = this.state;
    return(
      <div>
        <InspectionFormWrapper
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''},
            {title:'Excel', extension: 'xls', key:''}
          ]}
          exportReportFn={(file) => this.exportRobberyListApi(this.props, file)}
        />
        <div style={{marginBottom:'20px', textAlign:'right'}}>
          { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
            <Button onClick={() => this.toggleModalFn({data:robberyObj, error:{}, modal:true, mode:'add'})} color="primary" variant="contained">Add Robbery Item</Button>:null
          }
        </div>
        { skeletonLoader ? (<STableLoader count={6} />):
          (
            <Fragment>
              <FilterUIComp
                 filter={filter}
                 applyingFilter={applyingFilter}
                 removeAllFilter={() => this.getRobberyListApi(this.props)}
                 removeFilter={this.removeFilter}
                 submitFilter={() => this.getRobberyListApi(this.props, applyingFilter)}
                 applyFilter={this.applyFilter}
                 filterMenu={robberyFilterOps}
               />
              <TableListComp
                heads={[
                  { id: 'part_number', label: 'Part Number', sortOption: true },
                  { label: 'Part Description', sortOption: false },
                  { id:'part_location', label: 'Location', sortOption: true },
                  { label: 'Removal Date', sortOption: false },
                  { label: 'Reason For Removal', sortOption: false },
                  { label: 'Date Verified Removed from Aircraft', sortOption: false },
                  { label: 'Date Verified in Storehouse', sortOption: false },
                  { label: 'Date Verified installed on Aircraft', sortOption: false },
                  { label: 'Price', sortOption: false },
                  { label: 'Action', sortOption: false, actionCell: true }
                ]}
                sort={sort}
                sort_by={sort_by}
                data={robberies.list.map((item,index) =>
                  <List
                    inspectionHdInfo={inspectionHdInfo}
                    key={index}
                    item={item}
                    previewDetail={(mode) => {mode === 'D' ? this.setState({deleteModal:true, deleteIds:[item.id]}) : this.getRobberyApi(this.props, mode, item.id)} }
                  />
                )}
                noRecord={ robberies.list.length ? null:
                  <EmptyCollection title="No records found" />
                }
                onChangePage={(event, newPage) => {this.getRobberyListApi(this.props, { page:newPage+1, per_page: robberies.pagination.per_page, ...filter}, 'pageLoader')}}
                onChangeRowsPerPage={(event) => this.getRobberyListApi(this.props, { page:1, per_page: event.target.value, ...filter}, 'pageLoader')}
                createSortHandler={this.createSortHandler}
                pagination={robberies.pagination}
              />
            </Fragment>
          )
        }

        { robbery.modal ?
          <RobberyCRU
            robbery={robbery}
            toggleModalFn={() => this.toggleModalFn({data:{}, error:{}, modal:false, mode:''})}
            onFieldChange={this.onFieldChange}
            addEditRobberyFn={() => this.addEditRobberyApi(this.props, robbery)}
            error={error}
            handleError={this.handleError}
            resetErrorKey={(key) => {
              this.setState(prevState => ({
                ...prevState,
                robbery: {
                  ...prevState.robbery,
                error: {
                  ...prevState.robbery.error,
                  [key]: ''
                }
              }
              }))
            }}
          />:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:[]})}}
          title="Delete Robbery"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.deleteRobberyApi(this.props, {ids: deleteIds})}
        />
        { pageLoader ? <PageLoader />:null}
      </div>
    )
  }
}
export default withSnackbar(withRouter(InspectionFormT009));
