import React from 'react';
import { Grid } from '@material-ui/core';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import ListIcon from '@material-ui/icons/List';
import BarChartIcon from '@material-ui/icons/BarChart';
import PRSDeletedLogs from './PRSDeletedLogs'
const PRSHeading = ({layoutType, onChangeLayoutType}) => {
  return(
    <div className="prs-heading">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <h2>Problem Resolution Sheet</h2>
        </Grid>
        <Grid item xs={12} md={6}>
          <ul className="list-inline flex-centered">
            <li className="list-inline-item">
              <PRSDeletedLogs />
            </li>
            <li className="list-inline-item">
              <span className="list-bar-layout flex-centered">
                <span onClick={() => onChangeLayoutType(0)} className={`list-layout ${layoutType === 0 ? 'active':''}`}><ListIcon color="primary" /></span>
                <span onClick={() => onChangeLayoutType(1)} className={`bar-layout ${layoutType === 1 ? 'active':''}`}><BarChartIcon /></span>
              </span>
            </li>
            <li className="list-inline-item">
              <ZoomOutMapIcon color="primary"  />
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  )
}
export default PRSHeading;
