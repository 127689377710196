import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { TableRowActions } from '../../../../shared_elements';
export default function List({inspectionHdInfo, item, previewDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.full_name ? item.full_name:'--'}</TableCell>
      <TableCell>{item.email ? item.email:'--'}</TableCell>
      <TableCell>{item.title ? item.title:'--'}</TableCell>
      <TableCell>{item.phone_number ? item.phone_number:'--'}</TableCell>
      <TableCell>{item.fax ? item.fax:'--'}</TableCell>
      <TableCell className="actions-cell" style={{width: '120px'}}>
        <TableRowActions
          actions={ inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ? ['V','E','D']:['V']}
          previewDetail={(mode) => previewDetail(mode)}
        />
      </TableCell>
    </TableRow>
  )
}
