import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { LabelValueCard } from '../../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from '../../../../constants';
import { regexConstants } from '../../../../constants/regEx';
import { capitalizeFirstLetter } from '../../../../utils';
export default function RobberyCRU({robbery, addEditRobberyFn, toggleModalFn, onFieldChange, error, handleError, resetErrorKey}){
  return(
    <Dialog
      open={robbery.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {capitalizeFirstLetter(robbery.mode)} Robbery
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={3}>
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Part Number' value={robbery.data.part_number ? robbery.data.part_number:'--'} /> :
            <Grid item xs={6}>
              <TextField
                required
                id="part_number"
                label="Part Number"
                fullWidth
                margin="normal"
                inputProps={{maxLength: 20}}
                value={robbery.data.part_number ? robbery.data.part_number:''}
                onChange={(e, value) => onFieldChange(e, 'part_number', e.target.value)}
                error={robbery.error.part_number ? true:false }
                helperText={robbery.error.part_number ? robbery.error.part_number:''}
                InputLabelProps={{shrink: true}}
                onBlur={(e) => handleError(e.target.value,'part_number')}
                onFocus={(e) => resetErrorKey('part_number')}
              />
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Start Date' value={robbery.data.removal_date ? moment(robbery.data.removal_date).format(displayDateFormat):'--'} /> :
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                   margin="normal"
                   id="removal_date"
                   label="Removal Date"
                   format={fieldDateFormat}
                   fullWidth
                   InputLabelProps={{shrink: true}}
                   disableFuture={true}
                   inputProps={{readOnly: true}}
                   clearable={true}
                   value={robbery.data.removal_date ? robbery.data.removal_date:null}
                   onChange={(data, value) => {onFieldChange(value, 'removal_date', data ? moment(data).format(backendDateFormat):data);}}
                 />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Part Description' value={robbery.data.part_description ? robbery.data.part_description:'--'} /> :
            <Grid item xs={6}>
              <TextField
                id="part_description"
                label="Part Description"
                fullWidth
                margin="normal"
                inputProps={{maxLength: 30}}
                value={robbery.data.part_description ? robbery.data.part_description:''}
                onChange={(e, value) => onFieldChange(e, 'part_description', e.target.value)}
                error={robbery.error.part_description ? true:false }
                helperText={robbery.error.part_description ? robbery.error.part_description:''}
                InputLabelProps={{shrink: true}}
              />
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Part Location' value={robbery.data.part_location ? robbery.data.part_location:'--'} /> :
            <Grid item xs={6}>
              <TextField
                id="part_location"
                label="Part Location"
                fullWidth
                margin="normal"
                inputProps={{maxLength: 20}}
                value={robbery.data.part_location ? robbery.data.part_location:''}
                onChange={(e, value) => onFieldChange(e, 'part_location', e.target.value)}
                error={robbery.error.part_location ? true:false }
                helperText={robbery.error.part_location ? robbery.error.part_location:''}
                InputLabelProps={{shrink: true}}
              />
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Reason for Removal' value={robbery.data.reason_for_removal ? robbery.data.reason_for_removal:'--'} /> :
            <Grid item xs={6}>
              <TextField
                id="reason_for_removal"
                label="Reason for Removal"
                fullWidth
                margin="normal"
                inputProps={{maxLength: 50}}
                value={robbery.data.reason_for_removal ? robbery.data.reason_for_removal:''}
                onChange={(e, value) => onFieldChange(e, 'reason_for_removal', e.target.value)}
                error={robbery.error.reason_for_removal ? true:false }
                helperText={robbery.error.reason_for_removal ? robbery.error.reason_for_removal:''}
                InputLabelProps={{shrink: true}}
              />
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Date Verified Removed From Aircraft' value={robbery.data.date_verified_removed_from_aircraft ? moment(robbery.data.date_verified_removed_from_aircraft).format(displayDateFormat):'--'} /> :
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                   margin="normal"
                   id="date_verified_removed_from_aircraft"
                   label="Date Verified Removed From Aircraft"
                   format={fieldDateFormat}
                   fullWidth
                   InputLabelProps={{shrink: true}}
                   disableFuture={true}
                   inputProps={{readOnly: true}}
                   clearable={true}
                   value={robbery.data.date_verified_removed_from_aircraft ? robbery.data.date_verified_removed_from_aircraft:null}
                   onChange={(data, value) => {onFieldChange(value, 'date_verified_removed_from_aircraft', data ? moment(data).format(backendDateFormat):data);}}
                 />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Date Verified Installed On Aircraft' value={robbery.data.date_verified_installed_on_aircraft ? moment(robbery.data.date_verified_installed_on_aircraft).format(displayDateFormat):'--'} /> :
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                   margin="normal"
                   id="date_verified_installed_on_aircraft"
                   label="Date Verified Installed On Aircraft"
                   format={fieldDateFormat}
                   fullWidth
                   InputLabelProps={{shrink: true}}
                   disableFuture={true}
                   inputProps={{readOnly: true}}
                   clearable={true}
                   value={robbery.data.date_verified_installed_on_aircraft ? robbery.data.date_verified_installed_on_aircraft:null}
                   onChange={(data, value) => {onFieldChange(value, 'date_verified_installed_on_aircraft', data ? moment(data).format(backendDateFormat):data);}}
                 />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Date Verified in Store House' value={robbery.data.date_verified_in_store_house ? moment(robbery.data.date_verified_in_store_house).format(displayDateFormat):'--'} /> :
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                   margin="normal"
                   id="date_verified_in_store_house"
                   label="Date Verified in Store House"
                   format={fieldDateFormat}
                   fullWidth
                   InputLabelProps={{shrink: true}}
                   disableFuture={true}
                   inputProps={{readOnly: true}}
                   clearable={true}
                   value={robbery.data.date_verified_in_store_house ? robbery.data.date_verified_in_store_house:null}
                   onChange={(data, value) => {onFieldChange(value, 'date_verified_in_store_house', data ? moment(data).format(backendDateFormat):data);}}
                 />
              </MuiPickersUtilsProvider>
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Price (USD)' value={robbery.data.price ? robbery.data.price:'--'} /> :
            <Grid item xs={6}>
              <TextField
                id="price"
                label="Price (USD)"
                fullWidth
                margin="normal"
                inputProps={{maxLength: 10}}
                value={robbery.data.price ? robbery.data.price:''}
                onChange={(e, value) =>{regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'price', e.target.value): e.preventDefault() } }
                error={robbery.error.price ? true:false }
                helperText={robbery.error.price ? robbery.error.price:''}
                InputLabelProps={{shrink: true}}
                onBlur={(e) => handleError(e.target.value,'price')}
                onFocus={(e) => resetErrorKey('price')}
              />
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Work Card No' value={robbery.data.wcard_number ? robbery.data.wcard_number:'--'} /> :
            <Grid item xs={6}>
              <TextField
                id="wcard_number"
                label="Work Card No"
                fullWidth
                margin="normal"
                inputProps={{maxLength: 10}}
                value={robbery.data.wcard_number ? robbery.data.wcard_number:''}
                onChange={(e, value) => onFieldChange(e, 'wcard_number', e.target.value)}
                error={robbery.error.wcard_number ? true:false }
                helperText={robbery.error.wcard_number ? robbery.error.wcard_number:''}
                InputLabelProps={{shrink: true}}
                onBlur={(e) => handleError(e.target.value,'wcard_number')}
                onFocus={(e) => resetErrorKey('wcard_number')}
              />
            </Grid>
          }
          { robbery.mode === 'view' ?
            <LabelValueCard md={6} label='Comments' value={robbery.data.comments ? robbery.data.comments:'--'} /> :
            <Grid item xs={12}>
              <TextField
                id="comments"
                label="Comments"
                fullWidth
                multiline
                rows="4"
                margin="normal"
                inputProps={{maxLength: 50}}
                value={robbery.data.comments ? robbery.data.comments:''}
                onChange={(e, value) => onFieldChange(e, 'comments', e.target.value)}
                error={robbery.error.comments ? true:false }
                InputLabelProps={{shrink: true}}
              />
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
        { robbery.mode !== 'view' ?
          <Button onClick={addEditRobberyFn} variant="contained" color="primary">Save</Button>
          :null
        }
      </DialogActions>
    </Dialog>
  )
}
