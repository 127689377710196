import React from 'react';
import ImageGallery from 'react-image-gallery';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import "react-image-gallery/styles/css/image-gallery.css";

const ImageSlider = ({sliderInfo={}, toggleModalFn}) => {
  return(
    <Dialog
      fullScreen
      open={sliderInfo.modal}
      aria-labelledby="scroll-dialog-title"
      onClose={toggleModalFn}
    >
      <DialogTitle id="scroll-dialog-title">
        {sliderInfo.title}
      </DialogTitle>
      <DialogContent dividers={true}>
        <ImageGallery
          items={sliderInfo.images}
          startIndex={sliderInfo.startIndex}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
export default ImageSlider;
