import React, { Fragment } from 'react';
import { Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { DropDownComp, TextComp, DateComp } from '../../../Elements';
const QuestionsAnswers = ({inspectionHdInfo, section, onFieldChange, addDynamicComponent, removeDynamicComponent}) => {
  const readTheAnswer = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if(answer.length){
      return answer[0].value
    }
  }
  const plotFieldType = (question, key, answer, specsType, sectionId, componentIndex=null, subComponentIndex=null) => {
    if(question.field_type === 'dropdown'){
      let ans = readTheAnswer(answer, key);
      return(
        <DropDownComp
          label={question.label}
          keyParam={key}
          value ={ null }
          options={question.options ? question.options:[]}
          onFieldChange={(event, keyParam, value) => onFieldChange(question, key, value, specsType, sectionId, componentIndex, subComponentIndex)}
          disabled={question.editable === false ? true:false}
        />
      )
    }else if (question.field_type === 'date') {
      return(
        <DateComp
          label={question.label}
          keyParam={key}
          value = {readTheAnswer(answer, key)}
          onFieldChange={(event, keyParam, value) => onFieldChange(question, key, value, specsType, sectionId, componentIndex, subComponentIndex)}
          disabled={question.editable === false ? true:false}
        />
      )
    }else if(question.field_type === 'string'){
      return(
        <TextComp
          label={question.label}
          keyParam={key}
          value = {readTheAnswer(answer, key)}
          onFieldChange={(event, keyParam, value) => onFieldChange(question, key, value, specsType, sectionId, componentIndex, subComponentIndex)}
          disabled={question.editable === false ? true:false}
         />
      )
    }else{
      let ans = readTheAnswer(answer, key);
      return(
        <FormControlLabel
          className="checkbox-label"
          control={
            <Checkbox
              checked={(ans && ans === 'true') ? true:false}
              size="small"
              color="primary"
              value="is fitted"
              onChange={(e) => onFieldChange(question, key, e.target.checked ? 'true':'false', specsType, sectionId, componentIndex, subComponentIndex)}
            />
          }
          label="Is Fitted"
        />
      )
    }
  }
  return(
    <Fragment>
      <div className="t004-questios-answer-section">
        { section.specs && Object.keys(section.specs).length && section.show_specs_on_inspection ?
          <Grid container spacing={3}>
            { Object.keys(section.specs).map((key) =>
              <Fragment>
              {(() => {
                  let ans = readTheAnswer(section.answers, 'is_fitted');
                  if(key === 'is_fitted'){
                    return(
                      <Grid item md={12} xs={12} style={{position:'relative', top:'-15px'}}>
                        {plotFieldType(section.specs[key], key, section.answers, 'specs', section.id)}
                      </Grid>
                    )
                  }else if(section.specs[key].is_fitted === 'true' && ans && ans === 'true'){
                    return(
                      <Grid item md={3} xs={12}>
                        {plotFieldType(section.specs[key], key, section.answers, 'specs', section.id)}
                      </Grid>
                    )
                  }else if(section.specs[key].is_fitted === 'false' && (ans === undefined ||  ans === 'false')){
                    return(
                      <Grid item md={3} xs={12}>
                        {plotFieldType(section.specs[key], key, section.answers, 'specs', section.id)}
                      </Grid>
                    )
                  }
              })()}
              </Fragment>
            )}
          </Grid>:null
        }
        { section.component_questions &&  section.component_questions.length ?
          <div>
            {section.component_questions.map((component, componentIndex) =>
              <Fragment>
                { (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) && component.allow_dynamic ?
                  <Button variant="outlined" color="primary" size="small" onClick={() => addDynamicComponent('component', component, section.id, component.id)}>Add Component Item </Button>: null
                }
                <div style={{background:'#e0dfdf', padding:'15px', marginBottom:'15px'}}>

                  { component.name ? <h4>{component.name}</h4>:null }
                  { (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) && component.is_custom_component ?
                    <span className="delete-comp" onClick={() => removeDynamicComponent(section.id, componentIndex, component)}>
                      <DeleteOutlineIcon color="secondary" />
                    </span>:null
                  }
                  { component.specs && Object.keys(component.specs).length ?
                    <Grid container spacing={3}>
                      {Object.keys(component.specs).map((key) =>
                        <Fragment>
                          {(() => {
                              let ans = readTheAnswer(component.answers, 'is_fitted');
                              if(key === 'is_fitted'){
                                return(
                                  <Grid item md={12} xs={12}>
                                    {plotFieldType(component.specs[key], key, component.answers, 'specs', section.id, componentIndex)}
                                  </Grid>
                                )
                              }else if(component.specs[key].is_fitted === 'true' && ans && ans === 'true'){
                                return(
                                  <Grid item md={3} xs={12}>
                                    {plotFieldType(component.specs[key], key, component.answers, 'specs', section.id, componentIndex)}
                                  </Grid>
                                )
                              }else if(component.specs[key].is_fitted === 'false' && (ans === undefined ||  ans === 'false')){
                                return(
                                  <Grid item md={3} xs={12}>
                                    {plotFieldType(component.specs[key], key, component.answers, 'specs', section.id, componentIndex)}
                                  </Grid>
                                )
                              }else{
                                return(
                                  <Grid item md={3} xs={12}>
                                    {plotFieldType(component.specs[key], key, component.answers, 'specs', section.id, componentIndex)}
                                  </Grid>
                                )
                              }
                          })()}
                        </Fragment>
                      )}
                    </Grid>:null
                  }
                  { component.sub_components && component.sub_components.length ?
                    component.sub_components.map((subComponent, subIndex) =>
                    <div style={{padding:'15px', background:'#d4d4d4', marginBottom:'15px'}}>
                      <h5 style={{marginBottom:'6px'}}>{subComponent.name}</h5>
                      { subComponent.specs && Object.keys(subComponent.specs).length ?
                        <Grid container spacing={3}>
                          {Object.keys(subComponent.specs).map((key) =>
                            <Grid item md={3} xs={12}>
                              {plotFieldType(subComponent.specs[key], key, subComponent.answers, 'specs', section.id, componentIndex, subIndex)}
                            </Grid>
                          )}
                        </Grid>:null
                      }
                    </div>
                  ):null}

                </div>
              </Fragment>

            )}
          </div>:null
        }
      </div>
      { (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) && section.allow_dynamic ?
        <Fragment>
          <Button style={{marginBottom:'10px'}} variant="outlined" color="primary" size="small" onClick={() => addDynamicComponent('section', section, section.id)}>Add Section Item</Button>
          <hr/>
        </Fragment>:null
      }
    </Fragment>
  )
}
export default QuestionsAnswers;
