import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Checkbox } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
const ImportedPictures = ({inspectionHdInfo, importedPictures, addGenPicFn, toggleModalFn, selectImageFn, deleteSelectedImgFn, importGenPicFn}) => {
  return(
    <Dialog
      open={importedPictures.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
         {importedPictures.data.name}
      </DialogTitle>
      <DialogContent dividers={true}>
        <ul className="list-inline">
          { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
            <li className="list-inline-item" style={{marginRight:'15px'}}>
              <div style={{width:'250px'}}>
                <DropzoneArea
                  filesLimit={20}
                  maxFileSize={10000000}
                  showPreviewsInDropzone={false}
                  acceptedFiles={['image/*']}
                  dropzoneClass="drag-drop-cnt"
                  showAlerts={['error', 'info']}
                  onDrop={(files) => addGenPicFn({sectionId: importedPictures.data.id, files:files, status:0})}
                  alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                />
              </div>
            </li>:null
          }

          { importedPictures.data.pictures.map((item, index) =>
            <li className="list-inline-item" style={{marginRight:'15px', marginBottom:'15px'}}>
              <div className="dropped-pic-card" style={{cursor:'pointer', backgroundImage: "url(" + item.image + ")"}}>
                { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
                  <p style={{background:'#fff', display:'inline-block', width:'40px', height:'40px', top:'4px', left:'4px'}}>
                    <Checkbox
                      checked={(importedPictures.selectedImages && importedPictures.selectedImages.includes(item.id)) ? true:false}
                      color="secondary"
                      onChange={() => selectImageFn(item.id)}
                    />
                  </p>:null
                }
              </div>
            </li>
          )}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
        { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
          <Fragment>
            <Button onClick={deleteSelectedImgFn} disabled={importedPictures.selectedImages && importedPictures.selectedImages.length > 0 ? false:true} color="secondary">Delete Selected</Button>
            <Button disabled={ ((importedPictures.selectedImages.length + importedPictures.existingImgCount) <= (importedPictures.data.id === 19 ? 10:5)) ? (importedPictures.selectedImages && importedPictures.selectedImages.length > 0 ? false:true) :true} onClick={importGenPicFn} color="primary">Import Selected</Button>
          </Fragment>:null
        }
      </DialogActions>
    </Dialog>
  )
}
export default ImportedPictures;
