import React from 'react';
const AircraftTail = ({}) => {
  return(
    <div style={{position: 'relative', left: '-3px', paddingRight: '15px'}}>
      <div className="background-image-block"
        style={{ backgroundImage: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/tail.png)', width: '1000px', height: '635px'}}>
      </div>
    </div>
  )
}
export default AircraftTail;
