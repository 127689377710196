import React from 'react';
const PrerequisiteContent = ({}) => {
  return(
    <ol>
      <li>To ensure the initial workscope is available for the engine shop visit</li>
      <li>To ensure the latest LLP status of the engine is available prior to shop visit</li>
      <li>To ensure the engine accessories workscope is available</li>
      <li>To check if the previous shop visit details are available (if any)</li>
      <li>To ensure the Lease Agreement is available to check for the engine build life / lease return condition</li>
      <li>Coordinate with the Client and MRO for the table inspection date and availability of engine hardware in dirty condition</li>
      <li>Perform the table inspection of the available hardware and capture pictures</li>
      <li>Relate the engine hardware condition to the initial workscope for assessment</li>
      <li>To conduct discussion with the MRO and the Client if the proposed workscope do not meet the hardware condition / engine build life</li>
      <li>Prepare the report with observations on the engine hardware and summary on the engine workscope and build life.</li>
    </ol>
  )
}
export default PrerequisiteContent;
