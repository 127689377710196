import React, { Component, Fragment } from 'react';
import {Grid} from '@material-ui/core';
import { InspectionFormWrapper, ReviewIssuesComp } from '../../Elements';
import { LabelValueCard, PageLoader } from '../../../shared_elements';
import { getFormReviewListApi } from '../apiServices';
import { getInspectionHeaderApi } from '../../forms/t001/apiServices';
class InspectionReviewT005 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      inspectionHdInfo:{},
      t005ReviewList: []
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.getFormReviewListApi = getFormReviewListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getFormReviewListApi(this.props, 't005ReviewList');
  }
  readTheAnswer = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if(answer.length){
      return answer[0].value
    }
  }
  render(){
    const { t005ReviewList, inspectionHdInfo, skeletonLoader } = this.state;
    return(
      <Fragment>
        <InspectionFormWrapper reviewerForm={true} skeletonLoader={skeletonLoader} inspectionHdInfo={inspectionHdInfo} />
        <div className="technical-review-forms-cn">
          <div className="t005-technical-specs">
            { t005ReviewList.map((section) =>
              <div className="">
                <h1>{section.name}</h1>
                { section.asset_specs && Object.keys(section.asset_specs).length ?
                  <div className="">
                    <Grid container spacing={3}>
                      { Object.keys(section.asset_specs).map((key, assetIndex) =>
                        <Grid item xs={3} className="label-value-card">
                          <p>
                            {section.asset_specs[key].label}
                            <span className="add-issue">
                              { section.asset_specs[key].issue && section.asset_specs[key].issue.id ?
                                <ReviewIssuesComp type="view" issue={section.asset_specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                :
                                <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:section.asset_specs[key].label, obj_id:section.id, form_id:5}} />
                              }
                            </span>
                          </p>
                          <h6>{this.readTheAnswer(section.answers, key) ? this.readTheAnswer(section.answers, key):'--'}</h6>
                        </Grid>
                      )}
                    </Grid>
                  </div>:null
                }
                { section.maintenance_specs && Object.keys(section.maintenance_specs).length ?
                  <Grid container spacing={3}>
                    { Object.keys(section.maintenance_specs).map((key, mntIndex) =>
                      <Grid item xs={3} className="label-value-card">
                        <p>
                          {section.maintenance_specs[key].label}
                          <span className="add-issue">
                            { section.maintenance_specs[key].issue && section.maintenance_specs[key].issue.id ?
                              <ReviewIssuesComp type="view" issue={section.maintenance_specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                              :
                              <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:section.maintenance_specs[key].label, obj_id:section.id, form_id:5}} />
                            }
                          </span>
                        </p>
                        <h6>{this.readTheAnswer(section.answers, key) ? this.readTheAnswer(section.answers, key):'--'}</h6>
                      </Grid>
                    )}
                  </Grid>:null
                }
                { section.specs && Object.keys(section.specs).length ?
                  <Grid container spacing={3}>
                  { Object.keys(section.specs).map((key, secIndex) =>
                    <Grid item xs={3} className="label-value-card">
                      <p>
                        {section.specs[key].label}
                        <span className="add-issue">
                          { section.specs[key].issue && section.specs[key].issue.id ?
                            <ReviewIssuesComp type="view" issue={section.specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                            :
                            <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:section.specs[key].label, obj_id:section.id, form_id:5}} />
                          }
                        </span>
                      </p>
                      <h6>{this.readTheAnswer(section.answers, key) ? this.readTheAnswer(section.answers, key):'--'}</h6>
                    </Grid>
                  )}
                  </Grid>:null
                }
                <div style={{background:'#fafafa', padding:'15px'}}>
                    { section.component_questions && section.component_questions.length ?
                      section.component_questions.map((component, componentIndex) =>
                      <div key={componentIndex} style={{padding:'15px', background:'#f1f1f1', marginBottom:'15px'}}>
                        <h4 style={{fontSize: '16px', color: '#691AD3', marginBottom: '20px'}}>{component.name}</h4>
                        { component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                          <Grid container spacing={3}>
                            { Object.keys(component.maintenance_specs).map((key, index) =>
                              <Grid item xs={3} className="label-value-card">
                                <p>
                                  { component.maintenance_specs[key].label }
                                  <span className="add-issue">
                                    { component.maintenance_specs[key].issue && component.maintenance_specs[key].issue.id ?
                                      <ReviewIssuesComp type="view" issue={component.maintenance_specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                      :
                                      <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:component.maintenance_specs[key].label, obj_id:section.id, form_id:5}} />
                                    }
                                  </span>
                                </p>
                                <h6>{this.readTheAnswer(component.answers, key)?this.readTheAnswer(component.answers, key):'--'}</h6>
                              </Grid>
                            )}
                          </Grid>:null
                        }
                        { component.specs && Object.keys(component.specs).length ?
                          <Grid container spacing={3}>
                            { Object.keys(component.specs).map((key, index) =>
                              <Grid item xs={3} className="label-value-card">
                                <p>
                                  { component.specs[key].label }
                                  <span className="add-issue">
                                    { component.specs[key].issue && component.specs[key].issue.id ?
                                      <ReviewIssuesComp type="view" issue={component.specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                      :
                                      <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:component.specs[key].label, obj_id:section.id, form_id:5}} />
                                    }
                                  </span>
                                </p>
                                <h6>{this.readTheAnswer(component.answers, key)?this.readTheAnswer(component.answers, key):'--'}</h6>
                              </Grid>
                            )}
                          </Grid>:null
                        }
                        { component.custom_components && component.custom_components.length ?
                          component.custom_components.map((customComponents, subComponentIndex) =>
                          <Grid container spacing={3}>
                            { customComponents.maintenance_specs && Object.keys(customComponents.maintenance_specs).length ?
                              Object.keys(customComponents.maintenance_specs).map((key, index) =>
                              <Grid item xs={3} className="label-value-card">
                                <p>
                                  { customComponents.maintenance_specs[key].label }
                                  <span className="add-issue">
                                    { customComponents.maintenance_specs[key].issue && customComponents.maintenance_specs[key].issue.id ?
                                      <ReviewIssuesComp type="view" issue={customComponents.maintenance_specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                      :
                                      <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:customComponents.maintenance_specs[key].label, obj_id:section.id, form_id:5}} />
                                    }
                                  </span>
                                </p>
                                <h6>{this.readTheAnswer(customComponents.answers, key)?this.readTheAnswer(customComponents.answers, key):'--'}</h6>
                              </Grid>
                            ):null}

                            { customComponents.specs && Object.keys(customComponents.specs).length ?
                              Object.keys(customComponents.specs).map((key, index) =>
                              <Grid item xs={3} className="label-value-card">
                                <p>
                                  { customComponents.specs[key].label }
                                  <span className="add-issue">
                                    { customComponents.specs[key].issue && customComponents.specs[key].issue.id ?
                                      <ReviewIssuesComp type="view" issue={customComponents.specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                      :
                                      <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:customComponents.specs[key].label, obj_id:section.id, form_id:5}} />
                                    }
                                  </span>
                                </p>
                                <h6>{this.readTheAnswer(customComponents.answers, key)?this.readTheAnswer(customComponents.answers, key):'--'}</h6>
                              </Grid>
                            ):null}
                          </Grid>
                        ):null}
                        { component.sub_components && component.sub_components.length ?
                          component.sub_components.map((subComponent, subComponentIndex) =>
                          <div style={{padding:'15px', marginBottom:'15px', background:'#f1f1f1'}}>
                            <h5 style={{fontSize: '14px', color: '#691ad3', paddingBottom: '10px', marginBottom: '20px'}}>{subComponent.name}</h5>
                            <Grid container spacing={3}>
                              { subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                <Grid item xs={3} className="label-value-card">
                                  <p>
                                    { subComponent.maintenance_specs[key].label }
                                    <span className="add-issue">
                                      { subComponent.maintenance_specs[key].issue && subComponent.maintenance_specs[key].issue.id ?
                                        <ReviewIssuesComp type="view" issue={subComponent.maintenance_specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                        :
                                        <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:subComponent.maintenance_specs[key].label, obj_id:section.id, form_id:5}} />
                                      }
                                    </span>
                                  </p>
                                  <h6>{this.readTheAnswer(subComponent.answers, key)?this.readTheAnswer(subComponent.answers, key):'--'}</h6>
                                </Grid>
                              ):null}
                              { subComponent.specs && Object.keys(subComponent.specs).length ?
                                Object.keys(subComponent.specs).map((key, index) =>
                                <Grid item xs={3} className="label-value-card">
                                  <p>
                                    { subComponent.specs[key].label }
                                    <span className="add-issue">
                                      { subComponent.specs[key].issue && subComponent.specs[key].issue.id ?
                                        <ReviewIssuesComp type="view" issue={subComponent.specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                        :
                                        <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:subComponent.specs[key].label, obj_id:section.id, form_id:5}} />
                                      }
                                    </span>
                                  </p>
                                  <h6>{this.readTheAnswer(subComponent.answers, key)?this.readTheAnswer(subComponent.answers, key):'--'}</h6>
                                </Grid>
                              ):null}
                            </Grid>
                          </div>
                        ):null}
                      </div>
                    ):null}
                    <div>
                      { section.sections && section.sections.length ?
                        section.sections.map((component, componentIndex) =>
                        <div key={componentIndex} className="" style={{background: '#F7F7F7', marginBottom: '20px', padding: '15px', borderTop: '1px solid #'}}>
                          <h4 style={{fontSize: '16px', color: '#691AD3', marginBottom: '20px'}}>{component.name}</h4>
                          <Grid container spacing={3}>
                            { component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                              Object.keys(component.maintenance_specs).map((key, index) =>
                              <Grid item xs={3} className="label-value-card">
                                <p>
                                  { component.maintenance_specs[key].label}
                                  <span className="add-issue">
                                    { component.maintenance_specs[key].issue && component.maintenance_specs[key].issue.id ?
                                      <ReviewIssuesComp type="view" issue={component.maintenance_specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                      :
                                      <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:component.maintenance_specs[key].label, obj_id:section.id, form_id:5}} />
                                    }
                                  </span>
                                </p>
                                <h6>{this.readTheAnswer(component.answers, key)?this.readTheAnswer(component.answers, key):'--'}</h6>
                              </Grid>
                            ):null}
                            { component.specs && Object.keys(component.specs).length ?
                              Object.keys(component.specs).map((key, index) =>
                              <Grid item xs={3} className="label-value-card">
                                <p>
                                  { component.specs[key].label}
                                  <span className="add-issue">
                                    { component.specs[key].issue && component.specs[key].issue.id ?
                                      <ReviewIssuesComp type="view" issue={component.specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                      :
                                      <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:component.specs[key].label, obj_id:section.id, form_id:5}} />
                                    }
                                  </span>
                                </p>
                                <h6>{this.readTheAnswer(component.answers, key)?this.readTheAnswer(component.answers, key):'--'}</h6>
                              </Grid>
                            ):null}
                            { component.sub_components && component.sub_components.length ?
                              component.sub_components.map((subComponent, subComponentIndex) =>
                              <div style={{padding:'15px', marginBottom:'15px', background:'#f1f1f1'}}>
                                <Grid container spacing={3}>
                                  { subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                    Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                    <Grid item xs={3} className="label-value-card">
                                      <p>
                                        { subComponent.maintenance_specs[key].label }
                                        <span className="add-issue">
                                          { subComponent.maintenance_specs[key].issue && subComponent.maintenance_specs[key].issue.id ?
                                            <ReviewIssuesComp type="view" issue={subComponent.maintenance_specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                            :
                                            <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:subComponent.maintenance_specs[key].label, obj_id:section.id, form_id:5}} />
                                          }
                                        </span>
                                      </p>
                                      <h6>{this.readTheAnswer(subComponent.answers, key)?this.readTheAnswer(subComponent.answers, key):'--'}</h6>
                                    </Grid>
                                  ):null}
                                  { subComponent.specs && Object.keys(subComponent.specs).length ?
                                    Object.keys(subComponent.specs).map((key, index) =>
                                    <Grid item xs={3} className="label-value-card">
                                      <p>
                                        { subComponent.specs[key].label }
                                        <span className="add-issue">
                                          { subComponent.specs[key].issue && subComponent.specs[key].issue.id ?
                                            <ReviewIssuesComp type="view" issue={subComponent.specs[key].issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')}  />
                                            :
                                            <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{label:subComponent.specs[key].label, obj_id:section.id, form_id:5}} />
                                          }
                                        </span>
                                      </p>
                                      <h6>{this.readTheAnswer(subComponent.answers, key)?this.readTheAnswer(subComponent.answers, key):'--'}</h6>
                                    </Grid>
                                  ):null}
                                </Grid>
                              </div>
                            ):null}
                          </Grid>
                        </div>
                      ):null}
                    </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}
export default InspectionReviewT005;
