import React from 'react'
import Highcharts from 'highcharts';
import HighMaps from 'highcharts/highmaps'
import HighchartsReact from 'highcharts-react-official';
import map from "highcharts/modules/map";
import world from "@highcharts/map-collection/custom/world.geo.json";
import mapData from '../mapData';
map(Highcharts);
const ProjectsChart = ({plots, onClickCountry}) => {
  const options = {
    chart: {
        height: 488,
        map: world
    },
    title:'',
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    colorAxis: {
        min: 1,
        max: 1000,
        type: 'logarithmic',
    },
    plotOptions:{
      series:{
        point:{
          events:{
            click: function(){onClickCountry(this.country_code)}
          }
        }
      }
    },
    series:[
      {
        data:plots,
        joinBy: ['iso-a2', 'country_code'],
        name: 'Projects',
        borderColor: 'black',
        borderWidth: 0.2,
        states: {
            hover: {
                borderWidth: 1
            }
        },
        dataLabels:{
          enabled: true,
        }
      }
    ],
    credits: {
      enabled: false
    }
  };
  return(
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'mapChart'}
      options={options}
    />
  )
}
export default ProjectsChart;
