import React, { Fragment } from 'react';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { displayDateFormat } from '../../../constants';
export default function WorkOrderDate({label, flag, date}){
  return(
    <Grid item md={flag?4:6} xs={12}>
      <Paper className="project-date-card text-center" style={{background: '#fff'}}>
        <label>{label}</label>
        <div className="">
          {date ?
            <Fragment>
              <h6>{moment(date).format('MMM').toUpperCase()}</h6>
              <h3>{moment(date).date()}</h3>
              <h6>{moment(date).year()}</h6>
            </Fragment>:<h3 style={{padding:'20px 0'}}>--</h3>
          }
        </div>
      </Paper>
    </Grid>
  )
}
