import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';
export default function AuditSideBar({toggleModalFn, inspectionQA}){
  return(
    <div className="t004-form-sidebar">
      <ul className="list-unstyled">
        <li className="flex-centered">Cover Photo <EditIcon style={{float: 'right', marginLeft: 'auto'}} onClick={() => toggleModalFn({}, 'coverImgModal')} /></li>
        {inspectionQA.map((item, index) =>
          <li className="flex-centered">
            {item.title}
            <EditIcon style={{float: 'right', marginLeft: 'auto'}} onClick={() => toggleModalFn(item, 'aircraftDocsRecordModal')} />
          </li>
        )}
      </ul>
    </div>
  )
}
