import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Grid, Paper, Avatar, TextField, CircularProgress, Popover, Box, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader } from '../../../shared_elements';
import Rating from '@material-ui/lab/Rating';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { getProjectFeedbackFormApi, updateProjectFeedbackFormApi } from '../apiServices';
class FeedbackForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader: false,
      feedbackForm:{},
      selectedUserIds:null
    }
    this.getProjectFeedbackFormApi = getProjectFeedbackFormApi.bind(this);
    this.updateProjectFeedbackFormApi = updateProjectFeedbackFormApi.bind(this);
  }
  componentDidMount(){
    this.getProjectFeedbackFormApi(this.props);
  }
  updateAnswers = (answers,userId, key, value) => {
    let newAnswers = answers;
    if(answers.filter(answer => answer.user_id == userId).length){
      newAnswers = newAnswers.map(newAnswer => newAnswer.user_id == userId ? {...newAnswer, [key]: value} : newAnswer)
    }else{
      let answer = { [key]: value, user_id: userId }
      newAnswers = [...newAnswers, answer]
    }
    return newAnswers;
  }
  onFieldChange = (event, keyParam, value, questionId, user) => {
    if(keyParam === 'selectedUserIds'){
      this.setState({selectedUserIds: value ? value.id:value});
    }else{
      this.setState(prevState => ({
        ...prevState,
        feedbackForm: {
          ...prevState.feedbackForm,
          questions: prevState.feedbackForm.questions.map(question => question.id === questionId ? {
            ...question,
            answers: this.updateAnswers(question.answers, user.id, keyParam, value)
          } : question)
        }
      }))
    }
  }
  render(){
    const { selectedUserIds, feedbackForm, pageLoader } = this.state;
    return(
      <div className="project-cor-form">
        <div className="project-cor-header">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <h1>
                <Link className="flex-centered" to={`/project/view/${this.props.match.params.projectSlug}`}><ArrowBackIcon /> Feedback</Link>
              </h1>
            </Grid>
            <Grid item xs={12} md={6}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Button color="primary" variant="contained" onClick={() => this.updateProjectFeedbackFormApi(this.props, feedbackForm)}>Save</Button>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <Paper>
          { Object.keys(feedbackForm).length ?
            <div className="cor-form-section">
              <h2 className="flex-centered">
                Feedback Questionnaires
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <span>
                      <InfoIcon {...bindTrigger(popupState)} />
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Box p={2}>
                          <Typography>Feedback Rating</Typography>
                          <Typography>1 - Highly Unsatisfied</Typography>
                          <Typography>2 - Unsatisfied</Typography>
                          <Typography>3 - Ok</Typography>
                          <Typography>4 - Satisfied</Typography>
                          <Typography>5 - Very Satisfied</Typography>
                        </Box>
                      </Popover>
                    </span>
                  )}
                </PopupState>
                { feedbackForm.users && feedbackForm.users.length > 1 ?
                  <div className="" style={{width:'200px', marginLeft: 'auto'}}>
                    <Autocomplete
                      options = {feedbackForm.users ? feedbackForm.users:[]}
                      getOptionLabel={option => option.name}
                      id="selectedUserIds"
                      value={selectedUserIds && feedbackForm.users && feedbackForm.users.length ? feedbackForm.users.find(user => user.id === selectedUserIds) : null}
                      onChange={(e, value) => this.onFieldChange(e, 'selectedUserIds', value)}
                      renderInput={params => <TextField {...params} label="Filter By User" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </div>:null
                }
              </h2>
              {feedbackForm.questions.map((qus, qusIndex) =>
                <div className="cor-form-question">
                  <h4>{qusIndex+1}. {qus.question}</h4>
                  {(() => {
                    let users = selectedUserIds ? feedbackForm.users.filter(user => user.id === selectedUserIds) : feedbackForm.users;
                    return users.map((user) => {
                      let answser = qus.answers.find(answer => answer.user_id === user.id);
                      return(
                        <div className="cor-form-user">
                          <h5 className="flex-centered"><Avatar sizes="small" src={user.profile_pic} /> {user.name}</h5>
                          <ul className="list-inline flex-centered">
                            <li className="list-inline-item">
                              <Rating
                                name={`simple-controlled-${qus.id}-${user.id}`}
                                value={answser && answser.rating ? answser.rating:0}
                                onChange={(event, newValue) => this.onFieldChange(event, 'rating', newValue, qus.id, user)}
                              />
                            </li>
                            <li className="list-inline-item">
                              <TextField
                                id="ata_code"
                                label="Remarks"
                                fullWidth
                                multiline
                                rowsMax={4}
                                margin="normal"
                                value={answser && answser.comments ? answser.comments:''}
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => this.onFieldChange(e, 'comments', e.target.value, qus.id, user)}

                              />
                            </li>
                          </ul>
                        </div>
                      )
                    })
                  })()}
                </div>
              )}
            </div>:null
          }
        </Paper>
        { pageLoader ? <PageLoader /> : null }
      </div>
    )
  }
}
export default withSnackbar(FeedbackForm);
