import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress, Tabs, Tab } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { FindingsTable } from '../../../Elements';
const PhyscialFindingList = ({physcialFinding, toggleModalFn, handleTabChange, deleteFindingFn, getFindingResponse}) => {
  let upGalleys = [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169];
  return(
    <Dialog
      fullScreen
      open={physcialFinding.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {physcialFinding.title}
      </DialogTitle>
      <DialogContent dividers={true}>
        <form>
          { physcialFinding.data.length <= 1 ?
            <FindingsTable
              sectionId={physcialFinding.data[0].id}
              findings={physcialFinding.data[0].findings}
              previewDetail={(mode, id) => deleteFindingFn(id, physcialFinding.data[0].id)}
              getFindingResponse={getFindingResponse}
            />:
            physcialFinding.upperCabinTab ?
            <div>
              <Tabs
                value={physcialFinding.deckIndex}
                onChange={(event, newValue) => handleTabChange(event, newValue, 'deckIndex')}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons={isMobile ? 'on':'auto'}
              >
                <Tab label='Main Deck' />
                <Tab label='Upper Deck' />
              </Tabs>
              { physcialFinding.deckIndex === 0 ?
                <div>
                  <Tabs
                    value={physcialFinding.maindeckIndex}
                    onChange={(event, newValue) => handleTabChange(event, newValue, 'maindeckIndex')}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons={isMobile ? 'on':'auto'}
                  >
                    { physcialFinding.data.map((section, index) =>
                      !upGalleys.includes(section.id) &&
                      <Tab label={section.section_name} value={section.id} />
                    )}
                  </Tabs>
                  { physcialFinding.data.map((section, index) =>
                    !upGalleys.includes(section.id) && section.id === physcialFinding.maindeckIndex &&
                    <div>
                      <FindingsTable
                        sectionId={section.id}
                        findings={section.findings}
                        previewDetail={(mode, id) => deleteFindingFn(id, section.id)}
                        getFindingResponse={getFindingResponse}
                      />
                    </div>
                  )}
                </div>:null
              }
              { physcialFinding.deckIndex === 1 ?
                <div>
                  <Tabs
                    value={physcialFinding.upperdeckIndex}
                    onChange={(event, newValue) => handleTabChange(event, newValue, 'upperdeckIndex')}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons={isMobile ? 'on':'auto'}
                  >
                    { physcialFinding.data.map((section, index) =>
                      upGalleys.includes(section.id) &&
                      <Tab label={section.section_name} value={section.id} />
                    )}
                  </Tabs>
                  { physcialFinding.data.map((section, index) =>
                    upGalleys.includes(section.id) && section.id === physcialFinding.upperdeckIndex &&
                    <div>
                      <FindingsTable
                        sectionId={section.id}
                        findings={section.findings}
                        previewDetail={(mode, id) => deleteFindingFn(id, section.id)}
                        getFindingResponse={getFindingResponse}
                      />
                    </div>
                  )}
                </div>:null
              }
            </div>:
            <div>
              <Tabs
                value={physcialFinding.tabIndex}
                onChange={(event, newValue) => handleTabChange(event, newValue, 'tabIndex')}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons={isMobile ? 'on':'auto'}
              >
                { physcialFinding.data.map((section, index) =>
                  <Tab label={section.name} />
                )}
              </Tabs>
              { physcialFinding.data.map((section, index) =>
                index === physcialFinding.tabIndex ?
                <div>
                  <FindingsTable
                    sectionId={section.id}
                    findings={section.findings}
                    previewDetail={(mode, id) => deleteFindingFn(id, section.id)}
                    getFindingResponse={getFindingResponse}
                  />
                </div>:null
              )}
            </div>
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={toggleModalFn}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
export default PhyscialFindingList;
