import moment from 'moment';
import { globalGetService, globalExportService, globalFileUploadService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
export function getTechnicalSpecsApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`/technical/workorder/${props.match.params.workOrderSlug}/technical-specification-sections/`)
  .then(response => {
    this.setState({pageLoader:false, skeletonLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        technicalSpecs: response.data.data
      })
    }
  })
}
export function editTechnicalSpecsApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalPostService(`/technical/workorder/${props.match.params.workOrderSlug}/technical-specification-sections/save/`,data)
  .then(response => {
    this.setState({pageLoader:false, skeletonLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        technicalSpecs: response.data.data
      })
    }
  })
}
export function exportTechnicalSpecsApi(props={}, file={}){
  this.setState({pageLoader:true});
  globalExportService(`/technical/workorder/${props.match.params.workOrderSlug}/technical-specification-sections/`, {download: file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE005_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.props.enqueueSnackbar('Technical Specifications Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function getAircraftTypesApi(props={}){
  globalGetService(`console/aircraft-types/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        aircraft_model: response.data.data.aircraftTypes.map(item => { return{value:item.id, label:item.name}})
      })
    }
  })
}
export function getEngineTypesApi(props={}){
  globalGetService(`console/engine-types/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        engine_modal: response.data.data.engineTypes.map(item => { return{value:item.id, label:item.name}})
      })
    }
  })
}
export function getLesseesApi(props={}){
  globalGetService(`console/lessees/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        lessee_list: response.data.data.lessees.map(item => { return{value:item.id, label:item.name}})
      })
    }
  })
}
export function removeComponentApi(props, id, sectionId){
  globalPostService(`/technical/workorder/${props.match.params.workOrderSlug}/section/${sectionId}/custom-components/delete/`, {custom_component_ids:id})
  .then(response => {
    if(checkApiStatus(response)){
      this.getTechnicalSpecsApi(props);
    }
  })
}
export function removeSubComponentApi(props={}, sectionIndex, componentIndex, subComponentIndex,customSubComponentIndex,component,sectionId){
  let ids=[];
  component.sub_components.map((subComponent, subComponentIndex) =>{
    subComponent.custom_components.map((customSubComponent, customComponentIndex) =>{
      if(customComponentIndex==customSubComponentIndex){
        ids.push(customSubComponent.id)
      }
    }
  )});
  globalPostService(`/technical/workorder/${props.match.params.workOrderSlug}/section/${sectionId}/custom-components/delete/`, {custom_component_ids:ids})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
          ...section, component_questions: [...section.component_questions].map((component, innerIndex) => innerIndex === componentIndex ? {
            ...component, sub_components: [...component.sub_components].map((subComponent, subIndex) => subIndex === subComponentIndex ? {
              ...subComponent, custom_components: [...subComponent.custom_components].filter((subComponent, customIndex) => customIndex !== customSubComponentIndex)
            } : subComponent)
          } : component)
        } : section)
      }))
    }
  })
}
export function uploadEquipmentAttachmentApi(props, sectionId, componentId, file){
  this.setState({pageLoader:true})
  let formData = new FormData();
  formData.append('label', 'attachment');
  formData.append('component_id', componentId);
  formData.append('file', file);
  globalFileUploadService(`/technical/workorder/${props.match.params.workOrderSlug}/section/${sectionId}/upload-file/`, formData)
  .then(response => {
    if(checkApiStatus(response)){
      this.getTechnicalSpecsApi(props)
    }
  })
}
