import React from 'react';
import { TableRow, TableCell, TextField, Checkbox, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { fieldDateFormat, backendDateFormat } from '../../../../constants';
export default function RptList({projectUsers, inspectionHdInfo, item, item_ids, ids, sectionId, error, index, onFieldChange, updateRptFieldFn, previewDetail, toggleBulkOps}){
  let canSaveFlag = inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save;
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell width="30">
        <Checkbox
          name={item.id}
          color="primary"
          className="checkbox-icon"
          size="small"
          onChange={(e) => toggleBulkOps(e.target.checked)}
          checked={item_ids.includes(item.id)}
        />
      </TableCell>
      <TableCell>{index}</TableCell>
      <TableCell>
        { canSaveFlag ?
          <DeleteOutlineIcon onClick={() => previewDetail('D')} color="error" />:null
        }
      </TableCell>
      <TableCell>
        { canSaveFlag ?
          <TextField
            id="description"
            fullWidth
            margin="normal"
            value={item.description ? item.description:''}
            error={error.rowIndex && (error.rowIndex === index) && error.description ? true:false}
            helperText={error.rowIndex && (error.rowIndex === index) && error.description ? error.description:''}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
            onBlur={(e) => updateRptFieldFn('description', e.target.value)}
            InputLabelProps={{shrink: true}}
            style={{width:'200px'}}
          />:
          item.description ? item.description:'--'
        }

      </TableCell>
      <TableCell>
        <div style={{width:'200px'}}>
          {item.image_url ? <a style={{display: 'inline-block', marginBottom:'4px'}} href={item.image_url} target="_blank">View Attachment</a>:null}
          { canSaveFlag ?
            <div>
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{display:'none'}}
                onChange={(e) => updateRptFieldFn('image_url',e.target.files[0])}
              />
              <label htmlFor="contained-button-file">
                <Button size="small" variant="outlined" color="primary" component="span">
                  {item.image_url ? 'Change Attachment':'Add Attachment'}
                </Button>
              </label>
            </div>:null
          }
        </div>
      </TableCell>
      <TableCell>
        { canSaveFlag ?
          <Autocomplete
            options = {[{label:'P1',value:1},{label:'P2',value:2}]}
            getOptionLabel={option => option.label}
            id="priority"
            value={item.priority ? [{label:'P1',value:1},{label:'P2',value:2}].find(opt => opt.value === item.priority) :null}
            onChange={(e, value) => { onFieldChange(e, 'priority', value ? value.value : ''); updateRptFieldFn('priority', value ? value.value : '') }}
            renderInput={params => <TextField style={{width:'100px'}} {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />:
          item.priority ? [{label:'P1',value:1},{label:'P2',value:2}].find(opt => opt.value === item.priority).label :'--'
        }
      </TableCell>
      {ids.includes(sectionId) &&
        <TableCell>
          { canSaveFlag ?
            <TextField
              id="part_number"
              fullWidth
              margin="normal"
              value={item.part_number ? item.part_number:''}
              helperText=''
              onChange={(e) => onFieldChange(e, 'part_number', e.target.value)}
              onBlur={(e) => updateRptFieldFn('part_number', e.target.value)}
              InputLabelProps={{shrink: true}}
              style={{width:'200px'}}
            />:
            item.part_number ? item.part_number:'--'
          }

        </TableCell>
      }
      {ids.includes(sectionId) &&
        <TableCell>
          { canSaveFlag ?
            <TextField
              id="serial_number"
              fullWidth
              margin="normal"
              value={item.serial_number ? item.serial_number:''}
              helperText=''
              onChange={(e) => onFieldChange(e, 'serial_number', e.target.value)}
              onBlur={(e) => updateRptFieldFn('serial_number', e.target.value)}
              InputLabelProps={{shrink: true}}
              style={{width:'200px'}}
            />:
            item.serial_number ? item.serial_number:'--'
          }

        </TableCell>
      }
      <TableCell>
        { canSaveFlag ?
          <Autocomplete
            options = {[{label:'Open',value:0},{label:'Close',value:1}]}
            getOptionLabel={option => option.label}
            id="disposition"
            value={item.disposition ? [{label:'Open',value:0},{label:'Close',value:1}].find(opt => opt.value === item.disposition) :null}
            onChange={(e, value) => { onFieldChange(e, 'disposition', value ? value.value : ''); updateRptFieldFn('disposition', value ? value.value : '') }}
            renderInput={params => <TextField style={{width:'150px'}} {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />:
          item.disposition ? [{label:'Open',value:0},{label:'Close',value:1}].find(opt => opt.value === item.disposition).label :'--'
        }

      </TableCell>
      <TableCell>
        { canSaveFlag ?
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="started_date"
               format={fieldDateFormat}
               fullWidth
               InputLabelProps={{shrink: true}}
               disableFuture={true}
               inputProps={{readOnly: true}}
               clearable={true}
               value={item.started_date ? item.started_date:null}
               style={{width:'200px'}}
               onChange={(data, value) => {onFieldChange(value, 'started_date', data ? moment(data).format(backendDateFormat):data); updateRptFieldFn('started_date', data ? moment(data).format(backendDateFormat):data) }}
             />
          </MuiPickersUtilsProvider>:
          item.started_date ? item.started_date:'--'
        }
      </TableCell>
      <TableCell>
        { canSaveFlag ?
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="estimated_completion_date"
               format={fieldDateFormat}
               fullWidth
               InputLabelProps={{shrink: true}}
               inputProps={{readOnly: true}}
               clearable={true}
               value={item.estimated_completion_date ? item.estimated_completion_date:null}
               style={{width:'200px'}}
               onChange={(data, value) => {onFieldChange(value, 'estimated_completion_date', data ? moment(data).format(backendDateFormat):data); updateRptFieldFn('estimated_completion_date', data ? moment(data).format(backendDateFormat):data)}}
             />
          </MuiPickersUtilsProvider>:
          item.estimated_completion_date ? item.estimated_completion_date:'--'
        }

      </TableCell>
      <TableCell>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <TableCell>
                { canSaveFlag ?
                  <TextField
                    id="requirement_remarks"
                    fullWidth
                    margin="normal"
                    value={item.requirement_remarks ? item.requirement_remarks:''}
                    helperText=''
                    onChange={(e) => onFieldChange(e, 'requirement_remarks', e.target.value)}
                    onBlur={(e) => updateRptFieldFn('requirement_remarks', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    style={{width:'200px'}}
                  />:
                  item.requirement_remarks ? item.requirement_remarks:'--'
                }
              </TableCell>
              <TableCell>
                { canSaveFlag ?
                  <TextField
                    id="background_remarks"
                    fullWidth
                    margin="normal"
                    value={item.background_remarks ? item.background_remarks:''}
                    helperText=''
                    onChange={(e) => onFieldChange(e, 'background_remarks', e.target.value)}
                    onBlur={(e) => updateRptFieldFn('background_remarks', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    style={{width:'200px'}}
                  />:
                  item.background_remarks ? item.background_remarks:'--'
                }
              </TableCell>
            </tr>
          </tbody>
        </table>
      </TableCell>
      <TableCell>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <TableCell>
                { canSaveFlag ?
                  <TextField
                    id="acumen_comments"
                    fullWidth
                    margin="normal"
                    value={item.acumen_comments ? item.acumen_comments:''}
                    helperText=''
                    onChange={(e) => onFieldChange(e, 'acumen_comments', e.target.value)}
                    onBlur={(e) => updateRptFieldFn('acumen_comments', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    style={{width:'200px'}}
                  />:
                  item.acumen_comments ? item.acumen_comments:'--'
                }

              </TableCell>
              <TableCell>
                { canSaveFlag ?
                  <TextField
                    id="operator_comments"
                    fullWidth
                    margin="normal"
                    value={item.operator_comments ? item.operator_comments:''}
                    helperText=''
                    onChange={(e) => onFieldChange(e, 'operator_comments', e.target.value)}
                    onBlur={(e) => updateRptFieldFn('operator_comments', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    style={{width:'200px'}}
                  />:
                  item.operator_comments ? item.operator_comments:'--'
                }
              </TableCell>
              <TableCell>
                { canSaveFlag ?
                  <TextField
                    id="operator_2_comments"
                    fullWidth
                    margin="normal"
                    value={item.operator_2_comments ? item.operator_2_comments:''}
                    helperText=''
                    onChange={(e) => onFieldChange(e, 'operator_2_comments', e.target.value)}
                    onBlur={(e) => updateRptFieldFn('operator_2_comments', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    style={{width:'200px'}}
                  />:
                  item.operator_2_comments ? item.operator_2_comments:'--'
                }
              </TableCell>
            </tr>
          </tbody>
        </table>
      </TableCell>

      { ids.includes(sectionId) &&
        <TableCell>
          { canSaveFlag ?
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="install_date"
                 format={fieldDateFormat}
                 fullWidth
                 InputLabelProps={{shrink: true}}
                 disableFuture={true}
                 inputProps={{readOnly: true}}
                 clearable={true}
                 value={item.install_date ? item.install_date:null}
                 style={{width:'200px'}}
                 onChange={(data, value) => {onFieldChange(value, 'install_date', data ? moment(data).format(backendDateFormat):data); updateRptFieldFn('install_date', data ? moment(data).format(backendDateFormat):data)}}
               />
            </MuiPickersUtilsProvider>:
            item.install_date ? item.install_date:'--'
          }

        </TableCell>
      }
      { ids.includes(sectionId) &&
        <TableCell>
          {canSaveFlag ?
            <TextField
              id="position"
              fullWidth
              margin="normal"
              value={item.position ? item.position:''}
              helperText=''
              onChange={(e) => onFieldChange(e, 'position', e.target.value)}
              onBlur={(e) => updateRptFieldFn('position', e.target.value)}
              InputLabelProps={{shrink: true}}
              style={{width:'200px'}}
            />:
            item.position ? item.position:'--'
          }
        </TableCell>
      }
      <TableCell>
        { canSaveFlag ?
          <Autocomplete
            options = {projectUsers}
            getOptionLabel={option => option.name}
            id="assignee"
            value={null}
            onChange={(e, value) => { onFieldChange(e, 'priority', value ? value.value : ''); updateRptFieldFn('priority', value ? value.value : '') }}
            renderInput={params => <TextField style={{width:'100px'}} {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />:
          item.priority ? [{label:'P1',value:1},{label:'P2',value:2}].find(opt => opt.value === item.priority).label :'--'
        }
      </TableCell>
    </TableRow>
  )
}
