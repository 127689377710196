export function returnBluePrintClass(section){
  let customClass = '';
  if(Object.keys(section).length && section.list.length){
    switch (section.aircraft_model.blueprint.id) {
      case 1:
        customClass = 'model-two';
        break;
      case 2:
        customClass = 'model-three';
        break;
      case 3:
        customClass = 'model-four';
        break;
      case 4:
        customClass = 'model-eight';
        break;
      case 5:
        customClass = 'model-eight';
        break;
      case 6:
        customClass = 'model-one';
        break;
      case 7:
        customClass = 'model-one';
        break;
      case 8:
        customClass = 'model-nine';
        break;
      case 9:
        customClass = 'model-nine';
        break;
      case 10:
        customClass= 'model-seven';
        break;
      case 11:
        customClass = 'model-eleven';
        break;
      case 12:
        customClass = 'model-eleven model-embarier-165';
        break;
      default:
        customClass=''
    }
  }
  return customClass;
}
