import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import {DropzoneArea} from 'material-ui-dropzone';
import { InspectionFormWrapper, ImageSlider, EditImageDialog } from '../../../Elements';
import { DeletePopUp, PageLoader } from '../../../../shared_elements';
import { getDataPlateListApi, addDataPlatesApi, deleteDataPlatesApi, annotateDataPlatesApi, exportDataPlateListApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
class InspectionFormT002 extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:true,
      inspectionHdInfo:{},
      dataPlates: [],
      deleteModal:false,
      deleteIds: {
        id:null,
        pictures:[]
      },
      sliderInfo: {
        modal:false,
        images:[],
        startIndex:0
      },
      imgAnnotation: {
        modal:false,
        data:{}
      },
    };
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getDataPlateListApi = getDataPlateListApi.bind(this);
    this.addDataPlatesApi = addDataPlatesApi.bind(this);
    this.deleteDataPlatesApi = deleteDataPlatesApi.bind(this);
    this.annotateDataPlatesApi = annotateDataPlatesApi.bind(this);
    this.exportDataPlateListApi = exportDataPlateListApi.bind(this);
  }
  componentDidMount(){
    this.getDataPlateListApi(this.props);
    this.getInspectionHeaderApi(this.props);
  }
  updateSlider = (section) => {
    this.setState(prevState => ({
      ...prevState,
      sliderInfo: {
        ...prevState.sliderInfo,
        modal:true,
        images: section.pictures.map(item => {return {title:section.name, original:item.image, thumbnail:item.image}}),
        startIndex:0,
        title: section.name
      }
    }))
  }
  render(){
    const { inspectionHdInfo, dataPlates, sliderInfo, imgAnnotation, deleteModal, deleteIds, skeletonLoader, pageLoader } = this.state;
    return(
      <div className="technical-aircraft-froms">
        <InspectionFormWrapper
          saveButton={false}
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''}
          ]}
          exportReportFn={(file) => this.exportDataPlateListApi(this.props, file)}
        />
        <div className="form-t002-cn">
          <Grid container spacing={2}>
            {dataPlates.map((plate, index) =>
              <Grid item md={3} xs={12} style={{minHeight: '250px'}}>
                <h4>{plate.name} {plate.id}</h4>
                { plate.pictures.length ?
                  <div className="dropped-pic-card" style={{backgroundImage: "url(" + plate.pictures[0].image + ")"}}>
                    <div className="overlay"></div>
                    <div className="dropped-picture-cta">
                      <span onClick={() => this.updateSlider(plate)}>View</span><br/>
                      { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
                        <Fragment>
                          <span onClick={() => this.setState({imgAnnotation:{modal:true, sectionId: plate.id, data:plate.pictures[0]}})}>Edit</span><br/>
                          <span onClick={() => {this.setState({deleteModal:true, deleteIds:{id:plate.id, pictures:[plate.pictures[0].id]}})}}>Remove</span><br/>
                          <span>
                            <input
                              accept="image/*"
                              id={`contained-button-file${plate.id}`}
                              type="file"
                              style={{display:'none'}}
                              onChange={(e) => this.addDataPlatesApi(this.props, {sectionId: plate.aircraft_section_id, plateId: plate.id, files:e.target.files, picture_id:plate.pictures[0].id})}
                            />
                            <label htmlFor={`contained-button-file${plate.id}`}>Change</label>
                          </span>
                        </Fragment>:null
                      }
                    </div>
                  </div>:
                  inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
                  <div style={{width:'250px'}}>
                    <DropzoneArea
                      filesLimit={1}
                      showPreviewsInDropzone={false}
                      acceptedFiles={['image/*']}
                      dropzoneClass="drag-drop-cnt"
                      onDrop={(files) => this.addDataPlatesApi(this.props, {sectionId: plate.aircraft_section_id, plateId: plate.id, files:files} )}
                      alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                      showAlerts={['error', 'info']}
                    />
                  </div>:null
                }
              </Grid>
            )}
          </Grid>
        </div>
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{id:null, pictures:[]}})}}
          title="Delete Data Plate"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.deleteDataPlatesApi(this.props, deleteIds)}
        />
        { imgAnnotation.modal ?
          <EditImageDialog
            imgAnnotation={imgAnnotation}
            saveAnnotateImg={(file) => this.annotateDataPlatesApi(this.props, file, imgAnnotation)}
            toggleModalFn={() => this.setState({imgAnnotation: {modal:false}})}
          />:null
        }
        { sliderInfo.modal ?
          <ImageSlider
            sliderInfo={sliderInfo}
            toggleModalFn={() => this.setState({sliderInfo:{modal:false, images:[], startIndex:''}})}
          />:null
        }
        { pageLoader ? <PageLoader />:null }
      </div>
    )
  }
}
export default withSnackbar(InspectionFormT002)
