import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { AircraftBluePrints, InspectionFormWrapper, AddEditFinding } from '../../../Elements';
import { DeletePopUp, PageLoader } from '../../../../shared_elements';
import { PhyscialFindingList } from '../components';
import { getFindingSectionsApi, getFindingsApi, deleteFindingApi, exportFindingSectionsApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
class InspectionFormT007 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:true,
      pageLoader:true,
      inspectionHdInfo:{},
      findingSections:{},
      physcialFinding:{
        modal:false,
        data:[],
        tabIndex: 0
      },
      deleteModal: false,
      deleteIds:{}
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getFindingSectionsApi = getFindingSectionsApi.bind(this);
    this.getFindingsApi = getFindingsApi.bind(this);
    this.deleteFindingApi = deleteFindingApi.bind(this);
    this.exportFindingSectionsApi = exportFindingSectionsApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getFindingSectionsApi(this.props);
  }
  handleTabChange =(event, value, tabType) => {
    this.setState(prevState => ({
      ...prevState,
      physcialFinding: {
        ...prevState.physcialFinding,
        [tabType]: value
      }
    }))
  }
  getFindingResponse = (response, sectionId, id) => {
    if(id){
      this.setState(prevState => ({
        ...prevState,
        physcialFinding: {
          ...prevState.physcialFinding,
          data: prevState.physcialFinding.data.map(section => section.id === sectionId ? {
            ...section, findings: section.findings.map(finding => finding.id === id ? response.data.data : finding)
          } :section)
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        physcialFinding: {
          ...prevState.physcialFinding,
          data: prevState.physcialFinding.data.map(section => section.id === sectionId ? {
            ...section, findings: [...section.findings, response.data.data]
          } : section)
        }
      }))
    }
  }
  render(){
    const { inspectionHdInfo, findingSections, physcialFinding, deleteModal, deleteIds, skeletonLoader, pageLoader } = this.state;
    let customClass = '';
    if(Object.keys(findingSections).length && findingSections.list.length){
      switch(findingSections.aircraft_model.blueprint.id){
        case 1 :
          customClass = 'model-two'
          break;
        case 2 :
          customClass = 'model-three'
          break;
        case 3:
          customClass = 'model-four'
          break;
        case 4:
          customClass = 'model-eight'
          break;
        case 5:
          customClass = 'model-eight'
          break;
        case 6:
          customClass = 'model-one'
          break;
        case 7:
          customClass = 'model-one'
          break;
        case 8:
          customClass = 'model-nine'
          break;
        case 9:
          customClass = 'model-nine'
          break;
        case 10:
          customClass='model-seven'
          break;
        case 11:
          customClass ='model-eleven'
          break;
        case 12:
          customClass ='model-eleven model-embarier-165'
          break;
        default:
      }
    }
    return(
      <div>
        <InspectionFormWrapper
          saveButton={false}
          inspectionHdInfo={inspectionHdInfo}
          skeletonLoader={skeletonLoader}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''}
          ]}
          exportReportFn={(file) => this.exportFindingSectionsApi(this.props, file)}
        />
        <div className="form-t004-cn">
          <div className="indicater-detail-block">
            { Object.keys(findingSections).length && findingSections.list.length ?
              <ul className="list-unstyled indicater-block">
                <li>
                  <span className="poor"></span> Findings ({findingSections['list'].reduce((total, sectionList) => total + sectionList.finding_count, 0)})
                </li>
              </ul>:null
            }
          </div>
          <div className={customClass}>
            { Object.keys(findingSections).length && findingSections.list.length ?
              <div className="model-image">
                <img className="model-image" src={'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/' + findingSections.aircraft_model.blueprint.image} alt="img" />
                { findingSections['list'].map((item, index) =>
                  <AircraftBluePrints
                    auditSectionFn={() => this.getFindingsApi(this.props, item)}
                    bluePrintId={findingSections.aircraft_model.blueprint.id}
                    key={index}
                    sectionDetail={item}
                    formInfo={{form: 'T007'}}
                  />
                )}
              </div>:null
            }
          </div>
        </div>
        { physcialFinding.modal ?
          <PhyscialFindingList
            physcialFinding={physcialFinding}
            toggleModalFn={() => this.setState({physcialFinding:{modal:false, data:[]}})}
            handleTabChange={this.handleTabChange}
            deleteFindingFn={(id, sectionId) => this.setState({deleteModal:true, deleteIds:{id: id, sectionId: sectionId}})}
            getFindingResponse={this.getFindingResponse}
          />:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => this.setState({deleteModal:false, deleteIds:{}}) }
          title="Delete Finding"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.deleteFindingApi(this.props, deleteIds)}
        />
        { pageLoader ? <PageLoader /> :null }
      </div>
    )
  }
}
export default withSnackbar(InspectionFormT007)
