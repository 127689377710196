import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../utils/globalApiServices';
import { checkApiStatus } from '../../utils';
const urlEndPoint = {
  t001ReviewList: 'general-pictures/',
  t002ReviewList: 'data-plate-pictures/',
  t003ReviewList: 'document-sections/',
  t004ReviewList: 'audit-report-sections/',
  t005ReviewList: 'technical-specification-sections/',
  t006ReviewList: 'contacts/',
  t007ReviewList: 'inspection-finding-sections/',
  t009ReviewList: 'robbery-list/'
}
export function getFormReviewListApi(props={}, formType=''){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/${urlEndPoint[formType]}`, {for_review:true})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        [formType]: response.data.data,
        skeletonLoader:false
      })
    }
  })
}
export function getFormFeedbacksApi(params={}, query={}){
  globalGetService(``, query)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
export function addEditReviewCommentApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.location_slug){
        globalPutService(`technical/${props.match.params.assetType}/workorder/${props.match.params.workOrderSlug}/forms/${props.match.params.formSlug}/review/${data.location_slug}/`, {description:data.description})
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`technical/${props.match.params.assetType}/workorder/${props.match.params.workOrderSlug}/forms/${props.match.params.formSlug}/review/`, data)
        .then(response => {
          if(checkApiStatus(response)){
            resolve(response);
          }
        })
      }
    }
  )
}

export function addCommentToIssueApi(props, slug, data){
  this.setState({pageLoader:true});
  globalPostService(`technical/review/${slug}/comment/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({message:''})
      this.getFormIssueApi(props, slug);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({pageLoader:false});
    }
  })
}
export function resolveCommentToIssueApi(props, slug, id, data){
  this.setState({pageLoader:true});
  globalPutService(`technical/review/${slug}/comment/${id}/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.getFormIssueApi(props, slug);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({pageLoader:false});
    }
  })
}

export function getFormIssueListApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/${props.match.params.assetType}/workorder/${props.match.params.workOrderSlug}/forms/${props.match.params.formSlug}/review/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        formIssueList: response.data.data,
        issueListDrawer:true
      }))
    }
  })
}
export function getFormIssueApi(props={}, slug, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/${props.match.params.assetType}/workorder/${props.match.params.workOrderSlug}/forms/${props.match.params.formSlug}/review/${slug}/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        issueDetail:{modal:true, data:response.data.data}
      }))
    }
  })
}
export function toggleFormIssueStatusApi(props={}, slug, data={}){
  this.setState({pageLoader:true});
  globalPutService(`technical/${props.match.params.assetType}/workorder/${props.match.params.workOrderSlug}/forms/${props.match.params.formSlug}/review/${slug}/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      if(data.status === 0){
        this.getFormIssueApi(props, slug);
      }else{
        this.getFormIssueListApi(props);
      }

      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({pageLoader:false});
    }
  })
}
export function resolveAllCommentsApi(props, slug){
  this.setState({pageLoader:true});
  globalGetService(`technical/${props.match.params.assetType}/workorder/${props.match.params.workOrderSlug}/forms/${props.match.params.formSlug}/review/${slug}/`, {resolve_all:true})
  .then(response => {
    if(checkApiStatus(response)){
      this.getFormIssueApi(props, slug);
      this.getFormIssueListApi(props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({pageLoader:false});
    }
  })
}
export function deleteFormIssueApi(props={}, slug, data={}){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`technical/${props.match.params.assetType}/workorder/${props.match.params.workOrderSlug}/forms/${props.match.params.formSlug}/review/${slug}/`, data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function notifylClientApi(params={}, query={}){
  globalGetService(``, query)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
