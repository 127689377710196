
import ProjectListing from './projects/containers';
import ProjectView from './projects/containers/View';
import AddTechnicalEngine from './projects/containers/AddTechnicalEngine';
import CorForm from './projects/containers/CorForm';
import FeedbackForm from './projects/containers/FeedbackForm';

import CorTracker from './trackers/containers/CorTracker';
import ManPowerTracker from './trackers/containers/ManPowerTracker';
import ProjectTracker from './trackers/containers/ProjectTracker';
import ProjectsAnalyticTracker from './trackers/containers/ProjectsAnalyticTracker';
import ResourcesAnalyticTracker from './trackers/containers/ResourcesAnalyticTracker';

import ProgramScheduler from './program_planner/containers/ProgramScheduler';
import ProgramTracker from './program_planner/containers/ProgramTracker';

import InspectionFormT001 from './forms/t001/containers';
import InspectionFormT002 from './forms/t002/containers';
import InspectionFormT003 from './forms/t003/containers';
import InspectionFormT004 from './forms/t004/containers';
import InspectionFormT005 from './forms/t005/containers';
import InspectionFormT006 from './forms/t006/containers';
import InspectionFormT007 from './forms/t007/containers';
import InspectionFormT008 from './forms/t008/containers';
import InspectionFormT009 from './forms/t009/containers';
import InspectionFormT011 from './forms/t011/containers';

import InspectionFormTE001 from './forms/te001/containers';
import InspectionFormTE002 from './forms/te002/containers';
import InspectionFormTE003 from './forms/te003/containers';
import InspectionFormTE004 from './forms/te004/containers';
import InspectionFormTE005 from './forms/te005/containers';
import InspectionFormTE006 from './forms/te006/containers';

import InspectionReviewT001 from './reviewer/containers/ReviewT001';
import InspectionReviewT002 from './reviewer/containers/ReviewT002';
import InspectionReviewT003 from './reviewer/containers/ReviewT003';
import InspectionReviewT004 from './reviewer/containers/ReviewT004';
import InspectionReviewT005 from './reviewer/containers/ReviewT005';
import InspectionReviewT006 from './reviewer/containers/ReviewT006';
import InspectionReviewT007 from './reviewer/containers/ReviewT007';
import InspectionReviewT009 from './reviewer/containers/ReviewT009';


import pageLayoutHoc from '../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../hocs/userRoleBaseAccessHoc';
export const formInspectionRoutes = [
  {
    path: '/projects',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProjectListing,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProjectListing'
  },
  {
    path: '/',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProjectListing,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProjectListing'
  },
  {
    path: '/project/view/:projectSlug',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProjectView,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProjectView'
  },
  {
    path: '/project/create-engine/:projectSlug',
    component: pageLayoutHoc(userRoleBaseAccessHoc(AddTechnicalEngine,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'AddTechnicalEngine'
  },
  {
    path: '/project/:projectSlug/engine/edit/:workOrderSlug',
    component: pageLayoutHoc(userRoleBaseAccessHoc(AddTechnicalEngine,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'EditTechnicalEngine'
  },
  {
    path: '/project/view/:projectSlug/project-cor-form',
    component: pageLayoutHoc(userRoleBaseAccessHoc(CorForm,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'CorForm'
  },
  {
    path: '/project/view/:projectSlug/project-feedback-form',
    component: pageLayoutHoc(userRoleBaseAccessHoc(FeedbackForm,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'FeedbackForm'
  },
  {
    path: '/tracker/project',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProjectTracker,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProjectTracker'
  },
  {
    path: '/tracker/analytic/projects',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProjectsAnalyticTracker,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProjectsAnalyticTracker'
  },
  {
    path: '/tracker/analytic/resources',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ResourcesAnalyticTracker,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ResourcesAnalyticTracker'
  },
  {
    path: '/tracker/cor',
    component: pageLayoutHoc(userRoleBaseAccessHoc(CorTracker,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'CorTracker'
  },
  {
    path: '/tracker/man-power',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ManPowerTracker,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ManPowerTracker'
  },
  {
    path: '/program-planner',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProgramScheduler,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProgramScheduler1'
  },
  {
    path: '/program-planner/create/program',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProgramScheduler,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProgramScheduler2'
  },
  {
    path: '/program-planner/assets',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProgramScheduler,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProgramSchedule3'
  },
  {
    path: '/program-planner/assets/program/:programId',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProgramScheduler,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProgramSchedule4'
  },
  {
    path: '/program-planner/program/:programId',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProgramScheduler,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProgramSchedule5'
  },
  {
    path: '/program-planner/program/:programId/details',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProgramScheduler,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProgramSchedule6'
  },
  {
    path: '/program-planner/program/:programId/tracker',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ProgramTracker,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'ProgramTracker'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t001',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT001,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT001'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t002',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT002,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT002'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t003',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT003,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT003'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t004',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT004,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT004'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t005',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT005,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT005'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t006',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT006,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT006'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t007',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT007,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT007'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t008',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT008,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT008'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t009',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT009,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT009'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/t011',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormT011,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormT011'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/te001',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormTE001,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormTE001'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/te002',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormTE002,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormTE002'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/te003',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormTE003,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormTE003'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/te004',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormTE004,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormTE004'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/te005',
    component: pageLayoutHoc(InspectionFormTE005, {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormTE005'
  },
  {
    path: '/:assetType/:assetSlug/:workOrderSlug/forms/:formSlug/te006',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionFormTE006,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionFormTE006'
  },
  {
    path: '/:assetType/:assetSlug/review/:workOrderSlug/forms/:formSlug/t001',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionReviewT001,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionReviewT001'
  },
  {
    path: '/:assetType/:assetSlug/review/:workOrderSlug/forms/:formSlug/t002',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionReviewT002,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionReviewT002'
  },
  {
    path: '/:assetType/:assetSlug/review/:workOrderSlug/forms/:formSlug/t003',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionReviewT003,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionReviewT003'
  },
  {
    path: '/:assetType/:assetSlug/review/:workOrderSlug/forms/:formSlug/t004',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionReviewT004,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionReviewT004'
  },
  {
    path: '/:assetType/:assetSlug/review/:workOrderSlug/forms/:formSlug/t005',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionReviewT005,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionReviewT005'
  },
  {
    path: '/:assetType/:assetSlug/review/:workOrderSlug/forms/:formSlug/t006',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionReviewT006,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionReviewT006'
  },
  {
    path: '/:assetType/:assetSlug/review/:workOrderSlug/forms/:formSlug/t007',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionReviewT007,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionReviewT007'
  },
  {
    path: '/:assetType/:assetSlug/review/:workOrderSlug/forms/:formSlug/t009',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InspectionReviewT009,[]), {apps: 'InspectionForm', layoutPhase: 1}),
    key: 'InspectionReviewT009'
  }
]
