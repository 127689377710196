const env = process.env.REACT_APP_ENV
export const appConfig = {
  api: {
    networkInterface: ({
      development: 'https://dev.beta.sparta.aero:8222/',
      staging: 'https://qa.beta.sparta.aero:8223/',
      production: 'https://api.sparta.aero/',
    })[env],
    // add more here
    s3ImageUrl: ({
      development: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      staging: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      production: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
    })[env]
  },
}

export default appConfig
