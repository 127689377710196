import moment from 'moment';
import { globalGetService, globalExportService, globalPutService, globalPostService, globalDeleteService } from '../../../utils/globalApiServices';
import { fieldValidation, dateRangeValidation } from '../../../utils/formValidation';
import { errorCode} from './index';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
export function getPowerPlantDetailApi(props={}, query={}){
  globalGetService(`technical/engine-workorder/${props.match.params.workOrderSlug}/get-powerplant-accessories/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        powerPlantInfo: response.data.data,
        skeletonLoader: false
      })
    }
  })
}
export function exportPowerPlantDetailApi(props={}, file={}){
  this.setState({pageLoader:true});
  globalExportService(`technical/engine-workorder/${props.match.params.workOrderSlug}/get-powerplant-accessories/`, {download:'pdf'})
  .then(response => {
    downloadFileType(response.data, (`TE002_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.setState({pageLoader: false});
    this.props.enqueueSnackbar('Powerplant Inventory List downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function editPowerPlantDetailApi(props={}, data={}){
  let accessoriesFlag = true;
  let accessoriesError = [];
  let additionalFlag = true;
  let additionalError = [];
  let missingHardwareFlag = true;
  let missingHardwareError = [];
  let validationInputs = {
    place_of_inspection:errorCode['place_of_inspection'][fieldValidation({...errorCode['place_of_inspectionObj'], fieldval: data.general_details.place_of_inspection})],
    date_of_inspection:errorCode['date_of_inspection'][fieldValidation({...errorCode['date_of_inspectionObj'], fieldval: data.general_details.date_of_inspection})],
    report_date:errorCode['report_date'][fieldValidation({...errorCode['report_dateObj'], fieldval: data.general_details.report_date})],
    report_issued_place:errorCode['report_issued_place'][fieldValidation({...errorCode['report_issued_placeObj'], fieldval: data.general_details.report_issued_place})],
  }
  if(data.general_details.date_of_inspection && data.general_details.report_date){
    validationInputs = {
      ...validationInputs,
      report_date: dateRangeValidation(data.general_details.date_of_inspection, data.general_details.report_date, 0) ? 'Report Date should be greater then Inspection Date':''
    }
  }
  accessoriesError = data.accessories_list.map(item => {
    return {
      ata: item.ata && item.ata.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.ata) ? '':'Alphanumeric with space and hypens only':'',
      delivery_part_number: item.delivery_part_number && item.delivery_part_number.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.delivery_part_number) ? '':'Alphanumeric with space and hypens only':'',
      delivery_serial_number: item.delivery_serial_number && item.delivery_serial_number.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.delivery_serial_number) ? '':'Alphanumeric with space and hypens only':'',
      installed_part_number: item.installed_part_number && item.installed_part_number.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.installed_part_number) ? '':'Alphanumeric with space and hypens only':'',
      installed_serial_number: item.installed_serial_number && item.installed_serial_number.trim() ? regexConstants.alphanumericWithHyphenSpace.test(item.installed_serial_number) ? '':'Alphanumeric with space and hypens only':'',
    }
  })
  accessoriesError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      accessoriesFlag = false
    }
  });

  additionalError = data.additional_data.map(item => {
    return {
      part_number: item.part_number && item.part_number.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.part_number) ? '':'Alphanumeric with space and hypens only':'',
      serial_number: item.serial_number && item.serial_number.trim() !== '' ? regexConstants.alphanumericWithHyphenSpace.test(item.serial_number) ? '':'Alphanumeric with space and hypens only':'',
    }
  })
  additionalError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      additionalFlag = false
    }
  });

  missingHardwareError = data.missing_hardware.map(item => {
    return {
      ata: item.ata && item.ata.trim() ? regexConstants.alphanumericWithHyphenSpace.test(item.ata) ? '':'Alphanumeric with space and hypens only':'',
      part_number: item.part_number && item.part_number.trim() ? regexConstants.alphanumericWithHyphenSpace.test(item.part_number) ? '':'Alphanumeric with space and hypens only':'',
      serial_number: item.serial_number && item.serial_number.trim() ? regexConstants.alphanumericWithHyphenSpace.test(item.serial_number) ? '':'Alphanumeric with space and hypens only':'',
    }
  })
  missingHardwareError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      missingHardwareFlag = false
    }
  });

  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) && accessoriesFlag && additionalFlag && missingHardwareFlag){
    this.setState({pageLoader:true});
    globalPostService(`technical/engine-workorder/${props.match.params.workOrderSlug}/update-powerplant-accessories/`, data)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState({
          powerPlantInfo: response.data.data,
          skeletonLoader: false
        })
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        general_details: validationInputs,
        accessories_list: accessoriesError,
        additional_data: additionalError,
        missing_hardware: missingHardwareError,
      }
    }));
  }
}
export function deletePowerPlantDetailApi(props={}, data){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/engine-workorder/${props.match.params.workOrderSlug}/powerplant-accessory/${data.id}/delete/`, data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        powerPlantInfo: {
          ...prevState.powerPlantInfo,
          [data.type]: [...prevState.powerPlantInfo[data.type]].filter((item, index) => index !== data.deleteIndex)
        },
        deleteIds:{},
        deleteModal:false
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
