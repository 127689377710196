import React, { Fragment } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AddReport from './AddReport'
const PRSBulkOps = ({gapReport, item_ids, projectUsers, selectedListItem, handleChange}) => {
  return(
    <Grid item xs={12} md={6}>
      <div className="table-items-selection">
        <ul className="list-inline flex-centered">
          <li className="list-inline-item">
            <Autocomplete
              options = {[{id:0, name:'Table Content'}, ...gapReport.list.map(item => { return {...item, name:`${item.name} ${item.total_item > 0 ? `(${item.total_item})`:''}` }})]}
              getOptionLabel={option => option.name}
              id="selectedListItem"
              value={selectedListItem}
              onChange={(e, data) => handleChange(e, data)}
              renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} />}
            />
          </li>
          { selectedListItem.id !== 0 ?
            <Fragment>
              <li className="list-inline-item">
                <AddReport
                  type="add"
                  gapReport={gapReport}
                  selectedListItem={selectedListItem}
                />
              </li>
              { item_ids.length ?
                <Fragment>
                  <li className="list-inline-item flex-centered">
                    <DeleteOutlineIcon color="secondary" fontSize="small" /> <span className="action-menu">Delete</span>
                  </li>
                  <li className="list-inline-item flex-centered">
                    <ControlCameraIcon color="primary" fontSize="small" /> <span className="action-menu">Move</span>
                  </li>
                  <li className="list-inline-item flex-centered">
                    <PeopleOutlineIcon color="primary" fontSize="small" /> <span className="action-menu">Assign</span>
                  </li>
                </Fragment>:null
              }
            </Fragment>:null
          }
        </ul>
      </div>
    </Grid>
  )
}
export default PRSBulkOps;
