import React from 'react';
const PrerequisiteContent = ({}) => {
  return(
    <ol>
      <li>To check for the documents received against the list of engine documents pertaining to the scope of review</li>
      <li>To update the objective of the review report</li>
      <li>Review and extract the engine origin details</li>
      <li>Review and extract the engine operation and the shop visit details through out the engine operation</li>
      <li>Analyse the extracted data to understand the engine operational and shop visit history</li>
      <li>Review and analyse the latest ECTM, BSI report, LLP status, any soft time limited engine hardware and / or any additional information</li>
      <li>Update all extracted details in the engine data review master sheet till the latest available date</li>
      <li>Update the extracted details in the engine data review master sheet</li>
      <li>Select the required check box pertaining to the scope of review for generating report</li>
    </ol>
  )
}
export default PrerequisiteContent;
