import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Switch } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { InspectionFormWrapper, ImageSlider } from '../../../Elements';
import { TechSpecsConfig, SeatDetails, SetSeatsConfig, LopaConfig, CrewSeatConfig, AircraftTail } from '../components';
import { PageLoader } from '../../../../shared_elements';
import { imgStoragePath } from '../../../../constants';
import { getSeatingConfigApi, getSeatFindingApi, getSeatFindingQuestionsApi, getSeatFindingsAnsApi, saveSeatFindingsAnsApi, changeSeatStatusApi, exportSeatingConfigApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
import { getManufacturersAc, getPlatformConstantsAc } from '../../../../shared_elements/actionCreators';
import { pilotConfig } from '../';
class InspectionFormT008 extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:true,
      skeletonLoader:true,
      inspectionHdInfo:{},
      pilotSeats : pilotConfig,
      seatConfig: {interior_config_details:[]},
      existingFinding:{},
      seatarr: [],
      windowArr:[],
      seatWindFlag: 1,
      configtype: 'edit',
      selectedAllWindowSeats: false,
      seatFindings:{findings:[]},
      seatDetails: {
        modal:false,
        data:[]
      }
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getSeatingConfigApi = getSeatingConfigApi.bind(this);
    this.getSeatFindingApi = getSeatFindingApi.bind(this);
    this.getSeatFindingQuestionsApi = getSeatFindingQuestionsApi.bind(this);
    this.getSeatFindingsAnsApi = getSeatFindingsAnsApi.bind(this);
    this.saveSeatFindingsAnsApi = saveSeatFindingsAnsApi.bind(this);
    this.changeSeatStatusApi = changeSeatStatusApi.bind(this);
    this.exportSeatingConfigApi = exportSeatingConfigApi.bind(this);
  }
  componentDidMount(){
    this.props.getManufacturers();
    this.props.getPlatformConstants();
    this.getInspectionHeaderApi(this.props);
    this.getSeatingConfigApi(this.props);
    this.getSeatFindingApi(this.props);
  }
  getCrewSeatClasses = (seatType) => {
    const { existingFinding, seatarr } = this.state;
    return existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == seatType)).length  && seatarr.filter(seat => seat.seat_number == seatType).length &&  [seatarr.filter(seat => seat.seat_number == seatType)[0]['seat_number']].includes(seatType) ? 'disabled-seat current' : existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == seatType)).length  ? 'disabled-seat' :  seatarr.filter(seat => seat.seat_number == seatType).length &&  [seatarr.filter(seat => seat.seat_number == seatType)[0]['seat_number']].includes(seatType) ? 'active current enabled-seat ' : existingFinding.seats && existingFinding.seats.filter(findingData => findingData.seat_number == (seatType) &&  findingData.findings.length).length && [existingFinding.seats.filter(findingData => findingData.seat_number == (seatType))[0]['seat_number']].includes((seatType)) ?  'has-finding findings-found enabled-seat' : 'enabled-seat';
  }
  addSeatsFn = (seat) => {
    const { existingFinding, seatarr } = this.state;
    let disabledSeats = existingFinding.seats.filter(item => item.status === 0).map(item => item.seat_number);
    if(seatarr.length){
      let status = disabledSeats.includes(seat.seat_number) ? 0:1;
      if(status === 0 && seatarr.map(item=>item.seat_number).every(seat => disabledSeats.includes(seat))){
        this.setState(prevState => ({
          ...prevState,
          seatarr: prevState.seatarr.find(item => item.seat_number === seat.seat_number) ? prevState.seatarr.filter(item => item.seat_number !== seat.seat_number) : [...prevState.seatarr, seat]
        }));
      }
      if(status === 1 && !seatarr.map(item=>item.seat_number).every(seat => disabledSeats.includes(seat))){
        this.setState(prevState => ({
          ...prevState,
          seatarr: prevState.seatarr.find(item => item.seat_number === seat.seat_number) ? prevState.seatarr.filter(item => item.seat_number !== seat.seat_number) : [...prevState.seatarr, seat]
        }));
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        seatarr: prevState.seatarr.find(item => item.seat_number === seat.seat_number) ? prevState.seatarr.filter(item => item.seat_number !== seat.seat_number) : [...prevState.seatarr, seat]
      }));
    }
  }
  selectAllWindowOrSeat = () => {
    let seatRange = [];
    const { seatConfig, existingFinding, seatWindFlag, pilotSeats, selectedAllWindowSeats } = this.state;
    let disabledSeat = existingFinding.seats ? existingFinding.seats.filter(findingData => (findingData.status ===  0)).map(item => item.seat_number): [];
    if(seatWindFlag === 1){
      if(selectedAllWindowSeats){
        this.setState({seatarr:[], selectedAllWindowSeats:false});
      }else{
        let selectedSeats = [];
        seatConfig.interior_config_details.map((data, index) => {
          seatRange = this.range(data.starting_row, data.end_row);
          seatRange.map((seatNo, seatIndex) => {
            data.columns.map((colItem, index) => {
              colItem.map((col, colIndex) => {
                if(!disabledSeat.includes(seatNo+col)){
                  selectedSeats.push({
                    seat_number: seatNo+col,
                    seat_type : data.seat_type,
                    row_number: parseInt(seatIndex+1),
                    interior_inspection_id:  data.id
                  });
                }
              })
            })
          })
        });
        let crewSeats = pilotSeats.filter(item => !disabledSeat.includes(item.seat_number));
        this.setState({seatarr:[...selectedSeats, ...crewSeats], selectedAllWindowSeats:true});
      }
    }else{
      if(selectedAllWindowSeats){
        this.setState({windowArr:[], selectedAllWindowSeats:false});
      }else{
        let selectedWindows = [];
        seatConfig.interior_config_details.map((data, index) => {
          let endRow = data.row_diff ? data.row_diff : 0;
          let startRow = data.starting_row == 0 ? 1 : data.starting_row;
          let seatRange = this.range(startRow, parseInt(data.end_row + endRow));
          seatRange.map((seatNo, seatIndex) => {
            selectedWindows.push({ window_number : (seatNo+'R'), row_number : (seatIndex+1),  interior_inspection_id: data.id});
            selectedWindows.push({ window_number : (seatNo+'L'), row_number : (seatIndex+1),  interior_inspection_id: data.id});
          })
        });
        this.setState({windowArr:selectedWindows, selectedAllWindowSeats:true});
      }
    }
  }
  addWindowFn = (seat) => {
    this.setState(prevState => ({
      ...prevState,
      windowArr: prevState.windowArr.find(item => item.window_number === seat.window_number) ? prevState.windowArr.filter(item => item.window_number !== seat.window_number) : [...prevState.windowArr, seat]
    }));
  }
  updateFinding = (item, flag) => {
    this.setState(prevState => ({
      ...prevState,
      seatFindings: {
        ...prevState.seatFindings,
        findings:  flag ? [...prevState.seatFindings.findings, item]:prevState.seatFindings.findings.filter(finding => finding.id != item.id)
      }
    }));
  }
  range = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }
  render(){
    const { selectedAllWindowSeats, inspectionHdInfo, pilotSeats, seatConfig, existingFinding, seatarr, windowArr, seatWindFlag, configtype, seatDetails, seatFindings, skeletonLoader, pageLoader } = this.state;
    const { manufacturers, pltConstants } = this.props;
    return(
      <div className="technical-aircraft-froms">
        <InspectionFormWrapper
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''}
          ]}
          exportReportFn={(file) => this.exportSeatingConfigApi(this.props, file)}
        />
        <div className="form-t008-cn" style={{minHeight: window.innerHeight - 104-55}}>
          <div className="edit-config">
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                { seatConfig.interior_config_details.length ?
                  <ul className="list-inline seat-window-sw flex-centered">
                    <li className={`list-inline-item ${seatWindFlag === 1?'active':'non-active'}`}>Seat</li>
                    <li className="list-inline-item">
                      <Switch color="primary" checked={seatWindFlag === 1 ? true:false} onChange={(e) => this.setState({seatWindFlag:e.target.checked ? 1:0, seatarr:[], windowArr:[]})} />
                    </li>
                    <li className={`list-inline-item ${seatWindFlag === 0?'active':'non-active'}`}>Window</li>
                  </ul>:null
                }
              </Grid>
              <Grid item xs={12} md={9}>
                <ul className="list-inline seat-config-options">
                  { seatConfig.interior_config_details.length ?
                    <Fragment>
                      <li className="list-inline-item">{seatWindFlag === 1 ? seatarr.length:windowArr.length} {seatWindFlag === 1 ?'Seat(s)':'Window(s)'} selected</li>
                      <li className="list-inline-item" onClick={() => this.selectAllWindowOrSeat()}>{selectedAllWindowSeats ? 'Unselect All':'Select All'}</li>
                    </Fragment>:null
                  }
                  <li className="list-inline-item" style={{color: seatarr.length || windowArr.length ? '#ffffff': 'rgba(255,255,255,0.4)'}} onClick={() => {this.getSeatFindingQuestionsApi(this.props, seatWindFlag); this.getSeatFindingsAnsApi(this.props, seatWindFlag, seatWindFlag === 1 ? seatarr:windowArr); }}>Details</li>
                  <li className="list-inline-item">
                    <TechSpecsConfig manufacturers={manufacturers} />
                  </li>
                  <li className="list-inline-item">
                    { Object.keys(seatConfig).length ?
                      <LopaConfig is_lopa_details={seatConfig.is_lopa_details} />:null
                    }
                  </li>
                  <li className="list-inline-item">
                    <SetSeatsConfig
                      pltConstants={pltConstants}
                      seatConfig={seatConfig}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>
          <div className="seats-container">
            { Object.keys(seatConfig).length  && seatConfig.interior_config_details && seatConfig.interior_config_details.length ?
              <div className="seat-matrix" style={{display: "flex"}}>
                <CrewSeatConfig
                  seatWindFlag={seatWindFlag}
                  existingFinding={existingFinding}
                  seatarr={seatarr}
                  pilotSeats={pilotSeats}
                  addSeatsFn={this.addSeatsFn}
                />
                <div className="cabin-seats">
                  <img src={imgStoragePath + "aircraft-body.png"} className="cabin-image"/>
                  { seatConfig.interior_config_details.map((data, interiorIndex) => {
                    let endRow = data.row_diff ? data.row_diff : 0;
                    let startRow = data.starting_row == 0 ? 1 : data.starting_row;
                    let seatRange = this.range(startRow, parseInt(data.end_row + endRow));
                    return(
                      seatRange.map((seatNo, seatIndex) =>
                        <ul key={seatIndex} className="list-inline seat-list">
                          <li className="list-inline-item">
                            <ul className="list-unstyled">
                              <li>
                                <a
                                  className= { windowArr.filter(window => window.window_number == (seatNo+'R').toString()).length &&  [windowArr.filter(window => window.window_number == (seatNo+'R').toString())[0]['window_number']].includes((seatNo+'R').toString()) ? 'window window-selected' : existingFinding.windows && existingFinding.windows.filter(findingData => findingData.window_number == (seatNo+'R').toString()).length && [existingFinding.windows.filter(findingData => findingData.window_number == (seatNo+'R').toString())[0]['window_number']].includes((seatNo+'R').toString()) ?  'has-finding  window' : 'window'}
                                  onClick = {() => this.addWindowFn({ window_number : (seatNo+'R'), row_number : (seatIndex+1),  interior_inspection_id: data.id})}
                                  style={seatWindFlag ? {'opacity': '0.5', pointerEvents: 'none'} : {'opacity': '1', pointerEvents: 'auto'} }
                                  >
                                </a>
                              </li>
                              { data.columns.map((colItem, index) =>
                                  colItem.map((col, colIndex) =>  {
                                    let checkExistingFinding = existingFinding.seats ? existingFinding.seats.filter(findingData => (findingData.seat_number == seatNo+col)): [];
                                    let disabledSeat = existingFinding.seats ? existingFinding.seats.filter(findingData => (findingData.status ==  0 && findingData.seat_number == seatNo+col)): [];
                                    let selectedSeat = seatarr.length ? seatarr.filter(seat => seat.seat_number == (seatNo+col) ) : [];
                                    return(
                                      <li key={colIndex} className={((colItem.length - 1) == colIndex)  ? "column-seperator" : ""}
                                        style={seatRange.indexOf(seatNo) > seatRange.indexOf(data.end_row) || seatRange.indexOf(seatNo) < seatRange.indexOf(data.starting_row)   ? {opacity: 0, pointerEvents: 'none', visibility: 'hidden'} : {}}
                                      >
                                        <a
                                          style={seatWindFlag ? { opacity: 1,  pointerEvents: 'auto'} : { opacity: '0.5', pointerEvents: 'none'} }
                                          className= { (disabledSeat.length && [disabledSeat[0]['seat_number']].includes((seatNo+col)) && selectedSeat.length && [selectedSeat[0]['seat_number']].includes((seatNo+col)) ) ? 'disabled-seat current' : disabledSeat.length && [disabledSeat[0]['seat_number']].includes((seatNo+col)) ? 'disabled-seat' : selectedSeat.length &&  [selectedSeat[0]['seat_number']].includes((seatNo+col)) ? 'active current enabled-seat' : checkExistingFinding.length && checkExistingFinding[0].findings.length && [checkExistingFinding[0]['seat_number']].includes((seatNo+col)) ? 'has-finding findings-found enabled-seat' : 'enabled-seat'}
                                          onClick = {() => this.addSeatsFn({seat_number: (seatNo+col), seat_type: data.seat_type ,row_number: (seatIndex+1),  interior_inspection_id: data.id})}
                                          > {seatNo} {col}  </a>
                                      </li>
                                    )
                                  }
                                ))
                              }
                              <li>
                                <a
                                  className= { windowArr.filter(window => window.window_number == (seatNo+'L').toString()).length &&  [windowArr.filter(window => window.window_number == (seatNo+'L').toString())[0]['window_number']].includes((seatNo+'L').toString()) ? 'window window-selected' : existingFinding.windows && existingFinding.windows.filter(findingData => findingData.window_number == (seatNo+'L').toString()).length && [existingFinding.windows.filter(findingData => findingData.window_number  == (seatNo+'L').toString())[0]['window_number']].includes((seatNo+'L').toString()) ?  'has-finding  window' : 'window'}
                                  onClick = {() => this.addWindowFn({ window_number : (seatNo+'L'), row_number : (seatIndex+1),  interior_inspection_id: data.id})}
                                  style={seatWindFlag ? {'opacity': '0.5', pointerEvents: 'none'} : {'opacity': '1', pointerEvents: 'auto'} }
                                >
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      )
                    )
                  })}
                </div>
                <AircraftTail />
              </div>:
              <p style={{color:'#6f859b',textAlign:'center',fontSize:'16px',marginTop:'30px'}}>Seating configuration not found</p>
            }
          </div>
        </div>
        { seatDetails.modal ?
          <SeatDetails
            seatWindFlag={seatWindFlag}
            seatDetails={seatDetails}
            seatFindings={seatFindings}
            updateFinding={this.updateFinding}
            saveSeatFindingsAnsFn={(data) => this.saveSeatFindingsAnsApi(this.props, seatWindFlag, seatWindFlag === 1 ? {findings:seatFindings.findings,seats:seatarr}:{findings:seatFindings.findings,windows:windowArr})}
            changeSeatStatusFn={(status) => this.changeSeatStatusApi(this.props, {seats: seatarr, status:status})}
            toggleModalFn={() => this.setState({seatDetails: {modal:false, data: []}})}
          />:null
        }

        { pageLoader ? <PageLoader /> :null }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  manufacturers: state.shareReducer.manufacturers,
  pltConstants: state.shareReducer.pltConstants
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getManufacturers: (constants) => dispatch(getManufacturersAc()),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['pax_seat_type']}))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(InspectionFormT008))
