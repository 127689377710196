import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';
import { TableListComp, EmptyCollection, DeletePopUp, PageLoader } from '../../../../shared_elements';
import { RptTable, RptList, AddReport, CountCard, PRSFilter, PRSBulkOps, PRSHeading, PRSAnalytic } from '../components';
import { InspectionFormWrapper } from '../../../Elements';
import { removeEmptyKey, convertFilterObject } from '../../../../utils';
import { getIssueReportTableApi, getIssueReportListApi, addIssueReportApi, editIssueReportApi, deleteIssueReportApi, movePRSIssuesApi, assignPRSIssuesApi, projectUsersApi, exportIssueReportTableApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
import { prsRptHd1, prsRptHd2, prsRptFilterOps } from '../../../';
const ids = [5,6,7,8,9,10,14,15,16,17,21,22,23,24,25]
class InspectionFormT011 extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex:0,
      item_ids:[],
      layoutType:0,
      selectedListItem:{id:0, name:'Table Content'},
      skeletonLoader:true,
      pageLoader:false,
      inspectionHdInfo:{},
      filter: {},
      applyingFilter:{},
      userStats:[],
      gapReport: {
        list: [],
        items: {},
      },
      rptIssues: {
        list: [],
        pagination:{}
      },
      error:{},
      deleteModal: false,
      deleteIds:[],
      projectUsers:[]
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getIssueReportTableApi = getIssueReportTableApi.bind(this);
    this.getIssueReportListApi = getIssueReportListApi.bind(this);
    this.addIssueReportApi = addIssueReportApi.bind(this);
    this.editIssueReportApi = editIssueReportApi.bind(this);
    this.deleteIssueReportApi = deleteIssueReportApi.bind(this);
    this.movePRSIssuesApi = movePRSIssuesApi.bind(this);
    this.assignPRSIssuesApi = assignPRSIssuesApi.bind(this);
    this.exportIssueReportTableApi = exportIssueReportTableApi.bind(this);
    this.projectUsersApi = projectUsersApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getIssueReportTableApi(this.props);
    this.projectUsersApi(this.props);
  }

  applyFilter = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      applyingFilter: {
        ...prevState.applyingFilter,
        [keyParam]: value
      }
    }))
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, selectedListItem } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getIssueReportListApi(this.props, {...query, section:selectedListItem.id});
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  handleChange = (e, data) => {
    if(data){
      if(data.id === 0){
        this.setState(prevState => ({
          ...prevState,
          selectedListItem:data
        }))
      }else{
        this.getIssueReportListApi(this.props, {section:data.id})
        this.setState({filter: {},applyingFilter:{}, selectedListItem:data});
      }
    }
  }
  onFieldChangeRow = (event, keyParam, data, arrayIndex) => {
    this.setState(prevState => ({
      ...prevState,
      rptIssues: {
        ...prevState.rptIssues,
        list: [...prevState.rptIssues.list].map((item , index) => index === arrayIndex ? {
          ...item,
          [keyParam]:data
        } :item)
      },
      error:{}
    }));
  }
  render(){
    const { projectUsers, layoutType, item_ids, selectedListItem, inspectionHdInfo, gapReport, rptIssues, deleteModal, deleteIds, error, filter, applyingFilter, pageLoader, skeletonLoader } = this.state;
    return(
      <div className="technical-aircraft-froms">
        <InspectionFormWrapper
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'Excel', extension: 'xls', key:''}
          ]}
          exportReportFn={(file) => this.exportIssueReportTableApi(this.props, file)}
          importReportCta={
            <AddReport
              type="import"
              gapReport={gapReport}
              selectedListItem={selectedListItem}
              projectUsers={projectUsers}
            />
          }
        />
        <div className="form-t011-cn">
          <PRSHeading
            onChangeLayoutType={(type) => this.setState({layoutType:type})}
            layoutType={layoutType}
          />
          { layoutType === 0 ?
            <div>
              <Grid container spacing={2}>
                <CountCard classes="total-count" count={gapReport.items.total ? gapReport.items.total:0} label="Total No. Of Items" />
                <CountCard classes="open-count" count={gapReport.items.open ? gapReport.items.open:0} label="Total No. Of Open Items" />
                <CountCard classes="closed-count" count={gapReport.items.closed ? gapReport.items.closed:0} label="Total No. Of Closed Items" />
                <CountCard classes="reopen-count" count={gapReport.items.reopen ? gapReport.items.reopen:0} label="Total No. Of Reopen Items" />
              </Grid>
              <Grid container spacing={2}>
                <PRSBulkOps
                  gapReport={gapReport}
                  selectedListItem={selectedListItem}
                  handleChange={this.handleChange}
                  item_ids={item_ids}
                  projectUsers={projectUsers}
                />
                { selectedListItem.id !== 0 ? <PRSFilter />:null }
              </Grid>
              { selectedListItem.id === 0 ?
                <TableListComp
                  heads={[
                    { label: 'Sr No.', sortOption: false },
                    { label: 'Contents', sortOption: false },
                    { label: 'Items Open', sortOption: false },
                    { label: 'Items Closed', sortOption: false },
                    { label: 'Items Reopened', sortOption: false },
                    { label: 'Total Item', sortOption: false }
                  ]}
                  data={gapReport.list.map((item,index) =>
                    <RptTable
                      key={index}
                      item={item}
                      index={index+1}
                      showRptTab={() => this.handleChange('',item)}
                    />
                  )}
                />:
                <div className="prs-tabling">
                  <TableListComp
                    bulkOperation={true}
                    bulkFlag={rptIssues.list.length === item_ids.length && rptIssues.list.length > 0}
                    heads={ids.includes(selectedListItem.id) ? prsRptHd2:prsRptHd1}
                    data={rptIssues.list.map((item,index) =>
                      <RptList
                        projectUsers={projectUsers}
                        item_ids={item_ids}
                        inspectionHdInfo={inspectionHdInfo}
                        key={index}
                        item={item}
                        index={index+1}
                        ids={ids}
                        sectionId={selectedListItem.id}
                        error={error}
                        previewDetail={(mode) => {this.setState({deleteModal:true, deleteIds:{id:item.id}})}}
                        onFieldChange={(event, keyParam, data) => this.onFieldChangeRow(event, keyParam, data, index)}
                        updateRptFieldFn={(key, value) => this.editIssueReportApi(this.props, item, key, value, selectedListItem.id, index)}
                      />
                    )}
                    noRecord={ rptIssues.list.length ? null:
                      <EmptyCollection title="No records found" />
                    }
                  />
                </div>
              }
            </div>:
            <div>
              <PRSAnalytic
                gapReport={gapReport}
                selectedListItem={selectedListItem}
                userStats={[]}
              />
            </div>
          }
        </div>
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => this.setState({deleteModal:false, deleteIds:[]})}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.deleteIssueReportApi(this.props, deleteIds, selectedListItem.id )}
        />
        { pageLoader ? <PageLoader/> :null }
      </div>
    )
  }
}
export default withSnackbar(InspectionFormT011);
