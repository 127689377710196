import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, Popover, Avatar } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
const feedbackColorCode = ['#a45f5f', '#f9cd74', '#38a64f'];
const CorList = ({item}) => {
  function popOverCard(engineers){
    return(
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <Fragment>
            <InfoIcon {...bindTrigger(popupState)} />
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{maxHeight:'300px', overflow:'auto'}}>
                <table>
                  <thead>
                    <tr>
                      <th>Project Engineers</th>
                      <th>General</th>
                      <th>Physical Inspection</th>
                      <th>Maintenance</th>
                      <th>Technical Records</th>
                      <th>Operation of aircraft</th>
                      <th>GeoPolitical</th>
                      <th>Overall Rating</th>
                    </tr>
                  </thead>
                  <tbody>
                    {engineers.map((engineer, index) =>
                      <tr>
                        <td></td>
                        <td>{engineer.cor[0].average_rating}</td>
                        <td>{engineer.cor[1].average_rating}</td>
                        <td>{engineer.cor[2].average_rating}</td>
                        <td>{engineer.cor[3].average_rating}</td>
                        <td>{engineer.cor[4].average_rating}</td>
                        <td>{engineer.cor[5].average_rating}</td>
                        <td>{engineer.cor[6].average_rating}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Popover>
          </Fragment>
        )}
      </PopupState>
    )
  }
  return(
    <TableRow hover tabIndex={-1}>
    <TableCell style={{width:'250px'}}>
      <div className="project-info-col flex-centered">
        { item.lessor && item.lessor.logo ?
          <span className="lessor-info"><img src={item.lessor.logo} alt={item.lessor.name} /></span>:
          <span className="lessor-info">{item.lessor.name}</span>
        }

        <div className="project-details">
          <p className="project-number">{`#${item.project_number}`}</p>
          <h3>{item.name}</h3>
          <p className="lead-by">Lead By - <span>{item.leads.map(lead => lead.user.name).join(',')}</span></p>
        </div>
      </div>
    </TableCell>
      <TableCell>
        { item.lessee && item.lessee.name ? item.lessee.name:'--'}
      </TableCell>
      <TableCell>
        {(() => {
          if(item.engineers.length === 0){
            return(
              <Fragment>0 Engineer</Fragment>
            )
          }else if (item.engineers.length === 1) {
            return(
              <Fragment>
                {item.engineers[0].user.name}
              </Fragment>
            )
          }else{
            return(
              <Fragment>
                {item.engineers.length} Engineers
                {popOverCard(item.engineers)}
              </Fragment>
            )
          }
        })()}
      </TableCell>
      <TableCell>
        { item.engineers.length ?
          item.engineers.map((engineer, index) =>
          <div className="engineer-status">
            <h4>{engineer.user.name}</h4>
            <Link>
              <p><span style={{width: '6px', height: '6px', display: 'inline-block', position: 'relative', top: '-1px', background: feedbackColorCode[engineer.feedback_status]}}></span> {engineer.feedback_status ? engineer.feedback_status==1 ? 'Submitted':'Accepted':'Pending'}</p>
            </Link>
          </div>
        ):null}
      </TableCell>
      <TableCell>
        <div className="rating-info">
          <table style={{width: '100%'}}>
            <TableRow colSpan="8">
              <TableCell style={{width:'52px'}}>
                <p style={{width:'100px'}}>{ item.cor && item.cor.length && item.cor[0].average_rating?item.cor[0].average_rating+'/5':'-'}</p>
              </TableCell>
              <TableCell style={{width:'52px'}}>
                <p style={{width:'100px'}}>{ item.cor && item.cor.length && item.cor[1].average_rating?item.cor[1].average_rating+'/5':'-'}</p>
              </TableCell>
              <TableCell style={{width:'52px'}}>
                <p style={{width:'100px'}}>{ item.cor && item.cor.length && item.cor[2].average_rating?item.cor[2].average_rating+'/5':'-'}</p>
              </TableCell>
              <TableCell style={{width:'52px'}}>
                <p style={{width:'100px'}}>{ item.cor && item.cor.length && item.cor[3].average_rating?item.cor[3].average_rating+'/5':'-'}</p>
              </TableCell>
              <TableCell style={{width:'52px'}}>
                <p style={{width:'100px'}}>{ item.cor && item.cor.length && item.cor[4].average_rating?item.cor[4].average_rating+'/5':'-'}</p>
              </TableCell>
              <TableCell style={{width:'52px'}}>
                <p style={{width:'100px'}}>{ item.cor && item.cor.length && item.cor[5].average_rating?item.cor[5].average_rating+'/5':'-'}</p>
              </TableCell>
              <TableCell style={{width:'52px'}}>
                <p style={{width:'100px'}}>{ item.cor && item.cor.length && item.cor[6].average_rating?item.cor[6].average_rating+'/5':'-'}</p>
              </TableCell>
              <TableCell style={{width:'52px'}}>test</TableCell>
            </TableRow>
          </table>
        </div>
      </TableCell>
    </TableRow>
  )
}
export default CorList;
