import React from 'react';
import { TableRow, TableCell, Button } from '@material-ui/core';
import AddEditFinding from './AddEditFinding';
import { TableListComp, EmptyCollection, TableRowActions } from '../../shared_elements';
const FindingsTable = ({sectionId, findings, previewDetail, getFindingResponse, triggerNewPoint}) => {
  return(
    <div>
      <div className="text-right" style={{margin: '0 0 20px 0'}}>
{
  //        <AddEditFinding sectionId={sectionId} getFindingResponse={getFindingResponse} />
}
        <Button style={{marginLeft:'10px'}} color="primary" variant="outlined" onClick={triggerNewPoint}>Add Finding</Button>
      </div>
      <TableListComp
        heads={[
          { label: 'SL No.', sortOption: false },
          { label: 'Type of Damage', sortOption: false },
          { label: 'Description', sortOption: false },
          { label: 'Classification', sortOption: false },
          { label: 'Category', sortOption: false },
          { label: 'Repair Type', sortOption: false },
          { label: 'Action', sortOption: false, actionCell: true }
        ]}
        data={findings.map((item, index) =>
          <TableRow hover tabIndex={-1}>
            <TableCell style={{width:'100px'}}>{item.id}</TableCell>
            <TableCell>{item.finding_type ? item.finding_type:'--'}</TableCell>
            <TableCell>{item.remarks ? item.remarks: '--'}</TableCell>
            <TableCell>{item.classifications ? item.classifications :'--' }</TableCell>
            <TableCell>{item.category ? item.category :'--' }</TableCell>
            <TableCell>{item.repair_type ? item.repair_type :'--' }</TableCell>
            <TableCell className="actions-cell" style={{width: '120px'}}>
              <AddEditFinding sectionId={sectionId} id={item.id} getFindingResponse={getFindingResponse} />
              <TableRowActions
                actions={['D']}
                previewDetail={(mode) => previewDetail(mode, item.id)}
              />
            </TableCell>
          </TableRow>
        )}
        noRecord={ findings.length ? null:
          <EmptyCollection title="No records found" />
        }
      />
    </div>
  )
}
export default FindingsTable;
