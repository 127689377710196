import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { TableListComp, EmptyCollection, LabelValueCard } from '../../../../shared_elements';
import { Attachment } from '../../../Elements';
import { regexConstants } from '../../../../constants/regEx';
const ShopVisitCRU = ({inspectionHdInfo, shopVisitCrud, addEditShopVisitFn, onFieldChange, onAttachmentChange, toggleModalFn, uploadAttachmentFn, deleteAttachmentFn, error, handleError, resetErrorKey}) => {
  return(
    <Fragment>
      <Dialog
        fullScreen
        open={shopVisitCrud.modal}
        onClose={toggleModalFn}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          Shop Visit
        </DialogTitle>
        <DialogContent dividers={true}>
          <form>
            <Grid container spacing={3}>
              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    id="shop_visit_number"
                    label="Shop Visit Number"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 20}}
                    value={shopVisitCrud.data.shop_visit_number ? shopVisitCrud.data.shop_visit_number:''}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'shop_visit_number', e.target.value):e.preventDefault()}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Shop Visit Number' value={shopVisitCrud.data.shop_visit_number ? shopVisitCrud.data.shop_visit_number:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       id="date_of_removal"
                       label="Date of Removal"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture={true}
                       inputProps={{readOnly: true}}
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       value={shopVisitCrud.data.date_of_removal ? shopVisitCrud.data.date_of_removal:null}
                       onChange={(data, value) => {onFieldChange(value, 'date_of_removal', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Date of Removal' value={shopVisitCrud.data.date_of_removal ? shopVisitCrud.data.date_of_removal:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="reason_for_removal"
                    label="Reason of Removal"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 30}}
                    value={shopVisitCrud.data.reason_for_removal ? shopVisitCrud.data.reason_for_removal:''}
                    onChange={(e) => onFieldChange(e, 'reason_for_removal', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Reason of Removal' value={shopVisitCrud.data.reason_for_removal ? shopVisitCrud.data.reason_for_removal:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="shop_facility"
                    label="Shop Facility"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 30}}
                    value={shopVisitCrud.data.shop_facility ? shopVisitCrud.data.shop_facility:''}
                    onChange={(e) => onFieldChange(e, 'shop_facility', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Shop Facility' value={shopVisitCrud.data.shop_facility ? shopVisitCrud.data.shop_facility:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="tsn"
                    label="TSN"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.tsn ? shopVisitCrud.data.tsn:''}
                    onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value): e.preventDefault()}}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='TSN' value={shopVisitCrud.data.tsn ? shopVisitCrud.data.tsn:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="csn"
                    label="CSN"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.csn ? shopVisitCrud.data.csn:''}
                    onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value) : e.preventDefault() }}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='CSN' value={shopVisitCrud.data.csn ? shopVisitCrud.data.csn:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="tslsv"
                    label="TSLSV"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.tslsv ? shopVisitCrud.data.tslsv:''}
                    onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tslsv', e.target.value): e.preventDefault()}}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='TSLSV' value={shopVisitCrud.data.tslsv ? shopVisitCrud.data.tslsv:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="cslsv"
                    label="CSLSV"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.cslsv ? shopVisitCrud.data.cslsv:''}
                    onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cslsv', e.target.value) : e.preventDefault() }}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='CSLSV' value={shopVisitCrud.data.cslsv ? shopVisitCrud.data.cslsv:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="tsr"
                    label="TSR"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.tsr ? shopVisitCrud.data.tsr:''}
                    onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsr', e.target.value): e.preventDefault()}}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='TSR' value={shopVisitCrud.data.tsr ? shopVisitCrud.data.tsr:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="csr"
                    label="CSR"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.csr ? shopVisitCrud.data.csr:''}
                    onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csr', e.target.value) : e.preventDefault() }}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='CSR' value={shopVisitCrud.data.csr ? shopVisitCrud.data.csr:''} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="tso"
                    label="TSO"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.tso ? shopVisitCrud.data.tso:''}
                    onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tso', e.target.value): e.preventDefault()}}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='TSO' value={shopVisitCrud.data.tso ? shopVisitCrud.data.tso:''} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="cso"
                    label="CSO"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.cso ? shopVisitCrud.data.cso:''}
                    onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cso', e.target.value) : e.preventDefault() }}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='CSO' value={shopVisitCrud.data.cso ? shopVisitCrud.data.cso:''} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="tshi"
                    label="TSHI"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.tshi ? shopVisitCrud.data.tshi:''}
                    onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tshi', e.target.value): e.preventDefault()}}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='TSHI' value={shopVisitCrud.data.tshi ? shopVisitCrud.data.tshi:''} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="cshi"
                    label="CSHI"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 10}}
                    value={shopVisitCrud.data.cshi ? shopVisitCrud.data.cshi:''}
                    onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cshi', e.target.value) : e.preventDefault() }}
                    onChange={(e) => onFieldChange(e, 'cshi', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='CSHI' value={shopVisitCrud.data.cshi ? shopVisitCrud.data.cshi:''} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       id="induction_date"
                       label="Engine Induction Date"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture={true}
                       inputProps={{readOnly: true}}
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       value={shopVisitCrud.data.induction_date ? shopVisitCrud.data.induction_date:null}
                       onChange={(data, value) => {onFieldChange(value, 'induction_date', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Engine Induction Date' value={shopVisitCrud.data.induction_date ? moment(shopVisitCrud.data.induction_date).format(displayDateFormatShort) :'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="final_workscope"
                    label="Engine Workscope"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 30}}
                    value={shopVisitCrud.data.final_workscope ? shopVisitCrud.data.final_workscope:''}
                    onChange={(e) => onFieldChange(e, 'final_workscope', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Engine Workscope' value={shopVisitCrud.data.final_workscope ? shopVisitCrud.data.final_workscope:''} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="build_target"
                    label="Engine Build Target"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 30}}
                    value={shopVisitCrud.data.build_target ? shopVisitCrud.data.build_target:''}
                    onChange={(e) => onFieldChange(e, 'build_target', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Engine Build Target' value={shopVisitCrud.data.build_target ? shopVisitCrud.data.build_target:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="test_run_egt_margin"
                    label="Test Run EGT Margin"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 30}}
                    value={shopVisitCrud.data.test_run_egt_margin ? shopVisitCrud.data.test_run_egt_margin:''}
                    onChange={(e) => onFieldChange(e, 'test_run_egt_margin', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Test Run EGT Margin' value={shopVisitCrud.data.test_run_egt_margin ? shopVisitCrud.data.test_run_egt_margin:''} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="llp_status"
                    label="LLP Status"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 30}}
                    value={shopVisitCrud.data.llp_status ? shopVisitCrud.data.llp_status:''}
                    onChange={(e) => onFieldChange(e, 'llp_status', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='LLP Status' value={shopVisitCrud.data.llp_status ? shopVisitCrud.data.llp_status:''} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       id="arc_date"
                       label="ARC Date ( EASA/FAA)"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture={true}
                       inputProps={{readOnly: true}}
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       value={shopVisitCrud.data.arc_date ? shopVisitCrud.data.arc_date:null}
                       onChange={(data, value) => {onFieldChange(value, 'arc_date', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>:
                <LabelValueCard xs={12} md={3} label='ARC Date ( EASA/FAA)' value={shopVisitCrud.data.arc_date ? moment(shopVisitCrud.data.arc_date).format(displayDateFormatShort) :'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <TextField
                    id="total_air_temperature"
                    label="TAT"
                    fullWidth
                    margin="normal"
                    inputProps={{maxLength: 30}}
                    value={shopVisitCrud.data.total_air_temperature ? shopVisitCrud.data.total_air_temperature:''}
                    onChange={(e) => onFieldChange(e, 'total_air_temperature', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>:
                <LabelValueCard xs={12} md={3} label='TAT' value={shopVisitCrud.data.total_air_temperature ? shopVisitCrud.data.total_air_temperature:'--'} />
              }

              { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       id="date_of_reinstallation"
                       label="Date of Reinstallation"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture={true}
                       inputProps={{readOnly: true}}
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       value={shopVisitCrud.data.date_of_reinstallation ? shopVisitCrud.data.date_of_reinstallation:null}
                       onChange={(data, value) => {onFieldChange(value, 'date_of_reinstallation', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>:
                <LabelValueCard xs={12} md={3} label='Date of Reinstallation' value={shopVisitCrud.data.date_of_reinstallation ? moment(shopVisitCrud.data.date_of_reinstallation).format(displayDateFormatShort) :'--'} />
              }
              <Grid item xs={12}>
                { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
                  <div style={{float:'right', marginBottom:'20px'}}>
                    <input
                      id="contained-button-file"
                      type="file"
                      style={{display:'none'}}
                      onChange={(e) => uploadAttachmentFn(e.target.files[0])}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="outlined" color="primary" size="small" component="span">
                      Add Attachment
                      </Button>
                    </label>
                  </div>:null
                }
                <div className="">
                  <TableListComp
                    heads={[
                      {label:'File Name', sortOption:false},
                      {label:'Description', sortOption:false},
                      {label:'Uploaded on', sortOption:false},
                      {label:'Uploaded by', sortOption:false},
                      {label:'Action', sortOption:false, actionCell: true}
                    ]}
                    data={shopVisitCrud.data.attachments.map((item,index) =>
                      <Attachment
                        inspectionHdInfo={inspectionHdInfo}
                        item={item}
                        onFieldChange={(event, keyParam, data) => onAttachmentChange(event, keyParam, data, 'attachments', index)}
                        previewDetail={(mode) => deleteAttachmentFn(item, index)}
                      />
                    )}
                    noRecord={ shopVisitCrud.data.attachments.length ? null:
                      <EmptyCollection
                        title="No records found"
                      />
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModalFn} color="primary">Cancel</Button>
          { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
            <Button disabled={shopVisitCrud.data.shop_visit_number && shopVisitCrud.data.shop_visit_number.trim().length ? false:true} onClick={addEditShopVisitFn} variant="contained" color="primary">Save</Button>:null
          }
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default ShopVisitCRU
