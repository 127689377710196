import React, { Component, Fragment } from 'react';
import { Button, Paper, TextField, Grid } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import moment from 'moment'
import { TableListComp, CkEditorText, EmptyCollection, DeletePopUp, PageLoader } from '../../../../shared_elements';
import { GeneralDetail, ShopVisitList, ShopVisitCRU, PrerequisiteContent } from '../components';
import { InspectionFormWrapper, ReadPrerequisite } from '../../../Elements';
import { getShopVisitInfoApi, editShopVisitInfoApi, addEditShopVisitApi, deleteShopVisitApi, getShopVisitApi, uploadShopVisitAttachmentApi, deleteSVattachmentApi, exportShopVisitInfoApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
import { engineShopVist } from '../../../';
import { getLocalStorageInfo } from '../../../../utils';
import { backendDateFormat } from '../../../../constants';
import { fieldValidation } from '../../../../utils/formValidation';
import { errorCode } from '../';
class InspectionFormTE004 extends Component {
  constructor(props){
    super(props);
    this.state={
      skeletonLoader: true,
      pageLoader: false,
      inspectionHdInfo:{},
      shopVisitCrud:{
        data:{},
        error:{},
        modal:false,
        mode:''
      },
      shopVisitInfo: {
        details: {},
        shop_visits:[]
      },
      error: {},
      deleteModal: false,
      deleteIds:{},
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getShopVisitInfoApi = getShopVisitInfoApi.bind(this);
    this.editShopVisitInfoApi = editShopVisitInfoApi.bind(this);
    this.addEditShopVisitApi = addEditShopVisitApi.bind(this);
    this.deleteShopVisitApi = deleteShopVisitApi.bind(this);
    this.getShopVisitApi = getShopVisitApi.bind(this);
    this.uploadShopVisitAttachmentApi = uploadShopVisitAttachmentApi.bind(this);
    this.deleteSVattachmentApi = deleteSVattachmentApi.bind(this);
    this.exportShopVisitInfoApi = exportShopVisitInfoApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getShopVisitInfoApi(this.props);
  }
  toggleModalFn = (data) => {
    this.setState({shopVisitCrud: data});
  }
  onFieldChange = (event, keyParam, data, arrayType, arrayIndex) => {
    if(arrayType === 'shopVisit'){
      this.setState(prevState => ({
        ...prevState,
        shopVisitCrud: {
          ...prevState.shopVisitCrud,
          data: {
            ...prevState.shopVisitCrud.data,
            [keyParam]: data
          }
        }
      }));
    }else if (arrayType === 'attachments') {
      this.setState(prevState => ({
        ...prevState,
        shopVisitCrud:{
          ...prevState.shopVisitCrud,
          data: {
            ...prevState.shopVisitCrud.data,
            attachments: [...prevState.shopVisitCrud.data.attachments].map((item, index) => index===arrayIndex ? {...item, [keyParam]:data} :item)
          }
        }
      }))
    }
    else{
      this.setState(prevState => ({
        ...prevState,
        shopVisitInfo: {
          ...prevState.shopVisitInfo,
          details: {
            ...prevState.shopVisitInfo.details,
            [keyParam]: data
          }
        }
      }))
    }
  }
  uploadAttachmentFn = (file) => {
    var formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('shop_visit', true);
    this.setState({pageLoader:true});
    this.uploadShopVisitAttachmentApi(this.props, formData)
    .then(response => {

      this.setState(prevState => ({
        ...prevState,
        shopVisitCrud: {
          ...prevState.shopVisitCrud,
          data: {
            ...prevState.shopVisitCrud.data,
            attachments: [...prevState.shopVisitCrud.data.attachments, {
              attachment:response.data.url,
              name:response.data.name,
              description:'',
              created_at:moment().format(backendDateFormat),
              user: {id:getLocalStorageInfo().user.id,name: getLocalStorageInfo().user.name, profile_pic:getLocalStorageInfo().user.profile_pic}
            }]
          }
        }
      }));
      this.setState({pageLoader:false});
    })
  }
  handleError = (value, key) => {
    this.setState((prevState)=> ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
      }
    }
    ))
  }

  handleErrorShopVisit = (value, key) => {
    this.setState((prevState)=> ({
      ...prevState,
      shopVisitCrud: {
        ...prevState.shopVisitCrud,
      error: {
        ...prevState.shopVisitCrud.error,
        [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
      }
    }
    }))
}
  render(){
    const { skeletonLoader, inspectionHdInfo, shopVisitCrud, shopVisitInfo, deleteModal, deleteIds, pageLoader, error } = this.state;
    return(
      <div className="technical-engine-forms">
        <InspectionFormWrapper
          saveButton={true}
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          onSaveChanges={() => this.editShopVisitInfoApi(this.props, shopVisitInfo)}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'Evaluation Report', extension: 'pdf', key:''},
            {title:'Executive Report', extension: 'pdf', key:'executive'},
            {title:'Advisory Report', extension: 'pdf', key:'advisory'}
          ]}
          exportReportFn={(file) => this.exportShopVisitInfoApi(this.props, file)}
        />
        <h4 className="gen-detail-title">General Details <ReadPrerequisite content={<PrerequisiteContent />} /></h4>
        <GeneralDetail
          inspectionHdInfo={inspectionHdInfo}
          error={error}
          generalDetail={shopVisitInfo.details}
          onFieldChange={this.onFieldChange}
          handleError={this.handleError}
          resetErrorKey={(key) => {
            this.setState(prevState => ({
              ...prevState,
              error: {
                ...prevState.error,
                [key]: ''
              }
            }))
          }}
        />
        <div className="engine-te004-form">
          <h3 className="title">Background / Documents Reviewed</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="para">Background</p>
              <CkEditorText
                disabled = { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save?false:true}
                htmlData={shopVisitInfo.details.background ? shopVisitInfo.details.background:''}
                onChangeData={(data) => this.onFieldChange('', 'background', data)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="para">Documents Reviewed</p>
              <CkEditorText
                disabled = { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save?false:true}
                htmlData={shopVisitInfo.details.documents_reviewed ? shopVisitInfo.details.documents_reviewed:''}
                onChangeData={(data) => this.onFieldChange('', 'documents_reviewed', data)}
              />
            </Grid>
          </Grid>
        </div>
        <div className="engine-te004-form">
          { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
            <div className="text-right" style={{margin: '0 0 20px 0'}}>
              <Button onClick={() => this.toggleModalFn({data:engineShopVist, error:{}, modal:true, mode:'add'})} variant="outlined" color="primary" size="small" startIcon={<ControlPointIcon fontSize="small" />}>
                Add Shop Visit
              </Button>
            </div>:null
          }
          <TableListComp
            heads={[
              { label: 'Shop Visit Number', sortOption: false },
              { label: 'Date of Removal', sortOption: false },
              { label: 'Reason for Removal', sortOption: false },
              { label: 'Shop Facility', sortOption: false },
              { label: 'Engine Induction Date', sortOption: false },
              { label: 'Action', sortOption: false, actionCell: true }
            ]}
            data={shopVisitInfo.shop_visits.map((item,index) =>
              <ShopVisitList
                inspectionHdInfo={inspectionHdInfo}
                key={index}
                item={item}
                previewDetail={(mode) => { mode === 'D' ?  this.setState({deleteModal:true, deleteIds:{...item, deleteType: 'shopVisit', deleteIndex:index}}) : this.getShopVisitApi(this.props, item.id) }}
              />
            )}
            noRecord={ shopVisitInfo.shop_visits.length ? null:
              <EmptyCollection title="No records found" />
            }
          />
        </div>
        <div className="engine-te004-form">
          <h3 className="title">Executive Summary</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="para">Observation</p>
              <CkEditorText
                disabled = { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save?false:true}
                htmlData={shopVisitInfo.details.executive_summary_observation ? shopVisitInfo.details.executive_summary_observation:''}
                onChangeData={(data) => this.onFieldChange('', 'executive_summary_observation', data)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="para">Recommendation</p>
              <CkEditorText
                disabled = { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save?false:true}
                htmlData={shopVisitInfo.details.executive_summary_recommendation ? shopVisitInfo.details.executive_summary_recommendation:''}
                onChangeData={(data) => this.onFieldChange('', 'executive_summary_recommendation', data)}
              />
            </Grid>
          </Grid>
        </div>
        <div className="engine-te004-form">
          <h3 className="title">Advisory Report</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="para">Observation</p>
              <CkEditorText
                disabled = { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save?false:true}
                htmlData={shopVisitInfo.details.advisory_report_observation ? shopVisitInfo.details.advisory_report_observation:''}
                onChangeData={(data) => this.onFieldChange('', 'advisory_report_observation', data)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="para">Recommendation</p>
              <CkEditorText
                disabled = { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save?false:true}
                htmlData={shopVisitInfo.details.advisory_report_recommendation ? shopVisitInfo.details.advisory_report_recommendation:''}
                onChangeData={(data) => this.onFieldChange('', 'advisory_report_recommendation', data)}
              />
            </Grid>
          </Grid>
        </div>
        { shopVisitCrud.modal ?
          <ShopVisitCRU
            inspectionHdInfo={inspectionHdInfo}
            shopVisitCrud={shopVisitCrud}
            toggleModalFn={() => this.toggleModalFn({data:{}, error:{}, modal:false, mode:''})}
            onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'shopVisit')}
            onAttachmentChange={(event, keyParam, data, arrayType, arrayIndex) => this.onFieldChange(event, keyParam, data, arrayType, arrayIndex)}
            addEditShopVisitFn = {() => this.addEditShopVisitApi(this.props, shopVisitCrud)}
            deleteAttachmentFn = {(item, index) => { this.setState({deleteModal:true, deleteIds: {...item, deleteIndex: index, deleteType:'attachment'} }) }}
            uploadAttachmentFn={this.uploadAttachmentFn}
            handleError={this.handleErrorShopVisit}
            resetErrorKey={(key) => {
              this.setState(prevState => ({
                ...prevState,
                shopVisitCrud: {
                  ...prevState.shopVisitCrud,
                error: {
                  ...prevState.shopVisitCrud.error,
                  [key]: ''
                }
              }
              }))
            }}
          />:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{}})}}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => { deleteIds.deleteType === 'attachment' ? this.deleteSVattachmentApi(this.props, deleteIds) :this.deleteShopVisitApi(this.props, deleteIds)}}
        />
        { pageLoader ? <PageLoader /> :null }
      </div>
    )
  }
}
export default withSnackbar(withRouter(InspectionFormTE004))
