import moment from 'moment';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
export function getSeatingConfigApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/interior-inspection-config/`, query)
  .then(response => {
    this.setState({pageLoader:false, skeletonLoader:false});
    if(checkApiStatus(response)){
      let tempData = response.data.data;
      let configtype = 'edit';
      if(!Object.keys(response.data.data).length && response.data.data.interior_config_details && response.data.data.interior_config_details.length){
        configtype = 'add';
      }
      this.setState({
        seatConfig: response.data.data,
        configtype:configtype
      })
    }
  })
}
export function exportSeatingConfigApi(props={}, file={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/interior-inspection-download/`, {download:file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE008_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.props.enqueueSnackbar('Window Seat Mapping Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function updateSeatConfingApi(props={}, data){
  this.seatState({pageLoader:true});
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/interior-inspection-config/`, data)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
export function getSeatFindingApi(props={}, query={}){
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/seat-window-inspection-detail/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        existingFinding: response.data.data
      })
    }
  })
}

export function getSeatFindingQuestionsApi(props={}, seatType){
  globalGetService(`technical/interior/${seatType === 1 ?'seat-finding-questions':'window-finding-questions'}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        seatDetails: {modal:true, data: response.data.data}
      })
    }
  })
}

export function getSeatFindingsAnsApi(props={}, seatType, data={}){
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/${seatType === 1 ? 'get-seat-findings':'get-window-findings'}/`, seatType === 1 ? {seats:data}:{windows:data})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        seatFindings: response.data.data
      })
    }
  })
}
export function saveSeatFindingsAnsApi(props={}, seatType, data={}){
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/${seatType === 1 ? 'add-seat-findings':'add-window-findings'}/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        seatDetails: {modal:false, data:[]},
        seatarr:[],
        windowArr:[]
      }));
      this.getSeatFindingApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function changeSeatStatusApi(props={}, data){
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/change-seat-status/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        seatDetails: {modal:false, data:[]},
        seatarr:[],
        windowArr:[]
      }));
      this.getSeatFindingApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getTechSpecsConfigApi(props={}, query={}){
  let $this = this;
  return new Promise(
    function(resolve, reject){
      $this.setState({
        formSubmitLoader:true
      });
      globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/interior-technical-specs/`, query)
      .then(response => {
        $this.setState({
          formSubmitLoader:false
        });
        if(checkApiStatus(response)){
          resolve(response);
          $this.setState({
            techSpecs: response.data.data,
            modal:true,
          });
        }
      });
    }
  )
}
export function saveTechSpecsConfigApi(props={}, data={}){
  this.setState({formSubmitLoader:true});
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/interior-technical-specs/`, data)
  .then(response => {
    this.setState({formSubmitLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        techSpecs: [],
        modal:false,
      });
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function setSeatingConfigApi(props={}, sections={}){
  let sectionError = [];
  let sectionErrorFlag = true;
  function checkForOverlapSeat(section, sectionIndex){
    if(sectionIndex !== 0){
      let startRow = parseInt(section.starting_row);
      let endRow = parseInt(section.end_row);
      let lastSecEndRow = parseInt(sections[sectionIndex-1].end_row);
      if(startRow > endRow){
        return 'Start Row cannot be greater then End Row'
      }else if (lastSecEndRow > startRow || lastSecEndRow > endRow) {
        return 'Section row number cannot overlap'
      }else{
        return ''
      }
    }else{
      return ''
    }
  }
  function checkSeatNumber(section, sectionIndex){
    let mergedArray = [].concat(...section.columns);
    if(mergedArray.length === 0){
      return 'Please add Seat Number'
    }else if(mergedArray.length !== mergedArray.filter(item => item.trim()).length){
      return 'Please enter the Seat Number'
    }else if(mergedArray.length !== new Set(mergedArray).size){
      return 'Please enter unique Seat Number'
    }else{
      return ''
    }
  }
  function checkRowNumber(section, sectionIndex) {
    if(section.starting_row.toString().trim().length && section.end_row.toString().trim().length){
      return ''
    }else{
      return 'Please enter the Row Number'
    }
  }
  sections.map((section, sectionIndex) => {
    sectionError = [...sectionError, {
      'seat_type': section.seat_type ? '':'Please select Class',
      'unique_seat':checkSeatNumber(section, sectionIndex),
      'unique_number':section.starting_row && section.end_row ? checkForOverlapSeat(section, sectionIndex):'',
      'empty_numner': checkRowNumber(section, sectionIndex)
    }];
  });
  sectionError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      sectionErrorFlag = false
    }
  });
  if(sectionErrorFlag){
    this.setState({formSubmitLoader:true});
    globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/interior-inspection-config/`, sections)
    .then(response => {
      this.setState({formSubmitLoader:false});
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        this.setState({modal:false});
        window.location.reload()
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    this.setState({sectionError:sectionError});
  }
}

export function removeSeatConfigRowApi(props, sectionId){
  globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/interior-section/${sectionId}/delete-interior-section/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        configList: prevState.configList.filter(section => section.id !== sectionId),
        deleteModal:false,
        deleteIds:{
          deleteIndex:'',
          data:{}
        }
      }));
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getLopaInfoApi(props={}, flag){
  this.setState({pageLoader:true});
  if(!flag){
    this.setState({modal:true, lopaConfig:{lopa_image_url:'', lopa_remarks:''}});
  }else{
    globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-lopa-image/`)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState({modal:true,lopaConfig: response.data.data});
      }
    })
  }
}
export function updateLopaInfoApi(props={}, data){
  this.setState({formSubmitLoader:true});
  var formData = new FormData();
	formData.append('remarks', data.lopa_remarks);
  if(data.file && data.file.length){
    formData.append('file', data.file[0]);
  }
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-lopa-image/`, formData)
  .then(response => {
    this.setState({formSubmitLoader:false});
    if(checkApiStatus(response)){
      this.setState({modal:false, lopaConfig:{lopa_image_url:'',lopa_remarks:''}});
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
