import React from 'react';
import { Grid, TextField, Radio, RadioGroup, FormControl, FormLabel,  FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { regexConstants } from '../../../constants/regEx';
const EngineCurrentDetail = ({engine, engineList, aircraftTypes, engineTypes, pltConstants, onFieldChange, error, handleError, resetErrorKey}) => {

  return(
    <div className="">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <TextField
            id="aircraft_msn"
            label="Aircraft MSN"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={engine.aircraft_msn ? engine.aircraft_msn :''}
            error={error.aircraft_msn ? true:false}
            helperText={error.aircraft_msn ? error.aircraft_msn: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'aircraft_msn', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'aircraft_msn')}
            onFocus={(e) => resetErrorKey('aircraft_msn')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="aircraft_registration"
            label="Aircraft Reg"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 30}}
            value={engine.aircraft_registration ? engine.aircraft_registration :''}
            error={error.aircraft_registration ? true:false}
            helperText={error.aircraft_registration ? error.aircraft_registration: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'aircraft_registration', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'aircraft_registration')}
            onFocus={(e) => resetErrorKey('aircraft_registration')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options = {aircraftTypes}
            getOptionLabel={option => option.name}
            id="aircraft_type_id"
            value={engine.aircraft_type_id ? aircraftTypes.find(aircraft_type => aircraft_type.id === engine.aircraft_type_id):null}
            onChange={(e, value) => onFieldChange(e, 'aircraft_type_id', value ? value.id :value)}
            renderInput={params => <TextField {...params} label="Aircraft Model" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options = {engineTypes}
            getOptionLabel={option => option.name}
            id="engine_type_id"
            disabled={!engine.create_esn || !engine.aircraft_type_id}
            value={engine.engine_type_id ? engineTypes.find(engine_type => engine_type.id === engine.engine_type_id):null}
            onChange={(e, value) => { onFieldChange(e, 'engine_type_id', value ? value.id :value); handleError(value ? value.id:'','engine_type_id') }}
            renderInput={params => <TextField required onFocus={(e) => resetErrorKey('engine_type_id')} error={error.engine_type_id ? true:false} helperText={error.engine_type_id ? error.engine_type_id:''} {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="owned_by_type" name="owned_by_type" defaultValue="top">
              <FormControlLabel value="owned_by_type" control={<Radio checked={engine.owned_by_type === 1} onChange={(e) => onFieldChange(e, 'owned_by_type', 1)} color="primary" />} label="Owner" />
              <FormControlLabel value="owned_by_type" control={<Radio checked={engine.owned_by_type === 2} onChange={(e) => onFieldChange(e, 'owned_by_type', 2)} color="primary" />} label="Client" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="owned_by"
            label={engine.owned_by_type === 1 ? 'Owner Name':'Client Name'}
            fullWidth
            margin="normal"
            value={engine.owned_by ? engine.owned_by :''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'owned_by', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="operated_by"
            label="Engine Operated By"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 30}}
            value={engine.operated_by ? engine.operated_by :''}
            InputLabelProps={{shrink: true}}
            error={error.operated_by ? true:false}
            helperText={error.operated_by ? error.operated_by: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'operated_by', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'operated_by')}
            onFocus={(e) => resetErrorKey('operated_by')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="location"
            label="Location"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 30}}
            value={engine.location ? engine.location :''}
            InputLabelProps={{shrink: true}}
            error={error.location ? true:false}
            helperText={error.location ? error.location: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'location', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'location')}
            onFocus={(e) => resetErrorKey('location')}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            id="tsn"
            label="TSN"
            fullWidth
            margin="normal"
            required
            inputProps={{maxLength: 10}}
            value={engine.tsn ? engine.tsn :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value): e.preventDefault()}}
            error={error.tsn ? true:false}
            helperText={error.tsn ? error.tsn: ''}
            InputLabelProps={{shrink: true}}
            onBlur={(e) => handleError(e.target.value,'tsn')}
            onFocus={(e) => resetErrorKey('tsn')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="csn"
            label="CSN"
            fullWidth
            margin="normal"
            required
            inputProps={{maxLength: 10}}
            value={engine.csn ? engine.csn :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value) : e.preventDefault() }}
            error={error.csn ? true:false}
            helperText={error.csn ? error.csn: ''}
            InputLabelProps={{shrink: true}}
            onBlur={(e) => handleError(e.target.value,'csn')}
            onFocus={(e) => resetErrorKey('csn')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="tslv"
            label="TSLSV"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={engine.tslv ? engine.tslv :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tslv', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="cslv"
            label="CSLSV"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={engine.cslv ? engine.cslv :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cslv', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="tsr"
            label="TSR"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={engine.tsr ? engine.tsr :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsr', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="csr"
            label="CSR"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={engine.csr ? engine.csr :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csr', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="tso"
            label="TSO"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={engine.tso ? engine.tso :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tso', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="cso"
            label="CSO"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={engine.cso ? engine.cso :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cso', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="tshi"
            label="TSHSI"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={engine.tshi ? engine.tshi :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tshi', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="cshi"
            label="CSHSI"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 10}}
            value={engine.cshi ? engine.cshi :''}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cshi', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="engine_status" name="engine_status" defaultValue="top">
              <FormControlLabel value="engine_status" control={<Radio checked={engine.engine_status === 1} onChange={(e) => onFieldChange(e, 'engine_status', 1)} color="primary" />} label="On Wing" />
              <FormControlLabel value="engine_status" control={<Radio checked={engine.engine_status === 2} onChange={(e) => onFieldChange(e, 'engine_status', 2)} color="primary" />} label="In Shop" />
              <FormControlLabel value="engine_status" control={<Radio checked={engine.engine_status === 3} onChange={(e) => onFieldChange(e, 'engine_status', 3)} color="primary" />} label="In Storage" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options = {pltConstants.filter(item => item.type === 'engine_position')}
            getOptionLabel={option => option.label}
            id="install_position"
            value={engine.install_position ? pltConstants.filter(item => item.type === 'engine_position').find(enginePos => enginePos.value == engine.install_position) :null}
            onChange={(e, value) => onFieldChange(e, 'install_position', value ? value.value :value)}
            renderInput={params => <TextField {...params} label="Installed Position" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="mpa_test_margin"
            label="MPA Test Margin"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={engine.mpa_test_margin ? engine.mpa_test_margin :''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'mpa_test_margin', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="llp_status_sheet"
            label="LLP Status"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={engine.llp_status_sheet ? engine.llp_status_sheet :''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'llp_status_sheet', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="ad_status"
            label="AD Status"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={engine.ad_status ? engine.ad_status :''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'ad_status', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="sb_status"
            label="SB Status"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={engine.sb_status ? engine.sb_status :''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'sb_status', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="lease_return_standard"
            label="Engine Lease Return Condition"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={engine.lease_return_standard ? engine.lease_return_standard :''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'lease_return_standard', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="etops"
            label="ETOPS"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={engine.etops ? engine.etops :''}
            InputLabelProps={{shrink: true}}
            error={error.etops ? true:false}
            helperText={error.etops ? error.etops: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'etops', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'etops')}
            onFocus={(e) => resetErrorKey('etops')}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default EngineCurrentDetail;
