import config from '../../config';
import { globalPostService, globalGetService } from '../../utils/globalApiServices';
import { checkApiStatus } from '../../utils';
export function loginApi(props, data={}) {
  this.setState({formSubmitLoader: true});
  globalPostService('api/login/', data)
  .then(response => {
    this.setState({formSubmitLoader: false});
    if(checkApiStatus(response)){
      if(response.data.data.lessor_list.length){
        let baseDomain = '.sparta.aero', expireAfter = new Date();
        expireAfter.setDate(expireAfter.getDate() + 7);
        let cookieData = JSON.stringify({access:response.data.data.access})
        document.cookie="lessorAccess="+cookieData+"; domain=" + baseDomain + "; expires=" + expireAfter + "; path=/";
        localStorage.setItem('userInfo', JSON.stringify(response.data.data));
        if(response.data.data.lessor_list.length > 1){
          props.history.push('/select-lessor');
        }else{
          this.lessorUpdateApi(props, {lessor_id: response.data.data.lessor_list[0].id})
        }
      }else{

      }
      this.props.enqueueSnackbar('Login Successful.', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else if(response.data.statusCode == 1012){
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        props.history.push('/otp-verification/' + response.data.data.token)
        localStorage.setItem('userEmail', data.email)
      }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function lessorUpdateApi(props, data={}){

  this.setState({formSubmitLoader: true});
  globalPostService(`api/user-authorization/`, data)
  .then(response => {
    this.setState({formSubmitLoader: false});
    if(checkApiStatus(response)){
      let baseDomain = '.sparta.aero', expireAfter = new Date();
      expireAfter.setDate(expireAfter.getDate() + 7);
      let cookieData = JSON.stringify({access:response.data.data.lessor_access_token, id:response.data.data.current_lessor.id, refresh:''})
      document.cookie="lessorAccess="+cookieData+"; domain=" + baseDomain + "; expires=" + expireAfter + "; path=/";
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      userInfo = {
        ...userInfo,
        access: response.data.data.lessor_access_token,
        defaultLessor: response.data.data.current_lessor,
        user: response.data.data.user
      }
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      props.history.push('/projects');
      if(props.match.path === '/projects'){
        window.location.reload();
      }
    }else{
      props.history.push('/projects');
      if(props.match.path === '/projects'){
        window.location.reload();
      }
    }
  })
}
// State Update Related Functions
export function updateAuthInfoState(value, key){
  this.setState(prevState => ({
      ...prevState,
      authInfo: {
        ...prevState.authInfo,
        [key]: value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
  }))
}
