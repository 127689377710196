import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';
const PRSFilter = ({}) => {
  return(
    <Grid item xs={12} md={6}>
      <div className="prs-filter">
        <ul className="list-inline flex-centered">
          <li className="list-inline-item">Filter By:</li>
          <li className="list-inline-item action-list">
            <Autocomplete
              options = {[{label:'P1',value:1},{label:'P2',value:2}]}
              getOptionLabel={option => option.label}
              id="priority"
              value={null}
              renderInput={params => <TextField {...params} placeholder="Select Priority" margin="none" fullWidth InputLabelProps={{shrink: true}} />}
            />
          </li>
          <li className="list-inline-item action-list">
            <Autocomplete
              options = {[{label:'P1',value:1},{label:'P2',value:2}]}
              getOptionLabel={option => option.label}
              id="status"
              value={null}
              renderInput={params => <TextField {...params} placeholder="Select Status" margin="none" fullWidth InputLabelProps={{shrink: true}} />}
            />
          </li>
          <li className="list-inline-item action-list">
            <Autocomplete
              options = {[{label:'P1',value:1},{label:'P2',value:2}]}
              getOptionLabel={option => option.label}
              id="assignee"
              value={null}
              renderInput={params => <TextField {...params} placeholder="Select Assignee" margin="none" fullWidth InputLabelProps={{shrink: true}} />}
            />
          </li>
        </ul>
      </div>
    </Grid>
  )
}
export default PRSFilter;
