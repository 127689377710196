import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
export default function RptTable({item, index, showRptTab}){
  return(
    <TableRow style={{cursor:'pointer'}} hover tabIndex={-1} onClick={showRptTab}>
      <TableCell>{index}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.open_item}</TableCell>
      <TableCell>{item.close_item}</TableCell>
      <TableCell>{item.reopen_item}</TableCell>
      <TableCell>{item.total_item}</TableCell>
    </TableRow>
  )
}
