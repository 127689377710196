import React from 'react';
import { Avatar, Button, Tooltip, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const ProjectUsers = ({userType, users, removeUser, toggleModalFn}) => {
  return(
    <Paper className="work-order-users">
      <h4>{userType} <Button style={{float:'right'}} onClick={toggleModalFn} variant="outlined" size="small" color="primary" startIcon={<AddIcon />}>ADD</Button></h4>
      {
        <ul className="list-unstyled">
          {users.map(user =>
            <li className="flex-centered">
              <Avatar className="" src={user.profile_pic} />
              <div className="user-details">
                <h5 className="flex-centered">
                  {user.name}
                  <Tooltip arrow title="Remove">
                    <DeleteOutlineIcon onClick={() => removeUser(user.id)} color="error" fontSize="small" />
                  </Tooltip>
                </h5>
                <span>{user.designation}</span>
              </div>
            </li>
          )}
        </ul>
      }
    </Paper>
  )
}
export default ProjectUsers;
