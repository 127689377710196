import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Paper, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip, TextField } from '@material-ui/core';
import { DocsFileList } from '../components';
import { InspectionFormWrapper } from '../../../Elements';
import { PageLoader } from '../../../../shared_elements';
import { imgStoragePath } from '../../../../constants';
import { getAuditRptSectionsApi, getAuditRptDocsApi, linkRecordsFileApi, uploadFileToSectionApi, removeLinkFileApi, updateAuditRptAnsApi, exportAuditRptSectionsApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
class InspectionFormT003 extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader: true,
      skeletonLoader:true,
      inspectionHdInfo:{},
      auditRptSections: [],
      linkFilesInfo: {
        modal:false,
        fileInfos:{},
        selected:[]
      },
      error:{}
    }
    this.getAuditRptSectionsApi = getAuditRptSectionsApi.bind(this);
    this.getAuditRptDocsApi = getAuditRptDocsApi.bind(this);
    this.linkRecordsFileApi = linkRecordsFileApi.bind(this);
    this.uploadFileToSectionApi = uploadFileToSectionApi.bind(this);
    this.removeLinkFileApi = removeLinkFileApi.bind(this);
    this.updateAuditRptAnsApi = updateAuditRptAnsApi.bind(this);
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.exportAuditRptSectionsApi = exportAuditRptSectionsApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getAuditRptSectionsApi(this.props);
  }
  selectLinkFileFn = (id) => {
    const currentIndex = this.state.linkFilesInfo.selected.indexOf(id);
    const newFiles = [...this.state.linkFilesInfo.selected];
    if (currentIndex === -1) {
      newFiles.push(id);
    } else {
      newFiles.splice(currentIndex, 1);
    }
    this.setState(prevState => ({
      ...prevState,
      linkFilesInfo: {
        ...prevState.linkFilesInfo,
        selected: newFiles
      }
    }))
  }
  updateTheRemarksFn = (value, sectionIndex, subSectionIndex, questionIndex) => {
    // debugger
    this.setState(prevState => ({
      ...prevState,
      auditRptSections: [...prevState.auditRptSections].map((section, index) => index === sectionIndex ? {
        ...section, sub_sections: [...section.sub_sections].map((subSection, innerIndex) => innerIndex === subSectionIndex ? {
          ...subSection,
          questions: [...subSection.questions].map((question, qIndex) => qIndex === questionIndex ? {
            ...question,
            answer: {
              ...question.answer,
              remarks: value
            }
          } :question)
        } : subSection)
      } : section)
    }))
  }
  render(){
    const { inspectionHdInfo, auditRptSections, linkFilesInfo, error, skeletonLoader, pageLoader } = this.state;
    return(
      <div className="technical-aircraft-froms">
        <InspectionFormWrapper
          saveButton={false}
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''}
          ]}
          exportReportFn={(file) => this.exportAuditRptSectionsApi(this.props, file)}
        />
        { auditRptSections.map((section, index) =>
          <div className="form-t003-cn" key={index}>
            <h2>{section.name}</h2>
            <Paper>
              {section.sub_sections.map((subSections, subIndex) =>
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`section${subIndex}`}
                  key={subIndex}
                >
                  { subSections.name }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="t003-docs">
                    <table cellSpacing={0} cellPadding={0}>
                      <thead>
                        <tr>
                          <th>Document Name</th>
                          <th>Status</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subSections.questions.map((question, questionIndex) =>
                          <tr key={questionIndex}>
                            <td>
                              <h4>
                                {question.name}
                              </h4>
                              { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
                                <ul className="list-inline spacing-list">
                                  <li className="list-inline-item">
                                      <input
                                        id="contained-button-file"
                                        type="file"
                                        style={{display:'none'}}
                                        accept={".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"}
                                        multiple={true}
                                        onChange={(e) => this.uploadFileToSectionApi(this.props, e.target.files[0], question.id, question.answer.id)}
                                      />
                                      <label htmlFor="contained-button-file">
                                        <span className="flex-centered"><AttachFileIcon /> Upload File</span>
                                      </label>

                                  </li>
                                  <li className="list-inline-item flex-centered">
                                    <span onClick={() => this.getAuditRptDocsApi(this.props, question.id, question.answer.id)} className="flex-centered"><LinkIcon />Link file from Records Module</span>
                                  </li>
                                </ul>:null
                              }
                              { question.answer && question.answer.files && question.answer.files.length ?
                                <div className="attached-files">
                                  <ul className="list-inline file-listing">
                                    { question.answer.files.map((file, fileIndex) =>
                                      <li className="list-inline-item">
                                        {file.name}
                                        { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
                                          <ul className="list-inline file-action">
                                            <li className="list-inline-item">
                                              <a href={file.path} download><GetAppIcon color="primary" /></a>
                                            </li>
                                            <li className="list-inline-item">
                                              <CloseIcon color="primary" onClick={() => this.removeLinkFileApi(this.props, question.answer.files.map(list => list.id).filter(item => item !== file.id), question.id, question.answer.id)} />
                                            </li>
                                          </ul>:null
                                        }
                                      </li>
                                    )}
                                  </ul>
                                </div>
                                :null
                              }
                            </td>
                            <td>
                              <ul className="list-inline spacing-list">
                                <li className="list-inline-item" style={{cursor: inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ? 'pointer':'default'}} onClick={(e) => { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ? this.updateAuditRptAnsApi(this.props, {remarks: question.answer && question.answer.remarks ? question.answer.remarks :'' , value:1, questionId:question.id}, question.answer.id):e.preventDefault()}}>
                                  <img src={`${imgStoragePath}${question.answer && question.answer.value && question.answer.value === 1 ? 'tick_color.png':'tick_gray.png'}`} alt="" />
                                </li>
                                <li className="list-inline-item" style={{cursor: inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ? 'pointer':'default'}} onClick={(e) => { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ? this.updateAuditRptAnsApi(this.props, {remarks: question.answer && question.answer.remarks ? question.answer.remarks :'' , value:2, questionId:question.id}, question.answer.id, index, subIndex, questionIndex) : e.preventDefault() } }>
                                  <img src={`${imgStoragePath}${question.answer && question.answer.value && question.answer.value === 2 ? 'cross_color.png':'cross_gray.png'}`} alt="" />
                                </li>
                                <li className="list-inline-item" style={{cursor: inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ? 'pointer':'default'}} onClick={(e) => { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ? this.updateAuditRptAnsApi(this.props, {remarks: question.answer && question.answer.remarks ? question.answer.remarks :'' , value:3, questionId:question.id}, question.answer.id, index, subIndex, questionIndex) : e.preventDefault() } }>
                                  <img src={`${imgStoragePath}${question.answer && question.answer.value && question.answer.value === 3 ? 'na_color.png':'na_gray.png'}`} alt="" />
                                </li>
                              </ul>
                            </td>
                            <td>
                              { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
                                <TextField
                                  id="marketing_disclaimer"
                                  fullWidth
                                  margin="normal"
                                  multiline
                                  error={error.id === question.id}
                                  helperText={error.id === question.id ? error.message:''}
                                  inputProps={{maxLength: 250}}
                                  value={question.answer && question.answer.remarks ? question.answer.remarks:''}
                                  onChange={(e) => this.updateTheRemarksFn(e.target.value, index, subIndex, questionIndex)}
                                  onBlur={() => this.updateAuditRptAnsApi(this.props, {remarks: question.answer && question.answer.remarks ? question.answer.remarks :'' , value: question.answer.value ? question.answer.value:'', questionId:question.id}, question.answer.id)}
                                  onFocus={() => this.setState({error:{}})}
                                  InputLabelProps={{shrink: true}}
                                />:'--'
                              }

                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
            </Paper>
          </div>
        )}
        { linkFilesInfo.modal ?
          <DocsFileList
            linkFilesInfo={linkFilesInfo}
            selectLinkFileFn={this.selectLinkFileFn}
            toggleModalFn={() => {this.setState({linkFilesInfo: {modal:false, fileInfos:{}, selected:[], questionId:''} })}}
            linkFileToSectionFn = {() => this.linkRecordsFileApi(this.props, linkFilesInfo.selected, linkFilesInfo.questionId, linkFilesInfo.ansId)}
          />:null
        }

        { pageLoader ? <PageLoader /> : null }
      </div>
    )
  }
}
export default withSnackbar(InspectionFormT003);
