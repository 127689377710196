import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CkEditorText } from '../../../shared_elements';
import moment from 'moment';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat } from '../../../constants';

const EngineHistory = ({engine, onFieldChange, error, handleError, resetErrorKey}) => {
  return(
    <div className="">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               required
               id="engine_manufacturing_date"
               label="Manufacturing Date"
               format={fieldDateFormat}
               fullWidth
               required
               disableFuture={true}
               clearable={true}
               InputLabelProps={{shrink: true}}
               inputProps={{readOnly: true}}
               value={engine.engine_manufacturing_date ? engine.engine_manufacturing_date:null}
               error={error.engine_manufacturing_date ? true:false }
               helperText={error.engine_manufacturing_date ? error.engine_manufacturing_date: ''}
               onChange={(data, value) => {onFieldChange(value, 'engine_manufacturing_date', data ? moment(data).format(backendDateFormat):data); data ? resetErrorKey('engine_manufacturing_date'): handleError(null, 'engine_manufacturing_date')}}
             />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="constructed_model"
            label="Constructed Model"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 30}}
            value={engine.constructed_model ? engine.constructed_model:''}
            InputLabelProps={{shrink: true}}
            error={error.constructed_model ? true:false}
            helperText={error.constructed_model ? error.constructed_model: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'constructed_model', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'constructed_model')}
            onFocus={(e) => resetErrorKey('constructed_model')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="date_of_installation"
               label="Date Of Installation"
               format={fieldDateFormat}
               fullWidth
               disableFuture={true}
               clearable={true}
               InputLabelProps={{shrink: true}}
               inputProps={{readOnly: true}}
               value={engine.date_of_installation ? engine.date_of_installation:null}
               onChange={(data, value) => {onFieldChange(value, 'date_of_installation', data ? moment(data).format(backendDateFormat):data);}}
             />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <label className="for-label">Engine Operations</label>
          <CkEditorText
            htmlData={engine.operation ? engine.operation:''}
            onChangeData={(data) => onFieldChange('', 'operation', data)}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default EngineHistory;
