import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import { getLocalStorageInfo } from '../../utils';
import homeIcon from '../../assets/images/home_icon.png';
import reportIcon from '../../assets/images/report_icon.png';
import settingIcon from '../../assets/images/setting.svg';
import projectsIcon from '../../assets/images/project_icon.svg';
import trackerIcon from '../../assets/images/tracker_icon.svg';
import plannerIcon from '../../assets/images/planner_icon.svg';
export default function MainNavLink(){
  return(
    <ul className="list-unstyled">
      <li>
        <Link to="/dashboard">
          <img src={homeIcon} alt="Dashboard" />
          <p>Home</p>
        </Link>
      </li>
      <li>
        <Link to="/assets">
          <img src={`${config.api.s3ImageUrl}asset_list_icon.png`} alt="Assets" />
          <p>Assets</p>
        </Link>
      </li>
      <li>
        <Link to="/projects">
          <img src={projectsIcon} alt="Projects" />
          <p>Projects</p>
        </Link>
      </li>
      <li>
        <Link to="/tracker">
          <img src={trackerIcon} alt="Tracker" />
          <p>Tracker</p>
        </Link>
      </li>
      <li>
        <Link to="/planner">
          <img src={plannerIcon} alt="Program Planner" />
          <p>Planner</p>
        </Link>
      </li>
      <li>
        <Link to="/reports">
          <img src={reportIcon} alt="Reports" />
          <p>Reports</p>
        </Link>
      </li>
      <li>
        <Link to="/reports">
          <img src={settingIcon} alt="Reports" />
          <p>Setting</p>
        </Link>
      </li>
    </ul>
  )
}
