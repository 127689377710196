import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CkEditorText } from '../../../shared_elements';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
const BorescopeInspectionDetails = ({engine, onFieldChange, error, handleError, resetErrorKey}) => {
  return(
    <div className="">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="date_of_inspection"
               label="Date Of Inspection"
               format={fieldDateFormat}
               fullWidth
               disableFuture={true}
               clearable={true}
               InputLabelProps={{shrink: true}}
               inputProps={{readOnly: true}}
               value={engine.date_of_inspection ? engine.date_of_inspection :null}
               onChange={(data, value) => {onFieldChange(value, 'date_of_inspection', data ? moment(data).format(backendDateFormat):data);}}
             />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_performed_by"
            label="Inspection Performed By"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 50}}
            value={engine.inspection_performed_by ? engine.inspection_performed_by :''}
            InputLabelProps={{shrink: true}}
            error={error.inspection_performed_by ? true:false}
            helperText={error.inspection_performed_by ? error.inspection_performed_by: ''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'inspection_performed_by', e.target.value)}
            onBlur={(e) => handleError(e.target.value,'inspection_performed_by')}
            onFocus={(e) => resetErrorKey('inspection_performed_by')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_tsn"
            label="TSN"
            fullWidth
            margin="normal"
            value={engine.inspection_tsn ? engine.inspection_tsn :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'inspection_tsn', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_csn"
            label="CSN"
            fullWidth
            margin="normal"
            value={engine.inspection_csn ? engine.inspection_csn :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'inspection_csn', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_tslv"
            label="TSLSV"
            fullWidth
            margin="normal"
            value={engine.inspection_tslv ? engine.inspection_tslv :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'inspection_tslv', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_cslv"
            label="CSLSV"
            fullWidth
            margin="normal"
            value={engine.inspection_cslv ? engine.inspection_cslv :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'inspection_cslv', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_tsr"
            label="TSR"
            fullWidth
            margin="normal"
            value={engine.inspection_tsr ? engine.inspection_tsr :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'inspection_tsr', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_csr"
            label="CSR"
            fullWidth
            margin="normal"
            value={engine.inspection_csr ? engine.inspection_csr :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'inspection_csr', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_tso"
            label="TSO"
            fullWidth
            margin="normal"
            value={engine.inspection_tso ? engine.inspection_tso :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'inspection_tso', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_cso"
            label="CSO"
            fullWidth
            margin="normal"
            value={engine.inspection_cso ? engine.inspection_cso :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'inspection_cso', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_tshi"
            label="TSHSI"
            fullWidth
            margin="normal"
            value={engine.inspection_tshi ? engine.inspection_tshi :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'inspection_tshi', e.target.value): e.preventDefault()}}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="inspection_cshi"
            label="CSHSI"
            fullWidth
            margin="normal"
            value={engine.inspection_cshi ? engine.inspection_cshi :''}
            InputLabelProps={{shrink: true}}
            inputProps={{maxLength: 10}}
            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'inspection_cshi', e.target.value) : e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12}>
          <CkEditorText
            htmlData={engine.inspection_details ? engine.inspection_details:''}
            onChangeData={(data) => onFieldChange('', 'inspection_details', data)}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default BorescopeInspectionDetails;
