import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Drawer} from '@material-ui/core';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import CancelIcon from '@material-ui/icons/Cancel';
import { deletedPRSReportApi } from '../apiServices';
class PRSDeletedLogs extends Component{
  constructor(props){
    super(props);
    this.state = {
      logsStatus:false,
      deletedLogs:{list:[], pagination:null}
    }
    this.deletedPRSReportApi = deletedPRSReportApi.bind(this);
  }
  render(){
    const { logsStatus, deletedLogs } = this.state;
    return(
      <Fragment>
        <span onClick={() => this.deletedPRSReportApi(this.props)}><ShowChartIcon color="primary" fontSize="small" /> Deleted Logs</span>
        <Drawer anchor="right" open={logsStatus} onClose={() => this.setState({logsStatus:false})}>
          { logsStatus ?
            <div className="prs-deleted-logs">
              <h4>
                Deleted Logs
                <span onClick={() => this.setState({logsStatus:false})}>
                  <CancelIcon color="primary" fontSize="small" />
                </span>
              </h4>
              <ul className="list-unstyled">
                {deletedLogs.list.map((item) =>
                  <li>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Vivamus magna justo, lacinia</li>
                )}
              </ul>
            </div>:null
          }
        </Drawer>
      </Fragment>
    )
  }
}
export default withRouter(PRSDeletedLogs);
