import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {Paper} from '@material-ui/core';
import { PageLoader, TableListComp, FilterUIComp, ExportManu, EmptyCollection } from '../../../shared_elements';
import { STabsLoader, STableLoader } from '../../../shared_elements/loaders';
import { removeEmptyKey, convertFilterObject, getLocalStorageInfo } from '../../../utils';
import { ResourcesAnalyticList, ResourcesChart } from '../components';
import { getAnalyticResourceApi } from '../apiServices';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { resourceFilterOptions, resourceAnalyticHd } from '../'
import RefreshIcon from '@material-ui/icons/Refresh';
class ResourcesAnalyticTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:true,
      filter: {},
      applyingFilter:{},
      resourceDetail:{
        general_info:{},
        plots:[],
        user_list:[]
      },
    }
    this.getAnalyticResourceApi = getAnalyticResourceApi.bind(this);
  }
  componentDidMount(){
    this.getAnalyticResourceApi(this.props, {}, 'skeletonLoader');
    this.props.getPlatformConstants()
  }

  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getAnalyticResourceApi(this.props, {...query}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  render(){
    const { filter, applyingFilter, resourceDetail, skeletonLoader,  pageLoader } = this.state;
    const { pltConstants } = this.props;
    let filterOptions = resourceFilterOptions;
    filterOptions = {
      ...filterOptions,
      category: {
        ...filterOptions.category,
        options: pltConstants.filter(item => item.type === 'user_category')
      },
      department: {
        ...filterOptions.department,
        options: pltConstants.filter(item => item.type === 'project_departments')
      }
    }
    return(
      <section className="resources-analytic-tracker">
        <div className="analytic-head">
          <h2>Analytic</h2>
          <p>This analytic represents the overall technical manpower resource and projects globally for Acumen Aviation only.</p>
          <ul className="list-inline">
            <li className="list-inline-item active">Resources Tracker</li>
            <li className="list-inline-item" onClick={()=>this.props.history.push(`/tracker/analytic/projects`)}>Projects Tracker</li>
          </ul>
        </div>
        <div className="analytic-blk">
          <div className="flex-centered">
            <div>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Paper className="available">
                    <h4>{resourceDetail.general_info.available?resourceDetail.general_info.available:'--'}</h4>
                    <p>Available</p>
                  </Paper>
                </li>
                <li className="list-inline-item">
                  <Paper className="deployed">
                    <h4>{resourceDetail.general_info.deployed?resourceDetail.general_info.deployed:'--'}</h4>
                    <p>Deployed</p>
                  </Paper>
                </li>
              </ul>
            </div>
            <div style={{marginLeft:'auto'}}>
              <ul className="list-inline flex-centered">
                <li className="list-inline-item">
                  <RefreshIcon style={{cursor:'pointer'}} onClick={() => this.getAnalyticResourceApi(this.props, {}, 'pageLoader')} color="primary" />
                </li>
                <li className="list-inline-item">
                  <span style={{float: 'right', padding: '5px 0'}}>
                    <ExportManu title="Export" files={[{title:'Excel', extension: 'xls', key:''}]} />
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <FilterUIComp
               filter={filter}
               applyingFilter={applyingFilter}
               removeFilter={this.removeFilter}
               removeAllFilter={() => this.getAnalyticResourceApi(this.props, {}, 'pageLoader')}
               submitFilter={() => this.getAnalyticResourceApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')}
               applyFilter={this.applyFilter}
               filterMenu={filterOptions}
             />
            { resourceDetail.plots.length ? <ResourcesChart
                plots={resourceDetail.plots.map(plotItem => {return{...plotItem, value:plotItem.count}})}
                onClickCountry={(country_code) => this.getAnalyticResourceApi(this.props, {...filter, country_code:country_code}, 'pageLoader')}
              />:null
            }
            { skeletonLoader ? <STableLoader count={10} />:
              <Fragment>
                <TableListComp
                  heads={resourceAnalyticHd}
                  data={ resourceDetail.user_list.map((item,index) =>
                    <ResourcesAnalyticList
                      key={index}
                      item={item}
                      index={index+1}
                    />
                  )}
                  noRecord={ resourceDetail.user_list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              </Fragment>
            }
            { pageLoader ? <PageLoader />:null }
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['project_departments', 'user_category']}))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResourcesAnalyticTracker)
