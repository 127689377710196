import React from 'react';
export default function FindingPoints({}){
  return(
    <div className="indicater-detail-block">
      <ul className="list-unstyled">
        <li>
          <span className="poor"></span> Poor / Findings
        </li>
        <li>
          <span className="average"></span> Average
        </li>
        <li>
          <span className="good"> </span> Good
        </li>
      </ul>
    </div>
  )
};
