import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import { Avatar, Tooltip } from '@material-ui/core';
import { CircularProgress } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import { assetType } from '../../../constants';
import { dateTransformAllSet } from '../../../utils';
const FormList = ({form, engineerProfile, asset, workOrderSlug, toggleModalFn, match, history}) => {
  return(
    <tr onClick={() => history.push(`/${assetType[asset.type]}/${asset.slug}/${workOrderSlug}/forms/${form.slug}/${form.name.toLowerCase()}`)}>
      <td style={{width: '300px'}}>
        <div className="flex-centered">
          <CircularProgress value={form.progress ? form.progress:0} />
          <div style={{marginLeft:'6px'}}>
            <label>Form Type</label>
            <h4>{`${form.name}-${form.title}`}</h4>
          </div>
        </div>
      </td>
      <td>
        <label>Status</label>
        <span>{form.status.label}</span>
      </td>
      <td>
        <label>Last Activity</label>
        <h6>{dateTransformAllSet(form.updated_at)}</h6>
      </td>
      <td>
        <label>Porject Engineer</label>
        { form.engineer && form.engineer.name ?
          <Fragment>
            <span style={{cursor:'pointer'}} className="user-info flex-centered" onClick={(e) => {e.preventDefault(); e.stopPropagation();engineerProfile(form.engineer.slug)}}>
              <Avatar className="avator-icon" alt="" src={form.engineer.profile_pic} />
              {form.engineer.name}
              <Tooltip title="Change Project Engineer" arrow><EditIcon color="primary" onClick={(e) => {e.stopPropagation(); e.preventDefault(); toggleModalFn();}} fontSize="small" /></Tooltip>
            </span>
          </Fragment>
          :<span style={{cursor:'pointer'}} onClick={(e) => {e.stopPropagation(); e.preventDefault(); toggleModalFn()}}>Assign</span>
        }
      </td>
      <td></td>
    </tr>
  )
}
export default withRouter(FormList);
