import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import ImageSlider from './ImageSlider';
import EditImageDialog from './EditImageDialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeletePopUp, PageLoader } from '../../shared_elements';
import { dropzonePreviewUI } from '../../constants';
import { checkApiStatus } from '../../utils';
import { getFindingApi, addEditFindingApi, changeFindingImageApi, deleteFindingImageApi, annotateFindingImgApi } from '../forms/t007/apiServices';
import { findingErrorCode } from '../'
const findingObj = {
  finding_type: null,
  damage_dimension: '',
  damage_limit:'',
  remarks:'',
  classifications:null,
  category:null,
  repair_type: null,
  arm_srm_reference:'',
  dd_wo_reference:'',
  corrective_actions:'',
  intervals:''
};
const findingTypes = [
  {value:"Dent",label:"Dent"},
  {value:"Scratch",label:"Scratch"},
  {value:"Nick",label:"Nick"},
  {value:"Gouge",label:"Gouge"},
  {value:"Erosion",label:"Erosion"},
  {value:"Corrosion",label:"Corrosion"},
  {value:"Puncture",label:"Puncture"},
  {value:"Lightning Strike",label:"Lightning Strike"},
  {value:"Hail strike",label:"Hail strike"},
  {value:"Wave",label:"Wave"},
  {value:"Crack",label:"Crack"},
  {value:"Others", label:"Others"}
];
const classifications = [
  {value:"Major",label:"Major"},
  {value:"Minor",label:"Minor"},
];
const category = [
  {value:"A",label:"A"},
  {value:"B",label:"B"},
  {value:"C",label:"C"}
];
const repairType = [
  {value:"Doubler",label:"Doubler"},
  {value:"Composite",label:"Composite"},
]
class AddEditFinding extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      findingCrud:{
        modal:false,
        data:{},
        error:{},
        mode:''
      },
      deleteModal:false,
      deleteIds:{},
      sliderInfo: {
        modal:false,
        images:[],
        startIndex:0
      },
      imgAnnotation:{
        modal:false,
        data:{}
      }
    }
    this.getFindingApi = getFindingApi.bind(this);
    this.addEditFindingApi = addEditFindingApi.bind(this);
    this.changeFindingImageApi = changeFindingImageApi.bind(this);
    this.deleteFindingImageApi = deleteFindingImageApi.bind(this);
    this.annotateFindingImgApi = annotateFindingImgApi.bind(this);
  }
  toggleModalFn = (data) => {
    this.setState({findingCrud: data})
  }
  getFindingFn = () => {
    this.setState({pageLoader:true});
    this.getFindingApi(this.props, this.props.sectionId, this.props.id)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            modal:true,
            data:response.data.data,
            error:{},
            mode:'edit'
          }
        }))
      }
    })
  }
  addFindingFn = () => {
    const { findingCrud } = this.state;
    this.setState({pageLoader:true});
    this.addEditFindingApi(this.props, this.props.sectionId, findingCrud.data, 'contentOnly')
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            data:{},
            error:{},
            modal:false,
            mode:''
          }
        }));
        this.props.getFindingResponse(response, this.props.sectionId, this.props.id);
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else if (response.data.statusCode === 100) {
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            error: response.data.error
          }
        }))
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  uploadFindingImgFn = (files) => {
    const { findingCrud } = this.state;
    this.setState({pageLoader:true});
    this.addEditFindingApi(this.props, this.props.sectionId, {files:files, id: findingCrud.data.id}, 'fileOnly')
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            data:response.data.data
          }
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  changeFindingImageFn = (id, file) => {
    this.setState({pageLoader:true});
    this.changeFindingImageApi(this.props, id, file)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            data: {
              ...prevState.findingCrud.data,
              images: prevState.findingCrud.data.images.map(item => item.id === id ? response.data.data :item)
            }
          }
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  deleteFindingImageFn = () => {
    const { deleteIds } = this.state;
    this.setState({pageLoader:true});
    this.deleteFindingImageApi(this.props, deleteIds)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            data: {
              ...prevState.findingCrud.data,
              images: prevState.findingCrud.data.images.filter(item => item.id !== deleteIds.id)
            }
          },
          deleteModal:false,
          deleteIds:{}
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  onFieldChange = (event, keyParam, data) => {
    if(keyParam === 'reject_file'){
      this.props.enqueueSnackbar(`${data.name} File format is not supported`, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.setState(prevState => ({
        ...prevState,
        findingCrud: {
          ...prevState.findingCrud,
          data:{
            ...prevState.findingCrud.data,
            [keyParam]:data
          }
        }
      }));
    }
  }
  updateSlider = (pictures) => {
    this.setState(prevState => ({
      ...prevState,
      sliderInfo: {
        ...prevState.sliderInfo,
        modal:true,
        images: pictures.map(item => {return {title:'', original:item.image, thumbnail:item.image}}),
        startIndex:0
      }
    }))
  }
  saveAnnotateImg = (file) => {
    const { imgAnnotation } = this.state;
    this.setState({pageLoader:true});
    this.annotateFindingImgApi(this.props, file, imgAnnotation)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          imgAnnotation:{modal:false, data:{}},
          findingCrud: {
            ...prevState.findingCrud,
            data:{
              ...prevState.findingCrud.data,
              images: prevState.findingCrud.data.images.map(item => item.id === imgAnnotation.data.id ? response.data.data:item)
            }
          }
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  updateErrorField = (key, message) => {
    this.setState((prevState)=> ({
      ...prevState,
      findingCrud: {
        ...prevState.findingCrud,
        error: {
          ...prevState.findingCrud.error,
          [key]: message
        }
      }
    }))
  }
  render(){
    const { pageLoader, imgAnnotation, findingCrud, deleteModal, deleteIds, sliderInfo } = this.state;
    const { sectionId, id } = this.props;
    return(
      <Fragment>
        { id ?
          <Tooltip title="Edit" arrow>
            <IconButton size="small" color="primary" onClick={() => this.getFindingFn()}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          :
          <Button
            variant="outlined"
            color="primary"
            disableElevation={true}
            onClick={() => this.toggleModalFn({modal:true, data:findingObj, error:{}, mode:'add'})}
          >
            + Add Finding
          </Button>
        }
        { findingCrud.modal ?
          <Dialog
            open={findingCrud.modal}
            onClose={() => this.toggleModalFn({modal:false, data:{}, error:{}, mode:''})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {id ? 'Edit Finding':'Add Finding'}
            </DialogTitle>
            <DialogContent dividers={true}>
              <form>
                <Grid container spacing={3}>
                  {id ?
                    <Grid item xs={12} md={12}>
                      <ul className="list-inline">
                        <li className="list-inline-item" style={{marginRight:'15px', marginBottom:'0px'}}>
                          <div style={{width:'250px'}}>
                            <DropzoneArea
                              filesLimit={10}
                              showPreviewsInDropzone={false}
                              acceptedFiles={['image/png', 'image/jpeg', 'image/jpg']}
                              dropzoneClass="drag-drop-cnt"
                              onDrop={(files) => this.uploadFindingImgFn(files)}
                              showAlerts={['error', 'info']}
                            />
                          </div>
                        </li>
                        { findingCrud.data.images && findingCrud.data.images.map((item) =>
                          <li className="list-inline-item" style={{marginRight:'15px', marginBottom:'15px'}}>
                            <div className="dropped-pic-card" style={{backgroundImage: "url(" + item.image + ")"}}>
                              <div className="overlay"></div>
                              <div className="dropped-picture-cta">
                                <span onClick={() => this.updateSlider(findingCrud.data.images)}>View</span><br/>
                                <Fragment>
                                  <span onClick={() => this.setState({imgAnnotation:{modal:true, data:item}}) }>Edit</span><br/>
                                  <span onClick={() => this.setState({deleteModal:true, deleteIds:{id:item.id}}) }>Remove</span><br/>
                                  <span>
                                    <input
                                      accept="image/*"
                                      id={`contained-button-file`}
                                      type="file"
                                      style={{display:'none'}}
                                      onChange={(e) => this.changeFindingImageFn(item.id, e.target.files[0])}
                                    />
                                    <label htmlFor={`contained-button-file`}>Change</label>
                                  </span>
                                </Fragment>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </Grid>
                    :
                    <Grid item xs={12} md={12}>
                      <DropzoneArea
                        dropzoneText="Drag & Drop Attachment or Click"
                        filesLimit={10}
                        acceptedFiles={['image/png', 'image/jpeg', 'image/jpg']}
                        previewGridProps={dropzonePreviewUI}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        maxWidth='sm'
                        dropzoneClass="drag-drop-cnt"
                        showAlerts={['error', 'info']}
                        onChange={(files) => this.onFieldChange('', 'files', files)}
                      />
                    </Grid>
                  }
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={findingTypes}
                      getOptionLabel={option => option.label}
                      id="finding_type"
                      value={findingCrud.data.finding_type ? findingTypes.find(item => item.value === findingCrud.data.finding_type) :null}
                      renderInput={params => <TextField required error={findingCrud.error.finding_type ? true:false} helperText={findingCrud.error.finding_type ? findingCrud.error.finding_type:''} {...params} label="Damage Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      onChange={(e, value) => this.onFieldChange(e, 'finding_type', value ? value.value:value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="damage_dimension"
                      label="Damage Dimension"
                      fullWidth
                      margin="normal"
                      error={findingCrud.error.damage_dimension ? true:false}
                      helperText={findingCrud.error.damage_dimension ? findingCrud.error.damage_dimension:''}
                      value={findingCrud.data.damage_dimension ? findingCrud.data.damage_dimension:''}
                      inputProps={{ maxLength: 30 }}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'damage_dimension', e.target.value)}
                      onFocus={(e) => this.updateErrorField('damage_dimension', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="damage_limit"
                      label="Damage Within Limit"
                      fullWidth
                      margin="normal"
                      error={findingCrud.error.damage_limit ? true:false}
                      helperText={findingCrud.error.damage_limit ? findingCrud.error.damage_limit:''}
                      value={findingCrud.data.damage_limit ? findingCrud.data.damage_limit:''}
                      inputProps={{ maxLength: 30 }}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'damage_limit', e.target.value)}
                      onFocus={(e) => this.updateErrorField('damage_limit', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="remarks"
                      label="Description"
                      fullWidth
                      margin="normal"
                      error={findingCrud.error.remarks ? true:false}
                      helperText={findingCrud.error.remarks ? findingCrud.error.remarks:''}
                      value={findingCrud.data.remarks ? findingCrud.data.remarks:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                      onFocus={(e) => this.updateErrorField('remarks', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={classifications}
                      getOptionLabel={option => option.label}
                      id="classifications"
                      value={findingCrud.data.classifications ? classifications.find(item => item.value === findingCrud.data.classifications) :null}
                      renderInput={params => <TextField {...params} label="Classification" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      onChange={(e, value) => this.onFieldChange(e, 'classifications', value ? value.value:value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options = {category}
                      getOptionLabel={option => option.label}
                      id="category"
                      value={findingCrud.data.category ? category.find(item => item.value === findingCrud.data.category) :null}
                      renderInput={params => <TextField {...params} label="Category" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      onChange={(e, value) => this.onFieldChange(e, 'category', value ? value.value:value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options = {repairType}
                      getOptionLabel={option => option.label}
                      id="repair_type"
                      value={findingCrud.data.repair_type ? repairType.find(item => item.value === findingCrud.data.repair_type) :null}
                      renderInput={params => <TextField {...params} label="Repair Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      onChange={(e, value) => this.onFieldChange(e, 'repair_type', value ? value.value:value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="dd_wo_reference"
                      label="D&D WO Reference"
                      fullWidth
                      margin="normal"
                      error={findingCrud.error.dd_wo_reference ? true:false}
                      helperText={findingCrud.error.dd_wo_reference ? findingCrud.error.dd_wo_reference:''}
                      value={findingCrud.data.dd_wo_reference ? findingCrud.data.dd_wo_reference:''}
                      inputProps={{ maxLength: 30 }}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'dd_wo_reference', e.target.value)}
                      onFocus={(e) => this.updateErrorField('dd_wo_reference', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="arm_srm_reference"
                      label="AMM / SRM Reference"
                      fullWidth
                      margin="normal"
                      error={findingCrud.error.arm_srm_reference ? true:false}
                      helperText={findingCrud.error.arm_srm_reference ? findingCrud.error.arm_srm_reference:''}
                      value={findingCrud.data.arm_srm_reference ? findingCrud.data.arm_srm_reference:''}
                      inputProps={{ maxLength: 30 }}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'arm_srm_reference', e.target.value)}
                      onFocus={(e) => this.updateErrorField('arm_srm_reference', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="corrective_actions"
                      label="Corrective Action"
                      fullWidth
                      margin="normal"
                      error={findingCrud.error.corrective_actions ? true:false}
                      helperText={findingCrud.error.corrective_actions ? findingCrud.error.corrective_actions:''}
                      value={findingCrud.data.corrective_actions ? findingCrud.data.corrective_actions:''}
                      inputProps={{ maxLength: 30 }}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'corrective_actions', e.target.value)}
                      onFocus={(e) => this.updateErrorField('corrective_actions', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="intervals"
                      label="Threshold / Intervals"
                      fullWidth
                      margin="normal"
                      error={findingCrud.error.intervals ? true:false}
                      helperText={findingCrud.error.intervals ? findingCrud.error.intervals:''}
                      value={findingCrud.data.intervals ? findingCrud.data.intervals:''}
                      inputProps={{ maxLength: 30 }}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'intervals', e.target.value)}
                      onFocus={(e) => this.updateErrorField('intervals', '')}
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.toggleModalFn({modal:false, data:{}, error:{}, mode:''})}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={this.addFindingFn}>Save</Button>
            </DialogActions>
          </Dialog>:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{id:null, pictures:[]}})}}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={this.deleteFindingImageFn}
        />
        { sliderInfo.modal ?
          <ImageSlider
            sliderInfo={sliderInfo}
            toggleModalFn={() => this.setState({sliderInfo:{modal:false, images:[], startIndex:''}})}
          />:null
        }
        { imgAnnotation.modal ?
          <EditImageDialog
            imgAnnotation={imgAnnotation}
            saveAnnotateImg={this.saveAnnotateImg}
            toggleModalFn={() => this.setState({imgAnnotation: {modal:false}})}
          />:null
        }
        { pageLoader ? <PageLoader /> : null }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddEditFinding));
