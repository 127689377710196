import moment from 'moment';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
import { errorCode} from './index';
export function getContactListApi(props={}, query={}){
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/contacts/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      delete query.sort;
      delete query.sort_by;
      delete query.page;
      delete query.per_page;
      this.setState({
        contacts: response.data.data,
        filter: query,
        skeletonLoader: false
      })
    }
  })
}
export function exportContactListApi(props={}, file={}){
  this.setState({pageLoader: true});
  globalExportService(`technical/workorder/${props.match.params.workOrderSlug}/contacts/`, {download:file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE006_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.props.enqueueSnackbar('Contacts Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function getContactApi(props={}, mode, id){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/contacts/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({contact:{data:response.data.data, error:{}, modal:true, mode: mode === 'V' ? 'view':'edit'}})
    }
    this.setState({pageLoader:false});
  });
}
export function addEditContactApi(props={}, contact={}){
  let validationInputs={
    full_name:errorCode['full_name'][fieldValidation({...errorCode['full_nameObj'], fieldval: contact.data.full_name})],
    email:errorCode['email'][fieldValidation({...errorCode['emailObj'], fieldval: contact.data.email})],
    title:errorCode['title'][fieldValidation({...errorCode['titleObj'], fieldval: contact.data.title})],
    phone_number:errorCode['phone_number'][fieldValidation({...errorCode['phone_numberObj'], fieldval: contact.data.phone_number})],
    fax:errorCode['fax'][fieldValidation({...errorCode['faxObj'], fieldval: contact.data.fax})],
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    this.setState({pageLoader: true});
    if(contact.data.id){
      globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/contacts/${contact.data.id}/`, contact.data)
      .then(response => {
        if(checkApiStatus(response)){
          this.setState(prevState => ({
            ...prevState,
            contacts: {
              ...prevState.contacts,
              list: prevState.contacts.list.map(item => item.id === response.data.data.id ? response.data.data:item)
            },
            contact: {data:{}, error:{}, mode:'', modal: false}
          }));
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
        this.setState({pageLoader: false});
      })
    }else{
      globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/contacts/`, contact.data)
      .then(response => {
        if(checkApiStatus(response)){
          this.setState(prevState => ({
            ...prevState,
            contacts: {
              ...prevState.contacts,
              list: [response.data.data, ...prevState.contacts.list],
            },
            contact: {data:{}, error:{}, mode:'', modal: false}
          }));
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
        this.setState({pageLoader: false});
      })
    }
  }else{
    this.setState(prevState => ({
      ...prevState,
      contact: {
        ...prevState.contact,
        error: validationInputs
      }
    }))
  }
}
export function deleteContactApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/contacts/`, data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        deleteModal: false,
        deleteIds:[],
        contacts: {
          ...prevState.contacts,
          list: prevState.contacts.list.filter(item => !data.ids.includes(item.id))
        }
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
