import React, { Fragment } from 'react';
import moment from 'moment';
import { TableRow, TableCell, Popover, Grid, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import InfoIcon from '@material-ui/icons/Info';
import { TableRowActions, LabelValueCard } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants';
import { getTrackerRangeValueFn } from '../apiServices';
const ProjectTrackerList = ({project, csd, ced}) => {
  function popOverCard(assets){
    return(
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <Fragment>
            <InfoIcon {...bindTrigger(popupState)} />
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{maxHeight:'300px', overflow:'auto'}}>
                {assets.map((asset, index) =>
                  <div style={{padding:'15px 15px 0 15px', borderBottom:'1px solid #d7d7d7'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        {(() => {
                          if(asset.asset_type === 1){
                            return(
                              <div className="">
                                <h6>{asset.asset.aircraft_type}</h6>
                                <p>{`MSN ${asset.asset.msn}`}</p>
                              </div>
                            )
                          }
                          if(asset.asset_type === 2){
                            return(
                              <div className="">
                                <h6>{asset.asset.engine_type}</h6>
                                <p>{`ESN ${asset.asset.esn}`}</p>
                              </div>
                            )
                          }
                          if(asset.asset_type === 3){
                            return(
                              <div className="">
                                <h6>{asset.asset.apu_type}</h6>
                                <p>{`APU ${asset.asset.serial_number}`}</p>
                              </div>
                            )
                          }
                          if(asset.asset_type === 4){
                            return(
                              <div className="">
                                <h6>{asset.asset.apu_type}</h6>
                                <p>{`Landing Gear ${asset.asset.serial_number}`}</p>
                              </div>
                            )
                          }
                          if(asset.asset_type === 5){
                            return(
                              <div className="">
                                <h6>{asset.asset.apu_type}</h6>
                                <p>{`Propeller ${asset.asset.serial_number}`}</p>
                              </div>
                            )
                          }
                        })()}
                      </Grid>
                      <LabelValueCard md={6} label='' value={asset.status.label} />
                    </Grid>
                    <Grid container spacing={1}>
                      <LabelValueCard md={6} label='Start Date' value={asset.start_date ? moment(asset.start_date).format(displayDateFormatShort):'--'} />
                      <LabelValueCard md={6} label='End Date' value={asset.end_date ? moment(asset.end_date).format(displayDateFormatShort):'--'} />
                    </Grid>
                    { asset.project_engineers && asset.project_engineers.length ?
                      <List>
                        <p>Project Engineer</p>
                        { asset.project_engineers.map((enginer, index) =>
                          <Fragment>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar alt={enginer.name} src={enginer.profile_pic} />
                              </ListItemAvatar>
                              <ListItemText primary={enginer.name}></ListItemText>
                            </ListItem>
                            <Divider />
                          </Fragment>
                        )}
                      </List>:null
                    }

                  </div>
                )}
              </div>
            </Popover>
          </Fragment>
        )}
      </PopupState>
    )
  }
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <div className="project-info-col flex-centered">
          { project.lessor && project.lessor.logo ?
            <span className="lessor-info"><img src={project.lessor.logo} alt={project.lessor.name} /></span>:
            <span className="lessor-info">{project.lessor.name}</span>
          }

          <div className="project-details">
            <p className="project-number">{`#${project.project_number}`}</p>
            { project.lessee && project.lessee.name ? <p className="lessee-name">{project.lessee.name}</p>:null}
            <h3>{project.name}</h3>
            <p className="lead-by">Lead By - <span>{project.leads.map(lead => lead.user.name).join(',')}</span></p>
            {project.department && project.department.label ? <p className="department">{project.department.label}</p>:null}
          </div>
        </div>
      </TableCell>
      <TableCell>
        {(() => {
          let msn = project.assets.filter(asset => asset.asset_type === 1);
          let esn = project.assets.filter(asset => asset.asset_type === 2);
          let apu = project.assets.filter(asset => asset.asset_type === 3);
          let lg = project.assets.filter(asset => asset.asset_type === 4);
          let propeller = project.assets.filter(asset => asset.asset_type === 5);
          if(msn.length){
            return(
              <p className="flex-centered">{`${msn.length} MSN`} {popOverCard(msn)}</p>
            )
          }
          if(esn.length){
            return(
              <p className="flex-centered">{`${esn.length} ESN`} {popOverCard(esn)}</p>
            )
          }
          if(apu.length){
            return(
              <p className="flex-centered">{`${apu.length} APU`} {popOverCard(apu)}</p>
            )
          }
          if(lg.length){
            return(
              <p className="flex-centered">{`${lg.length} Landing Gear`} {popOverCard(lg)}</p>
            )
          }
          if(propeller.length){
            return(
              <p className="flex-centered">{`${propeller.length} Propeller`} {popOverCard(propeller)}</p>
            )
          }
        })()}

      </TableCell>
      <TableCell>{project.status.label}</TableCell>
      <TableCell>{project.remarks && project.remarks != '' ? project.remarks.length > 10  ? `${project.remarks.substr(0,10)}...` : `${project.remarks}` : '--'}
      { project.remarks && project.remarks != '' ?
        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
            <Fragment>
              <InfoIcon {...bindTrigger(popupState)} />
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div style={{padding:'10px', maxHeight:'200px', overflow:'auto'}}>
                  {
                    project.remarks
                  }
                </div>
              </Popover>
            </Fragment>
          )}
        </PopupState>:null}
      </TableCell>
      <TableCell>
        { getTrackerRangeValueFn(csd, ced, project.start_date, project.end_date) ?
          <span>{`${project.start_date} - ${project.end_date}`}</span>
          :''
        }
      </TableCell>
    </TableRow>
  )
}
export default ProjectTrackerList;
