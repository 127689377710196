import React from 'react';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { displayDateFormatShort } from '../../constants';
const WhatsNewCompleteList = ({modal, whtsNewList, toggleModalFn}) => {
  return(
    <Dialog
      open={modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        What's New
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="whats-new-timeline">
              <ul className="list-unstyled">
                {whtsNewList.map((item, index) =>
                  <li key={index}>
                    <div>
                      <span>{moment(item.release_date).format(displayDateFormatShort)}</span>
                      <h4>{item.title}</h4>
                      <div className="content" dangerouslySetInnerHTML={{ __html: item.description}}></div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={toggleModalFn}>Okey</Button>
      </DialogActions>
    </Dialog>
  )
}
export default WhatsNewCompleteList;
