import React, { Component, Fragment } from 'react';
import { Grid, Table, TableBody, TableRow, TableCell, Paper, Avatar } from '@material-ui/core';
import { InspectionFormWrapper, ReviewIssuesComp } from '../../Elements';
import { PageLoader } from '../../../shared_elements';
import { getFormReviewListApi } from '../apiServices';
import { getInspectionHeaderApi } from '../../forms/t001/apiServices';
class InspectionReviewT007 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      inspectionHdInfo:{},
      t007ReviewList: {}
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.getFormReviewListApi = getFormReviewListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getFormReviewListApi(this.props, 't007ReviewList');
  }
  render(){
    const { t007ReviewList, inspectionHdInfo, skeletonLoader } = this.state;
    return(
      <Fragment>
        <InspectionFormWrapper reviewerForm={true} skeletonLoader={skeletonLoader} inspectionHdInfo={inspectionHdInfo} />
        <div className="technical-review-forms-cn">
          <div className="t007-findings">
            {t007ReviewList.list ? t007ReviewList.list.map((section, index) => {
              if(section.findings.length){
                return(
                  <div className="section-info">
                    <h3>
                      {section.section_name}
                      <span className="add-issue">
                        { section.issue && section.issue.id ?
                          <ReviewIssuesComp type="view" issue={section.issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't007ReviewList')}  />
                          :
                          <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't005ReviewList')} type="add" payload={{aircraft_section_id: section.id,form_id:7}} />
                        }
                      </span>
                    </h3>
                    { section.findings.map((finding) =>
                      <div className="findings-info">
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Damage Type</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.finding_type}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Damage Dimension</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.damage_dimension}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Damage Within Limit</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.damage_limit}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Description</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.remarks}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Classification</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.classifications}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Category</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.category}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Repair Type</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.repair_type}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>AMM / SRM Reference</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.arm_srm_reference}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>D&D WO Reference</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.dd_wo_reference}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Corrective Action</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.corrective_actions}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Threshold / Intervals</TableCell>
                              <TableCell>:</TableCell>
                              <TableCell>{finding.intervals}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Grid container spacing={0}>
                          {finding.images ? finding.images.map((pic) =>
                            <Grid item md={6}>
                              <img className="findings-img" src={pic.image} alt=""/>
                            </Grid>
                          ):null}
                        </Grid>
                      </div>
                    )}
                  </div>
                )
              }else{
                return null
              }
            }):null}
          </div>
        </div>
      </Fragment>
    )
  }
}
export default InspectionReviewT007;
