import React from 'react';
import moment from 'moment';
import { TableRow, TableCell, Avatar, Tooltip, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { CircularProgress } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants';
export default function ProjectAnalyticList({item, index}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{index}</TableCell>
      <TableCell>
        {item.project_number}
      </TableCell>
      <TableCell>
        {item.name}
      </TableCell>

      <TableCell style={{width:'100px'}}>{item.project_status ? <span style={{padding:'5px 10px', background: item.project_status === 'Completed' ?'#5d3cdb': item.project_status === 'Ongoing' ? '#df8c0c':'#2eaeb5', display:'inline-block', color:'#ffffff', borderRadius:'4px'}}>{item.project_status}</span>:'--'}</TableCell>
      <TableCell>{item.lessor}</TableCell>
      <TableCell>{item.start_date}</TableCell>
      <TableCell>
        {item.expected_end_date}
      </TableCell>
      <TableCell>
        {item.location}
      </TableCell>
    </TableRow>
  )
}
