import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Paper, Link } from '@material-ui/core';
import { WorkOrderHd, FormList, WorkOrderDate, ProjectViewHd, ProjectUserProfile, AircraftCRU, ALPCRU, ProjectUsers, WorkOrderDropdown, ProjectUserPopUp } from '../components';
import { ProgressBar } from '../../Elements';
import { DeletePopUp, PageLoader } from '../../../shared_elements';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getProjectApi, getLinkAbleAssetApi, getProjectInspectionsApi, getEngineerProfileApi, getProjectUsersApi, addUserToProjectApi, removeUserFromProjectApi, lunchWorkOrderApi } from '../apiServices';
class ProjectView extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      project: {},
      userProfile:{
        modal:false,
        data:{}
      },
      project_role:null,
      deleteModal:false,
      deleteIds:[],
      addUserModal: false,
      addUserIds:[],
      userListPopup: {
        modal:false,
        userList:[],
        selectedUser:[],
        title: '',
        type:''
      },
      workOrderPop:{
        modal:false,
        asset:{}
      },
      assetList:[],
      inspectionTypes: [],
      projectEngineers:[],
      projectLeads:[],
      projectMembers: []
    }
    this.getProjectApi = getProjectApi.bind(this);
    this.getEngineerProfileApi = getEngineerProfileApi.bind(this);
    this.getLinkAbleAssetApi = getLinkAbleAssetApi.bind(this);
    this.getProjectInspectionsApi = getProjectInspectionsApi.bind(this);
    this.getProjectUsersApi = getProjectUsersApi.bind(this);
    this.addUserToProjectApi = addUserToProjectApi.bind(this);
    this.removeUserFromProjectApi = removeUserFromProjectApi.bind(this);
    this.lunchWorkOrderApi = lunchWorkOrderApi.bind(this);
  }
  componentDidMount(){
    this.getProjectApi(this.props);
    this.getLinkAbleAssetApi(this.props);
    this.getProjectInspectionsApi(this.props);
    this.getProjectUsersApi(this.props, {role:'lead'});
    this.getProjectUsersApi(this.props, {role:'engineer'});
    this.getProjectUsersApi(this.props, {role:'member'});
  }
  handleAddClick = (data) => {
    if(data.assetType === 'Engine'){
      this.props.history.push(`/project/create-engine/${this.props.match.params.projectSlug}`);
    }
    this.setState({workOrder:data})
  }
  selectProjectUser = (id) => {
    if(this.state.userListPopup.type === '2'){
      this.setState(prevState => ({
        ...prevState,
        userListPopup: {
          ...prevState.userListPopup,
          selectedUser:[id]
        }
      }));
    }else{
      const currentIndex = this.state.userListPopup.selectedUser.indexOf(id);
      const newUsers = [...this.state.userListPopup.selectedUser];
      if (currentIndex === -1) {
        newUsers.push(id);
      } else {
        newUsers.splice(currentIndex, 1);
      }
      this.setState(prevState => ({
        ...prevState,
        userListPopup: {
          ...prevState.userListPopup,
          selectedUser:newUsers
        }
      }));
    }

  }
  render(){
    const { project_role, assetList, inspectionTypes, projectLeads, projectEngineers, projectMembers, project, userProfile, deleteModal, deleteIds, userListPopup, workOrderPop, pageLoader }= this.state;
    return(
      <div className="technical-project-detail">
        <ProjectViewHd project={project} />
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <p className="add-workorder">Assets in the project{project.work_orders ? '('+project.work_orders.length+')':'' } &nbsp;
              <WorkOrderDropdown
                toggleModalFn={(assetType) => this.setState(prevState => ({
                  ...prevState,
                  workOrderPop: {
                    modal:true,
                    asset: {type:assetType}
                  }
                }))}
              />
            </p>
          </Grid>
          <Grid item xs={5}>
            <div className="" style={{float:'right', display:'none'}}>
              <p>Access Sparta Technical App on</p>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link>
                    <img width="100" src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/app-store-apple.png" alt="" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link>
                    <img width="125" src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/google-play-badge.png" alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            { project.work_orders && project.work_orders.map((workOrder,index) =>
              <ExpansionPanel className="working-groups-section" key={index}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`workOrder-${workOrder.id}`}
                >
                  <WorkOrderHd
                    inspectionTypes={inspectionTypes}
                    workOrder={workOrder}
                    toggleWorkOrderFn={() => {this.setState({workOrderPop:{modal:false, assetType: ''}})}}
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{padding: '0'}}>
                  <div style={{width:'100%'}}>
                    { workOrder.forms && workOrder.forms.length && workOrder.status.id === 1 ?
                      <div style={{margin:'10px 10px 10px 0', float: 'right'}}>
                        <Button variant="contained" color="primary" onClick={() => this.lunchWorkOrderApi(this.props, workOrder.slug, workOrder.asset)}>Lunch</Button>
                      </div>
                      :null
                    }
                    <div class="table-responsive">
                      <table className="form-table">
                        <tbody>
                          { workOrder.forms.map((form, formIndex) =>
                            <FormList
                              workOrderSlug={workOrder.slug}
                              asset={workOrder.asset}
                              engineerProfile={(slug) => this.getEngineerProfileApi(this.props, slug)}
                              key={formIndex}
                              form={form}
                              toggleModalFn={() => {this.setState({userListPopup: {modal:true, userList:project.engineers, selectedUser:[], workOrderSlug:workOrder.slug, formSlug:form.slug, title:'Select Project Engineer', type:'2', asset_type:workOrder.asset.type}})}}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="" style={{marginBottom: '20px'}}>
              <Grid container spacing={2}>
                <WorkOrderDate label="Start" flag={project.end_date ? true:false} date={project.start_date} />
                <WorkOrderDate label="End" flag={project.end_date ? true:false} date={project.expected_end_date} />
                { project.end_date ?
                  <WorkOrderDate label="Completed" flag={project.end_date ? true:false} date={project.end_date} />:null
                }
              </Grid>
            </div>
            <Paper className="cor-feedback">
              <h4 className="flex-centered" onClick={() => this.props.history.push(`/project/view/${this.props.match.params.projectSlug}/project-cor-form`)}>COR (Confidential Operator Report) <ArrowForwardIosIcon fontSize="small" /></h4>
            </Paper>
            <Paper className="cor-feedback">
              <h4 className="flex-centered">Feedback <ArrowForwardIosIcon fontSize="small" /></h4>
            </Paper>
            <ProjectUsers
              userType='Project Leads'
              users={project.leads ? project.leads:[]}
              removeUser={(id) => {this.setState({deleteModal:true, deleteIds:[id], project_role: 1})}}
              toggleModalFn={() => { this.setState({userListPopup: {modal:true, userList: project.leads && project.leads.length ? projectLeads.filter(lead => !project.leads.map(user => user.id).includes(lead.id)):projectLeads, selectedUser:[], title: 'Select Leads', type:'3'}}) }}
            />
            <ProjectUsers
              userType='Project Engineers'
              users={project.engineers ? project.engineers:[]}
              removeUser={(id) => {this.setState({deleteModal:true, deleteIds:[id], project_role: 2})}}
              toggleModalFn={() => { this.setState({userListPopup: {modal:true, userList: project.engineers && project.engineers.length ? projectEngineers.filter(engineer => !project.engineers.map(user => user.id).includes(engineer.id)):projectEngineers, selectedUser:[], title: 'Select Engineers', type:'1'}}) }}
            />
            <ProjectUsers
              userType='Current Lessee Engineers'
              users={project.members ? project.members.filter(member => member.project_role === 3 && member.operator_level === 1):[]}
              removeUser={(id) => {this.setState({deleteModal:true, deleteIds:[id], project_role: 3})}}
              toggleModalFn={() => { this.setState({userListPopup: {modal:true, userList: project.members && project.members.length ? projectMembers.filter(member => !project.members.map(user => user.id).includes(member.id)) : projectMembers, selectedUser:[], title: 'Current Lessee Engineer', type:'4'}}) }}
            />
            <ProjectUsers
              userType='Next Lessee Engineers'
              users={project.members ? project.members.filter(member => member.project_role === 3 && member.operator_level === 2):[]}
              removeUser={(id) => {this.setState({deleteModal:true, deleteIds:[id], project_role: 3})}}
              toggleModalFn={() => { this.setState({userListPopup: {modal:true, userList: project.members && project.members.length ? projectMembers.filter(member => !project.members.map(user => user.id).includes(member.id)) : projectMembers, selectedUser:[], title: 'Next Lessee Engineer', type:'5'}}) }}
            />
            <ProjectUsers
              userType='Clients/Third Party'
              users={project.members ? project.members.filter(member => member.project_role === 3 && member.operator_level === null):[]}
              removeUser={(id) => {this.setState({deleteModal:true, deleteIds:[id], project_role: 3})}}
              toggleModalFn={() => { this.setState({userListPopup: {modal:true, userList: project.members && project.members.length ? projectMembers.filter(member => !project.members.map(user => user.id).includes(member.id)) : projectMembers, selectedUser:[], title: 'Clients/Third Party', type:'6'}}) }}
            />
          </Grid>
        </Grid>
        <ProjectUserProfile
          userProfile={userProfile}
          toggleModalFn={() => {this.setState({userProfile:{modal:false, data:{}}})}}
        />
        <ProjectUserPopUp
          userListPopup={userListPopup}
          selectProjectUserFn={this.selectProjectUser}
          toggleModalFn={() => {this.setState({userListPopup: {modal:false, userList:[], selectedUser:[], title:''}})}}
          addUserToProject={() => this.addUserToProjectApi(this.props,  userListPopup)}
          assignToAllFormFn={(flag) => this.setState(prevState => ({
            ...prevState,
            userListPopup: {
              ...prevState.userListPopup,
              assign_to_all:flag
            }
          }))}
        />
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:[], project_role:null})}}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.removeUserFromProjectApi(this.props, {user_id: deleteIds[0], project_form:0,project_role:project_role })}
        />
        { workOrderPop.modal &&  workOrderPop.asset &&  workOrderPop.asset.type === 1 ?
          <AircraftCRU
            toggleWorkOrderFn={() => {this.setState({workOrderPop:{modal:false, assetType: ''}})}}
            workOrderPop={workOrderPop}
            asset={workOrderPop.asset}
            inspectionTypes={inspectionTypes}
            assetList={assetList}
          />:null
        }
        { workOrderPop.modal &&  workOrderPop.asset &&  workOrderPop.asset.type !== 1 ?
          <ALPCRU
            assetList={assetList}
            toggleWorkOrderFn={() => {this.setState({workOrderPop:{modal:false, assetType: ''}})}}
            workOrderPop={workOrderPop}
            asset={workOrderPop.asset}
            inspectionTypes={inspectionTypes}
          />:null
        }
        { pageLoader ? <PageLoader/>:null }
      </div>
    )
  }
}
export default withSnackbar(ProjectView)
