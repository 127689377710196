import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
let flag = 1;
export default class ProjectDetail  extends Component {
  constructor(props){
    super(props);
	}
  render() {
		const { projectTrackerDtls  } = this.props;
    return(
 			<div className="simulation-month-sidebar item-block">
				<div className="row-header">
					{ projectTrackerDtls.length ?
						projectTrackerDtls.map((project, index) =>
							<div className="item-detail-block flex-not-centered" style={{height:'100px', width: 'max-content'}} key={index}>
								<div className="innerheading-block flex-centered" style={{ width: '243px', flexBasis: '243px'}}>
									<div className="para-ui month-detail flex-centered" style={{ paddingLeft: '3px'}}>
										<div className="first-block" style={{paddingLeft: '7px'}}>
											{ project.lessor &&	project.lessor.logo ?
												<img  style={{ margin: '0 auto', display: 'block'}} className="lessor-logo" src={project.lessor.logo} alt="img" id={`project_lessor_${project.id}${project.lessor.id}`} />
												: null
											}
										</div>
										<div  className="second-block">
											{ project.project_number ?
													<span style={{ display: 'block', position: 'relative', top: '3px', color: '#000'}} className="lessee-name"> #{project.project_number} </span>
												: null
											}
											{project.lessee.name && project.lessee.name.length > 26 ?
											<span className="lessee-name" id={`project-lesse-name-${project.id}`}>{project.lessee && project.lessee.name ?  project.lessee.name.substring(0,26)+'...' : null}</span>:
											<span className="lessee-name" id={`project-lesse-name-${project.id}`}>{project.lessee && project.lessee.name ?  project.lessee.name : null}</span>
											}
											<p className="proj-name-block" style={{marginTop:'4px'}} >
												{project.name.length > 17 ?
												<Link style={{ color: '#8b8d91' }}>
													<b style={{fontSize:'14px'}} id={`project-name-${project.id}`}>{project.name.substring(0,17)+'...'}</b>
												</Link>:<Link style={{ color: '#8b8d91' }}>
													<b  style={{fontSize:'14px'}} id={`project-name-${project.id}`}>{project.name}</b>
												</Link> }
											</p>
											{ project.leads && project.leads[0] && project.leads[0].user && project.leads[0].user.name ?
											  	<span  className="lead-by"> Lead by <span style= {{cursor: 'pointer'}}  onClick = {(e) =>   this.props.getUserDetailFn(project.leads[0].user.slug, true)}> {project.leads[0].user.name }</span></span>
												: null
											}
											{ project.department && project.department.label ?
													<span className="lead-by" style={{ display: 'block', position: 'relative', top: '-3px'}}>  {project.department.label }</span>
												: null
											}
										</div>
									</div>
								</div>
								<div className="innerheading-block flex-centered" style={{ width: '121px', flexBasis: '121px', padding:'15px'}}>
									<p className="text-block">
										<span className="date-block">
											{ project.assets.filter(assetData =>  assetData.asset_type == 1) && project.assets.filter(assetData =>  assetData.asset_type == 1).length ? project.assets.filter(assetData =>  assetData.asset_type == 1).length + ' MSN' : null}
										 	<br/>
										  { project.assets.filter(assetData =>  assetData.asset_type == 2).length  > 0 ? project.assets.filter(assetData =>  assetData.asset_type == 2).length + ' ESN': null }
									 	</span>
								 	</p>
							 	</div>
								<div className="innerheading-block flex-centered" style={{ width: '121px', flexBasis: '121px', padding:'15px'}}>
									<span className="date-block status-text-block" style={{ marginBottom: 0}}>
										{project.status && project.status.label ?  project.status.label: '--' }
										{ project.closure_notes && project.closure_notes != '' ? <span> </span>:null}

									</span>
									<b className="proj-progress" id={`project-statustoolTp-${project.id}`}>
										<span style={{ width: project.status && project.status.label.toLowerCase() == "completed" ? '100%':project.progress + '%' }}></span>
									</b>
								</div>
                <div className="innerheading-block flex-centered" style={{flexBasis: '121px',width: '121px', padding:'5px'}}>
                  <span className="date-block status-text-block" style={{ marginBottom: 0, fontSize: '12px'}}>
                    {
                      project.project_remarks && project.project_remarks != '' ?
                      project.project_remarks.length > 45  ? `${project.project_remarks.substr(0,45)}...` :`${project.project_remarks}`
                      :null
                    }
                  </span>
                </div>
							</div>
							) : null
						}
				 </div>
			 </div>
      )
    }
  }
