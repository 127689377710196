import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel,  FormControlLabel, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AssetInspectionDate from './AssetInspectionDate';
import EditIcon from '@material-ui/icons/Edit';
import { PageLoader } from '../../../shared_elements';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { getProjectFormsApi, getProjectBlueprintsApi, getWorkOrderDetail, getWorkOrderAssetApi, addEditWorkOrderApi } from '../apiServices';
import { getAircraftTypesAc } from '../../../shared_elements/actionCreators';
import { aircraftWorkOrder } from '../../';
import { capitalizeFirstLetter } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const assetTypeInfo = {
  3: {
    label:'APU',
    key:'apu',
    params:'apu_id'
  },
  4: {
    label: 'Landing Gear',
    key:'lg',
    params:'lg_id'
  },
  5: {
    label: 'Propeller',
    key: 'propeller',
    params:'propeller_id'
  }
}
class AircraftCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      workOrderInfo:{
        data:{},
        error:{},
        modal: false,
        mode:'',
        type:''
      },
      forms:[],
      blueprints:[],
      assetList: []
    }
    this.getProjectFormsApi = getProjectFormsApi.bind(this);
    this.getProjectBlueprintsApi = getProjectBlueprintsApi.bind(this);
    this.getWorkOrderDetail = getWorkOrderDetail.bind(this);
    this.getWorkOrderAssetApi = getWorkOrderAssetApi.bind(this);
    this.addEditWorkOrderApi = addEditWorkOrderApi.bind(this);
  }
  componentDidMount(){
    const { workOrder } = this.props;
    if(!(workOrder && workOrder.slug)){
      this.updateApiInfo();
      this.setState(prevState => ({
        ...prevState,
        workOrderInfo: {
          data: aircraftWorkOrder,
          error:{},
          modal: true,
          mode:'add'
        }
      }))
    }
  }
  updateApiInfo = () => {
    this.getProjectBlueprintsApi(this.props);
    this.getProjectFormsApi(this.props);
    this.props.getAircraftTypes(this.props);
  }
  toggleModalFn = (data) => {
    this.updateApiInfo();
    this.setState({
      workOrderInfo: data
    });
  }
  onFieldChange = (event, keyParam, data, secKey) => {
    if(keyParam === 'inspection_types' && data){
      this.getProjectFormsApi(this.props, {inspection_type: data})
    }
    if(secKey) {
      this.setState(prevState => ({
        ...prevState,
        workOrderInfo: {
          ...prevState.workOrderInfo,
          data: {
            ...prevState.workOrderInfo.data,
            [keyParam]:{
              ...prevState.workOrderInfo.data[keyParam],
              [secKey]: data
            }
          }
        }
      }));
    }else{
      this.setState(prevState => ({
        ...prevState,
        workOrderInfo: {
          ...prevState.workOrderInfo,
          data: {
            ...prevState.workOrderInfo.data,
            [keyParam]:data
          }
        }
      }));
    }
  }
  updateErrorField = (key, message) => {
    this.setState((prevState)=> ({
      ...prevState,
      workOrderInfo: {
        ...prevState.workOrderInfo,
        error: {
          ...prevState.workOrderInfo.error,
          [key]: message
        }
      }
    }))
  }
  render(){
    const { assetList, inspectionTypes, aircraftTypes, workOrder, asset, workOrderPop } = this.props;
    const { workOrderInfo, modal, forms, blueprints, pageLoader } = this.state;
    return(
      <Fragment>
        { workOrder && workOrder.slug ?
          <span onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.updateApiInfo(); this.getWorkOrderDetail(this.props, workOrder.slug)}}>
            <EditIcon fontSize="small" /> Add Forms
          </span>:
          <span onClick={() => this.toggleModalFn({data:aircraftWorkOrder, error:{}, modal:true, mode:'add'})}>Aircraft</span>
        }
        { workOrderInfo.modal || (workOrderPop && workOrderPop.modal) ?
          <Dialog
            fullScreen
            open={(workOrderInfo.modal || (workOrderPop && workOrderPop.modal))}
            onClose={(e) => {this.toggleModalFn({data:{}, error:{}, modal:false, mode:''}); (workOrder && workOrder.slug) ? e.preventDefault() : this.props.toggleWorkOrderFn()}}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {capitalizeFirstLetter(workOrderInfo.mode)} Aircraft
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={3}>

                { !(workOrder && workOrder.slug) ?
                  <Fragment>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup row aria-label="create_msn" name="create_msn" defaultValue="top">
                          <FormControlLabel value="create_msn" control={<Radio checked={!workOrderInfo.data.create_msn} onChange={(e) => { this.toggleModalFn({data:{...aircraftWorkOrder, create_msn: false}, error:{}, modal:true, mode:'add'})}} color="primary" />} label="Link Existing MSN" />
                          <FormControlLabel value="create_msn" control={<Radio checked={workOrderInfo.data.create_msn} onChange={(e) => {this.toggleModalFn({data:{...aircraftWorkOrder, create_msn:true}, error:{}, modal:true, mode:'add'})}} color="primary" />} label="Create New MSN" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    { workOrderInfo.data.create_msn ?
                      <Grid item xs={12} md={3}>
                        <TextField
                          required
                          id="aircraft_id"
                          label="Serial Number"
                          fullWidth
                          margin="normal"
                          value={workOrderInfo.data.aircraft_id ? workOrderInfo.data.aircraft_id:''}
                          InputLabelProps={{shrink: true}}
                          error={workOrderInfo.error.aircraft_id ? true:false}
                          helperText={workOrderInfo.error.aircraft_id ? workOrderInfo.error.aircraft_id:''}
                          onChange={(e) => this.onFieldChange(e, 'aircraft_id', e.target.value)}
                        />
                      </Grid>
                      :
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          options = {assetList ? assetList.filter(asset => asset.asset_type === 1):[]}
                          getOptionLabel={option => option.msn}
                          id="aircraft_id"
                          value={assetList.filter(asset => asset.asset_type === 1).find(ast => ast.slug === workOrderInfo.data.aircraft_id)}
                          onChange={(e, value) => {this.onFieldChange(e, 'aircraft_id', value ? value.slug :value); value ? this.getWorkOrderAssetApi(this.props, workOrderInfo.data, 'aircraft', value.slug) :this.toggleModalFn({data:{}, error:{}, modal:true, mode:'add'})}}
                          renderInput={params => <TextField required {...params} error={workOrderInfo.error.aircraft_id ? true:false} helperText={workOrderInfo.error.aircraft_id ? workOrderInfo.data.aircraft_id:''} label="MSN" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                        />
                      </Grid>
                    }
                  </Fragment>:null
                }
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       required
                       id="start_date"
                       label="Start Date"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture={true}
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       inputProps={{readOnly: true}}
                       error={workOrderInfo.error.start_date ? true:false}
                       helperText={workOrderInfo.error.start_date ? workOrderInfo.error.start_date:''}
                       value={workOrderInfo.data.start_date ? workOrderInfo.data.start_date:null}
                       onChange={(data, value) => {this.onFieldChange(value, 'start_date', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       required
                       id="expected_end_date"
                       label="Expected End Date"
                       format={fieldDateFormat}
                       fullWidth
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       inputProps={{readOnly: true}}
                       error={workOrderInfo.error.expected_end_date ? true:false}
                       helperText={workOrderInfo.error.expected_end_date ? workOrderInfo.error.expected_end_date:''}
                       minDate={ workOrderInfo.data.start_date ? moment(workOrderInfo.data.start_date).add(30, 'days'):moment().add(30, 'days')}
                       value={workOrderInfo.data.expected_end_date ? workOrderInfo.data.expected_end_date:null}
                       onChange={(data, value) => {this.onFieldChange(value, 'expected_end_date', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options = {aircraftTypes}
                    getOptionLabel={option => option.name}
                    id="aircraft_type_id"
                    value={workOrderInfo.data.aircraft_type_id ? aircraftTypes.find(aircraft_type => aircraft_type.id === workOrderInfo.data.aircraft_type_id):null}
                    onChange={(e, value) => this.onFieldChange(e, 'aircraft_type_id', value ? value.id :value)}
                    renderInput={params => <TextField required {...params} error={workOrderInfo.error.aircraft_type_id ? true:false} helperText={workOrderInfo.error.aircraft_type_id ? workOrderInfo.error.aircraft_type_id:''} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options = {blueprints}
                    getOptionLabel={option => option.name}
                    id="blueprint_id"
                    value={workOrderInfo.data.blueprint_id ? blueprints.find(blueprint => blueprint.id === workOrderInfo.data.blueprint_id):null}
                    onChange={(e, value) => this.onFieldChange(e, 'blueprint_id', value ?value.id :value)}
                    renderInput={params => <TextField required {...params} error={workOrderInfo.error.blueprint_id ? true:false} helperText={workOrderInfo.error.blueprint_id ? workOrderInfo.error.blueprint_id:''} label="Aircraft Blueprint" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       id="lease_start_date"
                       label="Lease Start Date"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture={true}
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       inputProps={{readOnly: true}}
                       value={workOrderInfo.data.lease_start_date ? workOrderInfo.data.lease_start_date:null}
                       onChange={(data, value) => {this.onFieldChange(value, 'lease_start_date', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       required
                       id="date_of_manufacture"
                       label="Date of Manufacture"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture={true}
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       inputProps={{readOnly: true}}
                       error={workOrderInfo.error.date_of_manufacture ? true:false}
                       helperText={workOrderInfo.error.date_of_manufacture ? workOrderInfo.error.date_of_manufacture:''}
                       value={workOrderInfo.data.date_of_manufacture ? workOrderInfo.data.date_of_manufacture:null}
                       onChange={(data, value) => {this.onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="tsn"
                    label="TSN"
                    fullWidth
                    margin="normal"
                    value={workOrderInfo.data.tsn ? workOrderInfo.data.tsn:''}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'tsn', e.target.value) : e.preventDefault()}}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="csn"
                    label="CSN"
                    fullWidth
                    margin="normal"
                    value={workOrderInfo.data.csn ? workOrderInfo.data.csn:''}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'csn', e.target.value) : e.preventDefault() } }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="location"
                    label="Location"
                    fullWidth
                    margin="normal"
                    value={workOrderInfo.data.location ? workOrderInfo.data.location:''}
                    InputLabelProps={{shrink: true}}
                    error={workOrderInfo.error.location ? true:false}
                    helperText={workOrderInfo.error.location ? workOrderInfo.error.location:''}
                    onChange={(e) => this.onFieldChange(e, 'location', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="registration"
                    label="Registration"
                    fullWidth
                    margin="normal"
                    value={workOrderInfo.data.registration ? workOrderInfo.data.registration:''}
                    InputLabelProps={{shrink: true}}
                    error={workOrderInfo.error.registration ? true:false}
                    helperText={workOrderInfo.error.registration ? workOrderInfo.error.registration:''}
                    onChange={(e) => this.onFieldChange(e, 'registration', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="line_number"
                    label="Line Number"
                    fullWidth
                    margin="normal"
                    value=''
                    value={workOrderInfo.data.line_number ? workOrderInfo.data.line_number:''}
                    InputLabelProps={{shrink: true}}
                    error={workOrderInfo.error.registration ? true:false}
                    helperText={workOrderInfo.error.line_number ? workOrderInfo.error.line_number:''}
                    onChange={(e) => this.onFieldChange(e, 'line_number', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="normal"
                       id="status_as_of"
                       label="Status As Of"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture={true}
                       clearable={true}
                       InputLabelProps={{shrink: true}}
                       inputProps={{readOnly: true}}
                       value={workOrderInfo.data.status_as_of ? workOrderInfo.data.status_as_of:null}
                       onChange={(data, value) => {this.onFieldChange(value, 'status_as_of', data ? moment(data).format(backendDateFormat):data);}}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="operator_storage_facility"
                    label="Operator Storage Facility"
                    fullWidth
                    margin="normal"
                    value={workOrderInfo.data.operator_storage_facility ? workOrderInfo.data.operator_storage_facility:''}
                    InputLabelProps={{shrink: true}}
                    error={workOrderInfo.error.operator_storage_facility ? true:false}
                    helperText={workOrderInfo.error.operator_storage_facility ? workOrderInfo.error.operator_storage_facility:''}
                    onChange={(e) => this.onFieldChange(e, 'operator_storage_facility', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="aoc_regulation"
                    label="AOC Regulation"
                    fullWidth
                    margin="normal"
                    value={workOrderInfo.data.aoc_regulation ? workOrderInfo.data.aoc_regulation:''}
                    InputLabelProps={{shrink: true}}
                    error={workOrderInfo.error.aoc_regulation ? true:false}
                    helperText={workOrderInfo.error.aoc_regulation ? workOrderInfo.error.aoc_regulation:''}
                    onChange={(e) => this.onFieldChange(e, 'aoc_regulation', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="no_of_gears"
                    label="No. of Landing Gear"
                    fullWidth
                    margin="normal"
                    value={workOrderInfo.data.no_of_gears ? workOrderInfo.data.no_of_gears:''}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'no_of_gears', e.target.value): e.preventDefault()} }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options = {inspectionTypes}
                    getOptionLabel={option => option.name}
                    id="inspection_types"
                    value={workOrderInfo.data.inspection_types ? inspectionTypes.find(insp => insp.id === workOrderInfo.data.inspection_types):null}
                    onChange={(e, value) => this.onFieldChange(e, 'inspection_types', value ?value.id :value)}
                    renderInput={params => <TextField required {...params} error={workOrderInfo.error.inspection_types ? true:false} helperText={workOrderInfo.error.inspection_types ? workOrderInfo.error.inspection_types:''} label="Inspection Types" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options = {forms}
                    getOptionLabel={option => option.name}
                    id="forms"
                    multiple
                    value={workOrderInfo.data.forms ? forms.filter(form => workOrderInfo.data.forms.includes(form.id)):[]}
                    onChange={(e, value) => this.onFieldChange(e, 'forms', value ? value.map(form => form.id) :value)}
                    renderInput={params => <TextField required {...params} error={workOrderInfo.error.inspection_types ? true:false} helperText={workOrderInfo.error.inspection_types ? workOrderInfo.error.inspection_types:''} label="Forms" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div className="" style={{background: '#ecebeb', padding:'15px'}}>
                        <h4>No of Galleys</h4>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="no_of_gears_fwd"
                              label="Fwd Area"
                              fullWidth
                              margin="normal"
                              value={workOrderInfo.data.no_of_galleys && workOrderInfo.data.no_of_galleys.fwd ? workOrderInfo.data.no_of_galleys.fwd:''}
                              InputLabelProps={{shrink: true}}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'no_of_galleys', e.target.value, 'fwd'): e.preventDefault() } }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="no_of_gears_mid"
                              label="Mid Area"
                              fullWidth
                              margin="normal"
                              value={workOrderInfo.data.no_of_galleys && workOrderInfo.data.no_of_galleys.mid ? workOrderInfo.data.no_of_galleys.mid:''}
                              InputLabelProps={{shrink: true}}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'no_of_galleys', e.target.value, 'mid'):e.preventDefault()} }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="no_of_gears_aft"
                              label="Aft Area"
                              fullWidth
                              margin="normal"
                              value={workOrderInfo.data.no_of_galleys && workOrderInfo.data.no_of_galleys.aft ? workOrderInfo.data.no_of_galleys.aft:''}
                              InputLabelProps={{shrink: true}}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'no_of_galleys', e.target.value, 'aft'): e.preventDefault()} }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="" style={{background: '#ecebeb', padding:'15px'}}>
                        <h4>No of Lavatories</h4>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="no_of_lavatories_fwd"
                              label="Fwd Area"
                              fullWidth
                              margin="normal"
                              value={workOrderInfo.data.no_of_lavatories && workOrderInfo.data.no_of_lavatories.fwd ? workOrderInfo.data.no_of_lavatories.fwd:''}
                              InputLabelProps={{shrink: true}}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'no_of_lavatories', e.target.value, 'fwd') : e.preventDefault() } }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="no_of_lavatories_mid"
                              label="Mid Area"
                              fullWidth
                              margin="normal"
                              value={workOrderInfo.data.no_of_lavatories && workOrderInfo.data.no_of_lavatories.mid ? workOrderInfo.data.no_of_lavatories.mid:''}
                              InputLabelProps={{shrink: true}}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'no_of_lavatories', e.target.value, 'mid') : e.preventDefault()} }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="no_of_lavatories_aft"
                              label="Aft Area"
                              fullWidth
                              margin="normal"
                              value={workOrderInfo.data.no_of_lavatories && workOrderInfo.data.no_of_lavatories.aft ? workOrderInfo.data.no_of_lavatories.aft:''}
                              InputLabelProps={{shrink: true}}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e, 'no_of_lavatories', e.target.value, 'aft') : e.preventDefault() } }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <AssetInspectionDate
                  workOrderInfo={workOrderInfo}
                  onFieldChange={this.onFieldChange}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={(e) => {this.toggleModalFn({data:{}, error:{}, modal:false, mode:''}); (workOrder && workOrder.slug) ? e.preventDefault():this.props.toggleWorkOrderFn()}}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={() => this.addEditWorkOrderApi(this.props, workOrderInfo)}>Save</Button>
            </DialogActions>
          </Dialog>:null
        }
        { pageLoader ? <PageLoader />:null}
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  aircraftTypes: state.shareReducer.aircraftTypes,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: () => dispatch(getAircraftTypesAc()),
  }
}
export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AircraftCRU)))
