import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
const ReadPrerequisite = ({content}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  return(
    <Fragment>
      <span style={{float:'right'}} onClick={handleClickOpen}> Read Prerequisite</span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          Prerequisite
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="read-prerequisite-modal">
            {content}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Okay</Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  )
}
export default ReadPrerequisite;
