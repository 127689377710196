import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import { TableRowActions } from '../../../../shared_elements';
import moment from 'moment';
import { displayDateFormatShort } from '../../../../constants';
const ShopVisitList = ({inspectionHdInfo, item, previewDetail}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        {item.shop_visit_number}
      </TableCell>
      <TableCell>
      {item.date_of_removal ? moment(item.date_of_removal).format(displayDateFormatShort):'--'}
      </TableCell>
      <TableCell>
        {item.reason_for_removal ? item.reason_for_removal:'--'}
      </TableCell>
      <TableCell>
        {item.shop_facility ? item.shop_facility:'--'}
      </TableCell>
      <TableCell>
        {item.induction_date ? moment(item.induction_date).format(displayDateFormatShort):'--'}
      </TableCell>
      <TableCell className="actions-cell">
        <TableRowActions
          actions={ inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ? ['E','D']:['V']}
          previewDetail={(mode) => previewDetail(mode)}
        />
      </TableCell>
    </TableRow>
  )
}
export default ShopVisitList;
