import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
const MrAndInvoiceDetails = ({engine, onFieldChange}) => {
  return(
    <div className="">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options = {[{label:'Yes',value:1},{label:'No',value:0}]}
            getOptionLabel={option => option.label}
            id="qme"
            value={engine.qme ? [{label:'Yes',value:'1'},{label:'No',value:'0'}].find(item => item.value == engine.qme):null}
            onChange={(e, value) => onFieldChange(e, 'qme', value ? value.value :value)}
            renderInput={params => <TextField {...params} label="QME" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options = {[{label:'Yes',value:1},{label:'No',value:0}]}
            getOptionLabel={option => option.label}
            id="mr"
            value={engine.qme ? [{label:'Yes',value:'1'},{label:'No',value:'0'}].find(item => item.value == engine.mr):null}
            onChange={(e, value) => onFieldChange(e, 'mr', value ? value.value :value)}
            renderInput={params => <TextField {...params} label="MR" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options = {[{label:'Yes',value:1},{label:'No',value:0}]}
            getOptionLabel={option => option.label}
            id="lease_contract"
            value={engine.qme ? [{label:'Yes',value:'1'},{label:'No',value:'0'}].find(item => item.value == engine.lease_contract):null}
            onChange={(e, value) => onFieldChange(e, 'lease_contract', value ? value.value :value)}
            renderInput={params => <TextField {...params} label="LC" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options = {[{label:'Yes',value:'1'},{label:'No',value:'0'}]}
            getOptionLabel={option => option.label}
            id="end_of_lease"
            value={engine.qme ? [{label:'Yes',value:'1'},{label:'No',value:'0'}].find(item => item.value == engine.end_of_lease):null}
            onChange={(e, value) => onFieldChange(e, 'end_of_lease', value ? value.value :value)}
            renderInput={params => <TextField {...params} label="EOL" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="ratio"
            label="Hours/Cycles Ratio"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 20}}
            value={engine.ratio ? engine.ratio:''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'ratio', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="mr_available_for_erp"
            label="MR Available For EPR"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 50}}
            value={engine.mr_available_for_erp ? engine.mr_available_for_erp:''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'mr_available_for_erp', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="mr_available_for_llp"
            label="MR Available For LLP"
            fullWidth
            margin="normal"
            inputProps={{maxLength: 50}}
            value={engine.mr_available_for_llp ? engine.mr_available_for_llp:''}
            InputLabelProps={{shrink: true}}
            onChange={(e) => onFieldChange(e, 'mr_available_for_llp', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="as_on_date"
               label="As On Date"
               format={fieldDateFormat}
               fullWidth
               disableFuture={true}
               clearable={true}
               InputLabelProps={{shrink: true}}
               inputProps={{readOnly: true}}
               value={engine.as_on_date ? engine.as_on_date:null}
               onChange={(data, value) => {onFieldChange(value, 'as_on_date', data ? moment(data).format(backendDateFormat):data);}}
             />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </div>
  )
}
export default MrAndInvoiceDetails;
