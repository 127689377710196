import React from 'react';
import moment from 'moment';
import { TableRow, TableCell, TextField, Tooltip, IconButton } from '@material-ui/core';
import { TableRowActions } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants';
import GetAppIcon from '@material-ui/icons/GetApp';
const Attachment = ({item, onFieldChange, previewDetail}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        <TextField
          id="description"
          fullWidth
          margin="normal"
          value={item.description ? item.description:''}
          InputLabelProps={{shrink: true}}
          onChange={(e) => onFieldChange(e, 'description', e.target.value)}
        />
      </TableCell>
      <TableCell>{item.created_at ? moment(item.created_at).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.user.name}</TableCell>
      <TableCell className="actions-cell" style={{width: '120px'}}>
        <TableRowActions
          actions={['D']}
          previewDetail={(mode) => previewDetail(mode)}
        />
        <Tooltip title="Open Link" arrow>
          <IconButton size="small" color="primary">
            <GetAppIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
export default Attachment;
