import React from 'react';
import { TextField } from '@material-ui/core';
export default function TextComp({label, keyParam, value, disabled, onFieldChange}){
  return(
    <TextField
      id={keyParam}
      label={label}
      fullWidth
      margin="normal"
      value={value}
      onChange={(e) => onFieldChange(e, keyParam, e.target.value)}
      helperText=''
      InputLabelProps={{shrink: true}}
      disabled={disabled}
    />
  )
}
