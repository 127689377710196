import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { LabelValueCard } from '../../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { regexConstants } from '../../../../constants/regEx';
export default function ShopVisitCard({inspectionHdInfo, mode, shopVisit, error, onFieldChange}){
  return(
    <Grid container spacing={2} style={{marginBottom: '20px'}}>
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="shop_visit_number"
            label="Shop Visit Number"
            fullWidth
            margin="normal"
            value={shopVisit.shop_visit_number ? shopVisit.shop_visit_number:''}
            onChange={(e, value) => onFieldChange(e, 'shop_visit_number', e.target.value)}
            error={error.shop_visit_number ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='Shop Visit Number' value={shopVisit.shop_visit_number ? shopVisit.shop_visit_number:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="date_of_removal"
               label="Date of Removal"
               format={fieldDateFormat}
               fullWidth
               disableFuture={true}
               inputProps={{readOnly: true}}
               clearable={true}
               InputLabelProps={{shrink: true}}
               value={shopVisit.date_of_removal ? shopVisit.date_of_removal:null}
               onChange={(data, value) => onFieldChange(value, 'date_of_removal', moment(data).format(backendDateFormat))}
             />
          </MuiPickersUtilsProvider>
        </Grid>:
        <LabelValueCard md={4} label='Date of Removal' value={shopVisit.shop_visit_number ? shopVisit.shop_visit_number:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="reason_for_removal"
            label="Reason of Removal"
            fullWidth
            margin="normal"
            value={shopVisit.reason_for_removal ? shopVisit.reason_for_removal:''}
            onChange={(e, value) => onFieldChange(e, 'reason_for_removal', e.target.value)}
            error={error.reason_for_removal ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='Reason of Removal' value={shopVisit.reason_for_removal ? shopVisit.reason_for_removal:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="shop_facility"
            label="Shop Facility"
            fullWidth
            margin="normal"
            value={shopVisit.shop_facility ? shopVisit.shop_facility:''}
            onChange={(e, value) => onFieldChange(e, 'shop_facility', e.target.value)}
            error={error.shop_facility ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='Shop Facility' value={shopVisit.shop_facility ? shopVisit.shop_facility:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="tsn"
            label="TSN"
            fullWidth
            margin="normal"
            value={shopVisit.tsn ? shopVisit.tsn:''}
            onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value): e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.tsn ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='TSN' value={shopVisit.tsn ? shopVisit.tsn:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="csn"
            label="CSN"
            fullWidth
            margin="normal"
            value={shopVisit.csn ? shopVisit.csn:''}
            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value):e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.csn ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='CSN' value={shopVisit.csn ? shopVisit.csn:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="tslsv"
            label="TSLSV"
            fullWidth
            margin="normal"
            value={shopVisit.tslsv ? shopVisit.tslsv:''}
            onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tslsv', e.target.value): e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.tslsv ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='TSLSV' value={shopVisit.tslsv ? shopVisit.tslsv:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="cslsv"
            label="CSLSV"
            fullWidth
            margin="normal"
            value={shopVisit.cslsv ? shopVisit.cslsv:''}
            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cslsv', e.target.value):e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.cslsv ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='CSLSV' value={shopVisit.cslsv ? shopVisit.cslsv:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="tsr"
            label="TSR"
            fullWidth
            margin="normal"
            value={shopVisit.tsr ? shopVisit.tsr:''}
            onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsr', e.target.value): e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.tsr ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='TSR' value={shopVisit.tsr ? shopVisit.tsr:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="csr"
            label="CSR"
            fullWidth
            margin="normal"
            value={shopVisit.csr ? shopVisit.csr:''}
            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csr', e.target.value):e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.csr ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='CSR' value={shopVisit.csr ? shopVisit.csr:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="tso"
            label="TSO"
            fullWidth
            margin="normal"
            value={shopVisit.tso ? shopVisit.tso:''}
            onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tso', e.target.value): e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.tso ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='TSO' value={shopVisit.tso ? shopVisit.tso:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={4}>
          <TextField
            id="cso"
            label="CSO"
            fullWidth
            margin="normal"
            value={shopVisit.cso ? shopVisit.cso:''}
            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cso', e.target.value):e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.cso ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={4} label='CSO' value={shopVisit.cso ? shopVisit.cso:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <TextField
            id="tshi"
            label="TSHSI"
            fullWidth
            margin="normal"
            value={shopVisit.tshi ? shopVisit.tshi:''}
            onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tshi', e.target.value): e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.tshi ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={3} label='TSHSI' value={shopVisit.tshi ? shopVisit.tshi:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <TextField
            id="cshi"
            label="CSHSI"
            fullWidth
            margin="normal"
            value={shopVisit.cshi ? shopVisit.cshi:''}
            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cshi', e.target.value):e.preventDefault()}}
            inputProps={{ maxLength: 10 }}
            error={error.cshi ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={3} label='CSHSI' value={shopVisit.cshi ? shopVisit.cshi:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="induction_date"
               label="Engine Induction Date"
               format={fieldDateFormat}
               fullWidth
               disableFuture={true}
               inputProps={{readOnly: true}}
               clearable={true}
               InputLabelProps={{shrink: true}}
               value={shopVisit.induction_date ? shopVisit.induction_date:null}
               onChange={(data, value) => onFieldChange(value, 'induction_date', moment(data).format(backendDateFormat))}
             />
          </MuiPickersUtilsProvider>
        </Grid>:
        <LabelValueCard md={3} label='Engine Induction Date' value={shopVisit.induction_date ? moment(shopVisit.induction_date).format(displayDateFormatShort):'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <TextField
            id="final_workscope"
            label="Engine Workscope"
            fullWidth
            margin="normal"
            value={shopVisit.final_workscope ? shopVisit.final_workscope:''}
            onChange={(e, value) => onFieldChange(e, 'final_workscope', e.target.value)}
            error={error.final_workscope ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={3} label='Engine Workscope' value={shopVisit.final_workscope ? shopVisit.final_workscope:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <TextField
            id="build_target"
            label="Engine Build Target"
            fullWidth
            margin="normal"
            value={shopVisit.build_target ? shopVisit.build_target:''}
            onChange={(e, value) => onFieldChange(e, 'build_target', e.target.value)}
            error={error.build_target ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={3} label='Engine Build Target' value={shopVisit.build_target ? shopVisit.build_target:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <TextField
            id="test_run_egt_margin"
            label="Test Run EGT Margin"
            fullWidth
            margin="normal"
            value={shopVisit.test_run_egt_margin ? shopVisit.test_run_egt_margin:''}
            onChange={(e, value) => onFieldChange(e, 'test_run_egt_margin', e.target.value)}
            error={error.test_run_egt_margin ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={3} label='Test Run EGT Margin' value={shopVisit.test_run_egt_margin ? shopVisit.test_run_egt_margin:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <TextField
            id="llp_status"
            label="LLP Status"
            fullWidth
            margin="normal"
            value={shopVisit.llp_status ? shopVisit.llp_status:''}
            onChange={(e, value) => onFieldChange(e, 'llp_status', e.target.value)}
            error={error.llp_status ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={3} label='LLP Status' value={shopVisit.llp_status ? shopVisit.llp_status:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="arc_date"
               label="ARC Date ( EASA / FAA)"
               format={fieldDateFormat}
               fullWidth
               disableFuture={true}
               inputProps={{readOnly: true}}
               clearable={true}
               InputLabelProps={{shrink: true}}
               value={shopVisit.arc_date ? shopVisit.arc_date:null}
               onChange={(data, value) => onFieldChange(value, 'arc_date', moment(data).format(backendDateFormat))}
             />
          </MuiPickersUtilsProvider>
        </Grid>:
        <LabelValueCard md={3} label='ARC Date ( EASA/FAA)' value={shopVisit.arc_date ? moment(shopVisit.arc_date).format(displayDateFormatShort):'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <TextField
            id="total_air_temperature"
            label="TAT"
            fullWidth
            margin="normal"
            value={shopVisit.total_air_temperature ? shopVisit.total_air_temperature:''}
            onChange={(e, value) => onFieldChange(e, 'total_air_temperature', e.target.value)}
            error={error.total_air_temperature ? true:false }
            InputLabelProps={{shrink: true}}
          />
        </Grid>:
        <LabelValueCard md={3} label='TAT' value={shopVisit.total_air_temperature ? shopVisit.total_air_temperature:'--'} />
      }
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               margin="normal"
               id="date_of_reinstallation"
               label="Date of Reinstallation"
               format={fieldDateFormat}
               fullWidth
               disableFuture={true}
               inputProps={{readOnly: true}}
               clearable={true}
               InputLabelProps={{shrink: true}}
               value={shopVisit.date_of_reinstallation ? shopVisit.date_of_reinstallation:null}
               onChange={(data, value) => onFieldChange(value, 'date_of_reinstallation', moment(data).format(backendDateFormat))}
             />
          </MuiPickersUtilsProvider>
        </Grid>:
        <LabelValueCard md={3} label='Date of Reinstallation' value={shopVisit.date_of_reinstallation ? moment(shopVisit.date_of_reinstallation).format(displayDateFormatShort):'--'} />
      }
    </Grid>
  )
}
