import React, { Fragment } from 'react';
import { Button, Grid, TextField, Tooltip, IconButton } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CkEditorText, TableListComp, EmptyCollection } from '../../../shared_elements';
import Attachment from './Attachment';
import moment from 'moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
const ShopVisits = ({index, shopVisit, onFieldChange, onSVFieldChange, uploadSVAttachmentFn, removeShopVisitFn, removeSVAttachmentFn, error, handleError, resetErrorKey}) => {
  return(
    <Fragment>
      <div className="shop-visit-card">
        <span>
          <Tooltip title="Delete" arrow>
            <IconButton onClick={removeShopVisitFn} size="small" color="primary" >
              <DeleteOutlineIcon fontSize="small" color="secondary" />
            </IconButton>
          </Tooltip>
        </span>
        <h3>ShopVisit #{index}</h3>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <TextField
              id="shop_visit_number"
              label="Shop Visit Number"
              fullWidth
              required
              margin="normal"
              inputProps={{maxLength: 20}}
              value={shopVisit.shop_visit_number?shopVisit.shop_visit_number:''}
              onChange={(e) => onFieldChange(e, 'shop_visit_number', e.target.value)}
              error={error.shop_visit_number ? true:false}
              helperText={error.shop_visit_number ? error.shop_visit_number:''}
              InputLabelProps={{shrink: true}}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="date_of_removal"
                 label="Date of Removal"
                 format={fieldDateFormat}
                 fullWidth
                 disableFuture={true}
                 clearable={true}
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 value={shopVisit.date_of_removal?shopVisit.date_of_removal:null}
                 onChange={(data, value) => {onFieldChange(value, 'date_of_removal', data ? moment(data).format(backendDateFormat):data);}}
               />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="reason_for_removal"
              label="Reason of Removal"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 255}}
              value={shopVisit.reason_for_removal?shopVisit.reason_for_removal:''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange(e, 'reason_for_removal', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="shop_facility"
              label="Shop Facility"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 30}}
              value={shopVisit.shop_facility?shopVisit.shop_facility:''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange(e, 'shop_facility', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="tsn"
              label="TSN"
              fullWidth
              required
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.tsn?shopVisit.tsn:''}
              InputLabelProps={{shrink: true}}
              error={error.tsn ? true:false}
              helperText={error.tsn ? error.tsn:''}
              onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value): e.preventDefault()}}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="csn"
              label="CSN"
              fullWidth
              required
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.csn?shopVisit.csn:''}
              InputLabelProps={{shrink: true}}
              error={error.csn ? true:false}
              helperText={error.csn ? error.csn:''}
              onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value) : e.preventDefault() }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="tslsv"
              label="TSLSV"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.tslsv?shopVisit.tslsv:''}
              InputLabelProps={{shrink: true}}
              onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tslsv', e.target.value): e.preventDefault()}}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="cslsv"
              label="CSLSV"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.cslsv?shopVisit.cslsv:''}
              InputLabelProps={{shrink: true}}
              onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cslsv', e.target.value) : e.preventDefault() }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="tsr"
              label="TSR"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.tsr?shopVisit.tsr:''}
              InputLabelProps={{shrink: true}}
              onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsr', e.target.value): e.preventDefault()}}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="csr"
              label="CSR"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.csr?shopVisit.csr:''}
              InputLabelProps={{shrink: true}}
              onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csr', e.target.value) : e.preventDefault() }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="tso"
              label="TSO"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.tso?shopVisit.tso:''}
              InputLabelProps={{shrink: true}}
              onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tso', e.target.value): e.preventDefault()}}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="cso"
              label="CSO"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.cso?shopVisit.cso:''}
              InputLabelProps={{shrink: true}}
              onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cso', e.target.value) : e.preventDefault() }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="tshi"
              label="TSHSI"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.tshi?shopVisit.tshi:''}
              InputLabelProps={{shrink: true}}
              onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tshi', e.target.value): e.preventDefault()}}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="cshi"
              label="CSHSI"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={shopVisit.cshi?shopVisit.cshi:''}
              InputLabelProps={{shrink: true}}
              onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cshi', e.target.value) : e.preventDefault() }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="induction_date"
                 label="Engine Induction Date"
                 format={fieldDateFormat}
                 fullWidth
                 disableFuture={true}
                 clearable={true}
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 value={shopVisit.induction_date?shopVisit.induction_date:null}
                 onChange={(data, value) => {onFieldChange(value, 'induction_date', data ? moment(data).format(backendDateFormat):data);}}
               />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="final_workscope"
              label="Engine Workscope"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 20}}
              value={shopVisit.final_workscope?shopVisit.final_workscope:''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange(e, 'final_workscope', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="build_target"
              label="Engine Build Target"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 20}}
              value={shopVisit.build_target?shopVisit.build_target:''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange(e, 'build_target', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="test_run_egt_margin"
              label="Test Run EGT Margin"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 20}}
              value={shopVisit.test_run_egt_margin?shopVisit.test_run_egt_margin:''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange(e, 'test_run_egt_margin', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="llp_status"
              label="LLP Status"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 20}}
              value={shopVisit.llp_status?shopVisit.llp_status:''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange(e, 'llp_status', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="arc_date"
                 label="ARC Date ( EASA/FAA)"
                 format={fieldDateFormat}
                 fullWidth
                 disableFuture={true}
                 clearable={true}
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 value={shopVisit.arc_date?shopVisit.arc_date:null}
                 onChange={(data, value) => {onFieldChange(value, 'arc_date', data ? moment(data).format(backendDateFormat):data);}}
               />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="total_air_temperature"
              label="TAT"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 20}}
              value={shopVisit.total_air_temperature?shopVisit.total_air_temperature:''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange(e, 'total_air_temperature', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="date_of_reinstallation"
                 label="Date of Reinstallation"
                 format={fieldDateFormat}
                 fullWidth
                 disableFuture={true}
                 clearable={true}
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 value={shopVisit.date_of_reinstallation?shopVisit.date_of_reinstallation:null}
                 onChange={(data, value) => {onFieldChange(value, 'date_of_reinstallation', data ? moment(data).format(backendDateFormat):data);}}
               />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <div className="">
          <div style={{float:'right', marginBottom:'20px'}}>
            <input
              id="contained-button-file"
              type="file"
              style={{display:'none'}}
              onChange={(e) => uploadSVAttachmentFn(e.target.files[0])}
            />
            <label htmlFor="contained-button-file">
              <Button variant="outlined" color="primary" size="small" component="span">
              Add Attachment
              </Button>
            </label>
          </div>
          <TableListComp
            heads={[
              {label:'File Name', sortOption:false},
              {label:'Description', sortOption:false},
              {label:'Uploaded on', sortOption:false},
              {label:'Uploaded by', sortOption:false},
              {label:'Action', sortOption:false, actionCell: true}
            ]}
            data={shopVisit.attachments.map((item,index) =>
              <Attachment
                item={item}
                onFieldChange={(event, keyParam, data) => onSVFieldChange(event, keyParam, data, index)}
                previewDetail={(mode) => removeSVAttachmentFn(index)}
              />
            )}
            noRecord={ shopVisit.attachments.length ? null:
              <EmptyCollection
                title="No records found"
              />
            }
          />
        </div>
      </div>
    </Fragment>
  )
}
export default ShopVisits;
