import React from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
const CountCard = ({count, label, classes}) => {
  return(
    <Grid item xs={12} md={2}>
      <Paper className={`prs-count ${classes}`}>
        <span></span>
        <h4>{count}</h4>
        <p>{label}</p>
      </Paper>
    </Grid>
  )
}
export default CountCard;
