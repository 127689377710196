import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const errorCode = {
  place_of_inspection:{
    0:'',
    1: 'Please enter Place of Inspection',
  },
  place_of_inspectionObj:{
    required: true,
  },
  date_of_inspection:{
    0:'',
    1: 'Please enter Date of Inspection',
  },
  date_of_inspectionObj:{
    required: true,
  },
  report_date:{
    0:'',
    1: 'Please enter Report Date',
  },
  report_dateObj:{
    required: true,
  },
  report_issued_place:{
    0:'',
    1: 'Please enter Report Issued Place',
  },
  report_issued_placeObj:{
    required: true,
  },
}
