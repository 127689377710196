import moment from 'moment';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
const upGalleys = [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169];
export function getAuditsSectionsApi(props={}, query={}){
  return new Promise(function(resolve, reject){
    globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/audit-report-sections/`, query)
    .then(response => {
      resolve(response);
    })
  })
}
export function exportAuditsSectionsApi(props={}, file={}){
  this.setState({pageLoader:true});
  globalExportService(`technical/workorder/${props.match.params.workOrderSlug}/audit-report-sections/`, {download:file.extension, consolidate:1})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE004_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.props.enqueueSnackbar('Inspection Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function getAuditSectionApi(props={}, item){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/section/${item.id}/questions/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      let sectionResult = response.data.data;
      if(sectionResult.length === 1){
        let customHots =  sectionResult[0].inspection_findings.filter(customs => customs.x_coordinate);
        this.setState(prevState => ({
          ...prevState,
          auditSectionCrud: {
            modal:true,
            title: item.name,
            data: sectionResult,
            obsIndex:0
          }
        }));
        this.plotCustomPlots(customHots)
      }else{
        if(sectionResult.map(section => section.id).some(id => upGalleys.includes(id))){
          let ids = sectionResult.map(section => section.id);
          let upperDeckIds = ids.filter(id => upGalleys.includes(id));
          let mainDeckIds = ids.filter(id => !upGalleys.includes(id));
          this.setState(prevState => ({
            ...prevState,
            auditSectionCrud: {
              modal:true,
              title: item.name,
              data: sectionResult,
              upperCabinTab:true,
              deckIndex: 0,
              maindeckIndex: mainDeckIds.length ? mainDeckIds[0]:null,
              upperdeckIndex: upperDeckIds.length ? upperDeckIds[0]:null,
              obsIndex:0
            }
          }))
        }else{
          this.setState(prevState => ({
            ...prevState,
            auditSectionCrud: {
              modal:true,
              title: item.name,
              data: sectionResult,
              tabIndex: 0,
              obsIndex:0
            }
          }))
        }
      }
    }
  })
}

export function editAuditSectionApi(props={}, data){
  this.setState({pageLoader:true});
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/section/${data[0].id}/questions/`, {data:data})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      // this.setState({auditSectionCrud: {modal:false, data:[]}});
      // this.getAuditsSectionsApi(props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});

    }
  })
}

export function deleteCustomComponentApi(props={}, sectionId, componentIndex, deleteIds){
  this.setState({pageLoader:true});
  globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/section/${sectionId}/custom-components/delete/`,{custom_component_ids: [deleteIds]})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, component_questions: section.component_questions.filter((component, innerIndex) => innerIndex !== componentIndex)
          } : section)
        }
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getInspectionQuestionAnsApi(props={}){
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-qa/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        inspectionQA: response.data.data.map((question, index) => question.answer && Object(question.answer).length ? {
          ...question,
          answer: {
            ...question.answer,
            value : [...question.answer.value].map(ansValue => ansValue.flag ?  {
              ...ansValue,
              flag: false
            } : ansValue )
          }
        }: question)
      }))
    }
  })
}
export function editInspectionQuestionAnsApi(props, question){
  globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-qa/${question.id}/`, question)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function getInspectionCoverPicApi(props){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-cover-image/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        lopa_image_url: response.data.data.lopa_image_url
      }))
    }
  })
}
export function uploadInspectionCoverPicApi(props, file){
  var formData = new FormData();
  formData.append('file',file[0], file[0]['name']);
  this.setState({pageLoader:true});
  globalFileUploadService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-cover-image/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        lopa_image_url: response.data.data.lopa_image_url
      }));
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function deleteFindingApi(props={}, deleteIds){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/aircraft-section/${deleteIds.sectionId}/inspection-findings/${deleteIds.id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === deleteIds.sectionId ? {
            ...section, inspection_findings: section.inspection_findings.filter(finding => finding.id !== deleteIds.id)
          } : section)
        },
        deleteModal:false,
        deleteIds:{}
      }));
      this.getAuditSectionApi(this.props, {id:this.state.sectionId, name:this.state.auditSectionCrud.title, status:''})
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function uploadLOPAImgApi(props={}, sectionId, file){
  var formData = new FormData();
  formData.append('file',file);
  formData.append('shop_visit',true);
  globalFileUploadService(`technical/engine-workorder/file-upload/`, formData)
  .then(response => {
    if(response.data.statusCode === 200){
      this.setState(prevState => ({
        ...prevState,
        auditSectionCrud: {
          ...prevState.auditSectionCrud,
          data: prevState.auditSectionCrud.data.map(section => section.id === sectionId ? {
            ...section, lopa:
            {
              ...section.lopa,
              image:response.data.url
            }
          } :section)
        }
      }));
    }
  })
}
