import moment from 'moment';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService, globalFileUploadPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, dataURItoBlob } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { findingErrorCode } from '../../';
const upGalleys = [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169];
export function getFindingSectionsApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-finding-sections/`, query)
  .then(response => {
    this.setState({pageLoader:false, skeletonLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        findingSections: response.data.data
      })
    }
  })
}
export function exportFindingSectionsApi(props={}, file={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-finding-sections/`, {download:file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE007_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.props.enqueueSnackbar('Pictorial Findings Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function getFindingsApi(props={}, item={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/aircraft-section/${item.id}/inspection-findings/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      let sectionResult = response.data.data
      if(sectionResult.length === 1){
        this.setState(prevState => ({
          ...prevState,
          physcialFinding: {
            modal:true,
            data:sectionResult,
            title: item.name
          }
        }))
      }else{
        if(sectionResult.map(section => section.id).some(id => upGalleys.includes(id))){
          let ids = sectionResult.map(section => section.id);
          let upperDeckIds = ids.filter(id => upGalleys.includes(id));
          let mainDeckIds = ids.filter(id => !upGalleys.includes(id));

          this.setState(prevState => ({
            ...prevState,
            physcialFinding: {
              modal:true,
              data:sectionResult,
              upperCabinTab:true,
              deckIndex: 0,
              maindeckIndex: mainDeckIds.length ? mainDeckIds[0]:null,
              upperdeckIndex: upperDeckIds.length ? upperDeckIds[0]:null
            }
          }))
        }else{
          this.setState(prevState => ({
            ...prevState,
            physcialFinding: {
              modal:true,
              data:sectionResult,
              tabIndex: 0
            }
          }))
        }
      }
    }
  })
}
export function getFindingApi(props={}, sectionId, findingId){
  return new Promise(
    function(resolve, reject){
      globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/aircraft-section/${sectionId}/inspection-findings/${findingId}/`)
      .then(response => {
        resolve(response);
      })
    }
  )
}
export function addEditFindingApi(props, sectionId, data, contentType){
  return new Promise(
    function(resolve, reject){
      let validationInputs = {
        finding_type:findingErrorCode['finding_type'][fieldValidation({...findingErrorCode['finding_typeObj'], fieldval: data.finding_type})],
        damage_dimension:findingErrorCode['damage_dimension'][fieldValidation({...findingErrorCode['damage_dimensionObj'], fieldval: data.damage_dimension})],
        damage_limit:findingErrorCode['damage_limit'][fieldValidation({...findingErrorCode['damage_limitObj'], fieldval: data.damage_limit})],
        remarks:findingErrorCode['remarks'][fieldValidation({...findingErrorCode['remarksObj'], fieldval: data.remarks})],
        dd_wo_reference:findingErrorCode['dd_wo_reference'][fieldValidation({...findingErrorCode['dd_wo_referenceObj'], fieldval: data.dd_wo_reference})],
        arm_srm_reference:findingErrorCode['arm_srm_reference'][fieldValidation({...findingErrorCode['arm_srm_referenceObj'], fieldval: data.arm_srm_reference})],
        corrective_actions:findingErrorCode['corrective_actions'][fieldValidation({...findingErrorCode['corrective_actionsObj'], fieldval: data.corrective_actions})],
        intervals:findingErrorCode['intervals'][fieldValidation({...findingErrorCode['intervalsObj'], fieldval: data.intervals})],
      }
      if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) || contentType === 'fileOnly'){
        let formData = new FormData();
        if(contentType === 'contentOnly'){
          formData.append('arm_srm_reference', data.arm_srm_reference ? data.arm_srm_reference:'');
          formData.append('damage_dimension', data.damage_dimension ? data.damage_dimension:'');
          formData.append('damage_limit', data.damage_limit ? data.damage_limit:'');
          formData.append('dd_wo_reference', data.dd_wo_reference?data.dd_wo_reference:'');
          formData.append('finding_type', data.finding_type ? data.finding_type:'');
          formData.append('remarks', data.remarks ? data.remarks:'');
          formData.append('corrective_actions', data.corrective_actions ? data.corrective_actions:'');
          formData.append('classifications', data.classifications ?data.classifications:'');
          formData.append('category', data.category ? data.category:'');
          formData.append('repair_type', data.repair_type ? data.repair_type:'');
          formData.append('intervals', data.intervals ? data.intervals:'');
          formData.append('x_coordinate', data.x_coordinate ? data.x_coordinate:'');
          formData.append('y_coordinate', data.x_coordinate ? data.y_coordinate:'');
          formData.append('z_coordinate', data.x_coordinate ? data.z_coordinate:'');
        }
        if(data.files){
          for(let i = 0; i < data.files.length; i++) {
             let file = data.files[i];
             formData.append('files[]', file);
          }
        }
        if(data.id){
          globalFileUploadPutService(`technical/workorder/${props.match.params.workOrderSlug}/aircraft-section/${sectionId}/inspection-findings/${data.id}/`, formData)
          .then(response => {
            resolve(response);
          })
        }else{
          globalFileUploadService(`technical/workorder/${props.match.params.workOrderSlug}/aircraft-section/${sectionId}/inspection-findings/`, formData)
          .then(response => {
            resolve(response);
          })
        }
      }else{
        resolve({data:{statusCode:100, error:validationInputs}})
      }
    }
  )
}
export function deleteFindingApi(props={}, deleteIds){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/aircraft-section/${deleteIds.sectionId}/inspection-findings/${deleteIds.id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        physcialFinding: {
          ...prevState.physcialFinding,
          data: prevState.physcialFinding.data.map(section => section.id === deleteIds.sectionId ? {
            ...section, findings: section.findings.filter(finding => finding.id !== deleteIds.id)
          } : section)
        },
        deleteModal:false,
        deleteIds:{}
      }));
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function deleteFindingImageApi(props={}, deleteIds){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-finding-picture/${deleteIds.id}/`)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function changeFindingImageApi(props={}, id, file){
  let formData = new FormData();
  formData.append('file', file);
  return new Promise(
    function(resolve, reject){
      globalFileUploadPutService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-finding-picture/${id}/`, formData)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function annotateFindingImgApi(props, file, annotated){
  debugger
  return new Promise(
    function(resolve, reject){
      dataURItoBlob(file).then(editedImage => {
        let formData = new FormData();
        formData.append('file', editedImage);
        globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/inspection-finding-picture/${annotated.data.id}/`, formData)
        .then(response => {
          resolve(response)
        })
      })
    }
  )
}
