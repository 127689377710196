import moment from 'moment';
import { globalGetService, globalPostService, globalExportService } from '../../utils/globalApiServices';
import { getLocalStorageInfo } from '../../utils';
import { checkApiStatus } from '../../utils';

export function getAnalyticResourceApi(props={}, query={}, loaderType){
  this.setState({[loaderType]: true});
  globalGetService(`technical/analytics/resource-tracker/`, query)
  .then(response => {
    this.setState({[loaderType]: false});
    if(checkApiStatus(response)){
      delete query.country_code
      this.setState({resourceDetail: response.data.data, filter: query})
    }
  })
}
export function getAnalyticProjectsApi(props={}, query={}, loaderType){
  this.setState({[loaderType]: true});
  globalGetService(`technical/analytics/project-tracker/`, query)
  .then(response => {
    this.setState({[loaderType]: false});
    if(checkApiStatus(response)){
      delete query.page;
      delete query.per_page;
      delete query.list;
      delete query.country_code;
      this.setState({projectsDetail: response.data.data, filter:query})
    }
  })
}
export function getAnalyticProjectsGrpApi(props={}, query={}, loaderType){
  this.setState({[loaderType]: true});
  globalGetService(`technical/analytics/project-tracker/`, query)
  .then(response => {
    this.setState({[loaderType]: false});
    if(checkApiStatus(response)){
      this.setState({projectsGrph:response.data.data})
    }
  })
}
export function getProjectTrackerApi(props={}, query={}, contentType=''){
  let filterQuery = Object.assign({}, query);
  delete filterQuery.project_date
  if(query.project_date && query.project_date.startDate){
    filterQuery = {
      ...filterQuery,
      startDate:query.project_date.startDate
    }
  }
  if(query.project_date && query.project_date.endDate){
    filterQuery = {
      ...filterQuery,
      endDate:query.project_date.endDate
    }
  }
  if(!(getLocalStorageInfo().user.is_root_user || ( getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')))){
    filterQuery  = {
      ...filterQuery,
      lessor_id:getLocalStorageInfo().defaultLessor.id
    }
  }
  if(contentType !== 'loadmore'){
    this.setState({[contentType]:true});
  }
  globalGetService(`technical/project-tracker/`, filterQuery)
  .then(response => {
    if(contentType !== 'loadmore'){
      this.setState({[contentType]:false});
    }
    if(checkApiStatus(response)){
      delete query.page;
      if(contentType === 'loadmore'){
        this.setState(prevState => ({
          ...prevState,
          projectTrackerDtls: {
            ...prevState.projectTrackerDtls,
            projects: [...prevState.projectTrackerDtls.projects, ...response.data.data.projects]
          },
          filter:query
        }))
      }else{
        this.setState({
          projectTrackerDtls: response.data.data,
          filter:query
        });
      }
    }
  })
}
export function exportProjectTrackerApi(props={}, file={}){
  globalGetService(`technical/project-tracker/`, {download:file.extension})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        pageLoader: false
      });
    }
  })
}
export function getProjectTrackerDetailApi(params={}, query={}){
  globalGetService(`technical/project-tracker/`, query)
  .then(response => {
    if(checkApiStatus(response.status)){

    }
  })
}
export function editProjectTrackerRemarksApi(params={}, data={}){
  globalPostService(`technical/project/${params.projectSlug}/update-remark/`, data)
  .then(response => {
    if(checkApiStatus(response.status)){

    }
  })
}
export function getCorTrackerApi(props={}, query={}, contentType=''){
  globalGetService(`technical/cor-tracker/`, query)
  .then(response => {
    this.setState({skeletonLoader:false})
    if(checkApiStatus(response)){
      if(contentType === 'loadmore'){
        this.setState(prevState => ({
          ...prevState,
          corTrackerInfo: {
            ...prevState.corTrackerInfo,
            projects:[...prevState.corTrackerInfo.projects, ...response.data.data.projects]
          }
        }))
      }else{
        this.setState({
          corTrackerInfo: response.data.data
        });
      }
    }
  })
}
export function exportCorTrackerFormApi(props={}, file={}){
  globalExportService(`technical/project/cor-tracker/`, {download:file.extension})
  .then(response => {
    if(checkApiStatus(response.status)){

    }
  })
}
export function getCorTrackerDetailApi(params={}, query={}){
  globalGetService(`technical/cor-tracker/`, query)
  .then(response => {
    if(checkApiStatus(response.status)){

    }
  })
}
export function getManPowerTrackerApi(props={}, query={}, contentType=''){
  let filterQuery = Object.assign({}, query);
  delete filterQuery.project_date
  if(query.project_date && query.project_date.startDate){
    filterQuery = {
      ...filterQuery,
      startDate:query.project_date.startDate
    }
  }
  if(query.project_date && query.project_date.endDate){
    filterQuery = {
      ...filterQuery,
      endDate:query.project_date.endDate
    }
  }
  if(contentType !== 'loadmore'){
    this.setState({[contentType]:true});
  }
  globalGetService(`technical/manpower-tracker/`, filterQuery)
  .then(response => {
    if(contentType !== 'loadmore'){
      this.setState({[contentType]:false});
    }
    if(checkApiStatus(response)){
      delete query.page;
      if(contentType === 'loadmore'){
        this.setState(prevState => ({
          ...prevState,
          manPowerTrackerDtls: {
            ...prevState.manPowerTrackerDtls,
            users: [...prevState.manPowerTrackerDtls.users, ...response.data.data.users]
          },
          filter:query
        }))
      }else{
        this.setState({
          manPowerTrackerDtls: response.data.data,
          filter:query
        })
      }
    }
  })
}
export function exportManPowerTrackerApi(props={}, file={}){
  globalGetService(`technical/manpower-tracker/`, {download:file.extension})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        pageLoader:false
      })
    }
  })
}
export function utilizationManPowerTrackerApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/manpower-tracker/`, {...query, utilization:true})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        utilizationInfo:response.data.data,
        modal:true,
        utilization_year:query.utilization_year ? query.utilization_year:null
      })
    }
  })
}
export function getTrackerRangeValueFn(csd, ced, psd, ped){
  /*
		PSD => Proejct Start Date
		PED => Proejct End Date
		CSD => Calendar Start Date
		CED => Calendar End Date
	*/
  if (( (moment(psd) == moment(csd)) && (moment(psd) == moment(ced)) )) {
    return moment.duration(moment(psd).diff(moment(ped))) > 0 ? true:false;
  }else if((moment(psd) >= moment(csd)) && (moment(ped) <= moment(ced))){
    return moment.duration(moment(ped).diff(moment(psd))) > 0 ?true:false;
  }else if ((moment(psd) <= moment(csd)) && (moment(ped) <= moment(ced) )) {
    return moment.duration(moment(ped).diff(moment(csd))) > 0 ? true:false;
  }else if ((moment(psd) <= moment(csd)) && (moment(ped) >= moment(ced) )) {
    return moment.duration(moment(ced).diff(moment(csd))) > 0 ? true:false;
  }else if (( (moment(psd) >= moment(csd)) && (moment(ped) >= moment(ced)))){
    return moment.duration(moment(ced).diff(moment(psd))) > 0 ? true:false;
  }else if ((moment(psd) >= moment(csd)) && (moment(ped) <= moment(ced))) {
    return moment.duration(moment(ped).diff(moment(csd))) > 0 ? true:false;
  }else{
    return null
  }
}
