import React, { Component, Fragment } from 'react';
import { InspectionFormWrapper, ReviewIssuesComp } from '../../Elements';
import { TableListComp,  EmptyCollection, PageLoader } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { ContactList } from '../components';
import { getFormReviewListApi } from '../apiServices';
import { getInspectionHeaderApi } from '../../forms/t001/apiServices';
class InspectionReviewT006 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: true,
      inspectionHdInfo:{},
      t006ReviewList: {
        list:[],
        pagination:{}
      }
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.getFormReviewListApi = getFormReviewListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getFormReviewListApi(this.props, 't006ReviewList');
  }
  render(){
    const { t006ReviewList, inspectionHdInfo, skeletonLoader } = this.state;
    return(
      <Fragment>
        <InspectionFormWrapper reviewerForm={true} skeletonLoader={skeletonLoader} inspectionHdInfo={inspectionHdInfo} />
        <div className="technical-review-forms-cn">
          { skeletonLoader ? <STableLoader count={6} />:
            (<div className="t006-section">
              <TableListComp
                heads={[
                  { label: 'Name', sortOption: false },
                  { label: 'Email', sortOption: false },
                  { label: 'Designation', sortOption: false },
                  { label: 'Phone Number', sortOption: false },
                  { label: 'Fax', sortOption: false },
                ]}
                data={t006ReviewList.list.map((item,index) =>
                  <ContactList
                    key={index}
                    item={item}
                    loadSectionFn={() => this.getFormReviewListApi(this.props, 't006ReviewList')}
                  />
                )}
                noRecord={ t006ReviewList.list.length ? null:
                  <EmptyCollection title="No records found" />
                }
              />
            </div>)
          }

        </div>
      </Fragment>
    )
  }
}
export default InspectionReviewT006;
