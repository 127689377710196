import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import {DropzoneArea} from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, Button,Grid, TextField, CircularProgress, Tabs, Tab } from '@material-ui/core';
import { FindingsTable, DropDownComp, TextComp, DateComp } from '../../../Elements';
import LOPA from './LOPA';
import Observations from './Observations';
import QuestionsAnswers from './QuestionsAnswers';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
let upGalleys = [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169];
export default function FabrikAudit({inspectionHdInfo, auditSectionCrud, toggleModalFn, handleTabChange, onObservationChange, editAuditSectionFn, onFieldChange, addDynamicComponent, removeDynamicComponent, getFindingResponse, deleteFindingFn, uploadLOPAImgFn, onLOPARemarksChange, triggerNewPoint, previewNextPrev,onChangeSection, hotSpotsList}){
  const readTheAnswer = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if(answer.length){
      return answer[0].value
    }
  }
  return(
    <Paper style={{height:window.innerHeight-244, overflowX:'auto'}} className="t004-finding-specs-modal">
      <div style={{padding:'10px', color:'#bd10e0'}}>
        <Button variant="outlined" color="primary" size="small" onClick={toggleModalFn} style={{float:'right', cursor:'pointer'}}>Back to Initial Step</Button>
        <Autocomplete
          disableClearable={true}
          options={hotSpotsList}
          getOptionLabel={option => option.title}
          id="finding_type"
          value={hotSpotsList.length ? hotSpotsList.find(item => item.title == auditSectionCrud.title):null}
          renderInput={params => <TextField {...params} label="" margin="none" fullWidth InputLabelProps={{shrink: true}} />}
          onChange={(e, value) => onChangeSection(e, value)}
        />
      </div>
      { auditSectionCrud.data.length <= 1 ?
        <div>
          { Object.keys(auditSectionCrud.data[0].lopa).length ?
            <div className="">
              <h4>LOPA</h4>
              <ul className="list-inline">
                { auditSectionCrud.data[0].lopa.image ?
                  <li className="list-inline-item">
                    <div className="dropped-pic-card" style={{backgroundImage: "url(" + auditSectionCrud.data[0].lopa.image + ")"}}>
                      <div className="overlay"></div>
                      <div className="dropped-picture-cta">
                        <Fragment>
                          <span>
                            <input
                              accept="image/*"
                              id={`contained-button-file${auditSectionCrud.data[0].id}`}
                              type="file"
                              style={{display:'none'}}
                              onChange={(e) => uploadLOPAImgFn(auditSectionCrud.data[0].id, e.target.files[0])}
                            />
                            <label htmlFor={`contained-button-file${auditSectionCrud.data[0].id}`}>Change</label>
                          </span>
                        </Fragment>
                      </div>
                    </div>
                  </li>:null
                }

                { !auditSectionCrud.data[0].lopa.image ?
                  <li className="list-inline-item">
                    <div style={{width:'250px'}}>
                      <DropzoneArea
                        filesLimit={1}
                        maxFileSize={10000000}
                        showPreviewsInDropzone={false}
                        acceptedFiles={['image/*']}
                        dropzoneClass="drag-drop-cnt"
                        showAlerts={['error', 'info']}
                        onDrop={(files) => uploadLOPAImgFn(auditSectionCrud.data[0].id, files[0])}
                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                      />
                    </div>
                  </li>:null
                }
              </ul>
              <TextField
                id="remarks"
                label="Remarks"
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 255 }}
                value={auditSectionCrud.data[0].lopa.remarks ? auditSectionCrud.data[0].lopa.remarks:''}
                InputLabelProps={{shrink: true}}
                onChange={(e) => onLOPARemarksChange('remarks', e.target.value, auditSectionCrud.data[0].id)}
              />
            </div>:null
          }
          <QuestionsAnswers onFieldChange={onFieldChange}  inspectionHdInfo={inspectionHdInfo} section={auditSectionCrud.data[0]} addDynamicComponent={addDynamicComponent} removeDynamicComponent={removeDynamicComponent} />
          <Tabs
            value={auditSectionCrud.obsIndex}
            onChange={(event, newValue) => handleTabChange(event, newValue, 'obsIndex')}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons={isMobile ? 'on':'auto'}
          >
            <Tab label='Observation' />
            <Tab label='Finding' />
          </Tabs>

          {auditSectionCrud.obsIndex === 0 &&
            <div style={{padding:'0 15px'}} className="table-responsive observation-table">
              <table style={{width:'100%'}} className="form-table" cellSpacing={0} cellPadding={0}>
                <tbody style={{marginBottom:'10px'}}>
                  {auditSectionCrud.data[0].physical_questions.map((observation, index) =>
                    <Observations observation={observation} onObservationChange={(keyParam, value) => onObservationChange(keyParam, value, auditSectionCrud.data[0].id, index)} />
                  )}
                  <tr>
                    <td colSpan={2}>
                      <TextField
                        id="additional_remarks"
                        label="Additional Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="3"
                        value={readTheAnswer(auditSectionCrud.data[0].answers, 'additional_remarks')}
                        onChange={(e) => onFieldChange({} ,'additional_remarks', e.target.value, 'specs', auditSectionCrud.data[0].id)}
                        InputLabelProps={{shrink: true}}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Grid container spacing={2}>
                <Grid item md={6} style={{marginTop:'10px'}}>
                  <div >
                    <ul className="list-inline">
                      <li className="list-inline-item" style={{marginRight:'5px'}}>
                        <Button color="primary" variant="contained" onClick={editAuditSectionFn} >Save</Button>
                      </li>
                      <li className="list-inline-item">
                        <Button color="primary" variant="outlined" onClick={toggleModalFn}>Cancel</Button>
                      </li>
                    </ul>
                  </div>
                </Grid>

              </Grid>
            </div>
          }
          {auditSectionCrud.obsIndex === 1 &&
            <div style={{padding:'0 15px', marginBottom:'30px'}}>
              <FindingsTable
                sectionId={auditSectionCrud.data[0].id}
                findings={auditSectionCrud.data[0].inspection_findings}
                getFindingResponse={getFindingResponse}
                previewDetail={(mode, id) => deleteFindingFn(id, auditSectionCrud.data[0].id)}
                triggerNewPoint={triggerNewPoint}
              />
            </div>
          }
        </div>:
        auditSectionCrud.upperCabinTab ?
        <div>
          <Tabs
            value={auditSectionCrud.deckIndex}
            onChange={(event, newValue) => handleTabChange(event, newValue, 'deckIndex')}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons={isMobile ? 'on':'auto'}
          >
            <Tab label='Main Deck' />
            <Tab label='Upper Deck' />
          </Tabs>
          { auditSectionCrud.deckIndex === 0 ?
            <div>
              <Tabs
                value={auditSectionCrud.maindeckIndex}
                onChange={(event, newValue) => handleTabChange(event, newValue, 'maindeckIndex')}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons={isMobile ? 'on':'auto'}
              >
                { auditSectionCrud.data.map((section, index) =>
                  !upGalleys.includes(section.id) &&
                  <Tab label={section.name} value={section.id} />
                )}
              </Tabs>
              { auditSectionCrud.data.map((section, sectionIndex) =>
                !upGalleys.includes(section.id) && section.id === auditSectionCrud.maindeckIndex &&
                <div>
                  <QuestionsAnswers onFieldChange={onFieldChange} inspectionHdInfo={inspectionHdInfo} section={section} addDynamicComponent={addDynamicComponent} removeDynamicComponent={removeDynamicComponent} />
                  <Tabs
                    value={auditSectionCrud.obsIndex}
                    onChange={(event, newValue) => handleTabChange(event, newValue, 'obsIndex')}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons={isMobile ? 'on':'auto'}
                  >
                    <Tab label='Observation' />
                    <Tab label='Finding' />
                  </Tabs>
                  { auditSectionCrud.obsIndex === 0 ?
                    <div className="table-responsive observation-table">
                      <table style={{width:'100%'}} className="form-table" cellSpacing={0} cellPadding={0}>
                        <tbody>
                          {section.physical_questions.map((observation, index) =>
                            <Observations observation={observation} onObservationChange={(keyParam, value) => onObservationChange(keyParam, value, section.id, index)} />
                          )}
                          <tr>
                            <td colSpan={2}>
                              <TextField
                                id="additional_remarks"
                                label="Additional Remarks"
                                fullWidth
                                margin="normal"
                                multiline
                                rows="3"
                                value={readTheAnswer(section.answers, 'additional_remarks')}
                                onChange={(e) => onFieldChange({} ,'additional_remarks', e.target.value, 'specs', section.id)}
                                InputLabelProps={{shrink: true}}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>:null
                  }
                  { auditSectionCrud.obsIndex === 1 ?
                    <div>
                      <FindingsTable
                        sectionId={section.id}
                        findings={section.inspection_findings}
                        getFindingResponse={getFindingResponse}
                        previewDetail={(mode, id) => deleteFindingFn(id, section.id)}
                        triggerNewPoint={triggerNewPoint}
                      />
                    </div>:null
                  }
                </div>
              )}
            </div>:null
          }
          { auditSectionCrud.deckIndex === 1 ?
            <div>
              <Tabs
                value={auditSectionCrud.upperdeckIndex}
                onChange={(event, newValue) => handleTabChange(event, newValue, 'upperdeckIndex')}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons={isMobile ? 'on':'auto'}
              >
                { auditSectionCrud.data.map((section, index) =>
                  upGalleys.includes(section.id) &&
                  <Tab label={section.name} value={section.id} />
                )}
              </Tabs>
              { auditSectionCrud.data.map((section, index) =>
                upGalleys.includes(section.id) && section.id === auditSectionCrud.upperdeckIndex &&
                <div>
                  <QuestionsAnswers onFieldChange={onFieldChange} inspectionHdInfo={inspectionHdInfo} section={section} addDynamicComponent={addDynamicComponent} removeDynamicComponent={removeDynamicComponent} />
                  <Tabs
                    value={auditSectionCrud.obsIndex}
                    onChange={(event, newValue) => handleTabChange(event, newValue, 'obsIndex')}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons={isMobile ? 'on':'auto'}
                  >
                    <Tab label='Observation' />
                    <Tab label='Finding' />
                  </Tabs>
                  { auditSectionCrud.obsIndex === 0 ?
                    <div className="table-responsive observation-table">
                      <table style={{width:'100%'}} className="form-table" cellSpacing={0} cellPadding={0}>
                        <tbody>
                          {section.physical_questions.map((observation, index) =>
                            <Observations observation={observation} onObservationChange={(keyParam, value) => onObservationChange(keyParam, value, section.id, index)} />
                          )}
                          <tr>
                            <td colSpan={2}>
                              <TextField
                                id="additional_remarks"
                                label="Additional Remarks"
                                fullWidth
                                margin="normal"
                                multiline
                                rows="3"
                                value={readTheAnswer(section.answers, 'additional_remarks')}
                                onChange={(e) => onFieldChange({} ,'additional_remarks', e.target.value, 'specs', section.id)}
                                InputLabelProps={{shrink: true}}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>:null
                  }
                  { auditSectionCrud.obsIndex === 1 ?
                    <div>
                      <FindingsTable
                        sectionId={section.id}
                        findings={section.inspection_findings}
                        getFindingResponse={getFindingResponse}
                        previewDetail={(mode, id) => deleteFindingFn(id, section.id)}
                        triggerNewPoint={triggerNewPoint}
                      />
                    </div>:null
                  }
                </div>
              )}
            </div>:null
          }
        </div>:
        <div>
          <Tabs
            value={auditSectionCrud.tabIndex}
            onChange={(event, newValue) => handleTabChange(event, newValue, 'tabIndex')}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons={isMobile ? 'on':'auto'}
          >
            { auditSectionCrud.data.map((section, index) =>
              <Tab label={section.name} />
            )}
          </Tabs>
          { auditSectionCrud.data.map((section, index) =>
            index === auditSectionCrud.tabIndex ?
            <div>
              <QuestionsAnswers onFieldChange={onFieldChange} inspectionHdInfo={inspectionHdInfo} section={section} addDynamicComponent={addDynamicComponent} removeDynamicComponent={removeDynamicComponent} />
              <Tabs
                value={auditSectionCrud.obsIndex}
                onChange={(event, newValue) => handleTabChange(event, newValue, 'obsIndex')}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons={isMobile ? 'on':'auto'}
              >
                <Tab label='Observation' />
                <Tab label='Finding' />
              </Tabs>
              { auditSectionCrud.obsIndex === 0 ?
                <div className="table-responsive observation-table">
                  <table style={{width:'100%'}} className="form-table" cellSpacing={0} cellPadding={0}>
                    <tbody>
                      {section.physical_questions.map((observation, index) =>
                        <Observations observation={observation} onObservationChange={(keyParam, value) => onObservationChange(keyParam, value, section.id, index)} />
                      )}
                      <tr>
                        <td colSpan={2}>
                          <TextField
                            id="additional_remarks"
                            label="Additional Remarks"
                            fullWidth
                            margin="normal"
                            multiline
                            rows="3"
                            value={readTheAnswer(section.answers, 'additional_remarks')}
                            onChange={(e) => onFieldChange({} ,'additional_remarks', e.target.value, 'specs', section.id)}
                            InputLabelProps={{shrink: true}}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>:null
              }
              { auditSectionCrud.obsIndex === 1 ?
                <div>
                  <FindingsTable
                    sectionId={section.id}
                    findings={section.inspection_findings}
                    getFindingResponse={getFindingResponse}
                    previewDetail={(mode, id) => deleteFindingFn(id, section.id)}
                    triggerNewPoint={triggerNewPoint}
                  />
                </div>:null
              }
            </div>:null
          )}
        </div>
      }

    </Paper>
  )
}
