import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, ListItemText, Avatar } from '@material-ui/core';
import { getLocalStorageInfo } from '../../utils';
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
export default function UserDropDownMenu({logout}){
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return(
    <div className="user-menu-dropdown">
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{padding: '0'}}
      >
        <Avatar alt={getLocalStorageInfo().user.name} style={{marginRight: '10px', width: '34px', height: '34px'}} src={getLocalStorageInfo().user.profile_pic} />
        <div className="" style={{textAlign: 'left'}}>
          <h3>{getLocalStorageInfo().user.name}</h3>
          <span>{getLocalStorageInfo().user.designation?getLocalStorageInfo().user.designation:''}</span>
        </div>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemText onClick={logout} primary="Logout" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}
