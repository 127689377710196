import React from 'react';
import moment from 'moment';
import { TableRow, TableCell, Avatar, Tooltip, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { CircularProgress } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants';
import ProjectCRU from './ProjectCRU';
export default function ProjectList({item}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <div className="flex-centered">
          <Tooltip title={`${item.progress}%`} arrow>
            <CircularProgress value={item.progress} />
          </Tooltip>
          <div style={{marginLeft:'6px'}}>
            <span className="project-no">{`#${item.project_number}`}</span>
            <h4 className="project-name">{item.name}</h4>
          </div>
        </div>
      </TableCell>
      <TableCell>
        { item.assets.filter(asset => asset.asset_type === 1).length ?
          <p>
            { item.assets.filter(asset => asset.asset_type == 1).length === 1 ?
              'MSN '+item.assets.find(asset => asset.asset_type == 1).msn:
              item.assets.filter(asset => asset.asset_type == 1).length+ ' MSN'
            }
          </p>:null
        }
        { item.assets.filter(asset => asset.asset_type === 2).length ?
          <p>
            { item.assets.filter(asset => asset.asset_type === 2).length === 1 ?
              'ESN '+item.assets.find(asset => asset.asset_type === 2).esn:
              item.assets.filter(asset => asset.asset_type === 2).length+ ' ESN'
            }
          </p>:null
        }
        { item.assets.filter(asset => asset.asset_type === 3).length ?
          <p>
            { item.assets.filter(asset => asset.asset_type === 3).length === 1 ?
              'APU '+item.assets.find(asset => asset.asset_type === 3).serial_number:
              item.assets.filter(asset => asset.asset_type === 3).length+ ' APU'
            }
          </p>:null
        }
        { item.assets.filter(asset => asset.asset_type === 4).length ?
          <p>
            { item.assets.filter(asset => asset.asset_type === 4).length === 1 ?
              'LG '+item.assets.find(asset => asset.asset_type === 4).serial_number:
              item.assets.filter(asset => asset.asset_type === 4).length+ ' LG'
            }
          </p>:null
        }
        { item.assets.filter(asset => asset.asset_type === 5).length ?
          <p>
            { item.assets.filter(asset => asset.asset_type === 5).length === 1 ?
              'Propeller '+item.assets.find(asset => asset.asset_type === 5).serial_number:
              item.assets.filter(asset => asset.asset_type === 5).length+ ' Propeller'
            }
          </p>:null
        }
      </TableCell>
      <TableCell>{item.location}</TableCell>
      <TableCell>{item.lessee && item.lessee.logo ? <img style={{maxWidth: '75px'}} src={item.lessee.logo} alt="" />:'--'}</TableCell>
      <TableCell>{item.start_date ? moment(item.start_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.expected_end_date ? moment(item.expected_end_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>
        { item.leads.length > 1 ?
          <AvatarGroup max={3}>
            { item.leads.map((lead, index) =>
              <Tooltip arrow title={lead.name}>
                <Avatar alt={lead.name} src={lead.profile_pic} />
              </Tooltip>
            )}
          </AvatarGroup>
          : item.leads.length === 1 ? item.leads.map((lead, index) => <Tooltip title={lead.name}><Avatar alt={lead.name} src={lead.profile_pic} /></Tooltip>):null
        }
      </TableCell>
      <TableCell>{item.status.label}</TableCell>
      <TableCell>
        <Link to={`/project/view/${item.slug}`}>
          <Tooltip title="View" arrow>
            <IconButton size="small" color="primary">
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Link>
        <ProjectCRU id={item.id} projectSlug={item.slug} />
      </TableCell>
    </TableRow>
  )
}
