import moment from 'moment';
import { globalGetService, globalPostService, globalPutService, globalDeleteService, globalExportService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
import { errorCode} from './index';
export function getRobberyListApi(props={}, query={}, loaderType){
  this.setState({[loaderType]:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/robbery-list/`, query)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      delete query.sort;
      delete query.sort_by;
      delete query.page;
      delete query.per_page;
      this.setState({
        robberies: response.data.data,
        filter: query
      })
    }
  })
}
export function exportRobberyListApi(props={}, file){
  this.setState({pageLoader: true});
  globalExportService(`technical/workorder/${props.match.params.workOrderSlug}/robbery-list/`, {download:file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE009_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.props.enqueueSnackbar('Robbery List Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function getRobberyApi(props={}, mode, id){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/robbery-list/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({robbery:{data:response.data.data, error:{}, modal:true, mode: mode === 'V' ? 'view':'edit'}})
    }
    this.setState({pageLoader:false});
  })
}

export function addEditRobberyApi(props={}, robbery={}){
  let validationInputs={
    part_number:errorCode['part_number'][fieldValidation({...errorCode['part_numberObj'], fieldval: robbery.data.part_number})],
    price:errorCode['price'][fieldValidation({...errorCode['priceObj'], fieldval: robbery.data.price})],
    wcard_number:errorCode['wcard_number'][fieldValidation({...errorCode['wcard_numberObj'], fieldval: robbery.data.wcard_number})],
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
  this.setState({pageLoader:true});
    if(robbery.data.id){
      globalPutService(`technical/workorder/${props.match.params.workOrderSlug}/robbery-list/${robbery.data.id}/`, robbery.data)
      .then(response => {
        if(checkApiStatus(response)){
          this.setState(prevState => ({
            ...prevState,
            robberies: {
              ...prevState.robberies,
              list: prevState.robberies.list.map(item => item.id === response.data.data.id ? response.data.data:item)
            },
            robbery: {data:{}, error:{}, mode:'', modal: false}
          }));
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
        this.setState({pageLoader:false});
      })
    }else{
      globalPostService(`technical/workorder/${props.match.params.workOrderSlug}/robbery-list/`, robbery.data)
      .then(response => {
        if(checkApiStatus(response)){
          this.setState(prevState => ({
            ...prevState,
            robberies: {
              ...prevState.robberies,
              list: [response.data.data, ...prevState.robberies.list],
            },
            robbery: {data:{}, error:{}, mode:'', modal: false}
          }));
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
        this.setState({pageLoader:false});
      })
    }
  }else{
    this.setState(prevState => ({
      ...prevState,
      robbery: {
        ...prevState.robbery,
        error: validationInputs
      }
    }))
  }
}
export function deleteRobberyApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/workorder/${props.match.params.workOrderSlug}/robbery-list/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({pageLoader:false});
      this.setState(prevState => ({
        ...prevState,
        deleteModal: false,
        deleteIds:[],
        robberies: {
          ...prevState.robberies,
          list: prevState.robberies.list.filter(item => !data.ids.includes(item.id))
        }
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
