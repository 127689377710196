import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Paper, CircularProgress, Button, Grid, TextField, Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment'
import { isMobile } from 'react-device-detect';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { EngineCurrentDetail, EngineHistory, ShopVisits, EctmDetails, MrAndInvoiceDetails, Remarks, Attachment , BorescopeInspectionDetails, RefLinkList } from '../components';
import { TableListComp, EmptyCollection, PageLoader } from '../../../shared_elements';
import { getPlatformConstantsAc, getAircraftTypesAc, getEngineTypesAc } from '../../../shared_elements/actionCreators';
import { getLinkAbleAssetApi, uploadEngineWorkorderAttachmentApi, getProjectFormsApi, getEngineDetailWoApi, getEngineWorkOrderAPI, addEditEngineWorkOrderApi } from '../apiServices';
import { engineWorkOrder, engineShopVist } from '../../';
import { getLocalStorageInfo } from '../../../utils';
import { backendDateFormat } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import { errorCode } from '../';
class AddTechnicalEngine extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      tabIndex:'engine-current-detail',
      engine: engineWorkOrder,
      assetLoader:false,
      assetList:[],
      forms:[],
      error:{
        shop_visits: []
      }
    }
    this.getLinkAbleAssetApi = getLinkAbleAssetApi.bind(this);
    this.getProjectFormsApi = getProjectFormsApi.bind(this);
    this.getEngineDetailWoApi = getEngineDetailWoApi.bind(this);
    this.getEngineWorkOrderAPI = getEngineWorkOrderAPI.bind(this);
    this.addEditEngineWorkOrderApi = addEditEngineWorkOrderApi.bind(this);
    this.uploadEngineWorkorderAttachmentApi = uploadEngineWorkorderAttachmentApi.bind(this);
  }
  componentDidMount(){
    this.props.getAircraftTypes();
    this.props.getEngineTypes();
    this.props.getPlatformConstants();
    this.getLinkAbleAssetApi(this.props, {switcher:true, asset_type:2});
    this.getProjectFormsApi(this.props,{form_type:2});
    if(this.props.match.params.workOrderSlug){
      this.getEngineWorkOrderAPI(this.props);
    }
  }
  addShopVisitFn = () => {
    this.setState(prevState => ({
      ...prevState,
      engine: {
        ...prevState.engine,
        shop_visits: [...prevState.engine.shop_visits, engineShopVist]
      }
    }))
  }
  addReferenceLinkFn = () => {
    this.setState(prevState => ({
      ...prevState,
      engine: {
        ...prevState.engine,
        reference_links: [...prevState.engine.reference_links, {link:'', description:'', updated_at:moment().format(backendDateFormat),user:{id:getLocalStorageInfo().user.id,name: getLocalStorageInfo().user.name, profile_pic:getLocalStorageInfo().user.profile_pic}}]
      }
    }))
  }
  uploadSVAttachmentFn = (file, index) => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('shop_visit', true);
    this.uploadEngineWorkorderAttachmentApi(this.props, formData)
    .then(response => {
      if(response.data.statusCode === 200){
        this.setState(prevState => ({
          ...prevState,
          engine: {
            ...prevState.engine,
            shop_visits: [...prevState.engine.shop_visits].map((sv, svIndex) => svIndex === index  ?
              {...sv, attachments: [...sv.attachments, { attachment:response.data.url, name:response.data.name, description:'', created_at:moment().format(backendDateFormat), user:{id:getLocalStorageInfo().user.id,name: getLocalStorageInfo().user.name, profile_pic:getLocalStorageInfo().user.profile_pic} }]}
            :sv)
          }
        }))
      }
    })
  }
  uploadAttachmentFn = (file) => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('shop_visit', true);
    this.uploadEngineWorkorderAttachmentApi(this.props, formData)
    .then(response => {
      if(response.data.statusCode === 200){
        this.setState(prevState => ({
          ...prevState,
          engine: {
            ...prevState.engine,
            attachments: [...prevState.engine.attachments, {attachment:response.data.url, name:response.data.name, description:'', created_at:moment().format(backendDateFormat),user:{id:getLocalStorageInfo().user.id,name: getLocalStorageInfo().user.name, profile_pic:getLocalStorageInfo().user.profile_pic}}]
          }
        }))
      }
    })
  }
  onSVFieldChange = (event, keyParam, data, arrayIndex, attachmentIndex) => {
    this.setState(prevState => ({
      ...prevState,
      engine:{
        ...prevState.engine,
        shop_visits: [...prevState.engine.shop_visits].map((sv, index) => index === arrayIndex ? {
          ...sv,
          attachments: [...sv.attachments].map((attachment, innerIndex) => innerIndex === attachmentIndex ? {...attachment,[keyParam]:data} :attachment)
        } :sv)
      }
    }))
  }
  onFieldChange = (event, keyParam, data, arrayType, arrayIndex) => {
    if(arrayType === 'shop_visits'){
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          shop_visits: [...prevState.engine.shop_visits].map((sv,index) =>  index === arrayIndex ?
            {...sv, [keyParam]:data}
           :sv)
        }
      }))
    }else if (arrayType === 'attachments') {
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          attachments: [...prevState.engine.attachments].map((attachment,index) =>  index === arrayIndex ?
            {...attachment, [keyParam]:data}
           :attachment)
        }
      }))
    }else if (arrayType === 'reference_links') {
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          reference_links: [...prevState.engine.reference_links].map((link,index) =>  index === arrayIndex ?
            {...link, [keyParam]:data}
           :link)
        }
      }))
    }else{
      if(keyParam === 'esn'){
        if(this.state.engine.create_esn){
          this.setState(prevState => ({
            ...prevState,
            engine: {
              ...prevState.engine,
              [keyParam]: data
            }
          }))
        }else{
          if(data){
            this.getEngineDetailWoApi(this.props,data.slug);
          }else{
            this.setState(prevState => ({
              ...prevState,
              engine: engineWorkOrder
            }))
          }
        }
        // this.setState(prevState => ({
        //   ...prevState,
        //   engine: {
        //     ...prevState.engine,
        //     esn: data ? data.esn:'',
        //     engine_id:data ? data.id:'',
        //     engine_slug:data ? data.slug:''
        //   }
        // }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          engine: {
            ...prevState.engine,
            [keyParam]: data
          }
        }))
      }
    }
  }

  removeItemFromEngine = (arrayType, arrayIndex, attachmentIndex) => {
    if(attachmentIndex >= 0){
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          [arrayType]: [...prevState.engine[arrayType]].map((item, index) => index === arrayIndex ?
            { ...item,
              attachments: item.attachments.filter((attachment, innerIndex) => innerIndex !==attachmentIndex )
            }
           : item)
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          [arrayType]: [...prevState.engine[arrayType]].filter((item, index) => index !== arrayIndex)
        }
      }))
    }
  }
  handleTabChange = (event, newValue) => {
    this.setState({tabIndex: newValue});
  }
  handleError = (value, key) => {
    this.setState((prevState)=> ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
      }
    }
    ))
  }

  render(){
    const { assetLoader, forms, engine, assetList, tabIndex, error, pageLoader } = this.state;
    const { aircraftTypes, engineTypes, pltConstants } = this.props;
    return(
      <div className="add-technical-engine">
        { pageLoader ? <PageLoader /> : null }
        <h2 className="flex-centered">
          <Link to={`/project/view/${this.props.match.params.projectSlug}`}><ArrowBackIcon /></Link>
            {this.props.match.params.workOrderSlug ? 'Edit Engine':'Add Engine' }
          <Button onClick={() => this.addEditEngineWorkOrderApi(this.props, engine)} style={{marginLeft: 'auto'}} variant="contained" color="primary" size="small">
            Save Engine
          </Button>
        </h2>
        <Paper className="papar-card form-list-selection">
          <Grid container spacing={1}>
            { !this.props.match.params.workOrderSlug ?
              <Fragment>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="ownership_type" defaultValue="top">
                      <FormControlLabel value="is_titled" control={<Radio checked={!engine.create_esn} color="primary" onChange={() => this.onFieldChange('', 'create_esn', false)} />} label="Link Existing ESN" />
                      <FormControlLabel value="is_fitted" control={<Radio checked={engine.create_esn} color="primary" onChange={() => this.onFieldChange('', 'create_esn', true)} />} label="Create New ESN" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>

                  { engine.create_esn ?
                    <TextField
                      required
                      id="esn"
                      label="Engine Serial Number"
                      fullWidth
                      margin="normal"
                      value={engine.esn ? engine.esn :''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'esn', e.target.value)}
                    />:
                    <Autocomplete
                      options = {assetList}
                      getOptionLabel={option => option.esn}
                      id="esn" // Need engine_slug
                      value={engine.esn ? assetList.find(item => item.esn == engine.esn):null}
                      onChange={(e, value) => { this.onFieldChange(e, 'esn', value); this.handleError(value,'esn') }}
                      renderInput={params => <TextField required placeholder={assetLoader ? 'Loading...':'Select Engine'} error={error.esn ? true:false} helperText={error.esn ? error.esn:''} {...params} label="Engine Serial Number" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  }
                </Grid>
              </Fragment>:null
            }

            <Grid item xs={12} md={6}>
              <Autocomplete
                options = {forms}
                getOptionLabel={option => option.name}
                id="forms"
                value={engine.forms}
                multiple
                onChange={(e, value) => this.onFieldChange(e, 'forms', value)}
                renderInput={params => <TextField {...params} label="Forms" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
              />
            </Grid>
          </Grid>
        </Paper>
        <div className="">
          <Tabs
            value={tabIndex}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons={isMobile ? 'on':'auto'}
          >
            <Tab label={`Engine Current Details`} value="engine-current-detail" />
            <Tab label={`Engine History`} value="engine-history" />
            <Tab label={`Shop Visits`} value="shop-visits" />
            <Tab label={`MR and Invoice Details`} value="mr-and-invoice-details" />
            <Tab label={`Borescope Inspection Details`} value="borescope-inspection-details" />
            <Tab label={`ECTM Details`} value="ectm-details" />
            <Tab label={`Remarks`} value="remarks" />
            <Tab label={`Attachments`} value="attachments" />
            <Tab label={`Reference Links`} value="reference-links" />
          </Tabs>
          <Paper className="papar-card">
            { tabIndex === 'engine-current-detail' ?
              <EngineCurrentDetail
                engine={engine}
                aircraftTypes={aircraftTypes}
                engineTypes={engineTypes}
                pltConstants={pltConstants}
                engineList= {[]}
                onFieldChange={this.onFieldChange}
                error={error}
                handleError={this.handleError}
                resetErrorKey={(key) => {
                  this.setState(prevState => ({
                    ...prevState,
                    error: {
                      ...prevState.error,
                      [key]: ''
                    }
                  }))
                }}
              />:null
            }
            { tabIndex === 'engine-history' ?
              <EngineHistory
                engine={engine}
                onFieldChange={this.onFieldChange}
                error={error}
                handleError={this.handleError}
                resetErrorKey={(key) => {
                  this.setState(prevState => ({
                    ...prevState,
                    error: {
                      ...prevState.error,
                      [key]: ''
                    }
                  }))
                }}
              />:null
            }
            { tabIndex === 'shop-visits' ?
              <div className="cta-cnt">
                <Button onClick={this.addShopVisitFn} variant="outlined" color="primary" size="small" startIcon={<AddCircleIcon />}>
                  Add Shop Visit
                </Button>
                {engine.shop_visits.map((shopVisit, index) =>
                  <ShopVisits
                    index={index+1}
                    shopVisit={shopVisit}
                    error={error.shop_visits.length && error.shop_visits[index] ? error.shop_visits[index] :{}}
                    onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'shop_visits', index)}
                    uploadSVAttachmentFn={(file) => this.uploadSVAttachmentFn(file, index)}
                    removeShopVisitFn={() => this.removeItemFromEngine('shop_visits', index)}
                    removeSVAttachmentFn={(attachmentIndex) => this.removeItemFromEngine('shop_visits', index, attachmentIndex)}
                    onSVFieldChange={(event, keyParam, data, attachmentIndex) => this.onSVFieldChange(event, keyParam, data, index, attachmentIndex)}
                  />
                )}
                {!engine.shop_visits.length?
                  <EmptyCollection
                    title="No records found"
                  />:null
                }
              </div>:null
            }
            { tabIndex === 'mr-and-invoice-details' ?
              <MrAndInvoiceDetails
                engine={engine}
                onFieldChange={this.onFieldChange}
              />:null
            }
            { tabIndex === 'borescope-inspection-details' ?
              <BorescopeInspectionDetails
                engine={engine}
                onFieldChange={this.onFieldChange}
                error={error}
                handleError={this.handleError}
                resetErrorKey={(key) => {
                  this.setState(prevState => ({
                    ...prevState,
                    error: {
                      ...prevState.error,
                      [key]: ''
                    }
                  }))
                }}
              />:null
            }
            { tabIndex === 'ectm-details' ?
              <EctmDetails
                engine={engine}
                onFieldChange={this.onFieldChange}
              />:null
            }
            { tabIndex === 'remarks' ?
              <Remarks
                engine={engine}
                onFieldChange={this.onFieldChange}
              />:null
            }
            { tabIndex === 'attachments' ?
              <div className="cta-cnt">
                <div style={{float:'right', marginBottom:'20px'}}>
                  <input
                    id="contained-button-file"
                    type="file"
                    style={{display:'none'}}
                    onChange={(e) => this.uploadAttachmentFn(e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="outlined" color="primary" size="small" component="span">
                    Add Attachment
                    </Button>
                  </label>
                </div>

                <div className="">
                  <TableListComp
                    heads={[
                      {label:'File Name', sortOption:false},
                      {label:'Description', sortOption:false},
                      {label:'Uploaded on', sortOption:false},
                      {label:'Uploaded by', sortOption:false},
                      {label:'Action', sortOption:false, actionCell: true}
                    ]}
                    data={engine.attachments.map((item,index) =>
                      <Attachment
                        item={item}
                        onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'attachments', index)}
                        previewDetail={(mode) => this.removeItemFromEngine('attachments', index)}
                      />
                    )}
                    noRecord={ engine.attachments.length ? null:
                      <EmptyCollection
                        title="No records found"
                      />
                    }
                  />
                </div>
              </div>:null
            }
            { tabIndex === 'reference-links' ?
              <div className="cta-cnt">
                <Button onClick={this.addReferenceLinkFn} variant="outlined" color="primary" size="small" startIcon={<AddCircleIcon />}>
                  Add Reference Link
                </Button>
                <div className="">
                  <TableListComp
                    heads={[
                      {label:'Link', sortOption:false},
                      {label:'Description', sortOption:false},
                      {label:'Uploaded on', sortOption:false},
                      {label:'Uploaded by', sortOption:false},
                      {label:'Action', sortOption:false, actionCell: true}
                    ]}
                    data={engine.reference_links.map((item,index) =>
                      <RefLinkList
                        item={item}
                        onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'reference_links', index)}
                        previewDetail={(mode) => this.removeItemFromEngine('reference_links', index)}
                      />
                    )}
                    noRecord={ engine.reference_links.length ? null:
                      <EmptyCollection
                        title="No records found"
                      />
                    }
                  />
                </div>
              </div>:null
            }
          </Paper>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  aircraftTypes: state.shareReducer.aircraftTypes,
  engineTypes: state.shareReducer.engineTypes,
  pltConstants: state.shareReducer.pltConstants
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: () => dispatch(getAircraftTypesAc()),
    getEngineTypes: () => dispatch(getEngineTypesAc()),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['engine_position']}))
  }
}
// export default connect(mapStateToProps, mapDispatchToProps)(AddTechnicalEngine)
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(AddTechnicalEngine))
