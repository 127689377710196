import React from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import AdjustIcon from '@material-ui/icons/Adjust';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { TableRowActions } from '../../../../shared_elements';
import { displayDateFormatShort } from '../../../../constants';
import { getCurrencyFormat } from '../../../../utils';
export default function List({inspectionHdInfo, item, previewDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell style={{position:'relative'}}>
        { !item.removal_date ?
          <p style={{position:'absolute', top:0, left:'8px'}}>
            <Tooltip title="No Track of Removal Date" arrow>
              <span className="highter" id="highligther-203"></span>
            </Tooltip>
          </p>:null
        }
        {item.part_number ? item.part_number:'--'}

      </TableCell>
      <TableCell>{item.part_description ? item.part_description:'--'}</TableCell>
      <TableCell>{item.part_location ? item.part_location:'--'}</TableCell>
      <TableCell>{item.removal_date ? moment(item.removal_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.reason_for_removal ? item.reason_for_removal:'--'}</TableCell>
      <TableCell>{item.date_verified_removed_from_aircraft ?  moment(item.date_verified_removed_from_aircraft).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.date_verified_in_store_house ? moment(item.date_verified_in_store_house).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.date_verified_installed_on_aircraft ? moment(item.date_verified_installed_on_aircraft).format(displayDateFormatShort) :'--'}</TableCell>
      <TableCell>{item.price ? <NumericLabel params={getCurrencyFormat()}>{item.price}</NumericLabel> :'--'}</TableCell>
      <TableCell className="actions-cell" style={{width: '120px'}}>
        <TableRowActions
          actions={ inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ? ['V','E','D']:['V']}
          previewDetail={(mode) => previewDetail(mode)}
        />
      </TableCell>
    </TableRow>
  )
}
