import React, { Component, Fragment } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Grid, TextField } from '@material-ui/core';
const SortableItem = SortableElement((props) => {
	return(
		<li className="list-inline-item" style={{width: '250px', zIndex:99999999}}>
			<div className="dropped-pic-card" style={{backgroundImage: "url(" + props.item.image + ")"}}>
				<div className="overlay"></div>
				<div className="dropped-picture-cta">
					<span onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.updateSlider();}}>View</span><br/>
					{ true ?
						<Fragment>
							<span onClick={() => props.onEditInspection()}>Edit</span><br/>
							<span onClick={() => props.onRemoveInpection()}>Remove</span>
						</Fragment>:null
					}
				</div>
			</div>
		</li>
	)
});

const SortableList = SortableContainer(({items, onRemoveInpection, onEditInspection, updateSlider}) => {
  return (
    <ul className="list-inline inspection-picture-list">
      {items.map((item, index) => (
        <SortableItem
					key={`item-${item.id}`}
					index={index}
					item={item}
					updateSlider={() => updateSlider(index)}
					onRemoveInpection={() => onRemoveInpection(item)}
					onEditInspection={() => onEditInspection(item)}
				/>
      ))}
    </ul>
  );
});
class SortTechList extends Component {
  render() {
    return <SortableList
			axis="xy"
			distance={1}
			items={this.props.items}
			onSortEnd={this.props.onSortEnd}
			updateSlider={(index) => this.props.updateSlider(index)}
			onRemoveInpection={(item) => this.props.onRemoveInpection(item)}
			onEditInspection={(item) => this.props.onEditInspection(item)}
		/>;
  }
}
export default SortTechList;
