import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
const AuthHeader = ({match}) => {
  return(
    <Fragment>
      <section className="auth-sec-hd">
        <Container maxWidth="lg">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={3}>
              <img className="auth-logo" src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/sparta_logo.svg" alt="" />
            </Grid>
            <Grid item xs={9}>
              {/* <ul className="list-inline">
                { !isMobile ?
                  <li className="list-inline-item">
                    { match.path.includes('signup') ? 'Already have an account ?':' Don’t have an account ?'}
                  </li>:null
                }
                <li className="list-inline-item sign-in">
                  { match.path.includes('signup') ?
                    <Link className="sign-in-link" to="/login">Sign In</Link>:
                    <Link className="sign-in-link" to="/signup">Sign Up</Link>
                  }
                </li>
                { !isMobile ?
                  <li style={{display: 'none'}} className="list-inline-item">
                    <p className="text-right buy-now-btn">
                      <Link to="" style={{marginLeft: '0px', borderRadius: '15px', border: '1.5px solid #fff'}}>Buy Now</Link>
                    </p>
                  </li>:null
                }
              </ul> */}
            </Grid>
          </Grid>
        </Container>
      </section>
    </Fragment>
  )
}
export default withRouter(AuthHeader);
