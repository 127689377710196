import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { LabelValueCard } from '../../../../shared_elements';
import { capitalizeFirstLetter } from '../../../../utils';
export default function ContactCRU({contact, error, addEditContactFn, toggleModalFn, onFieldChange, handleError, resetErrorKey}){
  return(
    <Dialog
      open={contact.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {capitalizeFirstLetter(contact.mode)} Contact
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={3}>
          { contact.mode === 'view' ?
            <LabelValueCard md={6} label='Name' value={contact.data.full_name ? contact.data.full_name:'--'} /> :
            <Grid item xs={6}>
              <TextField
                required
                id="full_name"
                label="Name"
                fullWidth
                margin="normal"
                inputProps={{maxLength: 30}}
                value={contact.data.full_name ? contact.data.full_name:''}
                onChange={(e, value) => onFieldChange(e, 'full_name', e.target.value)}
                error={contact.error.full_name ? true:false }
                helperText={contact.error.full_name ? contact.error.full_name:''}
                InputLabelProps={{shrink: true}}
                onBlur={(e) => handleError(e.target.value,'full_name')}
                onFocus={(e) => resetErrorKey('full_name')}
              />
            </Grid>
          }
          { contact.mode === 'view' ?
            <LabelValueCard md={6} label='Email' value={contact.data.email ? contact.data.email:'--'} /> :
            <Grid item xs={6}>
              <TextField
                required
                id="email"
                label="Email"
                fullWidth
                margin="normal"
                value={contact.data.email ? contact.data.email:''}
                onChange={(e, value) => onFieldChange(e, 'email', e.target.value)}
                error={contact.error.email ? true:false }
                helperText={contact.error.email ? contact.error.email:''}
                InputLabelProps={{shrink: true}}
                onBlur={(e) => handleError(e.target.value,'email')}
                onFocus={(e) => resetErrorKey('email')}
              />
            </Grid>
          }
          { contact.mode === 'view' ?
            <LabelValueCard md={6} label='Designation' value={contact.data.title ? contact.data.title:'--'} /> :
            <Grid item xs={6}>
              <TextField
                id="title"
                label="Designation"
                fullWidth
                margin="normal"
                inputProps={{maxLength: 30}}
                value={contact.data.title ? contact.data.title:''}
                onChange={(e, value) => onFieldChange(e, 'title', e.target.value)}
                error={contact.error.title ? true:false }
                helperText={contact.error.title ? contact.error.title:''}
                InputLabelProps={{shrink: true}}
                onBlur={(e) => handleError(e.target.value,'title')}
                onFocus={(e) => resetErrorKey('title')}
              />
            </Grid>
          }
          { contact.mode === 'view' ?
            <LabelValueCard md={6} label='Phone Number' value={contact.data.phone_number ? contact.data.phone_number:'--'} /> :
            <Grid item xs={6}>
              <TextField
                id="phone_number"
                label="Phone Number"
                fullWidth
                margin="normal"
                value={contact.data.phone_number ? contact.data.phone_number:''}
                onChange={(e, value) => onFieldChange(e, 'phone_number', e.target.value)}
                error={contact.error.phone_number ? true:false }
                helperText={contact.error.phone_number ? contact.error.phone_number:''}
                InputLabelProps={{shrink: true}}
                onBlur={(e) => handleError(e.target.value,'phone_number')}
                onFocus={(e) => resetErrorKey('phone_number')}
              />
            </Grid>
          }
          { contact.mode === 'view' ?
            <LabelValueCard md={6} label='Fax' value={contact.data.fax ? contact.data.fax:'--'} /> :
            <Grid item xs={6}>
              <TextField
                id="fax"
                label="Fax"
                fullWidth
                margin="normal"
                value={contact.data.fax ? contact.data.fax:''}
                onChange={(e, value) => onFieldChange(e, 'fax', e.target.value)}
                error={contact.error.fax ? true:false }
                helperText={contact.error.fax ? contact.error.fax:''}
                InputLabelProps={{shrink: true}}
                onBlur={(e) => handleError(e.target.value,'fax')}
                onFocus={(e) => resetErrorKey('fax')}
              />
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Cancel</Button>
        { contact.mode !== 'view' ?
          <Button onClick={addEditContactFn} variant="contained" color="primary">Save</Button>
          :null
        }
      </DialogActions>
    </Dialog>
  )
}
