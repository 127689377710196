import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Grid, Paper, Avatar, TextField, CircularProgress, Popover, Box, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader } from '../../../shared_elements';
import Rating from '@material-ui/lab/Rating';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { getProjectCorFormApi, updateProjectCorFormApi, clientAccessProjectCorFormApi, exportProjectCorFormApi } from '../apiServices';
class CorForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      corFormDetail: {},
      selectedUserIds:null
    }
    this.getProjectCorFormApi = getProjectCorFormApi.bind(this);
    this.updateProjectCorFormApi = updateProjectCorFormApi.bind(this);
    this.clientAccessProjectCorFormApi = clientAccessProjectCorFormApi.bind(this);
    this.exportProjectCorFormApi = exportProjectCorFormApi.bind(this);
  }
  componentDidMount(){
    this.getProjectCorFormApi(this.props);
  }
  updateAnswers = (answers,userId, key, value) => {
    let newAnswers = answers;
    if(answers.filter(answer => answer.user_id == userId).length){
      newAnswers = newAnswers.map(newAnswer => newAnswer.user_id == userId ? {...newAnswer, [key]: value} : newAnswer)
    }else{
      let answer = { [key]: value, user_id: userId }
      newAnswers = [...newAnswers, answer]
    }
    return newAnswers;
  }
  onFieldChange = (event, keyParam, value, sectionId, questionId, user) => {
    if(keyParam === 'selectedUserIds'){
      this.setState({selectedUserIds: value ? value.id:value});
    }else{
      this.setState(prevState => ({
        ...prevState,
        corFormDetail: {
          ...prevState.corFormDetail,
          sections: prevState.corFormDetail.sections.map(section => section.id === sectionId ? {
            ...section, questions: section.questions.map(question => question.id === questionId ? {
              ...question, answers: this.updateAnswers(question.answers, user.id, keyParam, value)
            } : question)
          } :section)
        }
      }))
    }

  }
  render(){
    const { selectedUserIds, corFormDetail, pageLoader } = this.state;
    if(!Object.keys(corFormDetail).length){
      return null
    }
    return(
      <div className="project-cor-form">
        <div className="project-cor-header">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <h1><Link className="flex-centered" to={`technical/project/view/${this.props.match.params.projectSlug}`}><ArrowBackIcon /> COR</Link></h1>
            </Grid>
            <Grid item xs={12} md={6}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" value="Access to Client" checked={corFormDetail.client_cor_show} onChange={(e) => this.clientAccessProjectCorFormApi(this.props, {client_cor_show:e.target.checked})} />
                    }
                    label="Access to Client"
                  />
                </li>
                <li className="list-inline-item">
                  <Button
                    color="primary"
                    onClick={() => this.exportProjectCorFormApi(this.props, {download:'pdf'})}
                  >
                    <SaveAltIcon /> Export
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button color="primary" variant="contained" onClick={() => this.updateProjectCorFormApi(this.props, corFormDetail)}>Save</Button>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>

        <Paper>
          <h2 className="flex-centered">
            COR Form Details
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <span>
                  <InfoIcon {...bindTrigger(popupState)} />
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={2}>
                      <Typography>COR Rating</Typography>
                      <Typography>1 - Highly Unsatisfied</Typography>
                      <Typography>2 - Unsatisfied</Typography>
                      <Typography>3 - Ok</Typography>
                      <Typography>4 - Satisfied</Typography>
                      <Typography>5 - Very Satisfied</Typography>
                    </Box>
                  </Popover>
                </span>
              )}
            </PopupState>
            { corFormDetail.users && corFormDetail.users.length > 1 ?
              <div className="" style={{width:'200px', marginLeft: 'auto'}}>
                <Autocomplete
                  options = {corFormDetail.users ? corFormDetail.users:[]}
                  getOptionLabel={option => option.name}
                  id="selectedUserIds"
                  value={selectedUserIds && corFormDetail.users && corFormDetail.users.length ? corFormDetail.users.find(user => user.id === selectedUserIds) : null}
                  onChange={(e, value) => this.onFieldChange(e, 'selectedUserIds', value)}
                  renderInput={params => <TextField {...params} label="Filter By User" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                />
              </div>:null
            }
          </h2>
          {corFormDetail.sections ?corFormDetail.sections.map((section, sectionIndex) =>
            <div className="cor-form-section">
              <h3>{section.name}</h3>
              {section.questions.map((qus, qusIndex) =>
                <div className="cor-form-question">
                  <h4>{qusIndex+1}. {qus.question}</h4>
                  {(() => {
                    let users = selectedUserIds ? corFormDetail.users.filter(user => user.id === selectedUserIds) : corFormDetail.users;
                    return users.map((user) => {
                      let answser = qus.answers.find(answer => answer.user_id === user.id);
                      return(
                        <div className="cor-form-user">
                          <h5 className="flex-centered"><Avatar sizes="small" src={user.profile_pic} /> {user.name}</h5>
                          <ul className="list-inline flex-centered">
                            <li className="list-inline-item">
                              <Rating
                                name={`simple-controlled-${section.id}-${qus.id}-${user.id}`}
                                value={answser && answser.rating ? answser.rating:0}
                                onChange={(event, newValue) => this.onFieldChange(event, 'rating', newValue, section.id, qus.id, user)}
                              />
                            </li>
                            <li className="list-inline-item">
                              <TextField
                                id="ata_code"
                                label="Remarks"
                                fullWidth
                                multiline
                                rowsMax={4}
                                margin="normal"
                                value={answser && answser.comments ? answser.comments:''}
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => this.onFieldChange(e, 'comments', e.target.value, section.id, qus.id, user)}
                              />
                            </li>
                          </ul>
                        </div>
                      )
                    })
                  })()}
                </div>
              )}
            </div>
          ):null}
        </Paper>
        { pageLoader ? <PageLoader /> : null }
      </div>
    )
  }
}
export default withSnackbar(CorForm);
