import React, { Fragment, Component } from 'react';
import { isMobile } from 'react-device-detect';
import {
  DesktopSideNav,
  DesktopHeader,
  MobileHeader,
  MobileSecMenu,
} from '../shared_elements';
export default function pageLayoutHoc(HocComponent, extraProps={}){
  return class extends Component{
    // constructor(props) {super(props)}
    layoutMarginProps = () => {
      if(extraProps.layoutPhase){
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return '50px 0 0 0'
          case 1:
            // Desktop with Primary Header + Primary Sidenav
            return '53px 0 0 70px'
          case 2:
            // Desktop with Primary Header + Primary Sidenav + Secondary Sidenav
            return '53px 0 0 270px';
          default:
            return '0'
        }
      }else{
        return '0'
      }
    }
    secondaryMenu = () => {
      if(extraProps.apps){
        let secondaryNav = null
        switch (extraProps.apps) {

          default:
            secondaryNav = null;
            break;
        }
        if(secondaryNav){
          return (
            <div style={{top:'113px', left: '70px'}} className="secondary-sidenav">
              <div style={{paddingBottom:'54px'}}>
                {secondaryNav}
              </div>
            </div>
          )
        }else{
          return null
        }
      }else{
        return null
      }
    }
    render(){
      return (
        <section>
          { isMobile ? <MobileHeader /> :
            <Fragment>
              <DesktopSideNav />
              <DesktopHeader />
            </Fragment>
          }
          { isMobile ? <MobileSecMenu extraProps={extraProps} /> : this.secondaryMenu()}
          <main style={{margin: isMobile ? '10px 0 0 0' : this.layoutMarginProps() , padding: '20px 20px 58px 20px'}}>
            <div>
              <HocComponent {...this.props} />
            </div>
          </main>
        </section>
      );
    }
  }
}
