import React from 'react';
const PrerequisiteContent = ({}) => {
  return(
    <ol>
      <li>To ensure that Lease Agreement with the Reimbursement Clauses are available</li>
      <li>To check for the availability of approved workscope</li>
      <li>To check for the receipt of preliminary invoices</li>
      <li>Review the preliminary invoices for labor, material, in house repair, outside vendor repair, sub contractor charges, etc., in relation to the approved workscope</li>
      <li>Review the minipack to relate the work carried out is as per the approved workscope</li>
      <li>To confirm the initial payment through Proof of Payment</li>
      <li>Recommend the Preliminary Claim amount</li>
      <li>Review the final invoices for labor, material, in house repair, outside vendor repair, sub contractor charges, etc., in relation to the approved workscope</li>
      <li>Review the detailed shop visit report to relate the work carried out is as per the approved workscope and charged accordingly</li>
      <li>To confirm the full payment through Proof of Payment</li>
      <li>Review the exclusions from the final invoices based on the Lease Agreement Clauses</li>
      <li>Check for any industry support, warranty adjustment, changes required in the final invoice value</li>
      <li>Recommend the Final Claim amount</li>
    </ol>
  )
}
export default PrerequisiteContent;
