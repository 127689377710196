import React from 'react';
import { ReviewIssuesComp } from '../../Elements';
import { TableRow, TableCell } from '@material-ui/core';
export default function ContactList({item, loadSectionFn}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.full_name ? item.full_name:'--'}</TableCell>
      <TableCell>{item.email ? item.email:'--'}</TableCell>
      <TableCell>{item.title ? item.title:'--'}</TableCell>
      <TableCell>{item.phone_number ? item.phone_number:'--'}</TableCell>
      <TableCell>
        {item.fax ? item.fax:'--'}
        <span className="add-issue">
          { item.issue && item.issue.id ?
            <ReviewIssuesComp type="view" issue={item.issue} loadSectionFn={loadSectionFn}  />
            :
            <ReviewIssuesComp loadSectionFn={loadSectionFn} type="add" payload={{contact_id:item.id,form_id:6}} />
          }
        </span>
      </TableCell>
    </TableRow>
  )
}
