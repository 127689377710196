import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { isMobile } from 'react-device-detect';
import { ProjectList, ProjectTabs, ProjectCRU } from '../components';
import { TableListComp, FilterUIComp, ExportManu, EmptyCollection, PageLoader } from '../../../shared_elements';
import { STabsLoader, STableLoader } from '../../../shared_elements/loaders';
import { getPlatformConstantsAc, getLesseeListAc } from '../../../shared_elements/actionCreators';
import { getProjectsApi, getProjectFormsApi, exportProjectsApi } from '../apiServices';
import { removeEmptyKey, convertFilterObject } from '../../../utils';
import { projectHd, projectFilterOps } from '../';
const queryString = require('query-string');
class ProjectListing extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      formSubmitLoader: false,
      tabIndex: queryString.parse(props.location.search).project_status ? queryString.parse(props.location.search).project_status:'all',
      filter:{},
      applyingFilter:{},
      projectsInfo:{
        pagination: {},
        list: [],
        projects_count: []
      },
      sort:'',
      sort_by:'',
      allForms:[]
    }
    this.getProjectsApi = getProjectsApi.bind(this);
    this.exportProjectsApi = exportProjectsApi.bind(this);
    this.getProjectFormsApi = getProjectFormsApi.bind(this);
  }
  componentDidMount(){
    let query = {per_page:10};
    if(queryString.parse(this.props.location.search).project_status){
      query = {
        ...query,
        project_status:queryString.parse(this.props.location.search).project_status
      }
    }
    this.getProjectsApi(this.props, query, 'skeletonLoader');
    this.getProjectFormsApi(this.props, {both:true});
    this.props.getLesseeList();
    this.props.getPlatformConstants();
  }
  applyFilter = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      applyingFilter: {
        ...prevState.applyingFilter,
        [keyParam]: value
      }
    }))
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, tabIndex } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getProjectsApi(this.props, {...query, project_status:tabIndex}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter, tabIndex, projectsInfo } = this.state;
    this.setState({
      sort:sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    });
    if(sortField === sort){
      if(sort_by === 'asc'){
        this.getProjectsApi(this.props, {...filter, sort: sortField, sort_by: 'desc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');
      }else{
        this.getProjectsApi(this.props, {...filter, sort: sortField, sort_by: 'asc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');
      }
    }else{
      this.getProjectsApi(this.props, {...filter, sort: sortField, sort_by: 'asc', per_page:projectsInfo.pagination.per_page, project_status: tabIndex}, 'pageLoader');
    }
  }

  handleTabChange = (event, newValue) => {
    this.props.history.push({pathname: '/projects',search: '?project_status='+newValue});
    this.getProjectsApi(this.props, {project_status:newValue}, 'pageLoader');
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue,
    }));
  }
  render(){
    const { projectsInfo, sort, sort_by, filter, applyingFilter, tabIndex, modal, skeletonLoader, pageLoader, formSubmitLoader, allForms } = this.state
    const { lessee, pltConstants } = this.props;
    let filterOptions = {
      ...projectFilterOps,
      lessee: {
        ...projectFilterOps.lessee,
        options: lessee
      },
      forms: {
        ...projectFilterOps.forms,
        options: allForms
      }
    }
    return(
      <div className="projects-list-sec">
        <div className="flex-centered" style={{marginBottom: '20px'}}>
          <div className="page-description">
            <Typography variant="h2" className="page-title">Projects</Typography>
            { !isMobile ? <p className="page-para">Offers a ‘LIST’ view of all Technical and Asset Management (TAM) projects created in SPARTA</p>:null}
          </div>
          <span style={{marginLeft: 'auto'}}>
            { skeletonLoader ? <Skeleton variant="rect" width={140} height={36} /> : <ProjectCRU /> }
          </span>
        </div>
        { skeletonLoader ? <STabsLoader count={7} /> :
          <ProjectTabs handleTabChange={this.handleTabChange} tabIndex={tabIndex}  count={projectsInfo.projects_count} />
        }
        { skeletonLoader ? <STableLoader count={9} />:
          <Fragment>
            <span style={{float: 'right', padding: '5px 0'}}>
              <ExportManu disabled={projectsInfo.list.length === 0 ? true:false} title="Export Projects" files={[{title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportProjectsApi(this.props, {...filter, project_status: tabIndex}, file)} />
            </span>
            <FilterUIComp
               filter={filter}
               applyingFilter={applyingFilter}
               removeFilter={this.removeFilter}
               removeAllFilter={() => this.getProjectsApi(this.props, {project_status: tabIndex}, 'pageLoader')}
               submitFilter={() => this.getProjectsApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), project_status: tabIndex}, 'pageLoader')}
               applyFilter={this.applyFilter}
               filterMenu={filterOptions}
             />
            <TableListComp
              heads={projectHd}
              sort={sort}
              sort_by={sort_by}
              data={ projectsInfo.list.map((item,index) =>
                <ProjectList
                  key={index}
                  item={item}
                />
              )}
              noRecord={ projectsInfo.list.length ? null:
                <EmptyCollection
                  title="No records found"
                />
              }
              pagination={projectsInfo.pagination}
              onChangePage={(event, newPage) => this.getProjectsApi(this.props, {...filter, project_status: tabIndex, page:newPage+1, per_page: projectsInfo.pagination.per_page}, 'pageLoader')}
              onChangeRowsPerPage={(event) => this.getProjectsApi(this.props, {...filter, project_status: tabIndex, page:1, per_page: event.target.value}, 'pageLoader')}
              createSortHandler={this.createSortHandler}
            />
          </Fragment>
        }
        { pageLoader ? <PageLoader />:null }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  lessee: state.shareReducer.lessee,
  pltConstants: state.shareReducer.pltConstants
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLesseeList: () => dispatch(getLesseeListAc()),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['project_departments']}))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectListing)
