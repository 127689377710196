export const projectFilterOptions = {
  status: {
    inputType: 'dropdown',
    placeholder: 'Select Status',
    options: [{id: 'upcoming', name: 'Up Coming'}, {id: 'ongoing', name: 'On Going'}, { id: 'overdue', name: 'Overdue'}, { id: 'completed', name: 'Completed'}],
    labelKey: 'name',
    valueKey: 'id',
    title: 'Status',
    multiple: true
  },
  department: {
    inputType: 'dropdown',
    placeholder: 'Select Department',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Department',
    multiple: true
  },
  project_type: {
    inputType: 'dropdown',
    placeholder: 'Select Project Type',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Project Type',
    multiple: true
  },
  search:{
    inputType: 'text',
    placeholder: 'Search',
    title: 'Search'
  },
  project_lead:{
    inputType: 'text',
    placeholder: 'Search by Lead',
    title: 'Project Lead'
  },
  project_engineer:{
    inputType: 'text',
    placeholder: 'Search by Engineer',
    title: 'Project Engineer'
  },
  project_no:{
    inputType: 'text',
    placeholder: 'Search by Project No',
    title: 'Project Number'
  },
  project_location:{
    inputType: 'text',
    placeholder: 'Search by Project Location',
    title: 'Project Location'
  },
  serial_number:{
    inputType: 'text',
    placeholder: 'Search by Asset',
    title: 'MSN/ESN'
  },
  operator:{
    inputType: 'text',
    placeholder: 'Search by Asset',
    title: 'Lessee'
  },
  project_date:{
    inputType: 'date',
    title: 'Project Date',
    firstKey:'startDate',
    lastKey:'endDate',
    range:true
  }
}
export const manPowerFilterOptions = {
  user_type: {
    inputType: 'dropdown',
    placeholder: 'Select Status',
    options: [{id: 'available', name: 'Available'}, {id: 'not_available', name: 'Not Available'}, {id: 'deployed', name: 'Deployed'}],
    labelKey: 'name',
    valueKey: 'id',
    title: 'Status',
    multiple: true
  },
  user:{
    inputType: 'text',
    placeholder: 'Search',
    title: 'User'
  },
  project_name:{
    inputType: 'text',
    placeholder: 'Search',
    title: 'Project Name'
  },
  location:{
    inputType: 'text',
    placeholder: 'Search',
    title: 'Location'
  },
  department: {
    inputType: 'dropdown',
    placeholder: 'Select Department',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Department',
    multiple: true
  },
  category: {
    inputType: 'dropdown',
    placeholder: 'Select Category',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Category',
    multiple: true
  },
  project_date:{
    inputType: 'date',
    title: 'Project Date',
    firstKey:'startDate',
    lastKey:'endDate',
    range:true
  },
}
export const corFilterOptions = {
  status: {
    inputType: 'dropdown',
    placeholder: 'Select Status',
    options: [ {id:0, name: 'Pending'}, {id:2 , name: 'Accepted'}, { id:1, name: 'Not Accepted'}],
    labelKey: 'name',
    valueKey: 'id',
    title: 'COR Status',
    multiple: true
  },
  search:{
    inputType: 'text',
    keyParam: 'search',
    placeholder: 'Search',
    title: 'Search'
  },
  project_date:{
    inputType: 'date',
    title: 'Project Date',
    firstKey:'startDate',
    lastKey:'endDate',
    range:true
  },
}

export const resourceFilterOptions = {
  department: {
    inputType: 'dropdown',
    placeholder: 'Select Department',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Department',
    multiple: true
  },
  user_type: {
    inputType: 'dropdown',
    placeholder: 'Select Resource Status',
    options: [ {value:'available', label: 'Available'}, {value:'deployed' , label: 'Deployed'}],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Resource Status',
    multiple: true
  },
  category: {
    inputType: 'dropdown',
    placeholder: 'Select Category',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Category',
    multiple: true
  }
}
export const projectsFilterOptions = {
  project_departments: {
    inputType: 'dropdown',
    placeholder: 'Select Department',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Project Department',
    multiple: true
  },
  status: {
    inputType: 'dropdown',
    placeholder: 'Select Project Status',
    options: [ {value:'completed', label: 'Completed'}, {value:'ongoing' , label: 'Ongoing'}, { value:'upcoming', label: 'Upcoming'}],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Project Status',
    multiple: true
  },
  name: {
    inputType: 'dropdown',
    placeholder: 'Select Project',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Project Name',
    multiple: false
  },
  project_date:{
    inputType: 'date',
    title: 'Project Date',
    firstKey:'date_from',
    lastKey:'date_to',
    range:true
  }
}
export const projectAnalyticHd = [
  { label: 'Index', sortOption: false },
  { label: 'Project No', sortOption: false },
  { label: 'Project', sortOption: false },
  { label: 'Project Status', sortOption: false },
  { label: 'Lessor', sortOption: false },
  { label: 'Start Date', sortOption: false },
  { label: 'Expected End Date', sortOption: false },
  { label: 'Project Location', sortOption: false },
]
export const resourceAnalyticHd = [
  { label: 'Index', sortOption: false },
  { label: 'Name of Staff', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'Status Detail', sortOption: false },
  { label: 'Projects', sortOption: false },
  { label: 'Designation', sortOption: false },
  { label: 'Department', sortOption: false },
  { label: 'Contact No', sortOption: false },
  { label: 'Email', sortOption: false },
  { label: 'Country', sortOption: false },
  { label: 'Base Location', sortOption: false },
  { label: 'Current Location', sortOption: false },
]
