import React from 'react';
import moment from 'moment';
import { TableRow, TableCell, Avatar, Tooltip, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { CircularProgress } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants';
export default function ResourcesAnalyticList({item, index}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{index}</TableCell>
      <TableCell>{item.name ? item.name:'--'}</TableCell>
      <TableCell style={{width:'100px'}}>{item.status ? <span style={item.status === 'Available' ?{padding:'5px 10px', background:'#00a753', display:'inline-block', color:'#ffffff', borderRadius:'4px'}:{padding:'5px 10px', background:'#ab3800', color:'#ffffff', borderRadius:'4px'}}>{item.status}</span>:'--'}</TableCell>
      <TableCell>
        {item.status_details && item.status_details.length ? item.status_details.map(d => <p style={{fontSize:'11px', marginBottom:'3px', padding:'3px', background:'#d3d1d1', borderRadius:'3px'}}>{d}</p>):'--'}
      </TableCell>
      <TableCell>--</TableCell>
      <TableCell>{item.designation ? item.designation:'--'}</TableCell>
      <TableCell>{item.department && item.department.label ? item.department.label:'--'}</TableCell>
      <TableCell>{item.contact_no ? item.contact_no:'--'}</TableCell>
      <TableCell>{item.email ? item.email:'--'}</TableCell>
      <TableCell>{item.base_country_code ? item.base_country_code:'--'}</TableCell>
      <TableCell>{item.base_location ? item.base_location:'--'}</TableCell>
      <TableCell>{item.current_location ? item.current_location:'--'}</TableCell>
    </TableRow>
  )
}
