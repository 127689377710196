import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import {arrayMoveImmutable} from 'array-move';
import {DropzoneArea} from 'material-ui-dropzone';
import { Grid } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { InspectionFormWrapper, ImageSlider, EditImageDialog, SortTechList } from '../../../Elements';
import { ImportedPictures } from '../components';
import { DeletePopUp, PageLoader } from '../../../../shared_elements';
import { getGenPicListApi, sortGenPicListApi, importGenPicListApi, importGenPicReportApi, addGenPicApi, editGenPicApi, removeGenPicApi, deleteGenPicApi, annotateGenPicApi, getInspectionHeaderApi, changeFormStatusApi, exportGenPicListApi } from '../apiServices';
class InspectionFormT001 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      inspectionHdInfo:{},
      generalPictures: [],
      sliderImages:[],
      importedPictures:{
        modal:false,
        data:{},
        selectedImages:[],
        existingImgCount:0
      },
      deleteModal:false,
      deleteIds:{},
      sliderInfo: {
        modal:false,
        images:[],
        startIndex:0
      },
      imgAnnotation: {
        sectionId:'',
        modal:false,
        data:{}
      }
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getGenPicListApi = getGenPicListApi.bind(this);
    this.sortGenPicListApi = sortGenPicListApi.bind(this);
    this.importGenPicListApi = importGenPicListApi.bind(this);
    this.importGenPicReportApi = importGenPicReportApi.bind(this);
    this.addGenPicApi = addGenPicApi.bind(this);
    this.editGenPicApi = editGenPicApi.bind(this);
    this.removeGenPicApi = removeGenPicApi.bind(this);
    this.deleteGenPicApi = deleteGenPicApi.bind(this);
    this.annotateGenPicApi = annotateGenPicApi.bind(this);
    this.exportGenPicListApi = exportGenPicListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getGenPicListApi(this.props);
  }
  selectImageFn = (id) => {
    this.setState(prevState => ({
      ...prevState,
      importedPictures: {
        ...prevState.importedPictures,
        selectedImages: [...prevState.importedPictures.selectedImages].includes(id) ? [...prevState.importedPictures.selectedImages].filter(item => item !== id) : [...prevState.importedPictures.selectedImages, id]
      }
    }))
  }
  updateSlider = (pictures, title, index) => {
    this.setState(prevState => ({
      ...prevState,
      sliderInfo: {
        ...prevState.sliderInfo,
        modal:true,
        images: pictures.map(item => {return {title:title, original:item.image, thumbnail:item.image}}),
        startIndex:index,
        title: title
      }
    }))
  }
  updateSortInpsection = (oldIndex, newIndex, sectionId) => {
    const { generalPictures } = this.state;
    let pictures = generalPictures.find(section => section.id === sectionId).pictures;
    this.setState(prevState => ({
      ...prevState,
      generalPictures: prevState.generalPictures.map(section => section.id === sectionId ? {
        ...section,
        pictures: arrayMoveImmutable(section.pictures, oldIndex, newIndex)
      } : section)
    }));
    this.sortGenPicListApi(this.props, {pictures:arrayMoveImmutable(pictures, oldIndex, newIndex).map(picture => picture.id)})
  }
  render(){
    const { inspectionHdInfo, generalPictures, importedPictures, sliderInfo, imgAnnotation, skeletonLoader, pageLoader, deleteModal, deleteIds } = this.state;
    return(
      <div className="technical-aircraft-froms">
        <InspectionFormWrapper
          saveButton={false}
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''}
          ]}
          exportReportFn={(file) => this.exportGenPicListApi(this.props, file)}
        />
        <div className="form-t001-cn">
          { generalPictures.map((section, index) =>
            <Grid container spacing={2} key={index}>
              <Grid item md={12} xs={12} style={{minHeight: '200px'}}>
                <h4 className="flex-centered">{section.name} <span className="flex-centered" onClick={() => this.importGenPicListApi(this.props, section.pictures.length, {status:0, aircraft_section_id: section.id})}><ImportExportIcon /> Import</span></h4>
                <div className="sort-image-cn">
                  { section.pictures.length ?
                    <SortTechList
                      items={section.pictures}
                      onSortEnd={(dataIndex) => this.updateSortInpsection(dataIndex.oldIndex, dataIndex.newIndex, section.id)}
                      updateSlider={(index) => this.updateSlider(section.pictures, section.name, index)}
                      onRemoveInpection={(picture) => this.setState({deleteModal:true, deleteIds:{...picture, sectionId:section.id}})}
                      onEditInspection={(picture) => this.setState({imgAnnotation:{modal:true, data:picture, sectionId:section.id}})}
                    />:null
                  }
                  { (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) && ( section.pictures.length < (section.id == 19 ? 10:5)) ?
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <div style={{width:'250px'}}>
                          <DropzoneArea
                            filesLimit={20}
                            maxFileSize={10000000}
                            showPreviewsInDropzone={false}
                            acceptedFiles={['image/*']}
                            dropzoneClass="drag-drop-cnt"
                            showAlerts={['error', 'info']}
                            onDrop={(files) => this.addGenPicApi(this.props, {sectionId: section.id, files:files, status:1} )}
                            alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                          />
                        </div>
                      </li>
                    </ul>
                    :null
                  }
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{id:null, pictures:[]}})}}
          title="Remove General Picture"
          content={<h4>Are you sure you want to remove?</h4>}
          deleteRecordFn={() => this.removeGenPicApi(this.props, deleteIds)}
          confirmText="Remove"
        />
        { importedPictures.modal ?
          <ImportedPictures
            inspectionHdInfo={inspectionHdInfo}
            importedPictures={importedPictures}
            toggleModalFn={() => this.setState({importedPictures:{modal:false, data:{}, selectedImages:[], existingImgCount:0}})}
            addGenPicFn={(data) => this.addGenPicApi(this.props, data)}
            selectImageFn={this.selectImageFn}
            deleteSelectedImgFn={() => this.deleteGenPicApi(this.props, importedPictures)}
            importGenPicFn={() => this.importGenPicReportApi(this.props, importedPictures)}
          />:null
        }
        { sliderInfo.modal ?
          <ImageSlider
            sliderInfo={sliderInfo}
            toggleModalFn={() => this.setState({sliderInfo:{modal:false, images:[], startIndex:''}})}
          />:null
        }
        { imgAnnotation.modal ?
          <EditImageDialog
            imgAnnotation={imgAnnotation}
            saveAnnotateImg={(file) => this.annotateGenPicApi(this.props, file, imgAnnotation)}
            toggleModalFn={() => this.setState({imgAnnotation: {modal:false}})}
          />:null
        }
        { pageLoader ? <PageLoader />:null}
      </div>
    )
  }
}
export default withSnackbar(InspectionFormT001);
