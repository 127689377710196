import React, { Component } from 'react';
import moment from 'moment';
let flag = 1;
export default class MonthAndDateHeader  extends Component {
  constructor(props){
	  super(props);
	  this.state = {
			positionHeader: 0,
	  }
	}
	componentDidMount(){
		document.addEventListener('scroll', this.handleScroll, true);
	}
	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleScroll);
	}
	getDaysArray = (year, month) => {
		var names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
		var date = new Date(year, month - 1, 1);
		var result = [];
		while (date.getMonth() == month - 1) {
			result.push(date.getDate());
			date.setDate(date.getDate() + 1);
		}
		return result;
	}
	handleScroll = (e) => {
		let left = e.srcElement.scrollLeft;
		if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight) {
			flag = 1
		} else if (e.srcElement.scrollTop <= 0) {
			flag = 0
		} else {
			flag = 1;
		}
		if(!e.srcElement.scrollTop  && flag){
			this.setState(prevState => ({
				...prevState,
				positionHeader : left
			}));
			flag = 1;
		}
	}
  render() {
		const { months, projectTrackerDtls, DateList  } = this.props;
    return(
    	<div style={{ width: (window.innerWidth - 480) + 'px', overflow: 'hidden', position: 'relative' }}>
     		<div className=" item-block" style={{ width: (window.innerWidth - 408) + 'px', flexBasis:  (window.innerWidth - 408) + 'px', position: 'relative', right: '0px', left: - this.state.positionHeader, right: 0, borderBottom: 0 }}>
					<div className="border-right" style={{width: (DateList.length ) * this.props.colWidth +'px', background: 'rgb(255, 255, 255)'}}>
						<div className="flex-centered" style={{flexFlow: 'nowrap'}}>
							{ months &&	months.length ?
								months.map((monthData, monthIndex) => {
									let customWidth = 0;
									let startDate = moment(projectTrackerDtls.start_date).format("D");
									let endDate = moment(projectTrackerDtls.end_date).format("D");
									customWidth  = this.getDaysArray(monthData.year, moment().month(monthData.month).format("M")).length ;
									if(startDate !== 1 && monthIndex == 0){
										customWidth = customWidth - ( startDate - 1)
									}
									if(endDate !== moment(projectTrackerDtls.end_date).endOf('month').format('D') &&  (monthIndex == (months.length - 1))){
										customWidth =  customWidth -  moment(projectTrackerDtls.end_date).endOf('month').format('D') + endDate
									}
									customWidth = customWidth * this.props.colWidth;
									return (
										<div className="first-block" key={monthIndex} style={{ width: (customWidth) +'px', borderRight: '1px solid #d7d7d7'}}>
											<span>{moment().month(monthData.month).format("MMM")} {monthData.year}</span>
										</div>
									)
								}): null
							}
						</div>
						<div className="second-block">
							<ul className="list-inline">
								{ DateList.map((date, index) =>
									<li  key={index} style={{width: this.props.colWidth +'px', margin: 0, textAlign: 'center'}}  className="list-inline-item">
										<p>{moment(date).format('ddd')}</p>
										<p>{moment(date).format('DD')}</p>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
    	)
    }
  }
