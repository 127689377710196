import moment from 'moment';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService, globalFileUploadPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils';
export function getAuditRptSectionsApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/`, query)
  .then(response => {
    this.setState({pageLoader:false, skeletonLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        auditRptSections: response.data.data,
        pageLoader:false
      })
    }
  })
}
export function exportAuditRptSectionsApi(props={}, file={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/`, {download:file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`TE003_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
    this.props.enqueueSnackbar('Inspection Docs Check List Report downloaded successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function linkRecordsFileApi(props, ids, questionId, ansId){
  let formData = new FormData();
  formData.append('files', ids.join(','));
  formData.append('question_id', questionId);
  if(ansId){
    globalFileUploadPutService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/${ansId}/`, formData)
    .then(response => {
      if(checkApiStatus(response)){

      }
    })
  }else{
    globalFileUploadService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/`, formData)
    .then(response => {
      if(checkApiStatus(response)){

      }
    })
  }

}

export function uploadFileToSectionApi(props, file, questionId, ansId){
  this.setState({pageLoader:true});
  let formData = new FormData();
  formData.append('question_id', questionId);
  formData.append('files[]', file, file.name);
  if(ansId){
    globalFileUploadPutService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/${ansId}/`, formData)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.getAuditRptSectionsApi(this.props);
      }
    })
  }else{
    globalFileUploadService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/`, formData)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.getAuditRptSectionsApi(this.props);
      }
    })
  }
}

export function removeLinkFileApi(props={}, ids, questionId, ansId){
  let formData = new FormData();
  formData.append('question_id', questionId);
  formData.append('delete', true);
  formData.append('files', ids.join(','));
  this.setState({pageLoader:true});
  globalFileUploadPutService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/${ansId}/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.getAuditRptSectionsApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getAuditRptDocsApi(props={}, questionId, ansId){
  this.setState({pageLoader: true})
  globalGetService(`technical/workorder/${props.match.params.workOrderSlug}/configuration/${questionId}/list-files/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        linkFilesInfo: {
          modal:true,
          fileInfos: response.data.data,
          selected:[],
          questionId: questionId,
          ansId: ansId
        },
        pageLoader: false
      }))
    }
  })
}
export function updateAuditRptAnsApi(props={}, data={}, ansId, sectionIndex, subSectionIndex, questionIndex){
  this.setState({error:{}})
  let errorFlag = 0;
  if(data.value){
    if(data.value !== 1 && data.remarks.trim() === ''){
      errorFlag = 1;
    }
  }else{
    errorFlag = 2;
  }
  if(errorFlag === 0){
    let formData = new FormData();
    formData.append('question_id', data.questionId);
    formData.append('value', data.value);
    formData.append('remarks', data.remarks);
    if(ansId){
      this.setState({pageLoader:true});
      globalFileUploadPutService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/${ansId}/`, formData)
      .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
          this.getAuditRptSectionsApi(this.props);
        }
      })
    }else{
      this.setState({pageLoader:true});
      globalFileUploadService(`technical/workorder/${props.match.params.workOrderSlug}/document-sections/`, formData)
      .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
          this.getAuditRptSectionsApi(this.props);
        }
      })
    }
  }else{
    if(data.value){
      this.setState(prevState => ({
        ...prevState,
        auditRptSections: [...prevState.auditRptSections].map((section, index) => index === sectionIndex ? {
          ...section, sub_sections: [...section.sub_sections].map((subSection, innerIndex) => innerIndex === subSectionIndex ? {
            ...subSection,
            questions: [...subSection.questions].map((question, qIndex) => qIndex === questionIndex ? {
              ...question,
              answer: {
                ...question.answer,
                value: data.value
              }
            } :question)
          } : subSection)
        } : section)
      }))
    }
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        id:data.questionId,
        message: errorFlag === 1 ? 'Please enter Remarks':'Please select Status'
      }
    }))
  }
}
