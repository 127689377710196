import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { regexConstants } from '../../../../constants/regEx';
const status = [{label: 'Serviceable', value: 1},{label: 'Unserviceable', value: 2},{label: 'Restricted', value: 3}];
export default function ComponentInpected({item, index, onFieldChange, inspectionHdInfo, previewDetail, error, handleError, resetErrorKey}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{index}</TableCell>
      <TableCell>
        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
          <TextField
            id={`description${index}`}
            fullWidth
            multiline
            rowsMax={4}
            margin="normal"
            inputProps={{maxLength: 255}}
            value={item.description ? item.description:''}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
          />:
          item.description ? item.description:'--'
        }

      </TableCell>
      <TableCell>
        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
          <TextField
            id={`findings${index}`}
            fullWidth
            margin="normal"
            multiline
            rowsMax={4}
            inputProps={{maxLength: 255}}
            value={item.findings ? item.findings:''}
            onChange={(e) => onFieldChange(e, 'findings', e.target.value)}
            error={false}
            helperText=''
            InputLabelProps={{shrink: true}}
          />:
          item.findings ? item.findings:''
        }

      </TableCell>
      <TableCell>
        { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
          <Autocomplete
            options = {status}
            getOptionLabel={option => option.label}
            id={`status${index}`}
            value={item.status ? status.find(obj => obj.value === item.status) :null}
            onChange={(e, data) => onFieldChange(e, 'status', data?data.value:'')}
            renderInput={params => <TextField {...params} margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
          />:
          item.status ? status.find(obj => obj.value === item.status).label :'--'
        }

      </TableCell>
      { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
        <TableCell className="actions-cell">
          <DeleteOutlineIcon onClick={() => previewDetail('D')} color="error" />
        </TableCell>:null
      }

    </TableRow>
  )
}
