import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { InspectionFormWrapper, ReviewIssuesComp } from '../../Elements';
import { PageLoader } from '../../../shared_elements';
import { getFormReviewListApi } from '../apiServices';
import { getInspectionHeaderApi } from '../../forms/t001/apiServices';
class InspectionReviewT003 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      inspectionHdInfo:{},
      t003ReviewList: []
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.getFormReviewListApi = getFormReviewListApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getFormReviewListApi(this.props, 't003ReviewList');
  }
  render(){
    const { t003ReviewList, inspectionHdInfo, skeletonLoader, pageLoader } = this.state;
    return(
      <Fragment>
        <InspectionFormWrapper reviewerForm={true} skeletonLoader={skeletonLoader} inspectionHdInfo={inspectionHdInfo} />
        <div className="technical-review-forms-cn">
          { t003ReviewList.map((section, sectionIndex) =>
            <div className="t003-section">
              <h3>{section.name}</h3>
              {section.sub_sections.map((subSection, subSectionIndex) =>
                <div className="t003-card">
                  <h4>{subSection.name}</h4>
                  {subSection.questions.map((question, questionIndex) =>
                    <div className="t003-question">
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <p>{question.name}</p>
                        </Grid>
                        <Grid item xs={4}>
                          <p>{question.answer.remarks ? question.answer.remarks:''}</p>
                        </Grid>
                        <Grid item xs={2}>
                          {question.answer.value?question.answer.value:''}
                          <span className="add-issue">
                            { question.issue && question.issue.id ?
                              <ReviewIssuesComp type="view" issue={question.issue} loadSectionFn={() => this.getFormReviewListApi(this.props, 't003ReviewList')}  />
                              :
                              <ReviewIssuesComp loadSectionFn={() => this.getFormReviewListApi(this.props, 't003ReviewList')} type="add" payload={{document_section_id:question.id,form_id:3}} />
                            }
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              )}

            </div>
          )}
        </div>
        { pageLoader ? <PageLoader /> :null }
      </Fragment>
    )
  }
}
export default InspectionReviewT003;
