import React from 'react';
import { Table, TableBody, TableRow, TableCell, Paper, Avatar } from '@material-ui/core';
import { ReviewIssuesComp } from '../../Elements';
export default function RobberyList({item, index, loadSectionFn}){
  return(
    <Paper className="t009-section">
      <p className="flex-centered"><Avatar className="part-number">{index+1}</Avatar><em>Part Number : {item.part_number}</em></p>
      <Table>
        <TableBody>
          <TableRow hover tabIndex={-1}>
            <TableCell>Part Description</TableCell>
            <TableCell>{item.part_description.value}</TableCell>
            <TableCell>
              <span className="add-issue">
                { item.part_description.issue && item.part_description.issue.id ?
                  <ReviewIssuesComp type="view" issue={item.part_description.issue} loadSectionFn={loadSectionFn}  />
                  :
                  <ReviewIssuesComp loadSectionFn={loadSectionFn} type="add" payload={{field_name:'part_description',robbery_list_id:item.id,form_id:9}} />
                }
              </span>
            </TableCell>
          </TableRow>
          <TableRow hover tabIndex={-1}>
            <TableCell>Removal Date</TableCell>
            <TableCell>{item.removal_date.value}</TableCell>
            <TableCell>
              <span className="add-issue">
                { item.removal_date.issue && item.removal_date.issue.id ?
                  <ReviewIssuesComp type="view" issue={item.removal_date.issue} loadSectionFn={loadSectionFn}  />
                  :
                  <ReviewIssuesComp loadSectionFn={loadSectionFn} type="add" payload={{field_name:'removal_date',robbery_list_id:item.id,form_id:9}} />
                }
              </span>
            </TableCell>
          </TableRow>
          <TableRow hover tabIndex={-1}>
            <TableCell>Location of the Part</TableCell>
            <TableCell>{item.part_location.value}</TableCell>
            <TableCell>
              <span className="add-issue">
                { item.part_location.issue && item.part_location.issue.id ?
                  <ReviewIssuesComp type="view" issue={item.part_location.issue} loadSectionFn={loadSectionFn}  />
                  :
                  <ReviewIssuesComp loadSectionFn={loadSectionFn} type="add" payload={{field_name:'part_location',robbery_list_id:item.id,form_id:9}} />
                }
              </span>
            </TableCell>
          </TableRow>
          <TableRow hover tabIndex={-1}>
            <TableCell>Date Verified</TableCell>
            <TableCell>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <h6>Removed from Aircraft</h6>
                  <span>{item.date_verified.value.date_verified_removed_from_aircraft}</span>
                </li>
                <li className="list-inline-item">
                  <h6>Verified in Storehouse</h6>
                  <span>{item.date_verified.value.date_verified_in_store_house}</span>
                </li>
                <li className="list-inline-item">
                  <h6>Installed on Aircraft</h6>
                  <span>{item.date_verified.value.date_verified_installed_on_aircraft}</span>
                </li>
              </ul>
            </TableCell>
            <TableCell>
              <span className="add-issue">
                { item.date_verified.issue && item.date_verified.issue.id ?
                  <ReviewIssuesComp type="view" issue={item.date_verified.issue} loadSectionFn={loadSectionFn}  />
                  :
                  <ReviewIssuesComp loadSectionFn={loadSectionFn} type="add" payload={{field_name:'date_verified',robbery_list_id:item.id,form_id:9}} />
                }
              </span>
            </TableCell>
          </TableRow>
          <TableRow hover tabIndex={-1}>
            <TableCell>Price</TableCell>
            <TableCell>
              {item.price.value}
            </TableCell>
            <TableCell>
              <span className="add-issue">
                { item.price.issue && item.price.issue.id ?
                  <ReviewIssuesComp type="view" issue={item.price.issue} loadSectionFn={loadSectionFn}  />
                  :
                  <ReviewIssuesComp loadSectionFn={loadSectionFn} type="add" payload={{field_name:'price',robbery_list_id:item.id,form_id:9}} />
                }
              </span>
            </TableCell>
          </TableRow>
          <TableRow hover tabIndex={-1}>
            <TableCell>Work Card No.</TableCell>
            <TableCell>
              {item.wcard_number.value}
            </TableCell>
            <TableCell>
              <span className="add-issue">
                { item.wcard_number.issue && item.wcard_number.issue.id ?
                  <ReviewIssuesComp type="view" issue={item.wcard_number.issue} loadSectionFn={loadSectionFn}  />
                  :
                  <ReviewIssuesComp loadSectionFn={loadSectionFn} type="add" payload={{field_name:'wcard_number',robbery_list_id:item.id,form_id:9}} />
                }
              </span>
            </TableCell>
          </TableRow>
          <TableRow hover tabIndex={-1}>
            <TableCell>Comments</TableCell>
            <TableCell>
              {item.comments.value}
            </TableCell>
            <TableCell>
              <span className="add-issue">
                { item.comments.issue && item.comments.issue.id ?
                  <ReviewIssuesComp type="view" issue={item.comments.issue} loadSectionFn={loadSectionFn}  />
                  :
                  <ReviewIssuesComp loadSectionFn={loadSectionFn} type="add" payload={{field_name:'comments',robbery_list_id:item.id,form_id:9}} />
                }
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}
