import React from 'react';
import { Drawer, Grid, Container, Avatar, Paper, TextField, Link, Tooltip, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import HeaderNotifyMenu from './HeaderNotifyMenu';
import MainNavLink from './MainNavLink';
import { getLocalStorageInfo } from '../../utils';
const MobileHeader = ({history}) => {
  const [state, setState] = React.useState({left: false});
  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };
  return (
    <div>
      <header className="mobile-header">
        <Container maxWidth={false}  style={{padding:'0px 15px'}}>
          <Grid container  spacing={0} alignItems="center" justify="center">
            <Grid item xs={12}>
              <MenuIcon
                color="primary"
                onClick={toggleDrawer('left', true)}
              />
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Tooltip title="What/'s New'" arrow>
                    <IconButton size="small" color="primary">
                      <FiberNewIcon />
                    </IconButton>
                  </Tooltip>
                </li>
                <li className="list-inline-item">
                  <Tooltip title="Report an Issue" arrow>
                    <IconButton size="small" color="primary">
                      <HeadsetMicIcon />
                    </IconButton>
                  </Tooltip>
                </li>
                <li className="list-inline-item">
                  <Tooltip title="FAQs" arrow>
                    <IconButton size="small" color="primary">
                      <LiveHelpIcon />
                    </IconButton>
                  </Tooltip>
                </li>
                <li className="list-inline-item">
                  <Tooltip title="Notifications" arrow>
                    <IconButton size="small" color="primary">
                      <NotificationsActiveIcon />
                    </IconButton>
                  </Tooltip>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </header>
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        <div className="mobile-main-navs" style={{width: '280px'}}>
          <Paper className="user-info">
            <div className="user-card">
              <Avatar className="user-avatar" alt={getLocalStorageInfo().user.name} src={getLocalStorageInfo().user.profile_pic} />
              <div>
                <h3>
                  {getLocalStorageInfo().user.name}
                  <span onClick={() => {localStorage.clear(); history.push('/login')}} style={{float:'right'}}><SettingsPowerIcon color="primary" /></span>
                </h3>
                <span>{getLocalStorageInfo().user.designation?getLocalStorageInfo().user.designation:''}</span>
              </div>
            </div>
            <Autocomplete
              options = {getLocalStorageInfo().lessor_list}
              clearOnBlur={false}
              getOptionLabel={option => option.name}
              disableClearable={true}
              filterSelectedOptions={true}
              value={{id:getLocalStorageInfo().defaultLessor.id, name: getLocalStorageInfo().defaultLessor.name}}
              onChange={(e, data) => {data ? console.log('data', data):e.preventDefault()}}
              renderInput={params => <TextField {...params} label="Accessing Lessor" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
            />
          </Paper>
          <Paper className="mobile-py-link" elevation={0}>
            <MainNavLink type="mobile" />
          </Paper>
        </div>
      </Drawer>
      <div style={{height:'50px'}}></div>
    </div>
  );
}
export default withRouter(MobileHeader);
