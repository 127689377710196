import moment from 'moment';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../utils';
import { fieldValidation } from '../../utils/formValidation';
import { errorCode, aircraftWorkOrderErrorCode } from './index';
import { regexConstants } from '../../constants/regEx';
export function getLinkAbleAssetApi(props={}, queryParams){
  this.setState({assetLoader:true});
  globalGetService(`console/list/`, queryParams)
  .then(response => {
    this.setState({assetLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        assetList: response.data.data.asset
      })
    }
  })
}

export function getProjectInitiateApi(props={}){
  return new Promise(
    function(resolve, reject){
      globalGetService(`technical/project-initiate/`)
      .then(response => {
        if(checkApiStatus(response)){
          resolve(response);
        }else{
          reject(response);
        }
      })
    }
  )
}

export function getProjectUsersApi(props={}, query={}){
  globalGetService(`technical/users/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      if(query.role === 'lead'){
        this.setState({projectLeads: response.data.data});
      }
      if(query.role === 'engineer'){
        this.setState({projectEngineers: response.data.data});
      }
      if(query.role === 'member'){
        this.setState({projectMembers: response.data.data})
      }
    }
  })
}

export function getProjectInspectionsApi(props={}){
  globalGetService(`technical/inspection-types/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({inspectionTypes: response.data.data})
    }
  })
}

export function getProjectFormsApi(props={}, query={}){
  globalGetService(`technical/forms/`, query)
  .then(response => {
    if(query && query.inspection_type){
      this.setState(prevState => ({
        ...prevState,
        workOrderInfo: {
          ...prevState.workOrderInfo,
          data: {
            ...prevState.workOrderInfo.data,
            forms: response.data.data.map(form => form.id)
          }
        }
      }))
    }else if (query && query.both) {
      this.setState({allForms: response.data.data})
    }else{
      this.setState({forms: response.data.data})
    }
  })
}

export function getProjectBlueprintsApi(props={}){
  globalGetService(`technical/blueprints/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({blueprints: response.data.data})
    }
  })
}

export function uploadEngineWorkorderAttachmentApi(props={}, data){
  return new Promise(
    function(resolve, reject){
      globalFileUploadService(`technical/engine-workorder/file-upload/`,data)
      .then(response => {
        resolve(response)
      })
    }
  )
}

export function getProjectsApi(props={}, query={}, loaderType){
  this.setState({[loaderType]: true});
  globalGetService(`technical/projects/`,query)
  .then(response => {
    this.setState({[loaderType]: false});
    if(checkApiStatus(response)){
      delete query.sort;
      delete query.sort_by;
      delete query.page;
      delete query.per_page;
      delete query.project_status;
      this.setState({
        projectsInfo: response.data.data,
        filter: query
      })
    }
  })
}
export function exportProjectsApi(props={}, query={}, file){
  this.setState({pageLoader: true});
  globalExportService(`technical/projects/`,{...query, download:file.extension})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`Project_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), file.extension);
  })
}

export function getProjectDetailsApi(props={}, projectSlug, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/projects/${ projectSlug ? projectSlug:props.match.params.projectSlug}/`,query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        project: {data:response.data.data, error:{}, modal:true, mode:'edit'}
      })
    }
  })
}

export function getProjectApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/project/${props.match.params.projectSlug}/`,query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        project: response.data.data
      })
    }
  })
}

export function addEditProjectApi(props={}, data={}){
  let clientContactFlag = true;
  let clientContactError = [];
  let operatorContactFlag = true;
  let operatorContactError = [];
  let thirdPartyContactFlag = true;
  let thirdPartyContactError = [];
  let validationInputs = {
    name:errorCode['name'][fieldValidation({...errorCode['nameObj'], fieldval: data.name})],
    start_date:errorCode['start_date'][fieldValidation({...errorCode['start_dateObj'], fieldval: data.start_date})],
    expected_end_date:errorCode['expected_end_date'][fieldValidation({...errorCode['expected_end_dateObj'], fieldval: data.expected_end_date})],
    leads:errorCode['leads'][fieldValidation({...errorCode['leadsObj'], fieldval: data.leads})],
    engineers:errorCode['engineers'][fieldValidation({...errorCode['engineersObj'], fieldval: data.engineers})],
    project_remarks:data.project_form === 1 ? errorCode['project_remarks'][fieldValidation({...errorCode['project_remarksObj'], fieldval: data.project_remarks})] : '',
  }
  clientContactError = data.client_contacts.map(item => {
    return {
      email: item.email && item.email.trim() !== '' ? regexConstants.email.test(item.email) ? '':'Valid email only':'',
      phone_number: item.phone_number && item.phone_number.trim() !== '' ? regexConstants.phoneNumber.test(item.phone_number) ? '':'Valid phone number only':'',
    }
  })
  clientContactError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      clientContactFlag = false
    }
  });
  operatorContactError = data.operator_contacts.map(item => {
    return {
      email: item.email && item.email.trim() !== '' ? regexConstants.email.test(item.email) ? '':'Valid email only':'',
      phone_number: item.phone_number && item.phone_number.trim() !== '' ? regexConstants.phoneNumber.test(item.phone_number) ? '':'Valid phone number only':'',
    }
  })
  operatorContactError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      operatorContactFlag = false
    }
  });
  thirdPartyContactError = data.third_party_contacts.map(item => {
    return {
      email: item.email && item.email.trim() !== '' ? regexConstants.email.test(item.email) ? '':'Valid email only':'',
      phone_number: item.phone_number && item.phone_number.trim() !== '' ? regexConstants.phoneNumber.test(item.phone_number) ? '':'Valid phone number only':'',
    }
  })
  thirdPartyContactError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      thirdPartyContactFlag = false
    }
  });
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) && clientContactFlag && operatorContactFlag && thirdPartyContactFlag){
    this.setState({formSubmitLoader:true});
    if(data.id){
      globalPutService(`technical/projects/${data.slug}/`, data)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          window.location.reload();
        }
      })
    }else{
      let formData = new FormData();
      Object.keys(data).map(item => {
        if(typeof data[item] == 'object' && item != 'files'){
          formData.append(item, JSON.stringify(data[item]))
        }else if(item === 'files' && data.files){
          let index = 1
          for(let file of data.files){
            formData.append('file_' + index, file)
            index++
          }
        }else{
          formData.append(item, data[item])
        }
      });
      globalPostService(`technical/projects/`, formData)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          props.history.push(`/project/view/${response.data.data.slug}`);
        }
      })
    }
  }else{
      let error = validationInputs;
      error = {
      ...error,
        client_contacts: clientContactError,
        operator_contacts: operatorContactError,
        third_party_contacts: thirdPartyContactError
      }
      this.setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          error: error
        }
      }))
    }
}

export function addUserToProjectApi(props={}, usersInfo){
  debugger
  /**
  1: Add Users as Engineer to ProjectView (project_role:2, operator_level:null)
  2: Add User as Engineer to Form
  3: Add Users as Lead to Project (project_role:1, operator_level:null)
  4: Add Users as Current Lessee Engineers (project_role:3, operator_level:1)
  5: Add Users as Next Lessee Engineers (project_role:3, operator_level:2)
  6: Add Users as Client & Third Party (project_role:1, operator_level:null)

  */
  this.setState({pageLoader:true});
  if(['1', '3', '4', '5', '6'].includes(usersInfo.type)){
    globalPostService(`technical/project/${props.match.params.projectSlug}/add-user/`, {add_user_list: usersInfo.selectedUser.map(user =>  { return {user_id:user, project_role: usersInfo.type === '1' ? 2: usersInfo.type === '3'? 1:3,operator_level: usersInfo.type === '4' ? 1: usersInfo.type === '5'? 2:null}})})
    .then(response => {
      if(checkApiStatus(response)){
        this.setState({
          userListPopup:{
            modal:false,
            userList:[],
            selectedUser:[],
            title: '',
            type:''
          }
        });
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        this.getProjectApi(props);
      }else{
        this.setState({pageLoader:false});
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }else{
    globalPostService(`technical/${usersInfo.asset_type === 2 ? 'engine-workorder':'workorder'}/${usersInfo.workOrderSlug}/form/${usersInfo.formSlug}/assign-user/`, {assign_to_all:usersInfo.assign_to_all ? true:false, user_id:usersInfo.selectedUser[0]})
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState({
          userListPopup:{
            modal:false,
            userList:[],
            selectedUser:[],
            title: '',
            type:''
          }
        });
        this.getProjectApi(props);
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
}

export function removeUserFromProjectApi(props={}, user={}){
  this.setState({pageLoader:true});
  globalDeleteService(`technical/project/${props.match.params.projectSlug}/remove-user/`, user)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        deleteModal:false,
        deleteIds:[],
      });
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.getProjectApi(props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}


// export function getWorkOrderAssetDetailApi(props={}, assetSlug, workOrder)
export function lunchWorkOrderApi(props={}, workOrderSlug, asset){
  globalGetService(`technical/${asset.type === 2? 'engine-workorder':'workorder'}/${workOrderSlug}/launch/`)
  .then(response => {
    if(checkApiStatus(response)){

    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getEngineDetailWoApi(props, engineSLug){
  globalGetService(`technical/engine/${engineSLug}/details/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        engine: response.data.data
      })
    }
  })
}

export function getEngineWorkOrderAPI(props={}){
  globalGetService(`technical/project/${props.match.params.projectSlug}/engine-workorder/${props.match.params.workOrderSlug}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({engine:response.data.data})
    }
  })
}
export function addEditEngineWorkOrderApi(props, data){
  let shopVisitFlag = true;
  let shopVisitError = [];
  let validationInputs = {
    aircraft_msn:errorCode['aircraft_msn'][fieldValidation({...errorCode['aircraft_msnObj'], fieldval: data.aircraft_msn})],
    engine_type_id:errorCode['engine_type_id'][fieldValidation({...errorCode['engine_type_idObj'], fieldval: data.engine_type_id})],
    esn:errorCode['esn'][fieldValidation({...errorCode['esnObj'], fieldval: data.esn})],
    tsn:errorCode['tsn'][fieldValidation({...errorCode['tsnObj'], fieldval: data.tsn})],
    csn:errorCode['csn'][fieldValidation({...errorCode['csnObj'], fieldval: data.csn})],
    operated_by:errorCode['operated_by'][fieldValidation({...errorCode['operated_byObj'], fieldval: data.operated_by})],
    location:errorCode['location'][fieldValidation({...errorCode['locationObj'], fieldval: data.location})],
    etops:errorCode['etops'][fieldValidation({...errorCode['etopsObj'], fieldval: data.etops})],
    engine_manufacturing_date:errorCode['engine_manufacturing_date'][fieldValidation({...errorCode['engine_manufacturing_dateObj'], fieldval: data.engine_manufacturing_date})],
    constructed_model:errorCode['constructed_model'][fieldValidation({...errorCode['constructed_modelObj'], fieldval: data.constructed_model})],
    inspection_performed_by:errorCode['inspection_performed_by'][fieldValidation({...errorCode['inspection_performed_byObj'], fieldval: data.inspection_performed_by})],
  }

  shopVisitError = data.shop_visits.map(item => {
    return {
      shop_visit_number: item.shop_visit_number.trim() === '' ? 'Please enter Shop Visit Number' :  regexConstants.alphanumericWithHyphenSpace.test(item.shop_visit_number) ? '':'Alphanumeric characters only. Space and Hyphen optional.',
      tsn: item.tsn === null || item.tsn.toString().trim() === '' ? 'Please enter TSN' : '',
      csn: item.csn === null || item.csn.toString().trim() === '' ? 'Please enter CSN' : '',
    }
  })
  shopVisitError.map(data => {
    if(!Object.keys(data).every((k) => { return data[k] === ''})){
      shopVisitFlag = false
    }
  });
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) && shopVisitFlag){
    this.setState({pageLoader:true});
    if(props.match.params.workOrderSlug){
      globalPutService(`technical/project/${props.match.params.projectSlug}/engine-workorder/${props.match.params.workOrderSlug}/`, {data:data})
      .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          props.history.push(`/project/view/${props.match.params.projectSlug}`);
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      globalPostService(`technical/project/${props.match.params.projectSlug}/create-engine-workorder/`, {data:data})
      .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          props.history.push(`/project/view/${props.match.params.projectSlug}`);
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }
  }else{
    this.setState({error: validationInputs});
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        shop_visits: shopVisitError,
      }
    }));
  }
}
export function getWorkOrderAssetApi(props={}, workOrder, assetType, assetSlug){
  globalGetService(`technical/${assetType}/${assetSlug}/work-order-details/`,)
  .then(response => {
    if(checkApiStatus(response)){
      let asset = Object.assign({}, response.data.data);
      delete asset['id']; delete asset['msn']; delete asset['slug'];
      this.setState(prevState => ({
        ...prevState,
        workOrderInfo: {
          ...prevState.workOrderInfo,
          data: {...workOrder, ...asset}
        }
      }))
    }
  })
}
export function addEditWorkOrderApi(props={}, data={}, assetType){
  let payload = Object.assign({}, data.data);
  payload = {
    ...payload,
    inspection_types: [data.data.inspection_types]
  }
  let validationInputs = {};
  if(payload.aircraft_id){
    validationInputs = {
      start_date:aircraftWorkOrderErrorCode['start_date'][fieldValidation({...aircraftWorkOrderErrorCode['start_dateObj'], fieldval: data.data.start_date})],
      expected_end_date:aircraftWorkOrderErrorCode['expected_end_date'][fieldValidation({...aircraftWorkOrderErrorCode['expected_end_dateObj'], fieldval: data.data.expected_end_date})],
      aircraft_type_id:aircraftWorkOrderErrorCode['aircraft_type_id'][fieldValidation({...aircraftWorkOrderErrorCode['aircraft_type_idObj'], fieldval: data.data.aircraft_type_id})],
      blueprint_id:aircraftWorkOrderErrorCode['blueprint_id'][fieldValidation({...aircraftWorkOrderErrorCode['blueprint_idObj'], fieldval: data.data.blueprint_id})],
      date_of_manufacture:aircraftWorkOrderErrorCode['date_of_manufacture'][fieldValidation({...aircraftWorkOrderErrorCode['date_of_manufactureObj'], fieldval: data.data.date_of_manufacture})],
      location:aircraftWorkOrderErrorCode['location'][fieldValidation({...aircraftWorkOrderErrorCode['locationObj'], fieldval: data.data.location})],
      registration:aircraftWorkOrderErrorCode['registration'][fieldValidation({...aircraftWorkOrderErrorCode['registrationObj'], fieldval: data.data.registration})],
      line_number:aircraftWorkOrderErrorCode['line_number'][fieldValidation({...aircraftWorkOrderErrorCode['line_numberObj'], fieldval: data.data.line_number})],
      operator_storage_facility:aircraftWorkOrderErrorCode['operator_storage_facility'][fieldValidation({...aircraftWorkOrderErrorCode['operator_storage_facilityObj'], fieldval: data.data.operator_storage_facility})],
      aoc_regulation:aircraftWorkOrderErrorCode['aoc_regulation'][fieldValidation({...aircraftWorkOrderErrorCode['aoc_regulationObj'], fieldval: data.data.aoc_regulation})],
      inspection_types:aircraftWorkOrderErrorCode['inspection_types'][fieldValidation({...aircraftWorkOrderErrorCode['inspection_typesObj'], fieldval: data.data.inspection_types})],
      forms:data.data.forms.length ? '':'Please select Forms',
    }
  }else{
    validationInputs = {
      [assetType]:aircraftWorkOrderErrorCode[assetType][fieldValidation({...aircraftWorkOrderErrorCode[assetType+'Obj'], fieldval: data.data[assetType]})],
      start_date:aircraftWorkOrderErrorCode['start_date'][fieldValidation({...aircraftWorkOrderErrorCode['start_dateObj'], fieldval: data.data.start_date})],
      expected_end_date:aircraftWorkOrderErrorCode['expected_end_date'][fieldValidation({...aircraftWorkOrderErrorCode['expected_end_dateObj'], fieldval: data.data.expected_end_date})],
      date_of_manufacture:aircraftWorkOrderErrorCode['date_of_manufacture'][fieldValidation({...aircraftWorkOrderErrorCode['date_of_manufactureObj'], fieldval: data.data.date_of_manufacture})],
      location:aircraftWorkOrderErrorCode['location'][fieldValidation({...aircraftWorkOrderErrorCode['locationObj'], fieldval: data.data.location})],
      inspection_types:aircraftWorkOrderErrorCode['inspection_types'][fieldValidation({...aircraftWorkOrderErrorCode['inspection_typesObj'], fieldval: data.data.inspection_types})],
      forms:data.data.forms.length ? '':'Please select Forms',
    }
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
    this.setState({pageLoader:true});
    if(payload.slug){
      globalPutService(`technical/project/${props.match.params.projectSlug}/workorder/${payload.slug}/`, payload)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          window.location.reload();
        }else{
          this.setState({pageLoader:false});
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      globalPostService(`technical/project/${props.match.params.projectSlug}/workorder/`, payload)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          window.location.reload();
        }else{
          this.setState({pageLoader:false});
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }
  }else{
    this.setState(prevState => ({
      ...prevState,
      workOrderInfo: {
        ...prevState.workOrderInfo,
        error: validationInputs
      }
    }))
  }
}

export function getWorkOrderDetail(props={}, workOrderSlug){
  globalGetService(`technical/project/${props.match.params.projectSlug}/workorder/${workOrderSlug}/`)
  .then(response => {
    if(checkApiStatus(response)){
      let wk = response.data.data;
      wk = {
        ...wk,
        inspection_types: response.data.data.inspection_types && response.data.data.inspection_types.length ? response.data.data.inspection_types[0] :null
      }
      this.setState(prevState => ({
        ...prevState,
        workOrderInfo:{
          data: wk,
          modal:true,
          mode:'edit',
          error:{}
        }
      }))
    }
  })
}
export function getAssetWorkOrderLinkApi(props={}, assetType, assetSlug, workOrder){
  let assetTypes = {
    1: 'aircraft',
    3:'apu',
    4:'lg',
    5:'propeller'
  }
  let wk = {
    forms:workOrder.forms.map(form => form.id),
    inspection_types: workOrder.inspection_types && workOrder.inspection_types.length ? workOrder.inspection_types[0].id:null ,
    start_date:workOrder.start_date ? workOrder.start_date:null,
    expected_end_date:workOrder.expected_end_date ? workOrder.expected_end_date:null,
    location:workOrder.location ? workOrder.location:'',
    slug:workOrder.slug,
    physical_inspection_on_start_date: workOrder.physical_inspection_on_start_date ?workOrder.physical_inspection_on_start_date:null,
    physical_inspection_on_end_date: workOrder.physical_inspection_on_end_date ?workOrder.physical_inspection_on_end_date:null,
    records_inspection_on_start_date:workOrder.records_inspection_on_start_date ?workOrder.records_inspection_on_start_date:null,
    records_inspection_on_end_date:workOrder.records_inspection_on_end_date ?workOrder.records_inspection_on_end_date:null
  };
  globalGetService(`technical/${assetTypes[assetType]}/${assetSlug}/work-order-details/`)
  .then(response => {
    if(checkApiStatus(response)){
      let assetDetail = {
        date_of_manufacture:response.data.data.date_of_manufacture ? response.data.data.date_of_manufacture:null,
        tsn:response.data.data.tsn ? response.data.data.tsn:'',
        csn:response.data.data.csn ? response.data.data.csn:'',
        status_as_of:response.data.data.status_as_of ? response.data.data.status_as_of:null,
        operator_storage_facility: response.data.data.operator_storage_facility ? response.data.data.operator_storage_facility:'',
      }
      if(response.data.data.asset_type === 3){
        assetDetail = {
          ...assetDetail,
          apu_id: response.data.data.apu_id
        }
      }else if (response.data.data.asset_type === 4) {
        assetDetail = {
          ...assetDetail,
          lg_id: response.data.data.lg_id
        }
      }else if (response.data.data.asset_type === 5) {
        assetDetail = {
          ...assetDetail,
          propeller_id: response.data.data.propeller_id
        }
      }
      this.setState(prevState => ({
        ...prevState,
        workOrderInfo: {
          ...prevState.workOrderInfo,
          data: {...wk, ...assetDetail},
          modal:true,
          mode:'edit',
          error:{}
        }
      }));
    }
  })
}
export function getEngineerProfileApi(props={}, slug=''){
  globalGetService(`technical/users/${slug}/details/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        userProfile: {data:response.data.data, modal:true}
      })
    }
  })
}
export function getProjectCorFormApi(props={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/project/${props.match.params.projectSlug}/cor/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        corFormDetail: response.data.data
      })
    }
  })
}
export function exportProjectCorFormApi(props={}, file){
  this.setState({pageLoader:true});
  globalExportService(`technical/project/${props.match.params.projectSlug}/cor/`, file)
  .then(response => {
    this.setState({pageLoader:false});
    downloadFileType(response.data, (`Project_COR_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), 'pdf');
    this.props.enqueueSnackbar('Project COR Report Downloaded Successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function updateProjectCorFormApi(props={}, data){
  this.setState({pageLoader:true});
  globalPostService(`technical/project/${props.match.params.projectSlug}/save-cor/`, {data:data})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.getProjectCorFormApi(props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function clientAccessProjectCorFormApi(props={}, data){
  this.setState({pageLoader:true});
  globalPutService(`technical/project/${props.match.params.projectSlug}/cor-client/status/`, data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        corFormDetail: {
          ...prevState.corFormDetail,
          client_cor_show:data.client_cor_show
        }
      }));
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function getProjectFeedbackFormApi(props={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/project/${props.match.params.projectSlug}/feedback_list/`)
  .then(response => {
    this.setState({pageLoader:false});
    this.setState({
      feedbackForm: {
          "questions": [{
            "id": 1,
            "question": "How satisfied were you with the inspector's quality of service?",
            "answers": []
          }, {
            "id": 2,
            "question": "Provide a rating on the time taken to submit the project report?",
            "answers": []
          }, {
            "id": 3,
            "question": "Provide a rating on the quality of pictures taken by the inspector",
            "answers": []
          }, {
            "id": 4,
            "question": "Provide a rating on the quality of records review performed by the inspector",
            "answers": []
          }, {
            "id": 5,
            "question": "Provide a rating on the quality of the daily reports sent by the inspector",
            "answers": []
          }, {
            "id": 6,
            "question": "Provide a rating on the inspectors understanding and knowledge of the project requirements",
            "answers": []
          }, {
            "id": 7,
            "question": "Provide a rating on the inspectors communication skills and inter-personal skills observed during the project",
            "answers": []
          }],
          "users": [{
            "id": 105,
            "name": "Murugan Engineer",
            "profile_pic": null
          }, {
            "id": 113,
            "name": "Babu ",
            "profile_pic": null
          }, {
            "id": 431,
            "name": "ARAVIND SHEKAR C",
            "profile_pic": "https://ams3.digitaloceanspaces.com/aims-dev/media-dev/users/WhatsApp_Image_2020-01-27_at_2.48.35_PM1592818813.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=4MFZU7TBCN3FWTR7ZZLJ%2F20200821%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20200821T050516Z&X-Amz-Expires=259200&X-Amz-SignedHeaders=host&X-Amz-Signature=21f6674bd19295d33c0f77a7de5fe2bc1f9e077254fa56d4ba8936f031860875"
          }],
          "allow_submit": true
        }
    })
  })
}
export function updateProjectFeedbackFormApi(props={}, data){
  this.setState({pageLoader:true});
  globalPostService(`technical/project/${props.match.params.projectSlug}/feedback_update/`, {data:data})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({pageLoader:false});
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
