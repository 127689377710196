import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel,  FormControlLabel, Tooltip, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import { TableListComp, CkEditorText, PageLoader, LabelValueCard } from '../../../shared_elements';
import ProjectContact from './ProjectContact';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { getProjectDetailsApi, addEditProjectApi, getProjectInitiateApi, getProjectUsersApi } from '../apiServices';
import { getPlatformConstantsAc, getLesseeListAc } from '../../../shared_elements/actionCreators';
import { createProject, projectContactHd } from '../../';
import { fieldValidation } from '../../../utils/formValidation';
import { errorCode } from '../';
class ProjectCRU extends Component {
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader: false,
      pageLoader:false,
      projectLeads:[],
      projectEngineers:[],
      project:{
        data:{},
        error:{},
        modal:false,
        mode:''
      },
      error:{}
    }
    this.getProjectDetailsApi = getProjectDetailsApi.bind(this);
    this.addEditProjectApi = addEditProjectApi.bind(this);
    this.getProjectInitiateApi = getProjectInitiateApi.bind(this);
    this.getProjectUsersApi = getProjectUsersApi.bind(this);
  }
  toggleModalFn = (data, projectSlug) => {
    if(data.modal){
      if(!projectSlug){
        let project = Object.assign({}, data);
        this.getProjectInitiateApi(this.props)
        .then(response => {
          project = {
            ...project,
            data: {
              ...project.data,
              instructions:response.data.data.project_general_instruction,
              miscellaneous_comments: response.data.data.miscellaneous_comments,
              equipment_requirements: response.data.data.equipment_requirements
            }
          }
          this.setState({project: project});
        });
      }else{
        this.getProjectDetailsApi(this.props, projectSlug);
      }
      this.getProjectUsersApi(this.props, {role: 'lead'});
      this.getProjectUsersApi(this.props, {role: 'engineer'});
      this.props.getLesseeList();
      this.props.getPlatformConstants();
    }else{
      this.setState({project: data});
    }
  }
  onFieldChange = (event, keyParam, data, contactType, index) => {
    if(contactType){
      this.setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          data: {
            ...prevState.project.data,
            [contactType]: prevState.project.data[contactType].map((item, contactIndex) => contactIndex === index ?
              {...item, [keyParam]: data}:item
            )
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          data: {
            ...prevState.project.data,
            [keyParam]:data
          }
        }
      }))
    }
  }
  addNewContact = (type) => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        data: {
          ...prevState.project.data,
          [type]: [...prevState.project.data[type], {name:'', type:null, email:'', designation:'', phone_number:''}]
        }
      }
    }))
  }
  removeContact = (type, index) => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        data: {
          ...prevState.project.data,
          [type]: prevState.project.data[type].filter((item, contactIndex) => contactIndex !== index)
        }
      }
    }))
  }
  handleError = (value, key) => {
    this.setState((prevState)=> ({
      ...prevState,
      project: {
        ...prevState.project,
      error: {
        ...prevState.project.error,
        [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
      }
    }
    }))
  }

  resetErrorKey = (key) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        project: {
          ...prevState.project.error,
          [key]: ''
        }
      }
    }));
  }
  render(){
    const { project, projectLeads, projectEngineers, onFieldChange,  handleError, resetErrorKey, formSubmitLoader, pageLoader } = this.state;
    const { lessee, pltConstants, id, projectSlug } = this.props;
    return(
      <Fragment>
        { id ?
          projectSlug ?
          <Tooltip title="Edit" arrow>
            <IconButton size="small" color="primary">
              <EditIcon onClick={() => this.toggleModalFn({data:createProject, error:{}, modal:true, mode:''},projectSlug)} fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          :
          <span onClick={() => this.toggleModalFn({data:createProject, error:{}, modal:true, mode:''},this.props.match.params.projectSlug)}><Tooltip title="Edit Project" arrow><EditIcon fontSize="small" color="primary" /></Tooltip></span>:
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => this.toggleModalFn({data:createProject, error:{}, modal:true, mode:''})}
          >
            Add Project
          </Button>
        }
        { project.modal && lessee.length && pltConstants.length && projectLeads.length && projectEngineers.length ?
          <Dialog
            fullScreen
            open={project.modal}
            onClose={() => this.toggleModalFn({data:{}, error:{}, modal:false, mode:''})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">{project.data.id ? 'Edit':'Add'} Project</DialogTitle>
            <DialogContent dividers={true}>
              <form className="add-edit-project-modal">
                <Grid container spacing={3}>
                  { project.data.slug ?
                    <LabelValueCard md={6} label='Project Name' value={project.data.name} />:
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options = {pltConstants.filter(item => item.type === 'project_name')}
                        getOptionLabel={option => option.label}
                        id="name"
                        value={project.data.name ? pltConstants.filter(item => item.type === 'project_name').find(projectList => projectList.label === project.data.name) :null}
                        onChange={(e, value) => { this.onFieldChange(e, 'name', value ?  value.label:value); this.handleError(value ? value.label:'','name') }}
                        renderInput={params => <TextField required onFocus={(e) => this.resetErrorKey('name')} error={project.error.name ? true:false} helperText={project.error.name ? project.error.name:''} {...params} label="Project Name" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                  }
                  <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         required
                         id="start_date"
                         label="Start Date"
                         format={fieldDateFormat}
                         fullWidth
                         disableFuture={true}
                         clearable={true}
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         value={project.data.start_date ? project.data.start_date:null}
                         error={project.error.start_date ? true:false }
                         helperText={project.error.start_date ? project.error.start_date: ''}
                         onChange={(data, value) => {this.onFieldChange(value, 'start_date', data ? moment(data).format(backendDateFormat):data); data ? this.resetErrorKey('start_date'): this.handleError(null, 'start_date')}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         margin="normal"
                         required
                         id="expected_end_date"
                         label="Expected End Date"
                         format={fieldDateFormat}
                         fullWidth
                         clearable={true}
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         value={project.data.expected_end_date ? project.data.expected_end_date:null}
                         minDate={ project.data.start_date ? moment(project.data.start_date).add(30, 'days'):moment().add(30, 'days')}
                         error={project.error.expected_end_date ? true:false }
                         helperText={project.error.expected_end_date ? project.error.expected_end_date: ''}
                         onChange={(data, value) => {this.onFieldChange(value, 'expected_end_date', data ? moment(data).format(backendDateFormat):data); data ? this.resetErrorKey('expected_end_date'): this.handleError(null, 'expected_end_date')}}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      options = {projectLeads}
                      getOptionLabel={option => option.name}
                      id="leads"
                      multiple
                      value={project.data.leads && project.data.leads.length ? projectLeads.filter(lead => project.data.leads.includes(lead.id)):[]}
                      // onChange={(e, value) => this.onFieldChange(e, 'leads', value ? value.map(lead => lead.id) :value)}
                      // renderInput={params => <TextField required {...params} label="Project Lead" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      onChange={(e, value) => { this.onFieldChange(e, 'leads', value ?  value.map(lead => lead.id) :value); this.handleError(value ? value.map(lead => lead.id):'','leads') }}
                      renderInput={params => <TextField required onFocus={(e) => this.resetErrorKey('leads')} error={project.error.leads ? true:false} helperText={project.error.leads ? project.error.leads:''} {...params} label="Project Lead" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      options = {projectEngineers}
                      getOptionLabel={option => option.name}
                      id="engineers"
                      multiple
                      value={project.data.engineers && project.data.engineers.length ? projectEngineers.filter(engineer => project.data.engineers.includes(engineer.id)):[]}
                      // onChange={(e, value) => this.onFieldChange(e, 'engineers', value ? value.map(engineer => engineer.id) :value)}
                      // renderInput={params => <TextField required {...params} label="Project Engineers" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      onChange={(e, value) => { this.onFieldChange(e, 'engineers', value ?  value.map(engineers => engineers.id) :value); this.handleError(value ? value.map(engineers => engineers.id):'','engineers') }}
                      renderInput={params => <TextField required onFocus={(e) => this.resetErrorKey('engineers')} error={project.error.engineers ? true:false} helperText={project.error.engineers ? project.error.engineers:''} {...params} label="Project Engineers" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      options = {pltConstants.filter(item => item.type === 'project_departments')}
                      getOptionLabel={option => option.label}
                      id="department"
                      value={project.data.department ? pltConstants.filter(item => item.type === 'project_departments').find(dept => dept.value === project.data.department):null}
                      onChange={(e, value) => this.onFieldChange(e, 'department', value?value.value:value)}
                      renderInput={params => <TextField {...params} label="Project Department" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      options = {lessee}
                      getOptionLabel={option => option.name}
                      id="lessee_id"
                      value={project.data.lessee_id ?lessee.find(les => les.id === project.data.lessee_id)  :null}
                      onChange={(e, value) => this.onFieldChange(e, 'lessee_id', value ? value.id:value)}
                      renderInput={params => <TextField {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="location"
                      label="Location"
                      fullWidth
                      margin="normal"
                      inputProps={{maxLength: 30}}
                      value={project.data.location}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'location', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="third_party"
                      label="Third Party"
                      fullWidth
                      margin="normal"
                      inputProps={{maxLength: 20}}
                      value={project.data.third_party}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'third_party', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="zoho_opportunity_id"
                      label="ZOHO Enquiry ID"
                      fullWidth
                      margin="normal"
                      inputProps={{maxLength: 20}}
                      value={project.data.zoho_opportunity_id}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'zoho_opportunity_id', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox color="primary" checked={project.data.cor_required} onChange={(e) => this.onFieldChange(e, 'cor_required', e.target.checked)} value="COR (Confidential Operator Report) Required" />
                      }
                      label="COR (Confidential Operator Report) Required"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox color="primary" checked={project.data.project_form} onChange={(e) => this.onFieldChange(e, 'project_form', e.target.checked ? 1:0)} value="Miscellaneous Services" />
                      }
                      label="Miscellaneous Services"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="project_remarks"
                      label="Project Description"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="4"
                      inputProps={{maxLength: 255}}
                      value={project.data.project_remarks}
                      InputLabelProps={{shrink: true}}
                      error={project.error.project_remarks ? true:false }
                      helperText={project.error.project_remarks ? project.error.project_remarks: ''}
                      onChange={(e) => this.onFieldChange(e, 'project_remarks', e.target.value)}
                      onBlur={(e) => this.handleError(e.target.value,'project_remarks')}
                      onFocus={(e) => this.resetErrorKey('project_remarks')}
                    />
                  </Grid>
                  {project.data.id ?
                    <Fragment>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="closure_notes"
                          label="Closure Notes"
                          fullWidth
                          margin="normal"
                          multiline
                          rowsMax="4"
                          inputProps={{maxLength: 255}}
                          value={project.data.closure_notes}
                          InputLabelProps={{shrink: true}}
                          onChange={(e) => this.onFieldChange(e, 'closure_notes', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                             margin="normal"
                             id="operator_contacted_at"
                             label="Operator Contacted at"
                             format={fieldDateFormat}
                             fullWidth
                             disableFuture={true}
                             clearable={true}
                             InputLabelProps={{shrink: true}}
                             inputProps={{readOnly: true}}
                             value={project.data.operator_contacted_at ? project.data.operator_contacted_at:null}
                             onChange={(data, value) => {this.onFieldChange(value, 'operator_contacted_at', data ? moment(data).format(backendDateFormat):data);}}
                           />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Fragment>:null
                  }
                  {!project.data.id ?
                    <Grid item xs={12} md={12}>
                      <label className="for-label">Project Attachment</label>
                      <div>
                        <input
                          accept=".xlsx,.xls,.doc,.docx,.png,.jpeg,.jpg,.pdf"
                          id="contained-button-file"
                          type="file"
                          style={{display:'none'}}
                          onChange={(files) => this.onFieldChange('', 'files', files)}
                        />
                        <label htmlFor="contained-button-file">
                          <Button size="small" variant="contained" color="primary" component="span">
                            Add Attachment
                          </Button>
                        </label>
                      </div>
                    </Grid>:null
                  }

                  <Grid item xs={12} md={12}>
                    <div className="project-contact">
                      <h4>
                        Contacts
                        <Button
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={() => this.addNewContact('contacts')}
                        >
                          Add Contact
                        </Button>
                      </h4>
                      <TableListComp
                        heads={projectContactHd}
                        data={project.data.contacts.map((contact,index) =>
                          <ProjectContact
                            item={contact}
                            rowCount={project.data.contacts.length}
                            index={index+1}
                            error={project.error.contacts && project.error.contacts.length && project.error.contacts[index] ? project.erro.contacts[index]:{}}
                            onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'contacts', index)}
                            previewDetail={() => this.removeContact('contacts', index)}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  {[{label:'General Instruction', key:'instructions'},{label:'Miscellaneous comments', key:'miscellaneous_comments'},{label:'Equipment requirement', key:'equipment_requirements'}].map((item) =>
                    <Grid item xs={12} md={6}>
                      <label className="for-label">{item.label}</label>
                      <CkEditorText
                        htmlData={project.data[item.key]}
                        onChangeData={(data) => this.onFieldChange('', item.key, data)}
                      />
                    </Grid>
                  )}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              { !formSubmitLoader ?
                <Button color="primary" onClick={() => this.toggleModalFn({data:{}, error:{}, modal:false, mode:''})}>Cancel</Button>:null
              }

              { project.data.id ?
                <Fragment>
                  {
                    // <Button color="secondary" variant="outlined">Cancel Project</Button>
                    // <Button color="secondary" variant="outlined">Archive Project</Button>
                    // <Button color="secondary" variant="outlined">Close Project</Button>
                  }
                </Fragment>:null
              }
              <Button onClick={() => this.addEditProjectApi(this.props, project.data)} variant="contained" color="primary" disabled={formSubmitLoader}>
                { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} /> : 'Save' }
              </Button>
            </DialogActions>
          </Dialog>:null
        }
        { project.modal ?
          lessee.length && pltConstants.length && projectLeads.length && projectEngineers.length ? null:<PageLoader/>:null
        }
        { pageLoader ? <PageLoader />:null}
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  lessee: state.shareReducer.lessee,
  pltConstants: state.shareReducer.pltConstants
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLesseeList: () => dispatch(getLesseeListAc()),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['project_departments', 'project_name']}))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectCRU))
