import React from 'react';
import { Button, Grid } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const PRSAnalytic = ({gapReport, selectedListItem, userStats}) => {
  let graphPieOptions = {
    chart:{
      type: 'pie',
      height: 550,
    },
    title:{text: null},
    plotOptions:{
      pie:{
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 35,
        dataLabels:{
          enabled: true,
          format: '{point.name}'
        }
      }
    },
    series:[
      {
        name: 'Item',
        colorByPoint: true,
        data:[
          {
            name: 'Open',
            color: '#7bb4ec',
            y: gapReport.list.reduce((result, content) => result + content.open_item, 0),
            sliced: true,
            selected: true
          },
          {
            name: 'Closed',
            color: '#90ed7d',
            y: gapReport.list.reduce((result, content) => result + content.close_item, 0)
          },
          {
            name: 'Reopen',
            color: '#f25d80',
            y: gapReport.list.reduce((result, content) => result + content.reopen_item, 0)
          }
        ]
      }
    ],
    credits: {
      enabled: false
    }
  };
  let options = {
    chart:{
      type: 'column',
      height: 550,
    },
    title:{text: null},
    xAxis: {
      categories: gapReport.list.filter(item => item.open_item != 0 || item.close_item != 0 || item.reopen_item !== 0).map(item => item.name)
    },
    yAxis:{
      allowDecimals: false,
      title:{text:''},
      labels: {
        formatter: function () {
          return this.value;
        }
      },
      tooltip:{
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat: 'Total: {point.stackTotal} <br/> Open: {point.open} <br/> Reopen: {point.reopen} <br/> Closed: {point.close}'
      },
      plotOptions:{
        column:{
          stacking: 'normal',
          depth: 40
        },
        series:{
          cursor: 'pointer',
          point:{
            events:{
              click: (e) => {this.graphToTabMove(e.point.category)}
            }
          }
        }
      },
      series:[
        {
          name: 'Reopen',
          type: 'column',
          color: '#f25d80',
          data: gapReport.list.filter(item => item.open_item !== 0 || item.close_item !== 0 ||  item.reopen_item !== 0).map(item => (item.name === selectedListItem.name ? { y: item.reopen_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item} : { y: item.reopen_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item}))
        },
        {
          name: 'Open',
          type: 'column',
          color: '#7bb4ec',
          data: gapReport.list.filter(item => item.open_item != 0 || item.close_item != 0 ||  item.reopen_item != 0).map(item => (item.name == selectedListItem.name ? { y: item.open_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item } : { y: item.open_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item }))
        },
        {
          name: 'Closed',
          type: 'column',
          color: '#90ed7d',
          data: gapReport.list.filter(item => item.open_item != 0 || item.close_item != 0 ||  item.reopen_item != 0).map(item => (item.name == selectedListItem.name ? { y: item.close_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item } : { y: item.close_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item }))
        }
      ]
    },
    credits: {
      enabled: false
    }
  };
  let usersOptions = {
    chart:{
      type: 'column',
      height: 550,
    },
    title:{text: "User Wise Stats"},
    xAxis:{
      categories: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened !== 0).map(item => item.user.name)
    },
    yAxis:{
      allowDecimals: false,
      title:{text: ''},
      labels: {
        formatter: function () {
          return this.value;
        }
      }
    },
    tooltip:{
      headerFormat: '<b>{point.key}</b><br>',
      pointFormat: 'Total: {point.stackTotal} <br/> Open: {point.open} <br/> Reopen: {point.reopen} <br/> Closed: {point.close}'
    },
    plotOptions:{
      column:{
        stacking: 'normal',
        depth: 40
      },
      series:{
        cursor: 'pointer',
        point: {
          events: {
            click: (e) => {
              this.graphToTabMove(e.point.category)
            }
          }
        }
      }
    },
    series:[
      {
        name: 'Reopen',
        type: 'column',
        color: '#f25d80',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 ||  item.reopened != 0).map(item => ({ y: item.reopened, open: item.open,close: item.closed, reopen: item.reopened}))
      },
      {
        name: 'Open',
        type: 'column',
        color: '#7bb4ec',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 ||  item.reopened != 0).map(item => ({ y: item.open, open: item.open,close: item.closed, reopen: item.reopened }))
      },
      {
        name: 'Closed',
        type: 'column',
        color: '#90ed7d',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 ||  item.reopened != 0).map(item => ({ y: item.closed, open: item.open,close: item.closed, reopen: item.reopened }))
      }
    ],
    credits: {
      enabled: false
    }
  }
  return(
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </Grid>
        <Grid item xs={4}>
          <HighchartsReact
            highcharts={Highcharts}
            options={graphPieOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <HighchartsReact
            highcharts={Highcharts}
            options={usersOptions}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default PRSAnalytic;
