import React, { Fragment } from 'react';
import WorkOrderAsset from './WorkOrderAsset';
import { Grid } from '@material-ui/core';

import { dateTransformAllSet } from '../../../utils';
export default function WorkOrderHd({workOrder, getWorkOrderAsset , inspectionTypes}){
  return(
    <Grid container spacing={2} className="work-order-table">
      <Grid item xs={12} md={5}>
        {(() => {
          if (workOrder.asset.type === 1){
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_Aircraft.png"
                serialNo={`MSN ${workOrder.asset.msn}`}
                type={workOrder.asset.aircraft_type}
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                inspectionTypes={inspectionTypes}
              />
            )
          }else if (workOrder.asset.type === 2) {
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_engine.png"
                serialNo={`ESN ${workOrder.asset.esn}`}
                type={workOrder.asset.engine_type}
                asset={workOrder.asset}
                progress={workOrder.progress}
                workOrder={workOrder}

              />
            )
          }else if (workOrder.asset.type === 3) {
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_apu.svg"
                serialNo={`APU ${workOrder.asset.serial_number}`}
                type=''
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                inspectionTypes={inspectionTypes}
              />
            )
          }else if (workOrder.asset.type === 4) {
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_lg.svg"
                serialNo={`LG ${workOrder.asset.serial_number}`}
                type=''
                asset={workOrder.asset}
                progress={workOrder.progress}
                workOrder={workOrder}
                inspectionTypes={inspectionTypes}
              />
            )
          }else if (workOrder.asset.type === 5) {
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_propeller.svg"
                serialNo={`PROPELLER ${workOrder.asset.serial_number}`}
                type=''
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                inspectionTypes={inspectionTypes}
              />
            )
          }
        })()}
      </Grid>
      <Grid item xs={3} md={2}>
        <label>Forms</label>
        <h6>{workOrder.forms.length}</h6>
      </Grid>
      <Grid item xs={6} md={3}>
        <label>Last Activity</label>
        <h6>{dateTransformAllSet(workOrder.updated_at)}</h6>
      </Grid>
      <Grid item xs={3} md={2}>
        <label>Status</label>
        <span>{workOrder.status.label}</span>
      </Grid>
    </Grid>
  )
}
