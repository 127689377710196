import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { DeletePopUp } from '../../../../shared_elements';
import { setSeatingConfigApi, removeSeatConfigRowApi } from '../apiServices';
import { regexConstants } from '../../../../constants/regEx';
import { alphaChar } from '../../../../constants';
import { nextChar } from '../../../../utils';
import { configObj } from '../';
class SetSeatsConfig extends Component{
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader:false,
      modal:false,
      configList:[],
      deleteModal:false,
      deleteIds:{
        deleteIndex:'',
        data:{}
      },
      sectionError:[]
    }
    this.setSeatingConfigApi = setSeatingConfigApi.bind(this);
    this.removeSeatConfigRowApi = removeSeatConfigRowApi.bind(this);
  }
  onUpdateError = (key, index) => {
    const { sectionError } = this.state;
    if(sectionError[index]){
      this.setState(prevState => ({
        ...prevState,
        sectionError: prevState.sectionError.map((error, errorIndex) => errorIndex === index ? {
          ...error,
          [key]: ''
        } :error)
      }))
    }
  }
  onFieldChange = (keyParam, value, sectionIndex) => {
    this.setState(prevState => ({
      ...prevState,
      configList: prevState.configList.map((section, index) => index === sectionIndex ? {
        ...section,
        [keyParam]: value
      } : section)
    }));
  }
  onRemoveColumnSeat = (colIndex, sectionIndex) => {
    this.setState(prevState => ({
      ...prevState,
      configList: prevState.configList.map((section, index) => index === sectionIndex ? {
        ...section,
        columns: section.columns.map((column, cIndex) => cIndex === colIndex ? column.slice(0,-1) : column)
      } :section)
    }))
  }
  onRemoveConfigSection = (section, sectionIndex) => {
    this.setState(prevState => ({
      ...prevState,
      deleteModal:true,
      deleteIds: {
        ...prevState.deleteIds,
        deleteIndex:sectionIndex,
        data: section
      }
    }))
  }
  onAddConfigSection = () => {
    this.setState(prevState => ({
      ...prevState,
      configList: [...prevState.configList, ...configObj]
    }));
  }
  deleteSectionFn = () => {
    const { deleteIds } = this.state;
    if(deleteIds.data.id){
      this.removeSeatConfigRowApi(this.props, deleteIds.data.id);
    }else{
      this.setState(prevState => ({
        ...prevState,
        deleteModal:false,
        deleteIds:{deleteIndex:'', data:{}},
        configList: prevState.configList.filter((section, sectionIndex) => sectionIndex !== deleteIds.deleteIndex)
      }))
    }
  }
  onAddColumnSeat = (colIndex, sectionIndex) => {
    const { configList } = this.state;
    let seatLatter = '';
    let columns = [].concat(...configList[sectionIndex].columns);
    let columnArray = configList[sectionIndex].columns[colIndex];
    switch (colIndex) {
      case 0:
        seatLatter = columnArray.length === 0 ? 'A': nextChar(columnArray[columnArray.length-1]);
        break;
      case 1:
        if(columnArray.length === 0){
          if(configList[sectionIndex].columns[0].length === 0){
            seatLatter = 'C';
          }else if (configList[sectionIndex].columns[0].length) {
            let firstCol = configList[sectionIndex].columns[0];
            if(firstCol[firstCol.length-1]){
              seatLatter = nextChar(firstCol[firstCol.length-1]);
            }else{
              seatLatter = alphaChar[configList[sectionIndex].columns[0].length];
            }
          }
        }else{
          seatLatter = nextChar(columnArray[columnArray.length-1]);
        }

        // debugger
        break;
      case 2:
        if(columnArray.length === 0){
          if(configList[sectionIndex].columns[1].length === 0){
            seatLatter = 'E';
          }else if (configList[sectionIndex].columns[1].length) {
            let secondCol = configList[sectionIndex].columns[1];
            if(secondCol[secondCol.length-1]){
              seatLatter = nextChar(secondCol[secondCol.length-1]);
            }else{
              seatLatter = alphaChar[configList[sectionIndex].columns[1].length];
            }
          }
        }else{
          seatLatter = nextChar(columnArray[columnArray.length-1]);
        }
        break;
      default:
    }


    if(columnArray.length < 4){
      this.setState(prevState => ({
        ...prevState,
        configList: prevState.configList.map((section, index) => index === sectionIndex ? {
          ...section,
          columns: section.columns.map((column, cIndex) => cIndex === colIndex ? [...column, columns.includes(seatLatter) ? '':seatLatter] : column)
        } :section)
      }))
    }
  }
  onEditColumnSeatNo = (value, seatIndex, colIndex, sectionIndex) => {
    this.setState(prevState => ({
      ...prevState,
      configList: prevState.configList.map((section, sIndex) => sIndex === sectionIndex ? {
        ...section, columns: section.columns.map((column, cIndex) => cIndex === colIndex ? column.map((seat, sInndex) => sInndex === seatIndex ? value :seat) : column)
      } : section)
    }))
  }
  render(){
    const { formSubmitLoader, modal, sectionError, configList, deleteModal, deleteIds } = this.state;
    const { pltConstants, seatConfig } = this.props;
    return(
      <Fragment>
        <span onClick={() => this.setState({modal:true, configList:seatConfig.interior_config_details.length ? seatConfig.interior_config_details:configObj })}>
          { seatConfig.interior_config_details.length ? 'Edit Configuration':'Add Configuration'}

        </span>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              { seatConfig.interior_config_details.length ? 'Edit Configuration':'Add Configuration'}

            </DialogTitle>
            <DialogContent dividers={true}>
              <form className="aircaft-seat-config-modal">
                { configList.map((item, sectionIndex) => {
                  let seatOptions = pltConstants.filter(constant => constant.type === 'pax_seat_type');
                  let seatType = item.seat_type ? seatOptions.find(seat => seat.value === item.seat_type):null
                  return(
                    <div className="aircraft-section" key={sectionIndex}>
                      { configList.length > 1 ?
                        <span className="remove-section">
                          <DeleteOutlineIcon onClick={() => this.onRemoveConfigSection(item, sectionIndex)} color="secondary" />
                        </span>:null
                      }
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            options = {pltConstants.filter(item => item.type === 'pax_seat_type')}
                            getOptionLabel={option => option.label}
                            id="seat_type"
                            value={seatType}
                            onChange={(event, data) => {this.onFieldChange('seat_type',  data ? data.value:'', sectionIndex);this.onUpdateError('seat_type', sectionIndex)}}
                            renderInput={params =>
                              <TextField
                                required
                                error={sectionError[sectionIndex] && sectionError[sectionIndex].seat_type ? true : false }
                                helperText={ sectionError[sectionIndex] && sectionError[sectionIndex].seat_type ? sectionError[sectionIndex].seat_type : '' }
                                {...params}
                                label="Select Class"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{shrink: true}}
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                id="starting_row"
                                label="Enter Start Row Number"
                                fullWidth
                                margin="normal"
                                value={item.starting_row}
                                inputProps={{ maxLength: 2 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange('starting_row', e.target.value, sectionIndex): e.preventDefault()}}
                                onFocus={() => {this.onUpdateError('empty_numner', sectionIndex);this.onUpdateError('unique_number', sectionIndex)}}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                required
                                id="end_row"
                                label="Enter End Row Number"
                                fullWidth
                                margin="normal"
                                value={item.end_row}
                                inputProps={{ maxLength: 2 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange('end_row', e.target.value, sectionIndex): e.preventDefault()}}
                                onFocus={() => {this.onUpdateError('empty_numner', sectionIndex);this.onUpdateError('unique_number', sectionIndex)}}
                              />
                            </Grid>
                          </Grid>
                          { sectionError[sectionIndex] ?
                            <Fragment>
                              { sectionError[sectionIndex].empty_numner ?
                                <p className="error-msg">{sectionError[sectionIndex].empty_numner}</p>:null
                              }
                              { sectionError[sectionIndex].unique_number ?
                                <p className="error-msg">{sectionError[sectionIndex].unique_number}</p>:null
                              }
                            </Fragment>:null
                          }
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={0}>
                            { item.columns.map((col, colIndex) =>
                              <Grid item xs={12} md={4}>
                                  <p>
                                    <span><AddCircleIcon onClick={() => this.onAddColumnSeat(colIndex, sectionIndex)} color={col.length < 4 ? "primary":"disabled"} /></span>
                                    <span><RemoveCircleIcon onClick={() => this.onRemoveColumnSeat(colIndex, sectionIndex)} color={col.length > 0 ? "primary":"disabled"} /></span>
                                  </p>
                                  <ul className="list-inline seat-number">
                                    { col.map((seat, seatIndex) =>
                                      <li className="list-inline-item">
                                        <TextField
                                          style={{width:'30px', textAlign:'center'}}
                                          fullWidth
                                          margin="none"
                                          value={seat}
                                          inputProps={{ maxLength: 1 }}
                                          InputLabelProps={{shrink: true}}
                                          onChange={(e) => { e.target.value === '' || regexConstants.onlyCharacter.test(e.target.value) ? this.onEditColumnSeatNo(e.target.value.toUpperCase() , seatIndex, colIndex, sectionIndex): e.preventDefault()}}
                                          onFocus={() => this.onUpdateError('unique_seat', sectionIndex)}
                                        />
                                      </li>
                                    )}
                                  </ul>
                              </Grid>
                            )}
                          </Grid>
                          { sectionError[sectionIndex] && sectionError[sectionIndex].unique_seat ?
                            <p className="error-msg">{sectionError[sectionIndex].unique_seat}</p>:null
                          }
                        </Grid>
                      </Grid>
                    </div>
                  )
                })}
                <Button className="add-new-section" variant="outlined" color="primary" onClick={this.onAddConfigSection} size="small">Add Section</Button>
              </form>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false})}>Cancel</Button>
              <Button style={{width:'120px'}} disabled={formSubmitLoader} variant="contained" color="primary" onClick={() => this.setSeatingConfigApi(this.props, configList)}>
                { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save' }
              </Button>
            </DialogActions>
          </Dialog>:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{deleteIndex:'',data:{}}});}}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={this.deleteSectionFn}
        />
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(SetSeatsConfig));
