import React, { Fragment, Component } from 'react';
import { withSnackbar } from 'notistack';
import { Tabs, Tab, Button, Grid, Paper, FormControlLabel, Checkbox } from '@material-ui/core';
import { DropDownComp, TextComp, DateComp, InspectionFormWrapper } from '../../../Elements';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { PageLoader } from '../../../../shared_elements';
import { getTechnicalSpecsApi, editTechnicalSpecsApi, getAircraftTypesApi, getEngineTypesApi, getLesseesApi, exportTechnicalSpecsApi, uploadEquipmentAttachmentApi, removeComponentApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
class InspectionFormT005 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:true,
      pageLoader:true,
      inspectionHdInfo:{},
      technicalSpecs: [],
      aircraft_model:[],
      engine_modal:[],
      lessee_list:[],
      tabIndex: 0
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getAircraftTypesApi = getAircraftTypesApi.bind(this);
    this.getEngineTypesApi = getEngineTypesApi.bind(this);
    this.getLesseesApi = getLesseesApi.bind(this);
    this.getTechnicalSpecsApi = getTechnicalSpecsApi.bind(this);
    this.editTechnicalSpecsApi = editTechnicalSpecsApi.bind(this);
    this.exportTechnicalSpecsApi = exportTechnicalSpecsApi.bind(this);
    this.uploadEquipmentAttachmentApi = uploadEquipmentAttachmentApi.bind(this);
    this.removeComponentApi = removeComponentApi.bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getAircraftTypesApi(this.props);
    this.getEngineTypesApi(this.props);
    this.getLesseesApi(this.props);
    this.getTechnicalSpecsApi(this.props);
  }
  readTheAnswer = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if(answer.length){
      return answer[0].value
    }
  }
  updateAnswer = (otherInfo, answers, key, value) => {
    let newAnswers = answers;
    if(answers.filter(answer => answer.label == key).length){
      newAnswers = newAnswers.map(newAnswer => newAnswer.label == key ? {...newAnswer, value: value} : newAnswer)
    }else{
      if(otherInfo.component_configuration_id){
        newAnswers = [...newAnswers, {label: key, value: value, component_configuration_id: otherInfo.component_configuration_id}]
      }else if (otherInfo.sub_component_configuration_id) {
        newAnswers = [...newAnswers, {label: key, value: value, sub_component_configuration_id: otherInfo.sub_component_configuration_id}]
      }else{
        newAnswers = [...newAnswers, {label: key, value: value }]
      }
    }
    return newAnswers;
  }
  handleChange = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue
    }))
  }
  plotFieldType = (question, key, answer, specsType, sectionIndex, componentIndex=null, subComponentIndex=null, customSubComponentIndex=null) => {
    const { inspectionHdInfo } = this.state;
    if(question.field_type === 'dropdown'){
      let value = null;
      if(question.api && this.readTheAnswer(answer, key) && this.state[question.api_code].length){
        value = this.state[question.api_code].find(item => item.value === this.readTheAnswer(answer, key));
      }
      if(!question.api && this.readTheAnswer(answer, key)){
        value = question.options.find(item => item.value === this.readTheAnswer(answer, key))
      }
      if(inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save){
        let disabled = question.editable === false ? true:false;
        if(question.is_fitted && question.is_fitted === "true"){
          let checkedDisabled = this.readTheAnswer(answer, 'is_fitted');
          if(checkedDisabled && checkedDisabled === 'true'){
            disabled = false;
          }else{
            disabled = true;
          }
        }
        return(
          <div className="is-fitted-fields">
            {(() => {
              let checked = this.readTheAnswer(answer, 'is_fitted');
              if(question.is_fitted && question.is_fitted === "true"){
                return(
                  <FormControlLabel
                    className="checkbox-label"
                    control={
                      <Checkbox
                        checked={checked && checked === 'true' ? true:false}
                        size="small"
                        color="primary"
                        onChange={(e) => this.onFieldChange({label:'Is Fitted', field_type:'checkbox'}, 'is_fitted', e.target.checked ? 'true':'false', specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
                        value="is fitted"
                      />
                    }
                    label="Is Fitted"
                  />
                )
              }
            })()}
            <DropDownComp
              label={question.label}
              keyParam={key}
              value = {value}
              options={question.api === true ? this.state[question.api_code]: question.options}
              onFieldChange={(event, keyParam, value) => this.onFieldChange(question, key, value, specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
              disabled={disabled}
            />
          </div>
        )
      }else{
        return(
          <Fragment>
            <p>{question.label}</p>
            <h6>{value?value.label:'--'}</h6>
          </Fragment>
        )
      }

    }else if (question.field_type === 'date') {
      if(inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save){
        return(
          <DateComp
            label={question.label}
            keyParam={key}
            value = {this.readTheAnswer(answer, key)}
            onFieldChange={(event, keyParam, value) => this.onFieldChange(question, key, value, specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
            disabled={question.editable === false ? true:false}
          />
        )
      }else{
        let ans = this.readTheAnswer(answer, key)
        return(
          <Fragment>
            <p>{question.label}</p>
            <h6>{ans?ans:'--'}</h6>
          </Fragment>
        )
      }

    }else if(question.field_type === 'string'){
      if(inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save){
        let disabled = question.editable === false ? true:false;
        if(question.is_fitted && question.is_fitted === "true"){
          let checkedDisabled = this.readTheAnswer(answer, 'is_fitted');
          if(checkedDisabled && checkedDisabled === 'true'){
            disabled = false;
          }else{
            disabled = true;
          }
        }
        return(
          <div className="is-fitted-fields">
            {(() => {
              let checked = this.readTheAnswer(answer, 'is_fitted');
              if(question.is_fitted && question.is_fitted === "true"){
                return(
                  <FormControlLabel
                    className="checkbox-label"
                    control={
                      <Checkbox
                        checked={checked && checked === 'true' ? true:false}
                        size="small"
                        color="primary"
                        onChange={(e) => this.onFieldChange({label:'Is Fitted', field_type:'checkbox'}, 'is_fitted', e.target.checked ? 'true':'false', specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
                        value="is fitted"
                      />
                    }
                    label="Is Fitted"
                  />
                )
              }
            })()}
            <TextComp
              label={question.label}
              keyParam={key}
              value = {this.readTheAnswer(answer, key)}
              onFieldChange={(event, keyParam, value) => this.onFieldChange(question, key, value, specsType, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
              disabled={disabled}
             />
          </div>
        )
      }else{
        let ans = this.readTheAnswer(answer, key)
        return(
          <Fragment>
            <p>{question.label} {question.is_fitted && question.is_fitted === "true" ? '(Fitted)':''}</p>
            <h6>{ans ? ans:'--'}</h6>
          </Fragment>
        )
      }
    }else{
      return null
    }
  }

  addComponent = (component, componentIndex, sectionIndex) => {
    let componentObj = Object.assign({}, component);
    componentObj.answers = [];
    componentObj.allow_dynamic = false;
    componentObj.is_custom_component = true;
    componentObj.component_id = componentObj.id;
    delete componentObj.id;
    delete componentObj.name;
    delete componentObj.custom_components;
    delete componentObj.custom_components;
    delete componentObj.sub_components;
    this.setState(prevState => ({
      ...prevState,
      technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
        ...section, component_questions: [...section.component_questions].map((component, innerIndex) => innerIndex === componentIndex ? {
          ...component, custom_components: [...component.custom_components, componentObj]
        } : component)
      } :section)
    }))
  }
  removeComponent = (sectionIndex, componentIndex, subComponentIndex) => {
    this.setState(prevState => ({
      ...prevState,
      technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
        ...section, component_questions: [...section.component_questions].map((component, innerIndex) => innerIndex === componentIndex ? {
          ...component, custom_components: [...component.custom_components].filter((subComponent, subIndex) => subIndex !== subComponentIndex)
        } : component)
      } : section)
    }))
  }

  removeSubComponent = (sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex) => {
    this.setState(prevState => ({
      ...prevState,
      technicalSpecs: prevState.technicalSpecs.map((section, index) => index === sectionIndex ? {
        ...section, component_questions: section.component_questions.map((component, innerIndex) => innerIndex === componentIndex ? {
          ...component, sub_components: component.sub_components.map((subComponent, subIndex) => {return {...subComponent, custom_components: subComponent.custom_components.filter((subComponent, customIndex) => customIndex !== customSubComponentIndex)}} )
        } :component)
      } :section)
    }))
  }
  removesubComponentId = (subComponentIndex, customSubComponentIndex, component, sectionId) => {
    let ids=[];
    component.sub_components.map((subComponent, subComponentIndex) => {
      subComponent.custom_components.map((customSubComponent, customComponentIndex) =>{
        if(customComponentIndex==customSubComponentIndex){
          ids.push(customSubComponent.id)
        }
      }
    )});
    this.removeComponentApi(this.props, ids, sectionId)
  }
  addSection = (component, componentIndex, sectionIndex) => {
    let componentObj = Object.assign({}, component);
    componentObj.answers = [];
    componentObj.allow_dynamic = false;
    componentObj.is_custom = true;
    componentObj.component_id = componentObj.id;
    delete componentObj.id;
    delete componentObj.name;
    let technicalInspectionArray = Object.assign([], this.state.technicalSpecs);
    technicalInspectionArray[sectionIndex].sections.splice(componentIndex + 1, 0, componentObj);
    this.setState(prevState => ({
      ...prevState,
      technicalSpecs: technicalInspectionArray
    }))
  }
  addMaintenanceComponent = (component, componentIndex, sectionIndex) => {
    this.setState(prevState => ({
      ...prevState,
      technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
        ...section, component_questions: [...section.component_questions].map((component, innerIndex) => innerIndex === componentIndex ? {
          ...component, sub_components: [...component.sub_components].map((subComponent) =>{ return { ...subComponent, custom_components: [...subComponent.custom_components, { answers:[], specs: subComponent.specs, maintenance_specs: subComponent.maintenance_specs }]}} )
        } : component)
      } :section)
    }))
  }
  onFieldChange = (questionObj, key, value, specsType, sectionIndex, componentIndex=null, subComponentIndex=null, customSubComponentIndex=null) => {
    if(specsType === 'sections'){
      this.setState(prevState => ({
        ...prevState,
        technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
          ...section, sections: [...section.sections].map((sec, innerIndex) => innerIndex === componentIndex ? {
            ...sec, answers: this.updateAnswer(questionObj, sec.answers, key, value)
          } :sec)
        } : section)
      }))
    }else{
      if(componentIndex === null && subComponentIndex === null){
        this.setState(prevState => ({
          ...prevState,
          technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
            ...section, answers: this.updateAnswer(questionObj, section.answers, key, value)
          } :section)
        }))
      }else if (subComponentIndex === null) {
        this.setState(prevState => ({
          ...prevState,
          technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
            ...section, component_questions: [...section.component_questions].map((component,innerIndex) => innerIndex === componentIndex ? {
              ...component, answers: this.updateAnswer(questionObj, component.answers, key, value)
            } :component)
          } :section)
        }))
      }else{
        if(specsType === 'custom_components'){
          this.setState(prevState => ({
            ...prevState,
            technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
              ...section, component_questions: [...section.component_questions].map((component, innerIndex) => innerIndex === componentIndex ? {
                ...component, custom_components: [...component.custom_components].map((subComponent, subIndex) => subIndex === subComponentIndex ? {
                  ...subComponent, answers: this.updateAnswer(questionObj, subComponent.answers, key, value)
                } :subComponent)
              } :component)
            } : section)
          }))
        }else if (specsType == 'custom_subComponent') {
          this.setState(prevState => ({
            ...prevState,
            technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
              ...section, component_questions: [...section.component_questions].map((component, innerIndex) => innerIndex === componentIndex ? {
                ...component, sub_components: [...component.sub_components].map((subComponent, subIndex) => subIndex === subComponentIndex ? {
                  ...subComponent, custom_components: [...subComponent.custom_components].map((customSubComponent, customIndex) => customIndex === customSubComponentIndex ? {
                    ...customSubComponent, answers: this.updateAnswer(questionObj, customSubComponent.answers, key, value)
                  } : customSubComponent)
                } : subComponent)
              } :component)
            } :section)
          }))
        }else{
          this.setState(prevState => ({
            ...prevState,
            technicalSpecs: [...prevState.technicalSpecs].map((section, index) => index === sectionIndex ? {
              ...section, component_questions: [...section.component_questions].map((component, innerIndex) => innerIndex === componentIndex ? {
                ...component, sub_components: [...component.sub_components].map((subComponent, subIndex) => subIndex === subComponentIndex ? {
                  ...subComponent, answers: this.updateAnswer(questionObj, subComponent.answers, key, value)
                } : subComponent)
              } : component)
            } : section)
          }))
        }
      }
    }
  }

  getAttachmentName = (file) => {
    let files = file.split('?')[0].split('/');
    return files[files.length-1];
  }
  render(){
    const { inspectionHdInfo, technicalSpecs, tabIndex, skeletonLoader, pageLoader } = this.state;
    return(
      <div className="technical-aircraft-froms">
        <InspectionFormWrapper
          saveButton={true}
          inspectionHdInfo={inspectionHdInfo}
          skeletonLoader={skeletonLoader}
          onSaveChanges={() => this.editTechnicalSpecsApi(this.props, technicalSpecs)}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[
            {title:'PDF', extension: 'pdf', key:''},
            {title:'Document', extension: 'docx', key:''}
          ]}
          exportReportFn={(file) => this.exportTechnicalSpecsApi(this.props, file)}
        />
        <div className="form-t005-cn">
          <Tabs
            value={tabIndex}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="normal"
            aria-label="full width tabs example"
          >
            { technicalSpecs.map((section, index) =>
              <Tab label={section.name} key={index} />
            )}
          </Tabs>
          <Paper style={{padding:'15px'}}>
            {technicalSpecs.map((section, sectionIndex) =>
              sectionIndex === tabIndex &&
              <div className="">
                { section.name !== 'Maintenance Check updates' ?
                  <div className="">
                    <Grid container spacing={3}>
                      { section.asset_specs && Object.keys(section.asset_specs).length ?
                        Object.keys(section.asset_specs).map((key, assetIndex) =>
                          <Grid item md={3} xs={12}>
                            {this.plotFieldType(section.asset_specs[key], key, section.answers, 'asset_specs', sectionIndex)}
                          </Grid>
                        ):null
                      }
                      { section.maintenance_specs && Object.keys(section.maintenance_specs).length ?
                        Object.keys(section.maintenance_specs).map((key, mntIndex) =>
                          <Grid item md={3} xs={12}>
                            {this.plotFieldType(section.maintenance_specs[key], key, section.answers, 'maintenance_specs', sectionIndex)}
                          </Grid>
                        ):null
                      }
                      { section.specs && Object.keys(section.specs).length ?
                        Object.keys(section.specs).map((key, secIndex) =>
                          <Grid item md={3} xs={12}>
                            {this.plotFieldType(section.specs[key], key, section.answers, 'specs', sectionIndex)}
                          </Grid>
                        ):null
                      }
                    </Grid>
                    <div className="">
                      { section.component_questions && section.component_questions.length ?
                        section.component_questions.map((component, componentIndex) =>
                          <div className="" style={{background: '#F7F7F7', padding:'15px', marginTop:'20px'}}>
                            <h4>
                              {component.name}
                              { component.allow_dynamic && (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) ?
                                <Button style={{marginLeft:'15px'}} onClick={() => this.addComponent(component, componentIndex, sectionIndex)} size="small" variant="outlined" color="primary" component="span">Add {component.name}</Button>:null
                              }
                            </h4>
                            { component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                              <Grid container spacing={3}>
                                { Object.keys(component.maintenance_specs).map((key, index) =>
                                  component.maintenance_specs[key].field_type !== 'checkbox' &&
                                  <Grid item md={3} xs={12}>
                                    {this.plotFieldType(component.maintenance_specs[key], key, component.answers, 'maintenance_specs', sectionIndex, componentIndex)}
                                  </Grid>
                                )}
                              </Grid>
                              :null
                            }
                            { component.specs && Object.keys(component.specs).length ?
                              <Grid container spacing={3}>
                                { Object.keys(component.specs).map((key, index) =>
                                  component.specs[key].field_type !== 'checkbox' &&
                                  <Grid item md={3} xs={12}>
                                    { component.specs[key].field_type == 'attach' ?
                                      <div>
                                        <p>{component.specs[key].label}</p>
                                        <input
                                          accept="image/*"
                                          id={`contained-button-file-${component.id}`}
                                          type="file"
                                          accept={".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"}
                                          style={{display:'none'}}
                                          onChange={(e) => this.uploadEquipmentAttachmentApi(this.props, section.id, component.id  ,e.target.files[0])}
                                        />
                                        <label htmlFor={`contained-button-file-${component.id}`}>
                                          <Button size="small" variant="outlined" color="primary" component="span">
                                            Choose Attachment
                                          </Button>
                                        </label>
                                        <br/>
                                        { this.readTheAnswer(component.answers, key) ?
                                          <a style={{display:'inline-block', marginTop:'15px'}} href={this.readTheAnswer(component.answers, key)} download>
                                            {this.getAttachmentName(this.readTheAnswer(component.answers, key))}
                                          </a>:null
                                        }
                                      </div>
                                      :
                                      this.plotFieldType(component.specs[key], key, component.answers, 'specs', sectionIndex, componentIndex)
                                    }
                                  </Grid>
                                )}
                              </Grid>:null
                            }
                            { component.custom_components && component.custom_components.length ?
                              component.custom_components.map((customComponents, subComponentIndex) =>
                                <div key={subComponentIndex} style={{borderTop: '1px solid #d7d7d7', paddingTop: '20px'}}>
                                  { inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save ?
                                    <span onClick={() =>  customComponents.id ?this.removeComponentApi(this.props, [customComponents.id],section.id): this.removeComponent(sectionIndex, componentIndex, subComponentIndex)} style={{float: 'right', cursor: 'pointer'}}> <DeleteOutlineIcon color="error" /></span>:null
                                  }

                                  <Grid container spacing={3}>
                                    { customComponents.maintenance_specs && Object.keys(customComponents.maintenance_specs).length ?
                                      Object.keys(customComponents.maintenance_specs).map((key, index) =>
                                        customComponents.maintenance_specs[key].field_type !== 'checkbox' &&
                                        <Grid item md={3} xs={12}>
                                          {this.plotFieldType(customComponents.maintenance_specs[key], key, customComponents.answers, 'custom_components', sectionIndex, componentIndex, subComponentIndex)}
                                        </Grid>
                                      ):null
                                    }
                                    { customComponents.specs && Object.keys(customComponents.specs).length ?
                                      Object.keys(customComponents.specs).map((key, index) =>
                                        customComponents.specs[key].field_type !== 'checkbox' &&
                                        <Grid item md={3} xs={12}>
                                          {this.plotFieldType(customComponents.specs[key], key, customComponents.answers, 'specs', sectionIndex, componentIndex, subComponentIndex)}
                                        </Grid>
                                      ):null
                                    }
                                  </Grid>
                                </div>
                              ):null
                            }
                            { component.sub_components && component.sub_components.length ?
                                component.sub_components.map((subComponent, subComponentIndex) =>
                                  <div key={subComponentIndex} className="" style={{background: '#edecef', padding: '15px', marginTop: '20px'}}>
                                    <h5>
                                      {subComponent.name}
                                      { subComponent.allow_dynamic && (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) ?
                                        <Button style={{marginLeft:'15px'}} onClick={() => this.addComponent(subComponent, subComponentIndex, componentIndex, sectionIndex)} size="small" variant="outlined" color="primary" component="span">Add {subComponent.name}</Button>:null
                                      }
                                    </h5>
                                    <Grid container spacing={3}>
                                      { subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                        Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                          subComponent.maintenance_specs[key].field_type !== 'checkbox' &&
                                          <Grid item md={3} xs={12}>
                                            {this.plotFieldType(subComponent.maintenance_specs[key], key, subComponent.answers, 'maintenance_specs', sectionIndex, componentIndex, subComponentIndex)}
                                          </Grid>
                                        ):null
                                      }
                                      { subComponent.specs && Object.keys(subComponent.specs).length ?
                                        Object.keys(subComponent.specs).map((key, index) =>
                                          subComponent.specs[key].field_type !== 'checkbox' &&
                                          <Grid item md={3} xs={12}>
                                            {this.plotFieldType(subComponent.specs[key], key, subComponent.answers, 'specs', sectionIndex, componentIndex, subComponentIndex)}
                                          </Grid>
                                        ):null
                                      }
                                    </Grid>
                                  </div>
                                ):null
                            }
                          </div>
                        ):null
                      }
                    </div>
                    <div>
                      { section.sections && section.sections.length ?
                        section.sections.map((component, componentIndex) =>
                          <div key={componentIndex} className="" style={{background: '#F7F7F7', marginTop: '20px', padding: '15px'}}>
                            <h4 style={{fontSize: '16px', color: '#691AD3', marginBottom: '20px'}}>{component.name}</h4>
                            { component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                              <Grid container spacing={3}>
                                { Object.keys(component.maintenance_specs).map((key, index) =>
                                  component.maintenance_specs[key].field_type !== 'checkbox' &&
                                  <Grid item md={3} xs={12}>
                                    {this.plotFieldType(component.maintenance_specs[key], key, component.answers, 'sections', sectionIndex, componentIndex)}
                                  </Grid>
                                )}
                              </Grid>:null
                            }
                            { component.specs && Object.keys(component.specs).length ? // Need to add checkbox
                              <Grid container spacing={3}>
                                { Object.keys(component.specs).map((key, index) =>
                                  component.specs[key].field_type !== 'checkbox' &&
                                  <Grid item md={3} xs={12}>
                                    {this.plotFieldType(component.specs[key], key, component.answers, 'sections', sectionIndex, componentIndex)}
                                  </Grid>
                                )}
                              </Grid>:null
                            }
                            { component.sub_components && component.sub_components.length ?
                              component.sub_components.map((subComponent, subComponentIndex) =>
                                <div key={subComponentIndex} className="">
                                  <h5 style={{fontSize: '16px', borderBottom: '1px solid #d7d7d7', paddingBottom: '10px', marginBottom: '20px'}}>
                                    {subComponent.name}
                                    { subComponent.allow_dynamic && (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) ?
                                      <Button style={{marginLeft:'15px'}} onClick={() => this.addComponent(subComponent, subComponentIndex, componentIndex, sectionIndex)} size="small" variant="outlined" color="primary" component="span">Add {subComponent.name}</Button>:null
                                    }
                                  </h5>
                                  <Grid container spacing={3}>
                                    { subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                      Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                        subComponent.maintenance_specs[key].field_type !== 'checkbox' &&
                                        <Grid item md={3} xs={12}>
                                          {this.plotFieldType(subComponent.maintenance_specs[key], key, subComponent.answers, 'maintenance_specs', sectionIndex, componentIndex, subComponentIndex)}
                                        </Grid>
                                      ):null
                                    }
                                    { subComponent.specs && Object.keys(subComponent.specs).length ?
                                      Object.keys(subComponent.specs).map((key, index) =>
                                        subComponent.specs[key].field_type !== 'checkbox' &&
                                        <Grid item md={3} xs={12}>
                                          {this.plotFieldType(subComponent.specs[key], key, subComponent.answers, 'specs', sectionIndex, componentIndex, subComponentIndex)}
                                        </Grid>
                                      ):null
                                    }
                                  </Grid>
                                </div>
                              ):null
                            }
                            { component.allow_dynamic && (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) ?
                              <Button style={{marginLeft:'15px'}} onClick={() => this.addSection(component, componentIndex, sectionIndex)} size="small" variant="outlined" color="primary" component="span">Add {component.name}</Button>:null
                            }
                          </div>
                        ):null
                      }
                    </div>
                  </div>:

                  <div className="">
                    { section.component_questions.map((component, componentIndex) =>
                      <div>
                        <h4 style={{fontSize: '16px', color: '#691ad3'}}>
                          {component.name}
                          { (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save) ?
                            <Button style={{marginLeft:'15px'}} onClick={() => this.addMaintenanceComponent(component, componentIndex, sectionIndex)} size="small" variant="outlined" color="primary" component="span">Add {component.name}</Button>:null
                          }
                        </h4>
                        <table style={{width: '100%', marginBottom: '20px'}}>
                          <thead>
                            <tr>
                              {component.sub_components.map((subComponent, subComponentIndex) => <th style={{padding: '10px', fontSize: '14px', textAlign:'left'}}>{subComponent.name}</th>)}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              { component.sub_components.map((subComponent, subComponentIndex) =>
                                <td>
                                  <table style={{width: '100%'}}>
                                    <tr>
                                      { Object.keys(subComponent.specs).map((key, index) =>
                                        <td style={{padding: '10px'}}>
                                          {this.plotFieldType(subComponent.specs[key], key, subComponent.answers, 'specs', sectionIndex, componentIndex, subComponentIndex)}
                                        </td>
                                      )}
                                    </tr>
                                  </table>
                                  { subComponent.custom_components && subComponent.custom_components.length ?
                                    <table style={{width: '100%',padding:'0px'}}>
                                      { subComponent.custom_components.map((customSubComponent, customSubComponentIndex) =>
                                        <tr>
                                          { Object.keys(customSubComponent.specs).map((key, index) =>
                                            <td style={{padding: '10px'}}>
                                              {this.plotFieldType(customSubComponent.specs[key], key, customSubComponent.answers, 'custom_subComponent', sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)}
                                            </td>
                                          )}
                                          {(subComponentIndex+1) == component.sub_components.length && (inspectionHdInfo && inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions && inspectionHdInfo.current_form.permissions.can_save)?
                                            <span onClick={() => customSubComponent.id ?this.removesubComponentId(subComponentIndex,customSubComponentIndex,component,section.id):this.removeSubComponent(sectionIndex, componentIndex, subComponentIndex,customSubComponentIndex)} style={{float: 'right', cursor: 'pointer'}}><DeleteOutlineIcon color="error" /></span>:null
                                          }
                                        </tr>
                                      )}
                                    </table>:null
                                  }
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                }
              </div>
            )}
          </Paper>
        </div>
        { pageLoader ? <PageLoader />:null}
      </div>
    )
  }
}
export default withSnackbar(InspectionFormT005);
