import React from 'react';
import { TextField, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
export default function Observations({observation, onObservationChange}){
  return(
    <tr>
      <td>
        <label>{observation.label ? observation.label:'' }</label>
        <RadioGroup aria-label="gender" name="gender2" value={'female'} style={{flexDirection: 'row'}}>
          <FormControlLabel
            value="female"
            control={<Radio checked={observation.answer && observation.answer.value === 1 ? true:false} onChange={() => onObservationChange('value',1)} color="primary" />}
            label="Poor"
            labelPlacement="end"
          />
          <FormControlLabel
            value="male"
            control={<Radio checked={observation.answer && observation.answer.value === 2 ? true:false} onChange={() => onObservationChange('value',2)} color="primary" />}
            label="Average"
            labelPlacement="end"
          />
          <FormControlLabel
            value="other"
            control={<Radio checked={observation.answer && observation.answer.value === 3 ? true:false} onChange={() => onObservationChange('value',3)} color="primary" />}
            label="Good"
            labelPlacement="end"
          />
        </RadioGroup>
      </td>
      <td style={{width:'300px'}}>
        <TextField
          id="remarks"
          label="Enter Remarks"
          fullWidth
          margin="normal"
          multiline
          rowsMax="3"
          value={observation.answer && observation.answer.remarks ? observation.answer.remarks:''}
          InputLabelProps={{shrink: true}}
          error={observation.answer.value && observation.answer.value === 1 ? observation.answer.remarks ? false:true:false}
          helperText={(observation.answer.value && observation.answer.value === 1) && (observation.answer.remarks && observation.answer.remarks === '')?'Please Enter Remarks':''}
          onChange={(e) => onObservationChange('remarks',e.target.value)}
        />
      </td>
    </tr>
  )
}
