import React, { Component, Fragment } from 'react';
import { Paper, TextField, Grid, Tabs, Tab, Button } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import {arrayMoveImmutable} from 'array-move';
import { TableListComp, CkEditorText, EmptyCollection, DeletePopUp, PageLoader, WorksScopeTable } from '../../../../shared_elements';
import { ShopVisitAttachment, DisassemblyFinding, LLPDetails, GeneralDetail, ShopVisitCard, AddEditDisassemblyFind, PrerequisiteContent } from '../components';
import { InspectionFormWrapper, Attachment, ImageSlider, EditImageDialog, ReadPrerequisite } from '../../../Elements';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { getTableInspectionApi, editTableInspectionApi, deleteLLPPartApi, addEditDisassemblyApi, getDisassemblyApi, deleteDisassemblyApi, deleteDisassemblyFindingImgApi, attachFindingApi, generateSVAttachmentUrlApi, deleteSVAttachmentApi, exportTableInspectionApi } from '../apiServices';
import { getInspectionHeaderApi, changeFormStatusApi } from '../../t001/apiServices';
import { llpDetail005, te005LLPDetailHd } from '../../../';
import { fieldValidation } from '../../../../utils/formValidation';
import { errorCode } from '../';
class InspectionFormTE005 extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:true,
      pageLoader: false,
      inspectionHdInfo:{},
      tabIndex: 0,
      error:{
        llp_details: []
      },
      tableInspection: {
        general_details: {},
        shop_visit: {},
        disassembly_findings: [],
        llp_details: []
      },
      disAssemblyCrud:{
        data: {},
        modal:false,
        mode:''
      },
      deleteModal:false,
      deleteIds:{},
      imgAnnotation: {
        modal:false,
        data:{}
      },
      sliderInfo: {
        modal:false,
        images:[],
        startIndex:0
      }
    }
    this.getInspectionHeaderApi = getInspectionHeaderApi.bind(this);
    this.changeFormStatusApi = changeFormStatusApi.bind(this);
    this.getTableInspectionApi = getTableInspectionApi.bind(this);
    this.editTableInspectionApi = editTableInspectionApi.bind(this);
    this.deleteLLPPartApi = deleteLLPPartApi.bind(this);
    this.getDisassemblyApi = getDisassemblyApi.bind(this);
    this.deleteDisassemblyApi = deleteDisassemblyApi.bind(this);
    this.addEditDisassemblyApi = addEditDisassemblyApi.bind(this);
    this.deleteDisassemblyFindingImgApi = deleteDisassemblyFindingImgApi .bind(this);
    this.attachFindingApi = attachFindingApi .bind(this);
    this.generateSVAttachmentUrlApi = generateSVAttachmentUrlApi .bind(this);
    this.deleteSVAttachmentApi = deleteSVAttachmentApi .bind(this);
    this.exportTableInspectionApi = exportTableInspectionApi .bind(this);
  }
  componentDidMount(){
    this.getInspectionHeaderApi(this.props);
    this.getTableInspectionApi(this.props);
  }
  addComponent = (tableType, data) => {
    this.setState(prevState => ({
      ...prevState,
      tableInspection: {
        ...prevState.tableInspection,
        [tableType]: [...prevState.tableInspection[tableType], data]
      }
    }))
  }
  handleChange = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue
    }))
  }
  onFieldChange = (event, keyParam, data, arrayType, arrayIndex) => {
    if(arrayType === 'llp_details'){
      this.setState(prevState => ({
        ...prevState,
        tableInspection: {
          ...prevState.tableInspection,
          llp_details: [...prevState.tableInspection.llp_details].map((item ,index) => index===arrayIndex ? {...item, [keyParam]:data} :item)
        }
      }))
    }else if (arrayType === 'findings') {
      this.setState(prevState => ({
        ...prevState,
        disAssemblyCrud: {
          ...prevState.disAssemblyCrud,
          data: {
            ...prevState.disAssemblyCrud.data,
            [keyParam]: data
          }
        }
      }))
    }else if (arrayType === 'finding_pictures') {
      this.setState(prevState => ({
        ...prevState,
        disAssemblyCrud: {
          ...prevState.disAssemblyCrud,
          data: {
            ...prevState.disAssemblyCrud.data,
            finding_pictures: [...prevState.disAssemblyCrud.data.finding_pictures].map((item, index) => index === arrayIndex ? {
              ...item, description: data
            } :item)
          }
        }
      }))
    }else if (arrayType === 'attachments') {
      this.setState(prevState => ({
        ...prevState,
        tableInspection: {
          ...prevState.tableInspection,
          shop_visit: {
            ...prevState.tableInspection.shop_visit,
            attachments: [...prevState.tableInspection.shop_visit.attachments].map((item, index) => index === arrayIndex ? {
              ...item,
              description: data
            } :item )
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        tableInspection: {
          ...prevState.tableInspection,
          [arrayType]: {
            ...prevState.tableInspection[arrayType],
            [keyParam]: data
          }
        }
      }))
    }
  }
  toggleModalFn = (data) => {
    this.setState({disAssemblyCrud: data});
  }
  addWorkscopeModuleFn = () => {
    this.setState(prevState => ({
      ...prevState,
      tableInspection: {
        ...prevState.tableInspection,
        general_details: {
          ...prevState.tableInspection.general_details,
          workscope_modules: [...prevState.tableInspection.general_details.workscope_modules, {
            name: '',
            subModule: [
              {
                name:'',
                items:[
                  {
                    moduleNo: '',
                    moduleDescription: '',
                    initialLevel: '',
                    finalLevel:''
                  }
                ]
              }
            ]
          }]
        }
      }
    }))
  }
  addSBWorkscopeModuleFn = (arrayIndex) => {
    this.setState(prevState => ({
      ...prevState,
      tableInspection: {
        ...prevState.tableInspection,
        general_details: {
          ...prevState.tableInspection.general_details,
          workscope_modules: [...prevState.tableInspection.general_details.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
            ...majorMod, subModule: [...majorMod.subModule, {
              name:'',
              items:[{
                moduleNo: '',
                moduleDescription: '',
                initialLevel: '',
                finalLevel:''
              }]
            }]
          }:majorMod)
        }
      }
    }))
  }
  addWorkScopeItem = (arrayIndex, innerIndex) => {
    this.setState(prevState => ({
      ...prevState,
      tableInspection: {
        ...prevState.tableInspection,
        general_details: {
          ...prevState.tableInspection.general_details,
          workscope_modules:[...prevState.tableInspection.general_details.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
            ...majorMod, subModule: majorMod.subModule.map((sb, childIndex) => childIndex === innerIndex ? {...sb, items:[...sb.items, {
              moduleNo: '',
              moduleDescription: '',
              initialLevel: '',
              finalLevel:''
            }
            ]} : sb)
          }:majorMod)
        }
      }
    }))
  }
  updateWorkScope = (event, keyParam, data, type, arrayIndex, innerArrayIndex, lastArrayIndex) => {
    switch (type) {
      case 'majorModule':
        this.setState(prevState => ({
          ...prevState,
          tableInspection: {
            ...prevState.tableInspection,
            general_details: {
              ...prevState.tableInspection.general_details,
              workscope_modules: [...prevState.tableInspection.general_details.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
                ...majorMod,
                [keyParam]:data
              } : majorMod)
            }
          }
        }))
        break;
      case 'childModule':
        this.setState(prevState => ({
          ...prevState,
          tableInspection: {
            ...prevState.tableInspection,
            general_details: {
              ...prevState.tableInspection.general_details,
              workscope_modules: [...prevState.tableInspection.general_details.workscope_modules].map((majorMod,index) => index == arrayIndex ? {
                ...majorMod,
                subModule:[...majorMod.subModule].map((childModule,childIndex) => childIndex === innerArrayIndex ? {
                  ...childModule,
                  [keyParam]:data
                } :childModule)
              } : majorMod)
            }
          }
        }))
        break;
      case 'itemModule':
        this.setState(prevState => ({
          ...prevState,
          tableInspection: {
            ...prevState.tableInspection,
            general_details: {
              ...prevState.tableInspection.general_details,
              workscope_modules: [...prevState.tableInspection.general_details.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
                ...majorMod,
                subModule: [...majorMod.subModule].map((childModule,childIndex) => childIndex === innerArrayIndex ? {
                  ...childModule,
                  items: [...childModule.items].map((itemData,itemIndex) => itemIndex === lastArrayIndex ? {
                    ...itemData,
                    [keyParam]:data
                  } :itemData)
                } :childModule)
              } :majorMod)
            }
          }
        }))
        break;
      default:

    }
  }

  delWorkScopeItem = (arrayIndex, innerArrayIndex, lastArrayIndex) => {
    const workscope_modules = Object.assign([], this.state.tableInspection.general_details.workscope_modules);
    if(workscope_modules[arrayIndex].subModule.length === 1 && workscope_modules[arrayIndex].subModule[innerArrayIndex].items.length === 1){
      workscope_modules.splice(arrayIndex,1);
      this.setState(prevState => ({
        ...prevState,
        tableInspection: {
          ...prevState.tableInspection,
          general_details: {
            ...prevState.tableInspection.general_details,
            workscope_modules: workscope_modules
          }
        }
      }))
    }else if (workscope_modules[arrayIndex].subModule[innerArrayIndex].items.length==1) {
      workscope_modules[arrayIndex].subModule.splice(innerArrayIndex,1);
      this.setState(prevState => ({
        ...prevState,
        tableInspection: {
          ...prevState.tableInspection,
          general_details: {
            ...prevState.tableInspection.general_details,
            workscope_modules: workscope_modules
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        tableInspection:{
          ...prevState.tableInspection,
          general_details: {
            ...prevState.tableInspection.general_details,
            workscope_modules: [...prevState.tableInspection.general_details.workscope_modules].map((majorMod,index) => index === arrayIndex ? {
              ...majorMod,
              subModule: majorMod.subModule.map((sb, childIndex) => {
                let subLength=[...sb.items];
                return childIndex == innerArrayIndex ? {...sb, ...sb.items.splice(lastArrayIndex,1)} :sb;
              })
            } : majorMod)
          }
        }
      }))
    }
  }
  handleError = (value, key) => {
    this.setState((prevState)=> ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]
      }
    }))
  }
  updateSortInpsection = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      ...prevState,
      disAssemblyCrud: {
        ...prevState.disAssemblyCrud,
        data: {
          ...prevState.disAssemblyCrud.data,
          finding_pictures: arrayMoveImmutable(prevState.disAssemblyCrud.data.finding_pictures, oldIndex, newIndex)
        }
      }
    }))
  }
  updateSlider = (images, index) => {
    this.setState(prevState => ({
      ...prevState,
      sliderInfo: {
        ...prevState.sliderInfo,
        modal:true,
        images: images.map(item => {return {title:'test', original:item.image, thumbnail:item.image}}),
        startIndex:index
      }
    }))
  }
  render(){
    const { inspectionHdInfo, tabIndex, tableInspection, disAssemblyCrud, deleteModal, deleteIds, sliderInfo, imgAnnotation, error, skeletonLoader, pageLoader } = this.state;
    return(
      <div className="technical-engine-forms">
        <InspectionFormWrapper
          saveButton={true}
          skeletonLoader={skeletonLoader}
          inspectionHdInfo={inspectionHdInfo}
          onSaveChanges={() => this.editTableInspectionApi(this.props, tableInspection)}
          changeFormStatusFn={(data) => this.changeFormStatusApi(this.props, data)}
          files={[{title:'PDF', extension: 'pdf', key:''}]}
          exportReportFn={(file) => this.exportTableInspectionApi(this.props, file)}
        />
        <h4 className="gen-detail-title">General Details <ReadPrerequisite content={<PrerequisiteContent />} /></h4>
        <GeneralDetail
          inspectionHdInfo={inspectionHdInfo}
          generalDetail={tableInspection.general_details}
          onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'general_details')}
          error={error.general_details}
          handleError={this.handleError}
          resetErrorKey={(key) => {
            this.setState(prevState => ({
              ...prevState,
              error: {
                ...prevState.error,
                [key]: ''
              }
            }))
          }}
        />
        <div>
          <h4 className="gen-detail-title">Last Shop Visit <Link to={`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/${this.props.match.params.workOrderSlug}/forms/${this.props.match.params.formSlug}/te004`} className="sv-list-link">View all Shop Visits</Link></h4>
          <Paper style={{padding: '20px'}}>
            <ShopVisitCard
              inspectionHdInfo={inspectionHdInfo}
              mode={'edit'}
              shopVisit={tableInspection.shop_visit}
              onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'shop_visit')}
              error={error}
            />
            { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
              <div className="text-right" style={{margin: '0 0 20px 0'}}>
                <div>
                  <input
                    accept="application/pdf"
                    id="contained-button-file"
                    type="file"
                    style={{display:'none'}}
                    onChange={(e) => this.generateSVAttachmentUrlApi(this.props, e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file">
                    <Button startIcon={<ControlPointIcon fontSize="small" color="primary" />} size="small" variant="outlined" color="primary" component="span">
                      Add Attachment
                    </Button>
                  </label>
                </div>
              </div>:null
            }
            <TableListComp
              heads={[
                { label: 'File Name', sortOption: false },
                { label: 'Description', sortOption: false },
                { label: 'Uploaded on', sortOption: false },
                { label: 'Uploaded by', sortOption: false },
                { label: 'Action', sortOption: false }
              ]}
              data={ tableInspection.shop_visit.attachments ? tableInspection.shop_visit.attachments.map((item,index) =>
                <Attachment
                  inspectionHdInfo={inspectionHdInfo}
                  onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'attachments', index)}
                  previewDetail={(mode) => {this.setState({deleteModal: true,deleteIds:{...item, deleteType:'attachments', deleteIndex:index}})}}
                  item={item} />
                ):null
              }
              noRecord={ tableInspection.shop_visit.attachments && tableInspection.shop_visit.attachments.length ? null:
                <EmptyCollection title="No records found" />
              }
            />
          </Paper>
        </div>
        <Tabs
          value={tabIndex}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={isMobile ? 'on':'auto'}
        >
          <Tab label="Executive Summary" />
          <Tab label="Disassembly Findings" />
          <Tab label="LLP Details" />
          <Tab label="Workscope" />
        </Tabs>
        { tabIndex === 0 &&
          <CkEditorText
            disabled = { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save?false:true}
            htmlData={tableInspection.general_details.executive_summary ? tableInspection.general_details.executive_summary:''}
            onChangeData={(data) => this.onFieldChange('', 'executive_summary', data, 'general_details')}
          />
        }
        { tabIndex === 1 &&
          <div className="">
            { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
              <div className="text-right" style={{margin: '0 0 20px 0'}}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  disableElevation={true}
                  onClick={() => this.toggleModalFn({data:{title:'', remarks:'', finding_pictures:[]}, modal:true, mode:''})}
                  startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                >
                  Add Finding
                </Button>
              </div>:null
            }
            <TableListComp
              heads={[
                { label: 'SI No.', sortOption: false },
                { label: 'Title', sortOption: false },
                { label: 'Description', sortOption: false },
                { label: 'Action', sortOption: false, actionCell: true }
              ]}
              data={tableInspection.disassembly_findings.map((item,index) =>
                <DisassemblyFinding
                  inspectionHdInfo={inspectionHdInfo}
                  index={index+1} item={item}
                  previewDetail={(mode) => { mode !== 'D' ? this.getDisassemblyApi(this.props, item.id, mode): this.setState({deleteModal: true, deleteIds:{...item, deleteType:'disassembly_findings'}}) }}
                />
              )}
              noRecord={ tableInspection.disassembly_findings.length ? null:
                <EmptyCollection title="No records found" />
              }
            />
          </div>
        }
        { tabIndex === 2 &&
          <div className="">
            { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ?
              <div className="text-right" style={{margin: '0 0 20px 0'}}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  disableElevation={true}
                  onClick={() => {this.addComponent('llp_details', llpDetail005)}}
                  startIcon={<ControlPointIcon fontSize="small" color="primary" />}
                >
                  Add Item
                </Button>
              </div>:null
            }
            <TableListComp
              heads={ inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ? [...te005LLPDetailHd, { label: 'Action', sortOption: false, actionCell: true }]:te005LLPDetailHd}
              data={tableInspection.llp_details.map((item,index) =>
                <LLPDetails
                  inspectionHdInfo={inspectionHdInfo}
                  item={item}
                  index={index+1}
                  error={error.llp_details.length && error.llp_details[index] ? error.llp_details[index]:{}}
                  onFieldChange={(event, keyParam, data) => this.onFieldChange(event, keyParam, data, 'llp_details', index)}
                  previewDetail={(mode) =>  this.setState({deleteModal: true, deleteIds:{...item, deleteType:'llp_details', deleteIndex: index}})}
                />
              )}
              noRecord={ tableInspection.llp_details.length ? null:
                <EmptyCollection title="No records found" />
              }
            />
            <h4 className="gen-detail-title" style={{marginTop:'10px'}}>LLP summary</h4>
            <CkEditorText
              disabled = { inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save?false:true}
              htmlData={tableInspection.general_details.llp_summary ? tableInspection.general_details.llp_summary:''}
              onChangeData={(data) => this.onFieldChange('', 'llp_summary', data, 'general_details')}
            />
          </div>
        }
        { tabIndex === 3 &&
          <Fragment>
            { tableInspection.general_details.workscope_modules ?
              <WorksScopeTable
                workScopes={tableInspection.general_details.workscope_modules}
                addWorkscopeModuleFn={this.addWorkscopeModuleFn}
                addSBWorkscopeModuleFn={this.addSBWorkscopeModuleFn}
                addWorkScopeItem={this.addWorkScopeItem}
                updateWorkScope={this.updateWorkScope}
                delWorkScopeItem={this.delWorkScopeItem}
                flag={ inspectionHdInfo.current_form && inspectionHdInfo.current_form.permissions.can_save ? true : false}
              /> :null
            }

          </Fragment>

        }
        { disAssemblyCrud.modal ?
          <AddEditDisassemblyFind
            inspectionHdInfo={inspectionHdInfo}
            disAssemblyCrud={disAssemblyCrud}
            toggleModalFn={() => this.toggleModalFn({data:{}, modal:false, mode:''})}
            onFieldChange={(event, keyParam, data, arrayType, arrayIndex) => this.onFieldChange(event, keyParam, data, arrayType, arrayIndex)}
            addEditDisassemblyFn={() => this.addEditDisassemblyApi(this.props, disAssemblyCrud.data)}
            attachFindingFn={(files) => this.attachFindingApi(this.props, files)}
            removeFindingImgFn={(item, index) => {this.setState({deleteModal:true, deleteIds:{...item, deleteType:'finding_pictures', deleteIndex: index}})}}
            updateSlider={(images, startIndex) => this.updateSlider(images, startIndex)}
            editImageFn={(item) => this.setState({imgAnnotation:{modal:true, data:item}})}
            updateSortInpsection={this.updateSortInpsection}
          />:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{}})}}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => { deleteIds.deleteType === 'llp_details' ? this.deleteLLPPartApi(this.props, deleteIds) : deleteIds.deleteType === 'disassembly_findings' ? this.deleteDisassemblyApi(this.props, deleteIds) : deleteIds.deleteType === 'finding_pictures' ? this.deleteDisassemblyFindingImgApi(this.props, deleteIds) : deleteIds.deleteType === 'attachments' ? this.deleteSVAttachmentApi(this.props, deleteIds) : this.deleteShopVisitApi(this.props, deleteIds)}}
        />
        { imgAnnotation.modal ?
          <EditImageDialog
            imgAnnotation={imgAnnotation}
            toggleModalFn={() => this.setState({imgAnnotation: {modal:false}})}
          />:null
        }
        { sliderInfo.modal ?
          <ImageSlider
            sliderInfo={sliderInfo}
            toggleModalFn={() => this.setState({sliderInfo:{modal:false, images:[], startIndex:''}})}
          />:null
        }
        { pageLoader ? <PageLoader/>:null}
      </div>
    )
  }
}
export default withSnackbar(withRouter(InspectionFormTE005))
